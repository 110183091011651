<template>
  <div class="container">
    <div class="input_span">
      <p>强度:</p>
      <span id="one"></span>
      <span id="two"></span>
      <span id="three"></span>
    </div>
    <div id="font">
      <span>弱</span>
      <span>中</span>
      <span>强</span>
    </div>
    <div class="tips">请至少输入 8 个字符。请不要使用容易被猜到的密码。</div>
    <div class="tips-caps-lock" v-if="!isIEBrowser && showCapsLockTips">大小写锁定已打开</div>
  </div>
</template>

<script>
import { isIE } from '@/utils/utils';

export default {
  name: 'PwdEE',
  props: {
    pwdee: {
      type: String,
      default: '',
    },
    showCapsLockTips: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const isIEBrowser = isIE();
    return { msgText: '', isIEBrowser };
    // 弱【0-50）、中【50-60）、强【60-100）
    // 先校验长度：大于8位（25），小于（10）
    // 字母：大小字母（20）、小写字母或大写字母（10）、无字母（0）
    // 数字：有数字（10）、有多个数字（20）、无数字（0）
    // 符号：有符号（10），有多个符号（20）无符号（0）
    // 其他：字母、数字和符号组合（5）
  },
  methods: {
    checkStrong(sValue) {
      let modes = 0;
      const len = sValue.length;
      // //正则表达式验证符合要求的
      // if (sValue.length < 1) return modes;
      // if (/\d/.test(sValue)) modes++; //数字
      // if (/[a-z]/.test(sValue)) modes++; //小写
      // if (/[A-Z]/.test(sValue)) modes++; //大写
      // if (/\W/.test(sValue)) modes++; //特殊字符

      // //逻辑处理
      // switch (modes) {
      //   case 1:
      //     return 1;
      //   case 2:
      //     return 2;
      //   case 3:
      //   case 4:
      //     return sValue.length < 4 ? 3 : 4;
      // }

      if (len < 1) modes = 0;
      if (len > 0 && len < 8) modes += 10;
      if (len >= 8) modes += 25;
      if (len >= 8 && /[a-z]/.test(sValue)) modes += 10; //小写
      if (len >= 8 && /[A-Z]/.test(sValue)) modes += 10; //大写
      if (len >= 8 && /\d/.test(sValue)) modes += 10; //数字
      if (len >= 8 && /\W/.test(sValue)) modes += 10; //特殊字符

      return modes;
    },
  },
  components: {},
  watch: {
    pwdee(newname) {
      this.msgText = this.checkStrong(newname);
      // if (this.msgText > 1 || this.msgText == 1) {
      //   document.getElementById('one').style.background = 'red';
      // } else {
      //   document.getElementById('one').style.background = '#eee';
      // }
      // if (this.msgText > 2 || this.msgText == 2) {
      //   document.getElementById('two').style.background = 'orange';
      // } else {
      //   document.getElementById('two').style.background = '#eee';
      // }
      // if (this.msgText == 4) {
      //   document.getElementById('three').style.background = '#00D1B2';
      // } else {
      //   document.getElementById('three').style.background = '#eee';
      // }
      if (document.getElementById('one') && document.getElementById('two') && document.getElementById('three')) {
        if (this.msgText >= 10 && this.msgText <= 100) {
          document.getElementById('one').style.background = 'red';
        } else {
          document.getElementById('one').style.background = '#eee';
        }

        if (this.msgText >= 50 && this.msgText <= 100 && document.getElementById('two')) {
          document.getElementById('two').style.background = 'orange';
        } else {
          document.getElementById('two').style.background = '#eee';
        }

        if (this.msgText >= 60 && this.msgText <= 100 && document.getElementById('three')) {
          document.getElementById('three').style.background = '#00D1B2';
        } else {
          document.getElementById('three').style.background = '#eee';
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  .input_span {
    p {
      font-size: $fontSize_14;
    }
  }
  .tips {
    font-size: $fontSize_14;
  }

  .tips-caps-lock {
    font-size: $fontSize_14;
    color: $warningColor;
    padding-top: 5px;
  }
}
#inputValue {
  width: 240px;
  margin-left: 20px;
  padding-left: 10px;
  border-radius: 3px;
}
.input_span span {
  display: inline-block;
  width: 80px;
  height: 10px;
  background: #eee;
  line-height: 20px;
}

#one {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-right: 0px solid;
}

#two {
  border-left: 0px solid;
  border-right: 0px solid;
  // margin-left: -5px;
  // margin-right: 3px;
}

#three {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-left: 0px solid;
  // margin-left: -5px;
}
#font {
  font-size: $fontSize_14;

  span:nth-child(1) {
    color: red;
    margin-left: 40px;
  }
  span:nth-child(2) {
    color: orange;
    margin: 0 60px;
  }
  span:nth-child(3) {
    color: #00d1b2;
  }
}
</style>
