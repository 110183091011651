// 商品管理 权限路由
const commodityManagementRoutes = [
  // 商品配置 新增编辑
  {
    path: '/commodityManagement/commodityConfig/addOrEdit',
    name: 'historyInventory',
    component: (resolve) => require(['@/views/Storage/CommodityManagement/commodityConfig/addOrEdit.vue'], resolve),
    meta: {
      title: '商品新增/编辑',
      layout: 'ProductLayout',
      hidden: true,
      activeMenu: '/commodityManagement',
      parentMenu: '/commodityManagement/commodityConfig',
      authority: ['NO-AUTHORITY'],
    },
  },
  // 商品类别管理 新增编辑
  {
    path: '/commodityManagement/categoryManagement/addOrEdit',
    component: (resolve) => require(['@/views/Storage/CommodityManagement/categoryManagement/addOrEdit.vue'], resolve),
    meta: {
      title: '商品类别新增/编辑',
      layout: 'ProductLayout',
      hidden: true,
      activeMenu: '/commodityManagement',
      parentMenu: '/commodityManagement/categoryManagement',
      authority: ['NO-AUTHORITY'],
    },
  },
  // 存货企业新增/编辑
  {
    path: '/commodityManagement/inventoryEnterprise/addOrEdit',
    component: (resolve) => require(['@/views/Storage/CommodityManagement/inventoryEnterprise/addOrEdit.vue'], resolve),
    meta: {
      title: '存货企业新增/编辑',
      layout: 'ProductLayout',
      hidden: true,
      activeMenu: '/commodityManagement',
      parentMenu: '/commodityManagement/inventoryEnterprise',
      authority: ['NO-AUTHORITY'],
    },
  },
  // 关联商品
  {
    path: '/commodityManagement/inventoryEnterprise/associatedGoods',
    component: (resolve) => require(['@/views/Storage/CommodityManagement/inventoryEnterprise/associatedGoods.vue'], resolve),
    meta: {
      title: '关联商品',
      layout: 'ProductLayout',
      hidden: true,
      activeMenu: '/commodityManagement',
      parentMenu: '/commodityManagement/inventoryEnterprise',
      authority: ['NO-AUTHORITY'],
    },
  },
];

export default commodityManagementRoutes;
