// 基本信息管理
const basicInfoManagementRoutes = [
  {
    path: '/basicInfoManagement/whiteList/addOrEdit',
    name: 'repaymentApplication',
    component: () =>
      import(
        /* webpackChunkName: "basicInfoManagement" */ '@/views/Agency/Financial/BasicInfoManagement/whiteList/addOrEdit.vue'
      ),
    meta: {
      title: '白名单管理',
      icon: 'dashboard',
      activeMenu: '/basicInfoManagement',
      parentMenu: '/basicInfoManagement/whiteList',
      layout: 'ProductLayout',
      class: 'menu-level-first',
      corpIdentity: ['FINANCIAL_ENTERPRISE'],
      authority: ['NO-AUTHORITY'],
    },
  },
];

export default basicInfoManagementRoutes;
