<template>
  <el-scrollbar wrap-class="home-container" ref="scrollHomeRef">
    <div class="home-layout">
      <div class="top-fixed" v-if="$route.path !== '/prompt'" :style="{ boxShadow: visible ? '0 2px 18px 0 rgba(0, 0, 0, 0.20)' : 'unset' }">
        <section class="navbar horizontalNavbar">
          <div class="home-layout-nav">
            <div class="nav-left-nav"><LeftNav /></div>
            <div class="nav-right-menu"><RightMenu /></div>
          </div>
        </section>
      </div>
      <div>
        <section class="main-section">
          <transition name="fade-transform" mode="out-in" @after-enter="afterEnter">
            <keep-alive>
              <router-view />
            </keep-alive>
          </transition>
          <!-- <BackTop /> -->
        </section>
      </div>
      <div class="fixed-footer">
      <Copyright />
      </div>
    </div>
  </el-scrollbar>
</template>

<script>
import LeftNav from '../components/Navbar/LeftNav/index.vue';
import RightMenu from '../components/Navbar/RightMenu/index.vue';
import Copyright from './Copyright.vue';

export default {
  name: 'RegisterLayout',
  data() {
    return {
      visible: false,
    };
  },
  components: {
    LeftNav,
    RightMenu,
    Copyright,
  },
  computed: {
    key() {
      return this.$route.path;
    },
  },
  methods: {
    afterEnter() {
      // 门户路由切换滚动到页面顶端
      document.querySelector('.home-container.el-scrollbar__wrap') &&
        (document.querySelector('.home-container.el-scrollbar__wrap').scrollTop = 0);
    },
  },
};
</script>

<style lang="scss" scoped>
@import './index.scss';

.el-scrollbar {
  height: 100vh;
  width: 100%;
  position: relative;
  overflow: hidden;

  ::v-deep .home-container {
    overflow-x: hidden;
    overflow-y: auto;
  }
  .main-section {
    min-height: calc(100vh - 250px);
    // background-color: #fff;
  }
}
.fixed-footer{
  position: absolute;
  bottom: 0px;
  width: 100%;
}
</style>
