<template>
  <el-table
    ref="elTable"
    :key="renderTableKey"
    :row-key="rowKey"
    :tree-props="treeProps"
    :data="tableData"
    :size="size"
    :id="id"
    :stripe="stripe"
    :fit="fit"
    :border="isBorder"
    :show-header="showHeader"
    header-row-class-name="zx-table-header"
    :header-cell-style="headerCellStyle"
    :row-class-name="tableRowClassName"
    highlight-current-row
    :show-summary="showSummary"
    :sum-text="sumText"
    :defaultSelections="defaultSelections"
    v-loading="loading"
    element-loading-text="loading..."
    @cell-mouse-enter="cellMouseEnter"
    @cell-mouse-leave="cellMouseLeave"
    @selection-change="handleSelectionChange"
    @select="select"
    @select-all="selectAll"
    @cell-click="cellClick"
  >
    <el-table-column v-if="isSelection" :width="tableSelectionWidth" type="selection" align="center" />
    <el-table-column v-if="isIndex" type="index" :width="indexLabelWidth" :label="indexLabel" align="center" />
    <el-table-column
      v-for="(item, index) in tableCols"
      :key="index"
      :prop="item.prop"
      :fixed="item.fixed"
      :label="item.label"
      :min-width="!showWidth ? item.width : 'auto'"
      :width="showWidth ? item.width : 'auto'"
      :align="item.align"
    >
      <template slot-scope="scope">
        <slot v-if="item.eType === 'slot'" :name="item.slotName" :data="{ ...scope.row, ...item }"> </slot>
        <el-input
          v-if="item.eType === 'Input' || (scope.row.isEdit && item.eType !== 'Button' && item.rowEditType === 'Input')"
          v-model.trim="scope.row[item.prop]"
          :size="item.size || size"
          :disabled="item.disabled || (item.isDisabled && item.isDisabled(scope.row))"
          @focus="item.focus && item.focus(scope.row)"
          @blur="item.blur && item.blur(scope.row)"
        >
        </el-input>
        <el-select
          v-if="
            item.eType === 'Select' || (scope.row.isEdit && item.eType !== 'Button' && item.rowEditType === 'Select')
          "
          v-model="scope.row[item.prop]"
          :placeholder="item.placeholder"
          :size="item.size || size"
          :disabled="item.disabled || (item.isDisabled && item.isDisabled(scope.row))"
          @focus="item.focus && item.focus(scope.row)"
          @blur="item.blur && item.blur(scope.row)"
        >
          <el-option v-for="option in item.options" :key="option.value" :label="option.label" :value="option.value">
          </el-option>
        </el-select>
        <el-tag
          v-if="item.eType === 'Tag'"
          size="medium"
          :type="(item.formatter(scope.row) && item.formatter(scope.row).type) || ''"
          >{{ (item.formatter(scope.row) && item.formatter(scope.row).label) || '' }}</el-tag
        >
        <!-- 按钮数量小于等于2个 -->
        <template v-if="item.eType === 'Button' && (item.formatter(scope.row).length <= 2 || item.btnList <= 2)">
          <template v-for="(btn, btnIndex) in item.formatter(scope.row) || item.btnList">
            <el-popconfirm
              trigger="click"
              :ref="`popover-${scope.$index}`"
              :key="btnIndex"
              :tabindex="btnIndex"
              v-if="btn.showPopConfirm"
              :append-to-body="true"
              :class="btnIndex === 1 ? 'table-popover-right-btn' : ''"
              :confirm-button-text="btn.confirmText || '确定'"
              :cancel-button-text="btn.cancelText || '取消'"
              icon="el-icon-info"
              icon-color="#1f71ff"
              placement="top-end"
              popper-class="table-button-pop"
              :title="btn.title"
              @confirm="btn.handle(scope.row, 'popConfirm')"
              @cancel="btn.handle(scope.row, 'popCancel')"
            >
              <el-button
                slot="reference"
                :disabled="btn.disabled || (btn.isDisabled && btn.isDisabled(scope.row))"
                :type="btn.type"
                :size="btn.size || size"
                :icon="btn.icon"
                :class="['table-pop-confirm', btn.leftMargin ? 'left-margin' : '']"
              >
                {{ btn.label }}
              </el-button>
            </el-popconfirm>
            <el-button
              v-else
              :key="btnIndex + '_' + index"
              :disabled="btn.disabled || (btn.isDisabled && btn.isDisabled(scope.row))"
              :type="btn.type"
              :size="btn.size || size"
              :icon="btn.icon"
              @click="btn.handle(scope.row)"
            >
              {{ btn.label }}
            </el-button>
          </template>
        </template>
        <!-- 按钮数量大于2个 -->
        <template v-if="item.eType === 'Button' && (item.formatter(scope.row).length > 2 || item.btnList > 2)">
          <template v-for="(btn, btnIndex) in slice(item.formatter(scope.row), 0, 1) || slice(item.btnList, 0, 1)">
            <el-popconfirm
              trigger="click"
              :ref="`popover-${scope.$index}`"
              :key="btnIndex"
              :tabindex="btnIndex"
              v-if="btn.showPopConfirm"
              :append-to-body="true"
              :confirm-button-text="btn.confirmText || '确定'"
              :cancel-button-text="btn.cancelText || '取消'"
              icon="el-icon-info"
              icon-color="#1f71ff"
              popper-class="table-button-pop"
              placement="top-end"
              :title="btn.title"
              @confirm="btn.handle(scope.row, 'popConfirm')"
              @cancel="btn.handle(scope.row, 'popCancel')"
            >
              <el-button
                slot="reference"
                :disabled="btn.disabled || (btn.isDisabled && btn.isDisabled(scope.row))"
                :type="btn.type"
                :size="btn.size || size"
                :icon="btn.icon"
                :class="['table-pop-confirm', btn.leftMargin ? 'left-margin' : '']"
              >
                {{ btn.label }}
              </el-button>
            </el-popconfirm>
            <el-button
              v-else
              :key="btnIndex + '_' + index"
              :disabled="btn.disabled || (btn.isDisabled && btn.isDisabled(scope.row))"
              :type="btn.type"
              size="medium"
              :icon="btn.icon"
              @click="btn.handle(scope.row)"
            >
              {{ btn.label }}
            </el-button>
          </template>
          <el-dropdown
            :tabindex="scope.$index"
            :ref="`dropdown-${scope.$index}`"
            trigger="click"
            :hide-on-click="false"
            @command="handleCommand"
            style="margin-left: 5px"
          >
            <el-button type="text" size="medium">
              &nbsp;&nbsp;更多<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="(btn, btnIndex) in slice(item.formatter(scope.row), 1, item.formatter(scope.row).length) ||
                slice(item.btnList, 1, item.btnList.length)"
                :key="btnIndex"
                :disabled="btn.disabled || (btn.isDisabled && btn.isDisabled(scope.row))"
                :command="{ btn, row: scope.row }"
              >
                <el-popconfirm
                  trigger="click"
                  :ref="`popover-${scope.$index}`"
                  :key="btnIndex"
                  :tabindex="btnIndex"
                  v-if="btn.showPopConfirm"
                  :append-to-body="true"
                  :confirm-button-text="btn.confirmText || '确定'"
                  :cancel-button-text="btn.cancelText || '取消'"
                  icon="el-icon-info"
                  icon-color="#1f71ff"
                  placement="top-end"
                  popper-class="table-button-pop"
                  :title="btn.title"
                  @confirm="btn.handle(scope.row, 'popConfirm')"
                  @cancel="btn.handle(scope.row, 'popCancel')"
                >
                  <el-button
                    slot="reference"
                    :disabled="btn.disabled || (btn.isDisabled && btn.isDisabled(scope.row))"
                    :type="btn.type"
                    :size="btn.size || size"
                    :icon="btn.icon"
                    :class="['table-pop-confirm', btn.leftMargin ? 'left-margin' : '']"
                  >
                    {{ btn.label }}
                  </el-button>
                </el-popconfirm>
                <el-button
                  v-else
                  :key="btnIndex + '_' + index"
                  :disabled="btn.disabled || (btn.isDisabled && btn.isDisabled(scope.row))"
                  :type="btn.type"
                  size="medium"
                  :icon="btn.icon"
                >
                  {{ btn.label }}
                </el-button>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
        <template v-if="item.eType === 'Div'">
          <el-tooltip :tabindex="index" popper-class="table-tooltip" effect="dark" placement="top-start">
            <div slot="content" v-html="(item.formatter && item.formatter(scope.row)) || scope.row[item.prop]"></div>
            <div
              style="white-space: nowrap; word-break: break-all; text-overflow: ellipsis"
              v-html="(item.formatter && item.formatter(scope.row)) || scope.row[item.prop]"
            ></div>
          </el-tooltip>
        </template>
        <template v-if="item.eType === 'Status'">
          <el-tooltip :tabindex="index" popper-class="table-tooltip" effect="dark" placement="top-start">
            <div slot="content" v-html="(item.formatter && item.formatter(scope.row)) || scope.row[item.prop]"></div>
            <div style="display: flex; align-items: center">
              <div
                class="statusColor"
                :style="`width: 6px; height: 6px;border-radius: 50%;margin-right: 5px;margin-bottom: 2px;background:${
                  (item.formatter && item.formatter(scope.row)) === '已读' ? '#52c41a  ' : '#f5222d'
                }`"
              ></div>
              <span style="white-space: nowrap; word-break: break-all; text-overflow: ellipsis">
                {{ item.formatter && item.formatter(scope.row) }}
              </span>
            </div>
          </el-tooltip>
        </template>
        <template v-if="item.eType === 'Switch'">
          <el-switch
            :value="(item.formatter && item.formatter(scope.row)) || scope.row[item.prop]"
            active-color="#1f71ff"
            inactive-color="#DCDFE6"
          >
          </el-switch>
        </template>
        <el-tooltip :tabindex="index" popper-class="table-tooltip" effect="dark" placement="top-start">
          <div slot="content" v-text="(item.formatter && item.formatter(scope.row)) || scope.row[item.prop]"></div>
          <!-- <span
            v-if="!item.eType && !scope.row.isEdit"
            style="white-space: nowrap; word-break: break-all; text-overflow: ellipsis"
          >
            {{ (item.formatter && item.formatter(scope.row)) || scope.row[item.prop] }}
          </span> -->
          <span v-if="!item.eType" style="white-space: nowrap; word-break: break-all; text-overflow: ellipsis">
            {{ (item.formatter && item.formatter(scope.row)) || scope.row[item.prop] }}
          </span>
        </el-tooltip>
      </template>
    </el-table-column>
    <template v-slot:empty>
      <el-empty :image-size="100"></el-empty>
    </template>
  </el-table>
</template>

<script type="text/ecmascript-6">
import customTableMixin from '@/mixins/customTableMixin';
import { slice, throttle } from 'lodash';

export default {
  name: 'zxTable',
  mixins: [customTableMixin],
  data() {
    return {
      slice,
      showWidth: true,
    };
  },
  watch: {
    tableCols: {
      deep: true,
      immediate: true,
      handler(list) {
        const pageResize = () => {
          this.$nextTick(() => {
            // 整个表格的宽度
            const tableWidth = this.$refs.elTable && this.$refs.elTable?.$el?.clientWidth;
            // 设置的所有宽度总和
            const allListWidth = list?.reduce((total, current) => (total += Number(current.width)), 0);
            if (allListWidth && tableWidth > allListWidth) {
              this.showWidth = false;
              return;
            }
            this.showWidth = true;
          });
        };
        window.removeEventListener('resize', () => {});
        pageResize();
        window.addEventListener('resize', throttle(pageResize, 300));
      },
    },
    defaultSelections: {
      deep: true,
      immediate: true,
      handler(val) {
        this.$nextTick(() => {
          if (val?.length !== 0) {
            val.forEach((row) => {
              this.$refs.elTable && this.$refs.elTable.toggleRowSelection(row);
            });
          } else {
            this.$refs.elTable && this.$refs.elTable.clearSelection();
          }
        });
      },
    },

    clearAllselect(val) {
      if (val) {
        this.$refs.elTable && this.$refs.elTable.clearSelection();
      }
    },
    setChangeSelectList(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.elTable && this.$refs.elTable.toggleRowSelection(row);
        });
      }
    },
  },
  updated() {
    this.$nextTick(() => {
      this.$refs.elTable && this.$refs.elTable.doLayout(); // 解决表格错位
    });
  },
  methods: {
    // 手动是设置表格全选
    toggleAllSelection() {
      this.$refs.elTable && this.$refs.elTable.toggleAllSelection();
    },

    // 手动清除所有的已选中的表格
    clearSelection() {
      this.$refs.elTable && this.$refs.elTable.clearSelection();
    },

    // 手动设置勾选表格
    toggleRowSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.elTable && this.$refs.elTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.elTable && this.$refs.elTable.clearSelection();
      }
    },

    // 手动勾选表格
    select(rows, row) {
      if (this.isSingle) {
        this.$refs.elTable.clearSelection();
        this.$refs.elTable.toggleRowSelection(row);
        this.$emit('select', row);
      } else {
        this.$emit('select', rows, row);
      }
    },
    // 手动勾选全选表格
    selectAll(rows) {
      if (this.isSingle) {
        this.$refs.elTable.clearSelection();
        this.$message({ type: 'error', message: '只支持单选' });
      } else {
        this.$emit('selectAll', rows);
      }
    },

    // 行点击事件
    cellClick(row, column, cell, event) {
      this.$emit('cellClick', row, column, cell, event);
    },

    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex;
      if (this.tableRowClassNames) {
        return this.tableRowClassNames(row);
      }
    },

    //  获取选中
    handleSelectionChange(val) {
      this.$emit('getAllSelect', val);
    },

    // table中更多菜单中的按钮点击事件
    handleCommand(command) {
      const { btn, row } = command;
      btn.handle(row);
    },
  },
};
</script>

<style scoped lang="scss">
@import './zxTable.scss';
</style>
