<template>
  <CustomCard
    :dividerTitle="'进度信息'"
    labelPosition="top"
    :showFormLineButtons="false"
    :tabHidden="false"
    :formLineButtons="[]"
    :formData="formData"
    :formCols="formCols"
    @event="watchAllEvent"
  >
  </CustomCard>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { getStorage } from '@/utils/storage';

export default {
  name: 'processInfo',
  props: {
    showFooter: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState('serviceProgressQuery', ['processLogDetail']),
    formCols() {
      const processCols = [
        [
          {
            eType: 'Span',
            label: '申请编号',
            prop: 'txNo',
            span: 8,
          },
          {
            eType: 'Span',
            label: '业务编号',
            prop: 'bussNo',
            span: 8,
          },
          {
            eType: 'Span',
            label: '业务名称',
            prop: 'processName',
            span: 8,
          },
        ],
        [
          {
            eType: 'Span',
            label: '业务状态',
            prop: 'businessStatusDesc',
            span: 8,
          },
          {
            eType: 'Span',
            label: '业务环节',
            prop: 'userTaskDesc',
            span: 8,
          },
          {
            eType: 'Span',
            label: '业务开始日期',
            prop: 'bussStartDate',
            span: 8,
          },
        ],
        [
          {
            eType: 'Span',
            label: '业务结束日期',
            prop: 'bussEndDate',
            span: 8,
          },
          ...(this.formData?.businessStatusVal === 'REJECTED'
            ? [
                {
                  eType: 'Span',
                  label: '拒绝原因',
                  prop: 'approvalDesc',
                  span: 8,
                },
              ]
            : []),
        ],
        [
          ...(this.showFooter
            ? [
                {
                  eType: 'Button',
                  class: 'register-button',
                  value: '返回列表',
                  prop: 'goBack',
                  offset: 11,
                  span: 2,
                },
              ]
            : []),
        ],
      ];
      return processCols;
    },
  },

  async mounted() {
    const { txNo } = getStorage(this.$route.path) || {};
    await this.selectLogByTxNo({ txNo });
    if (this.processLogDetail) {
      const { bussStartDate, bussEndDate } = this.processLogDetail;
      // 业务进度信息
      this.formData = {
        ...this.processLogDetail,
        bussStartDate: (bussStartDate && this.formattedDate(bussStartDate,'YYYY-MM-DD HH:mm:ss')) || '暂无数据',
        bussEndDate: (bussEndDate && this.formattedDate(bussEndDate,'YYYY-MM-DD HH:mm:ss')) || '暂无数据',
      };
    }
  },
  data() {
    return {
      formData: {
        txNo: '',
        bussNo: '',
        processName: '',
        businessStatusDesc: '',
        userTaskDesc: '',
        bussStartDate: '',
        bussEndDate: '',
        approvalDesc: '',
      },
    };
  },
  methods: {
    ...mapActions('serviceProgressQuery', ['selectLogByTxNo']),
    watchAllEvent(e) {
      const { prop } = e;
      switch (prop) {
        case 'goBack':
          this.$router.push('/comprehensiveQuery/serviceProgressQuery');
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
