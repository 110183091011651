var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("el-switch", {
    style: _vm.item.style,
    attrs: {
      width: _vm.item.width,
      "active-color": _vm.item.activeColor,
      "inactive-color": _vm.item.inactiveColor,
      "active-value": _vm.item.activeValue,
      "inactive-value": _vm.item.inactiveValue,
      "active-text": _vm.item.activeText,
      "inactive-text": _vm.item.inactiveText,
      disabled: _vm.item.disabled,
    },
    on: { change: _vm.change },
    model: {
      value: _vm.formData[_vm.item.prop],
      callback: function ($$v) {
        _vm.$set(_vm.formData, _vm.item.prop, $$v)
      },
      expression: "formData[item.prop]",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }