// 计费管理 权限路由
const billingManagement = [
  // 手续费费用 权限路由
  {
    path: "/billingManagement/handlingFees/addOrEdit",
    component: (resolve) =>
      require(["@/views/WarehouseReceipt/BillingManagement/handlingFees/addOrEdit"], resolve),
    hidden: false,
    meta: {
      title: "手续费费用修改/详情",
      activeMenu: '/financingManagement',
      parentMenu: '/billingManagement/handlingFees',
      authority: ["NO-AUTHORITY"],
    },
  },
];

export default billingManagement;
