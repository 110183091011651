// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
/* Variables */
[data-v-e0c16b7c]:export {
  menuText: #000;
  menuActiveText: #fff;
  subMenuActiveText: #f4f4f5;
  menuActiveBg: #1589cd;
  menuBg: #fff;
  menuHover: rgba(45, 153, 255, 0.3);
  subMenuBg: #fff;
  subMenuHover: rgba(45, 153, 255, 0.3);
  sideBarWidth: 200px;
  primaryColor: #1589cd;
  primaryFontColor: rgb(45, 153, 255);
  primaryColor_8: rgba(31, 113, 255, 0.08);
  globalColor: #e60012;
  defaultFontColor: rgb(20, 35, 69);
  tableHeaderBg: #fafafa;
  tableHeaderHeight: 40px;
  tableAlign: center;
  fontSize_14: 14px;
  fontSize_16: 16px;
  fontSize_18: 18px;
  fontSize_24: 24px;
}

/* Mixins */
.el-upload[data-v-e0c16b7c] {
  display: inline-block;
  text-align: center;
  cursor: pointer;
  outline: none;
}
.upload-button[data-v-e0c16b7c] .el-upload {
  text-align: left;
}
[data-v-e0c16b7c] .el-dialog__body {
  display: flex;
  justify-content: center;
}
[data-v-e0c16b7c] .el-upload-dragger {
  background-color: #fff;
  border-radius: 6px;
  box-sizing: border-box;
  text-align: center;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 260px;
  height: 100%;
}
[data-v-e0c16b7c] .el-icon-upload {
  font-size: 78px;
  color: #c0c4cc;
  margin: 10px 0;
  line-height: 52px;
  color: rgba(31, 113, 255, 0.5);
}
[data-v-e0c16b7c] .el-upload--picture-card {
  border: none;
}
[data-v-e0c16b7c] .el-upload__tip {
  font-size: 12px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.65);
  white-space: pre-wrap;
  text-align: center;
}
[data-v-e0c16b7c] .el-upload-dragger ~ .el-upload__files {
  border-top: 1px solid #dcdfe6;
  margin-top: 7px;
  padding-top: 5px;
}
[data-v-e0c16b7c] .el-upload-dragger .el-upload__text {
  color: #606266;
  font-size: 14px;
  text-align: center;
}
[data-v-e0c16b7c] .el-upload-dragger .el-upload__text em {
  color: #409eff;
  font-style: normal;
}
[data-v-e0c16b7c] .el-upload-dragger:hover {
  border-color: #409eff;
}
[data-v-e0c16b7c] .el-upload-dragger.is-dragover {
  background-color: rgba(32, 159, 255, 0.06);
  border: 2px dashed #409eff;
}
.common-picture-card[data-v-e0c16b7c] {
  width: 260px;
  height: 100%;
  border-radius: 6px;
  overflow: hidden;
}
.common-picture-card[data-v-e0c16b7c]:hover {
  border-color: #409eff;
}
.common-picture-card[data-v-e0c16b7c] .el-upload__tip {
  margin-top: -50px;
}
.common-picture-card[data-v-e0c16b7c] .el-upload__text {
  margin-top: -100px;
}
.common-picture-card[data-v-e0c16b7c] .el-upload-list__item {
  width: 260px;
  height: 160px;
}
.common-picture-card[data-v-e0c16b7c] .el-upload-list__item-status-label {
  display: none;
}
.common-picture-card[data-v-e0c16b7c] .el-form-item__error {
  margin-top: 5px;
}
.common-picture[data-v-e0c16b7c] {
  width: 260px;
  height: 100%;
  border-radius: 6px;
  overflow: hidden;
}
.common-picture[data-v-e0c16b7c]:hover {
  border-color: #409eff;
}
.common-picture[data-v-e0c16b7c] .el-upload__tip {
  margin-top: -50px;
}
.common-picture[data-v-e0c16b7c] .el-upload__text {
  margin-top: -100px;
}
.common-picture[data-v-e0c16b7c] .el-upload-list__item-status-label {
  display: none;
}
.common-picture[data-v-e0c16b7c] .el-upload-list__item {
  width: 260px;
  height: 160px;
}
.common-picture[data-v-e0c16b7c] .el-form-item__error {
  margin-top: 5px;
}
.common-picture[data-v-e0c16b7c] .el-upload-dragger {
  background-color: #fff;
  border-radius: 6px;
  box-sizing: border-box;
  text-align: center;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.disUoloadSty[data-v-e0c16b7c] .el-upload--picture-card {
  display: none !important; /* 上传按钮隐藏 */
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
