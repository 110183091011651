var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("VueJsonTree", {
    staticClass: "img",
    attrs: {
      path: _vm.item.path,
      data: _vm.item.data,
      selectableType: _vm.item.selectableType,
      showSelectController: _vm.item.showSelectController,
      deep: _vm.item.deep,
    },
    on: { click: _vm.click },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }