<!-- 资产穿透 -->
<template>
  <el-drawer
    custom-class="pierce-through-drawer"
    title="资产穿透"
    :visible.sync="drawer"
    :direction="direction"
    size="80%"
    v-loading="loading"
  >
    <div v-if="data && data.length">
      <el-row v-for="(dataItem, dataIndex) in data" :key="dataIndex" type="flex">
        <template>
          <el-col :span="4">
            <div class="circle">
              <div class="outCircle">
                <div :class="`${showItem.includes(dataItem.typeCode) ? 'inCircle inCircleColor' : 'inCircle'}`">
                  {{ dataItem.typeName }}
                </div>
              </div>
            </div>
            <div :class="`${dataIndex + 1 === data.length ? '' : 'line'}`"></div>
          </el-col>
          <el-col :span="20" class="through-right-all">
            <el-row v-for="(dataItemV, dataItemK) in dataItem.items" :key="dataItemK">
              <div class="through-right">
                <div class="through-right-item">
                  <!-- 图标 -->
                  <div class="item-left" @click="showMore(dataItem, dataItemK)">
                    <svg-icon
                      class="iconfont top-svg-icon"
                      :icon-class="`${showItem === `${dataItem.typeCode}-${dataItemK}` ? 'arrowLeft' : 'arrowRight'}`"
                    />
                  </div>
                  <!-- 列表 -->
                  <el-row class="item-right-all">
                    <el-col
                      :span="item.label === 'fabrictxId' ? 16 : 8"
                      class="item-right"
                      v-for="(item, index) in dataItem.items && dataItemV"
                      :key="index"
                    >
                      <div class="item-right-row">
                        <div class="item-right-row-div">
                          <span class="span-left">{{ item.name }}</span> :
                          <span v-if="item.label !== 'bcstat'" class="span-right" :title="item.value"
                            >{{ item.value }}
                          </span>
                          <span v-else :class="`${item.url ? 'linkStyle' : ''}`" @click="linkPush(item.url)">
                            {{ item.value }}
                            <i class="el-icon-link" v-if="item.url"></i>
                          </span>
                        </div>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div v-if="dataItem.typeName !== '仓库实景'" class="through-right-detail">
                <!-- 接口二 -->
                <CustomForm
                  v-if="showItem === `${dataItem.typeCode}-${dataItemK}`"
                  ref="throughForm"
                  labelPosition="top"
                  :showFormLineButtons="false"
                  :formLineButtons="[]"
                  :formData="formData"
                  :formCols="formCols"
                  @event="handleDialogEvent"
                />
                <!-- 列表 -->
                <CustomTable
                  v-if="showItem === `${dataItem.typeCode}-${dataItemK}` && tableData.length > 0"
                  :tableData="tableData"
                  :tableCols="tableCols"
                  :loadData="updateData"
                  :isPagination="false"
                  :loading="loadingDetailList"
                />
              </div>
              <div v-if="dataItem.typeName === '仓库实景' && formDataImg.length > 0" class="formDataImg_Style">
                <el-row :gutter="0">
                  <el-col :span="12" v-for="(value, i) in formDataImg" :key="i">
                    <CustomForm
                      class="formDataImg_form"
                      v-if="showItem === `${dataItem.typeCode}-${dataItemK}`"
                      :ref="`throughForm${i}`"
                      labelPosition="left"
                      :showFormLineButtons="false"
                      :formLineButtons="[]"
                      :formData="value"
                      :formCols="formCols"
                      @event="handleDialogEvent"
                    />
                  </el-col>
                </el-row>
              </div>
            </el-row>
          </el-col>
        </template>
      </el-row>
    </div>
    <el-empty v-else :image-size="100" style="height: 100%"></el-empty>
    <ExampleDialog ref="exampleDialog" :pdfId="pdfId" :src="src" />
  </el-drawer>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { tableFixedWidth } from '@/utils/enums';
import { chunk } from 'lodash';
import { ExampleDialog } from '@/components';
import { isIE } from '@/utils/utils';

export default {
  name: 'PierceThrough',
  components: {
    ExampleDialog,
  },
  props: {
    direction: {
      type: String,
      default: 'rtl', // ltr-从左往右开  rtl-从右往左开  ttb-从上往下开   btt-从下往上开
    },
  },
  data() {
    return {
      pdfId: '',
      src: '',
      drawer: false,
      showItem: '', // 展开的单元: typeCode-dataItemK
      tableData: [],
      tableCols: [],
      formData: {},
      formDataImg: [], // gif数组
      formCols: [],
      requestDate: {},
    };
  },

  computed: {
    ...mapState('common', ['throughList', 'throughDetail']),
    ...mapState({
      loading: (state) => state.loading.effects['common/oq100015'],
      loadingDetailList: (state) => state.loading.effects['common/oq100033'],
    }),

    data() {
      return this.throughList || [];
    },

    dataInfo() {
      return this.throughDetail || {};
    },
  },

  watch: {
    drawer: {
      handler(val) {
        if (!val) {
          this.showItem = '';
          this.tableData = [];
          this.tableCols = [];
          this.formData = {};
          this.formDataImg = [];
          this.formCols = [];
          this.requestDate = {};
        }
      },
      deep: true,
      immediate: true,
    },
  },

  mounted() {
    if (isIE()) {
      this.isIEBrowser = true;
    }
  },

  methods: {
    ...mapActions('common', ['oq100015', 'oq100033']),

    // 定制tableCols
    tableColsItem(data) {
      let resData = [];
      data?.forEach((v, index) => {
        if (index === 0) {
          resData.push({
            ...v,
            label: v.title,
            prop: v.dataIndex,
            fixed: 'left',
            width: tableFixedWidth.left,
          });
        } else if (index + 1 === data.length) {
          resData.push({
            ...v,
            label: v.title,
            prop: v.dataIndex,
            fixed: 'right',
            width: tableFixedWidth.right,
          });
        } else {
          resData.push({
            ...v,
            label: v.title,
            prop: v.dataIndex,
            width: tableFixedWidth.medium,
          });
        }
      });
      this.tableCols = [...resData];
    },

    // 定制formCols
    formColsItem(data, span) {
      let resData = [];
      data?.forEach((v) => {
        let that = this;
        switch (true) {
          case v?.dataIndex === 'contractUrl':
            resData.push({
              ...v,
              eType: 'Link',
              label: v.title,
              prop: v.dataIndex,
              target: '_blank',
              href: v.dataIndex,
              span: span || 8,
              formatter: (item) => {
                this.href = item || '';
                let obj = that.formCols[0]?.find((v) => v.dataIndex === 'deviceName');
                obj.href = item;
              },
            });
            break;
          // 图片处理
          case v?.dataIndex === 'file_id':
          case v?.dataIndex === 'url':
          case v?.dataIndex.includes('screenshot'):
            resData.push({
              ...v,
              eType: 'ImagePdf',
              label: v.title,
              prop: v.dataIndex,
              span: span || 8,
              src: this.dataInfo.data[v.dataIndex],
            });
            break;
          default:
            resData.push({
              ...v,
              eType: 'Span',
              label: v.title,
              prop: v.dataIndex,
              span: span || 8,
            });
            break;
        }
      });
      this.formCols = chunk([...resData], 3);
    },

    async showMore(dataItem, dataItemK) {
      // 当前单元已展开，再次点击则关闭
      if (this.showItem && this.showItem === `${dataItem?.typeCode}-${dataItemK}`) {
        this.showItem = '';
        return;
      }

      // 查数据
      let list = dataItem?.items;
      let params = { ...this.requestDate, destOrderType: dataItem?.typeCode };
      if (this.requestDate.businessType === 2 || this.requestDate.businessType === 4) {
        let obj = list[dataItemK]?.find((v) => v.label === 'billno');
        params = {
          ...params,
          billNo: obj?.value,
        };
      } else {
        let obj = list[dataItemK]?.find((v) => v.label === 'billno');
        params = {
          ...params,
          businessNo: obj?.value,
        };
      }
      // 穿透详情
      await this.oq100033({ ...params });

      // tableCols
      this.tableColsItem(this.dataInfo?.subColumns);
      this.tableData = this.dataInfo?.data?.details || [];

      // formCols
      if (dataItem?.typeName === '仓库实景') {
        let cols = [];
        this.dataInfo?.mainColumns?.forEach((v) => {
          if (v.dataIndex === 'url') {
            cols[0] = { ...v };
          } else if (v.dataIndex === 'deviceName') {
            cols[1] = { ...v };
          } else if (v.dataIndex === 'stamp') {
            cols[2] = { ...v };
          }
        });
        this.formColsItem(cols, 22);
        this.formDataImg = this.dataInfo?.data || [];
      } else {
        let dataList = this.dataInfo?.data;
        if (dataList?.screenshot?.length) {
          let temp = [];
          this.dataInfo?.mainColumns.forEach((item) => {
            if (item?.dataIndex === 'screenshot') {
              let arr = [];
              dataList?.screenshot?.forEach((v, i) => {
                dataList[`screenshot${i}`] = v;
                arr.push({
                  ...item,
                  dataIndex: `screenshot${i}`,
                });
              });
              temp.push(...arr);
            } else {
              temp.unshift({ ...item });
            }
          });
          this.formColsItem(temp);
          this.formData = { ...dataList, details: [], invoiceUrlList: [] } || {};
        } else {
          this.formColsItem(this.dataInfo?.mainColumns);
          this.formData = { ...this.dataInfo?.data, details: [], invoiceUrlList: [] } || {};
        }
      }

      // 已展开单元信息
      this.showItem = `${dataItem?.typeCode}-${dataItemK}`;
    },

    updateData() {},

    open(data) {
      let resData = {};
      // 1: 应收（发票）,
      // 2: 预付（）,
      // 4： 仓单（）,

      if (data?.productFlag === 'PRODUCT_JX') {
        // 穿透式发票
        resData = {
          ...data,
          businessType: 1,
        };
      } else if (data?.productFlag === 'PRODUCT_PREPAYMENT') {
        // 订单穿透
        resData = {
          ...data,
          businessType: 2,
        };
      } else if (data?.productFlag === 'PRODUCT_WAREHOUSE') {
        // 仓单穿透
        resData = {
          ...data,
          businessType: 4,
        };
      }
      this.requestDate = { ...resData };

      this.drawer = true;
      // 穿透列表
      this.oq100015({ ...resData });
    },

    linkPush(value) {
      if (!value) return;
      const win = window.open(value, '_blank');
      win.focus();
    },

    // 查看实例
    handleDialogEvent(e) {
      const { prop, value } = e;
      switch (true) {
        // 图片处理
        case prop === 'file_id':
        case prop === 'url':
        case prop.includes('screenshot'):
          if (value.includes('.pdf')) {
            this.pdfId = value;
            this.src = undefined;
          } else {
            this.src = value;
            this.pdfId = undefined;
          }

          if (!this.isIEBrowser) {
            this.$nextTick(() => {
              this.$refs?.exampleDialog?.open();
            });
          } else {
            window.location.href = value;
          }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>
