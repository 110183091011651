<template>
  <div
    class="pdfContent"
    ref="pdfView"
    :style="`width:100%; height: ${isFile && '600px'}; margin-bottom: 24px`"
  ></div>
</template>

<script>
import { fetchShowPDF } from "@/api/common";

export default {
  name: "VuePdfViewer",
  props: {
    fileUrl: {
      type: String || Number,
    },
    isFile: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    fileUrl(val) {
      const element = this.$refs.pdfView;
      this.backend(val, element, this.isFile, () => {
        const iframe = document
          .querySelector("iframe")
          .contentWindow.document.querySelector("#viewerContainer");
        setTimeout(() => {
          iframe.scrollTop = 0;
        }, 500);
      });
    },
  },
  mounted() {
   
    if (this.fileUrl) {
      const element = this.$refs.pdfView;
      this.backend(this.fileUrl, element, this.isFile, () => {
        const iframe = document
          .querySelector("iframe")
          .contentWindow.document.querySelector("#viewerContainer");
        setTimeout(() => {
          iframe.scrollTop = 0;
        }, 500);
      });
    }
  },
  methods: {
    backend(source, element, isFile, callback) {
      const iframe = document.createElement("iframe");
      if (isFile) {
        iframe.src = `/pdfjs/web/viewer.html?file=${source}`;
        iframe.width = "100%";
        iframe.height = "100%";
        if (iframe.attachEvent && callback) {
          iframe.onreadystatechange = () => {
            if (iframe.readyState === "complete") {
              callback();
            }
          };
        } else if (callback) {
          iframe.onload = () => {
            callback();
          };
        }
        element.appendChild(iframe);
        iframe.contentWindow.close();
      } else {
        fetchShowPDF({ fileId: source }).then((response) => {
          if (response.data) {
            const { fileContent } = response.data;
            const arr = fileContent.split(",");
            const mime = arr[0].match(/:(.*?);/)[1];
            const bstr = atob(arr[1]);
            let n = bstr.length;
            const u8arr = new Uint8Array(n);
            while (n--) {
              u8arr[n] = bstr.charCodeAt(n);
            }
            iframe.src = `/pdfjs/web/viewer.html?file=${URL.createObjectURL(
              new Blob([u8arr], { type: mime })
            )}`;
            let height = `${document.documentElement.clientHeight}`;
            iframe.style.minHeight = `${height - 200}px`;
            iframe.width = "100%";
            if (iframe.attachEvent && callback) {
              iframe.onreadystatechange = () => {
                if (iframe.readyState === "complete") {
                  callback();
                }
              };
            } else if (callback) {
              iframe.onload = () => {
                callback();
              };
            }
            element.appendChild(iframe);
            iframe.contentWindow.close();
          }
        });
      }
    },
  },
};
</script>

<style scoped></style>
