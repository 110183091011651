/** * create by Songsong_Yu on 2021-12-14 17:31 * 类注释： * 备注： */
<template>
  <div class="father" :style="item.style" @click="click">
    <el-image
      v-if="!formData[item.prop].includes('.pdf')"
      class="img"
      :src="imgSrc"
      :fit="item.fit"
      :style="item.style || 'height:150px'"
      :alt="item.alt"
      :size="item.size"
      :lazy="item.lazy"
      ref="authImg"
    >
    </el-image>
    <div v-else>
      <el-button v-if="isIEBrowser" type="text" :onclick="isDownLoadFn"> 下载 </el-button>
      <div v-else>
        <PDFView :pdfId="formData[item.prop]" :width="'100%'" style="width: 100%; height: 200px" />
      </div>
    </div>
    <div v-if="!isIEBrowser" class="mySon">点击查看大图</div>
  </div>
</template>

<script>
import mixins from '../mixins';
import PDFView from '@/components/PDF/pdfPreview.vue';
import { handleAuthImg } from '@/utils/utils';
import { defaultImg } from '@/utils/enums';
import { isIE } from '@/utils/utils';

export default {
  components: {
    PDFView,
  },
  props: {},
  mixins: [mixins],
  data() {
    return {
      defaultImg,
      isIEBrowser: false,
    };
  },
  computed: {
    imgSrc() {
      let src = defaultImg;
      if (this.formData[this.item.prop]) {
        let url = this.formData[this.item.prop];
        if (isIE()) {
          src = `/server/api/pvbank/comm/file/t3ShowImagesByUrl?url=${url}`;
        }
      }
      return src;
    },
  },
  methods: {
    click() {
      this.mixinEvent({
        type: 'click',
        prop: this.item.prop,
        value: this.formData[this.item.prop],
      });
    },
  },
  activated() {},
  watch: {
    'item.src': {
      handler(val) {
        handleAuthImg(this.$refs.authImg, val);
      },
    },
  },
  mounted() {
    if (isIE()) {
      this.isIEBrowser = true;
    }
  },
  created() {},
};
</script>

<style scoped lang="scss">
.father {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.img {
  width: 100%;
  height: 100%;
}
.mySon {
  width: 100%;
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 14px;
  position: absolute;
  left: 0;
  bottom: 0;
}
.father:hover {
  .img {
    cursor: pointer;
  }
  .mySon {
    cursor: pointer;
  }
}
</style>
