var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-timeline",
    { style: _vm.item.style },
    _vm._l(_vm.item.option || [], function (itemOption, index) {
      return _c(
        "el-timeline-item",
        {
          key: index,
          attrs: { type: "primary", color: "'#009b4d'", size: "large" },
        },
        [
          _c("span", [
            _vm._v(
              _vm._s(itemOption.endTime && _vm.getEndTime(itemOption.endTime))
            ),
          ]),
          _vm._v(" "),
          _c("span", { staticStyle: { "margin-left": "10px" } }, [
            _vm._v(_vm._s(itemOption.taskName)),
          ]),
          _vm._v(" "),
          _c("span", { staticStyle: { "margin-left": "10px" } }, [
            _vm._v(_vm._s(_vm.getUserName(itemOption))),
          ]),
          _vm._v(" "),
          _c("span", { staticStyle: { "margin-left": "10px" } }, [
            _vm._v(_vm._s(_vm.getStatus(itemOption, _vm.item))),
          ]),
          _vm._v(" "),
          _c("span", { staticStyle: { "margin-left": "10px" } }, [
            _vm._v(_vm._s(_vm.getDesc(itemOption))),
          ]),
        ]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }