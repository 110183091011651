var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-descriptions",
    {
      staticClass: "descriptions",
      attrs: {
        title: _vm.title,
        extra: _vm.extra,
        column: _vm.column,
        border: _vm.border,
        direction: _vm.direction,
        size: _vm.size,
        colon: _vm.colon,
        labelClassName: _vm.labelClassName,
        contentClassName: _vm.contentClassName,
        labelStyle: _vm.labelStyle,
        contentStyle: _vm.contentStyle,
      },
    },
    [
      _vm._l(_vm.formCols, function (item) {
        return [
          !item.noShow
            ? _c(
                "el-descriptions-item",
                _vm._b({ key: item.prop }, "el-descriptions-item", item, false),
                [
                  item.eType === "slot"
                    ? _vm._t(item.slotName, null, {
                        data: { formData: _vm.formData },
                      })
                    : item.type === "img" && _vm.formData[item.prop]
                    ? _c(
                        "span",
                        {
                          staticClass: "imageCode",
                          on: {
                            click: function ($event) {
                              return _vm.getImage(_vm.formData[item.prop])
                            },
                          },
                        },
                        [_vm._v("\n        查看\n      ")]
                      )
                    : item.stateList
                    ? _c(
                        "span",
                        {
                          attrs: {
                            title: _vm.selectDictLabel(
                              item.stateList,
                              _vm.formData[item.prop]
                            ),
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.selectDictLabel(
                                item.stateList,
                                _vm.formData[item.prop]
                              )
                            )
                          ),
                        ]
                      )
                    : _c("span", [
                        _vm._v(
                          "\n        " +
                            _vm._s(
                              item.type === "amount"
                                ? _vm.numberFormatAmt(_vm.formData[item.prop])
                                : ""
                            ) +
                            "\n        " +
                            _vm._s(
                              item.type === "count"
                                ? _vm.numberFormatCnt(_vm.formData[item.prop])
                                : ""
                            ) +
                            "\n        " +
                            _vm._s(
                              item.type === "rate"
                                ? _vm.numberFormatRate(_vm.formData[item.prop])
                                : ""
                            ) +
                            "\n        " +
                            _vm._s(
                              !item.type
                                ? (item.formatter &&
                                    item.formatter(_vm.formData)) ||
                                    _vm.formData[item.prop]
                                : ""
                            ) +
                            "\n      "
                        ),
                      ]),
                ],
                2
              )
            : _vm._e(),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }