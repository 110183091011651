var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.isShow,
        width: _vm.width,
        height: _vm.height,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        title: _vm.text.title || "提示",
      },
      on: { close: _vm.close },
    },
    [
      _c("div", { staticClass: "wrap" }, [
        _c("p", { staticClass: "content" }, [
          !_vm.text.showImg
            ? _c("img", { attrs: { src: _vm.img, text: "" } })
            : _vm._e(),
          _c("span", { domProps: { innerHTML: _vm._s(_vm.text.msg) } }),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "btnGroup" },
        [
          _vm.text.btn.no
            ? _c(
                "el-button",
                {
                  staticClass: "close",
                  attrs: { icon: "", size: "medium" },
                  on: {
                    click: function ($event) {
                      return _vm.close()
                    },
                  },
                },
                [_vm._v("\n      " + _vm._s(_vm.text.btn.no) + "\n    ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.text.btn.ok
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "medium" },
                  on: {
                    click: function ($event) {
                      return _vm.ok()
                    },
                  },
                },
                [_vm._v("\n      " + _vm._s(_vm.text.btn.ok) + "\n    ")]
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }