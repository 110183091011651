<template>
  <!--custom-steps-item-finish    custom-steps-item-process custom-steps-item-active   custom-steps-item-wait-->
  <div class="custom-steps custom-steps-horizontal custom-steps-label-horizontal">
    <div
      class="custom-steps-item"
      v-for="(item, index) in stepsList"
      :key="index"
      :class="[
        `custom-steps-item-${stepsList.length}`,
        { 'custom-steps-item-process': current === item.value, 'custom-steps-item-finish': current > item.value },
      ]"
    >
      <div class="custom-steps-item-container">
        <div class="custom-steps-item-tail"></div>
        <div class="custom-steps-item-icon">
          <span class="custom-steps-icon" v-if="current > item.value">
            <i aria-label="图标: check" class="anticon anticon-check custom-steps-finish-icon">
              <svg
                viewBox="64 64 896 896"
                data-icon="check"
                width="1em"
                height="1em"
                fill="currentColor"
                aria-hidden="true"
                focusable="false"
                class=""
              >
                <path
                  d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 0 0-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z"
                ></path>
              </svg>
            </i>
          </span>
          <span class="custom-steps-icon" v-else>
            {{ item.value }}
          </span>
        </div>
        <div class="custom-steps-item-content">
          <div class="custom-steps-item-title">{{ item.key }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CustomSteps',
  props: {
    stepsList: {
      type: Array,
      default: () => [
        {
          key: '填写机构信息',
          value: 1,
        },
        {
          key: '补充管理员信息',
          value: 2,
        },
        {
          key: '填写对公账户信息',
          value: 3,
        },
        {
          key: '对公账户打款验证',
          value: 4,
        },
      ],
    },
    // 当前处于步骤n
    current: {
      type: Number,
      default: 1,
    },
  },
};
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>
