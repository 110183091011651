var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-radio-group",
    {
      attrs: { size: _vm.item.size },
      on: { change: _vm.change },
      model: {
        value: _vm.formData[_vm.item.prop],
        callback: function ($$v) {
          _vm.$set(_vm.formData, _vm.item.prop, $$v)
        },
        expression: "formData[item.prop]",
      },
    },
    _vm._l(_vm.options, function (option) {
      return _c(
        "el-radio-button",
        {
          key: option.value ? option.value : option,
          style: _vm.item.style,
          attrs: {
            label: option.value ? option.value : option,
            disabled: _vm.item.disabled,
          },
        },
        [
          _vm._v(
            "\n    " + _vm._s(option.label ? option.label : option) + "\n  "
          ),
        ]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }