<template>
  <div class="sliderVerifyCaptcha" ref="captcha" style="position: relative"></div>
</template>

<script>
export default {
  name: 'SlideVerify',
  props: {
    randomStr: { type: String },
    imgurl: {
      type: String,
      default: '',
    },
    miniimgurl: {
      type: String,
      default: '',
    },
    blockY: {
      type: Number,
      default: 0,
    },
    // 0 未验证 1: success 2：fail 3：非人工操作
    verifyStatus: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      formCustom: {
        w: 280,
        h: 170,
        bigImage: '',
        smallImage: '',
        yHeight: 0,
        trail: [],
      },
      DOM: {
        bigImage: '',
        block: '',
        sliderContainer: '',
        sliderPanel: '',
        refreshIcon: '',
        sliderMask: '',
        slider: '',
        sliderIcon: '',
        text: '',
      },
      degValue: 0,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.initDOM(this.$refs.captcha);
      this.bindEvents(this.$refs.captcha);
      this.initCodeImg();
    });
  },
  watch: {
    verifyStatus(value) {
      this.$nextTick(() => {
        if (value === 2 || value === 3) {
          this.DOM.sliderContainer.className = 'sliderContainer sliderContainer_fail';
        } else if (value === 1) {
          this.DOM.sliderContainer.className = 'sliderContainer sliderContainer_success';
        } else {
          this.DOM.sliderContainer.className = 'sliderContainer';
        }
      });
    },
  },
  methods: {
    initCodeImg() {
      /** 初始化拼图验证码 **/
      this.initImg();
    },
    onVerify(code) {
      /** 传给父组件 请求登录验证 **/
      this.$emit('parentHandleSubmit', code);
    },
    onSuccess() {
      this.addClass(this.DOM.sliderContainer, 'sliderContainer_success');
      this.reset();
    },
    onFail() {
      this.addClass(this.DOM.sliderContainer, 'sliderContainer_fail');
      this.reset();
    },
    initDOM(el) {
      this.DOM.bigImage = this.createElement('img'); // 大图
      this.DOM.block = this.createElement('img'); // 滑块
      this.DOM.sliderContainer = this.createElement('div');
      this.DOM.sliderPanel = this.createElement('div');
      this.DOM.refreshIcon = this.createElement('div');
      this.DOM.sliderMask = this.createElement('div');
      this.DOM.slider = this.createElement('div');
      this.DOM.sliderIcon = this.createElement('span');
      this.DOM.text = this.createElement('span');

      this.DOM.sliderPanel.className = 'sliderPanel';
      this.DOM.block.className = 'block';
      this.DOM.sliderContainer.className = 'sliderContainer';
      this.DOM.refreshIcon.className = 'refreshIcon';
      this.DOM.sliderMask.className = 'sliderMask';
      this.DOM.slider.className = 'slider';
      this.DOM.sliderIcon.className = 'sliderIcon';
      this.DOM.text.innerHTML = '向右滑动滑块填充拼图';
      this.DOM.text.className = 'sliderText';

      el.appendChild(this.DOM.sliderPanel);
      this.DOM.sliderPanel.appendChild(this.DOM.bigImage);
      this.DOM.sliderPanel.appendChild(this.DOM.refreshIcon);
      this.DOM.sliderPanel.appendChild(this.DOM.block);
      this.DOM.slider.appendChild(this.DOM.sliderIcon);
      this.DOM.sliderMask.appendChild(this.DOM.slider);
      this.DOM.sliderContainer.appendChild(this.DOM.sliderMask);
      this.DOM.sliderContainer.appendChild(this.DOM.text);
      el.appendChild(this.DOM.sliderContainer);
    },
    initImg() {
      this.formCustom.yHeight = this.blockY;
      this.formCustom.bigImage = this.imgurl;
      this.formCustom.smallImage = this.miniimgurl;
      if (this.formCustom.bigImage) {
        // this.DOM.bigImage.src = this.formCustom.bigImage;
        this.DOM.sliderPanel.style['background-image'] = 'url(' + this.formCustom.bigImage + ')';
      }
      if (this.formCustom.smallImage) {
        this.DOM.block.src = this.formCustom.smallImage;
      }
      this.DOM.block.style['padding-top'] = this.formCustom.yHeight + 'px';
    },
    createElement(tagName) {
      return document.createElement(tagName);
    },
    bindEvents(el) {
      let that = this;
      el.onselectstart = () => false;
      that.DOM.refreshIcon.onclick = () => {
        that.degValue += 360;
        that.DOM.refreshIcon.style.transform = `rotate(${that.degValue}deg)`;
        // that.reset();
        this.$emit('resetImg');
      };
      let originX,
        originY,
        blockLeft,
        trail = [],
        isMouseDown = false;
      /** 鼠标点击 **/
      that.DOM.slider.addEventListener('mousedown', function (e) {
        (originX = e.x), (originY = e.y);
        isMouseDown = true;
      });
      const formCustomW = that.formCustom.w;
      /** 鼠标拖动 **/
      document.addEventListener('mousemove', (e) => {
        if (!isMouseDown) return false;
        const moveX = e.x - originX;
        const moveY = e.y - originY;
        if (moveX < 0 || moveX + 28 >= formCustomW) return false;
        blockLeft = ((formCustomW - 40 - 20) / (formCustomW - 40)) * moveX;
        that.DOM.slider.style.left = blockLeft + 'px';
        that.DOM.block.style.left = blockLeft + 'px';

        that.addClass(that.DOM.sliderContainer, 'sliderContainer_active');
        that.DOM.sliderMask.style.width = moveX + 'px';
        trail.push(moveY);
      });
      /** 鼠标放开 **/
      document.addEventListener('mouseup', (e) => {
        if (!isMouseDown) return false;
        isMouseDown = false;
        if (e.x == originX) return false;
        that.removeClass(that.DOM.sliderContainer, 'sliderContainer_active');
        that.trail = trail;
        that.onVerify(parseInt(blockLeft));
      });
    },
    addClass(tag, className) {
      tag.classList.add(className);
    },
    removeClass(tag, className) {
      tag.classList.remove(className);
    },
    reset() {
      this.DOM.sliderContainer.className = 'sliderContainer';
      this.DOM.slider.style.left = 0;
      this.DOM.block.style.left = 0;
      this.DOM.sliderMask.style.width = 0;
      this.initCodeImg();
    },
  },
};
</script>

<style lang="scss">
@import './SlideVerify.scss';
</style>
