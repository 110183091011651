var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "button-wrap" },
        [
          !_vm.item.isPreview
            ? [
                _c("CustomForm", {
                  ref: "form",
                  attrs: {
                    labelPosition: "left",
                    showFormLineButtons: false,
                    formLineButtons: [],
                    formData: _vm.searchFormData,
                    formCols: _vm.searchFormCols,
                    rules: _vm.rules,
                  },
                  on: { event: _vm.handleEvent },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "button-content" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          size: _vm.item.size || "medium",
                          type: "primary",
                          disabled: _vm.item.disabled,
                          loading: this.loading,
                        },
                        on: { click: _vm.templateInput },
                      },
                      [_vm._v("点击上传")]
                    ),
                    _vm._v(" "),
                    _c("p", [_vm._v(_vm._s(_vm.file.buttonTips))]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form",
                  {
                    ref: "elForm",
                    attrs: { model: _vm.formData, rules: _vm.item.rules },
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { prop: _vm.item.prop, label: "" } },
                      [
                        _c(
                          "el-upload",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: false,
                                expression: "false",
                              },
                            ],
                            ref: "uploadFile",
                            attrs: {
                              action: "#",
                              headers: _vm.item.headers,
                              multiple: _vm.item.multiple,
                              data: _vm.item.data,
                              name: _vm.item.name,
                              accept: _vm.item.accept,
                              "auto-upload": !_vm.item.notAutoUpload,
                              disabled: _vm.item.disabled,
                              limit: _vm.item.limit || 5,
                              "list-type": _vm.item.listType,
                              "file-list":
                                (_vm.fileList && _vm.fileList.length > 0) ||
                                _vm.showFileList
                                  ? _vm.fileList
                                  : _vm.item.fileList,
                              "show-file-list": false,
                              "on-preview": !_vm.item.previewDisabled
                                ? _vm.handlePictureCardPreview
                                : _vm.defaultFunction,
                              "on-change": _vm.handleChange,
                              "on-exceed": _vm.handleExceed,
                              "http-request": (file, fileList) => {
                                return _vm.uploadSectionFile(file, fileList)
                              },
                            },
                          },
                          [
                            _c("el-button", { ref: "button" }, [
                              _vm._v(
                                _vm._s(_vm.item.showButtonTitle || "点击上传")
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "preview" },
            _vm._l(_vm.item.fileType, function (val) {
              return _c(
                "div",
                { key: val.value, staticClass: "preview-item" },
                [
                  _vm.getFileList(val) && _vm.getFileList(val).length > 0
                    ? [
                        val.value !== "video"
                          ? _c("el-upload", {
                              staticClass: "disUoloadSty",
                              attrs: {
                                action: "#",
                                disabled: _vm.item.isPreview,
                                "auto-upload": false,
                                "list-type":
                                  val.value !== "file"
                                    ? "picture-card"
                                    : "text",
                                "on-preview": _vm.handlePictureCardPreview,
                                "on-remove": _vm.handleRemove,
                                "on-change": _vm.handleChange,
                                "file-list": _vm.getFileList(val),
                              },
                            })
                          : _c(
                              "div",
                              [
                                _c("el-upload", {
                                  staticClass: "disUoloadSty",
                                  attrs: {
                                    action: "#",
                                    "auto-upload": false,
                                    disabled: true,
                                    "list-type": "picture-card",
                                    "on-preview": _vm.handlePictureCardPreview,
                                    "on-change": _vm.handleChange,
                                    "file-list": _vm.getFileList(val),
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "file",
                                        fn: function ({ file }) {
                                          return [
                                            _c(
                                              "div",
                                              { staticClass: "video-item" },
                                              [
                                                _c("video", {
                                                  attrs: {
                                                    src: file.url,
                                                    controls: "",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                !_vm.item.isPreview
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "el-upload-list__item-actions",
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "el-upload-list__item-delete",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.handleRemove(
                                                                  file
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "el-icon-delete",
                                                            }),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            ),
                      ]
                    : _vm._e(),
                ],
                2
              )
            }),
            0
          ),
        ],
        2
      ),
      _vm._v(" "),
      _vm.dialogImageUrl || _vm.fileUrl
        ? _c("ExampleDialog", {
            ref: "exampleDialog",
            attrs: {
              src: _vm.dialogImageUrl,
              fileUrl: _vm.fileUrl,
              isFile: false,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }