<template>
  <!-- 模版 -->
  <CustomCard
    :headerTitle="headerTitle"
    :subDividerTitle="subDividerTitle"
    :subDividerTitleType="subDividerTitleType"
    :subDividerTitlePath="subDividerTitlePath"
    :rowObj="rowObj"
    :dividerTitle="dividerTitle"
    :formLineButtons="[]"
    :tabHidden="false"
    :showFormLineButtons="false"
    :span="24"
    @subDividerTitleEvent="$emit('subDividerTitleEvent', true)"
  >
    <slot></slot>
    <!-- 头部 -->
    <div slot="bodyHeaderSlot">
      <ElTabsComponent :current="current" :tabs="tabs" :dangers="dangers" @tabsClick="tabsClick" />
    </div>
    <!-- 身体 -->
    <div slot="bodySlot">
      <slot name="bodySlot"> </slot>
    </div>
  </CustomCard>
</template>

<script>
import { ElTabsComponent } from '@/components';
export default {
  name: 'TabsCustomCard',
  components: {
    ElTabsComponent,
  },
  props: {
    headerTitle: {
      type: String,
      default: '暂无数据',
    },
    dividerTitle: {
      type: String,
      default: '',
    },
    subDividerTitle: {
      type: String,
      default: '客户详情',
      required: false,
    },
    subDividerTitleType: {
      type: String,
      default: '',
      required: false,
    },
    subDividerTitlePath: {
      type: String,
      default: '',
      required: false,
    },
    current: {
      type: String,
      default: '暂无数据',
    },
    tabs: {
      type: Array,
      require: true,
      default: () => [],
    },
    dangers: {
      type: Array,
      default: () => [],
    },
    insertCard: {
      type: Boolean,
      default: true,
    },
    className: {
      type: String,
      default: '',
    },
    rowObj: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    tabsClick(val) {
      this.$emit('tabsClick', val);
    },
  },
};
</script>

<style lang="scss" scoped></style>
