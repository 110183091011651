var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("el-slider", {
    attrs: {
      min: _vm.item.min,
      max: _vm.item.max,
      step: _vm.item.step,
      disabled: _vm.item.disabled,
      "show-input": _vm.item.showInput,
      "show-input-controls": _vm.item.showInputControls,
      "show-stops": _vm.item.showStops,
      "show-tooltip": _vm.item.showTooltip,
      range: _vm.item.range,
      height: _vm.item.height,
      vertical: _vm.item.vertical,
      marks: _vm.item.marks,
    },
    on: { change: _vm.change },
    model: {
      value: _vm.formData[_vm.item.prop],
      callback: function ($$v) {
        _vm.$set(_vm.formData, _vm.item.prop, $$v)
      },
      expression: "formData[item.prop]",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }