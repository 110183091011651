// 风险监控
const riskMonitoringRoutes = [
  {
    path: '/riskMonitoring/riskWarning/detail',
    name: 'riskWarningDetail',
    component: () =>
      import(
        /* webpackChunkName: "riskMonitoring" */ '@/views/Agency/Financial/RiskMonitoring/riskWarning/detail.vue'
      ),
    meta: {
      title: '融资详情',
      icon: 'dashboard',
      activeMenu: '/riskMonitoring',
      parentMenu: '/riskMonitoring/riskWarning',
      layout: 'ProductLayout',
      corpIdentity: ['FINANCIAL_ENTERPRISE'],
      authority: ['NO-AUTHORITY'],
    },
  },
];
export default riskMonitoringRoutes;
