
const getters = {
  sidebar: (state) => state.app.sidebar,
  size: (state) => state.app.size,
  device: (state) => state.app.device,
  Device: (state) => state.app.Device,
  isRefresh: (state) => state.user.isRefresh, //是否请求刷新token
  token: (state) => state.user.token,
  avatar: (state) => state.user.avatar,
  name: (state) => state.user.name,
  email: (state) => state.user.email,
  currentUser: (state) => state.user.currentUser,
  loginInfo: (state) => state.user.loginInfo,
  resouceCodes: (state) => state.permission.resouceCodes,
  captchaImgData: (state) => state.user.captchaImgData,
  msgKey: (state) => state.user.msgKey,
  commonMenuTree: (state) => state.permission.commonMenuTree,
  projectMenuTree: (state) => state.permission.projectMenuTree,
  permission_routes: (state) => state.permission.routes,
  seqNo: (state) => state.user.seqNo,
  currentProduct: (state) => state.dashboard.currentProduct,
  productList: (state) => state.dashboard.productList,
};
export default getters;
