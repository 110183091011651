<template>
  <div style="height: 0">
    <Dialog
      ref="exampleDialog"
      :showFooter="false"
      :appendToBody="true"
      :destroyOnClose="true"
      :title="fileUrl ? '预览' : ''"
      @event="eventChange"
      v-if="fileUrl || isScreenFull"
    >
      <div
        slot="dialogBody"
        ref="fullImgWrapper"
      >
        <PDFView
          :fileUrl="fileUrl"
          :isFile="isFile"
          v-if="fileUrl && show"
          :width="'952px'"
        />
        <img
          v-else
          width="100%"
          style="margin-top: 25px"
          :src="src || idMap[type]"
          alt=""
          @click="isScreenFull && onScreenFull()"
        />
      </div>
    </Dialog>
    <el-image
      style="width: 0px; height: 0px"
      :src="src || idMap[type]"
      :preview-src-list="[src || idMap[type]]"
      ref="previewImage"
    >
    </el-image>
  </div>
</template>

<script>
import { Dialog } from '@/components';
import PDFView from '../PDF/pdfPreview.vue';
import screenfull from 'screenfull';

export default {
  name: 'ExampleDialog',
  components: {
    Dialog,
    PDFView,
  },
  props: {
    type: {
      type: String,
      default: '',
    },
    src: {
      type: String,
      default: '',
    },
    fileUrl: {
      type: String,
      default: '',
    },
    isFile: {
      type: Boolean,
      default: true,
    },
    isScreenFull: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {},
  data() {
    return {
      idMap: {},
      show: false,
      previewSrcList: [],
    };
  },
  methods: {
    open() {
      this.show = true;
        const { fileUrl, isScreenFull } = this.$options.propsData;
        if (fileUrl || isScreenFull) {
          this.$refs.exampleDialog.open();
          return;
        }
        this.$refs.previewImage.clickHandler();
    },

    close() {
      this.$refs.exampleDialog.close();
    },

    eventChange() {
      this.show = false;
    },

    onScreenFull() {
      if (screenfull.isEnabled) {
        screenfull.toggle(this.$refs.fullImgWrapper);
      }
    },
  },
};
</script>
