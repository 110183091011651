import { fetchFileList, fetchFileListByTxNo, getCommFileByIds, selectListByBussId } from '@/api/common';
import { getPdfOrImageConfig } from '@/utils/utils';

const defaultState = () => {
  return {
    fileList: [],
    commFileList: [],
  };
};
export const state = defaultState();

const mutations = {
  SET_FILE_LIST(state, data) {
    state.fileList = data || [];
  },
  // 批量查询文件
  SET_COMMFILELIST(state, payload) {
    state.commFileList = payload?.map((item) => {
      return { name: item.fileName, id: item.fileId, ...getPdfOrImageConfig(item) };
    });
  },

  RESET_STATE: (state) => {
    Object.assign(state, defaultState());
  },
};

const actions = {
  // 批量查询文件
  async getCommFileByIds({ commit }, payload) {
    const { data } = (await getCommFileByIds(payload)) || {};
    commit('SET_COMMFILELIST', data);
  },

  async fetchFileList({ commit }, payload) {
    const { data } = await fetchFileList(payload);
    if (data) {
      commit('SET_FILE_LIST', data);
      return data;
    }
  },

  async fetchFileListByTxNo({ commit }, payload) {
    const { data } = await fetchFileListByTxNo(payload);
    if (data) {
      commit('SET_FILE_LIST', data);
      return data;
    }
    return [];
  },

  async selectListByBussId({ commit }, payload) {
    const { data } = await selectListByBussId(payload);
    if (data) {
      commit('SET_FILE_LIST', data);
      return data;
    }
  },

  //传递附件信息数组
  async transmitList({ commit }, payload) {
    commit('SET_FILE_LIST', payload);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
