<template>
  <CustomCard
    cardClassName="result-card"
    :headerTitle="title"
    :tabHidden="false"
    :formHidden="false"
    :bodyHidden="false"
    :formLineButtons="[]"
    :subTitle="headerSubTitle"
    :type="headerSubTitleType"
  >
    <div class="body-wrap">
      <div class="sub-title-wrap">
        <span type="text" v-if="subDividerTitle" class="sub-title-right">
          {{ subDividerTitle }}
        </span>
        <el-divider v-if="subDividerTitle"></el-divider>
      </div>
      <div class="result-content-wrap">
        <el-result>
          <template slot="icon">
            <svg-icon :icon-class="status" :className="`icon-${status}`"></svg-icon>
          </template>
          <template slot="extra">
            <el-row>
              <el-col>
                <div class="tipTitle">{{ statusTxt }}</div>
              </el-col>
            </el-row>
            <el-row v-show="!!subTitle">
              <el-col>
                <div class="txtMsg" v-html="subTitle"></div>
              </el-col>
            </el-row>
            <el-row v-show="urlArr && urlArr.length > 0">
              <el-col>
                <div class="subtitle">
                  预览
                  <span class="link" v-for="(item, index) in urlArr" :key="index" @click="$router.push(item.url)">
                    {{ item.txt }}
                  </span>
                </div>
              </el-col>
            </el-row>
            <el-row v-show="!!goBack">
              <el-col>
                <el-button size="medium" @click="handleClick">{{ buttonTitle }}</el-button>
              </el-col>
            </el-row>
          </template>
        </el-result>
      </div>
    </div>
  </CustomCard>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: 'Result',
  data() {
    let timer;
    const { obj } = this.$route.params;
    const {
      title,
      headerSubTitle,
      subDividerTitle,
      headerSubTitleType,
      status,
      statusTxt,
      subTitle,
      urlArr,
      goBack,
      callback,
      buttonTitle,
      timeout,
      pushUrl,
      getNewToken,
      exit,
    } = obj || {};
    if (timeout) {
      timer = setTimeout(async () => {
        // 个人实名认证 需要重新调用token接口
        if (getNewToken) {
          await this.GetUserInfo();
        }
        this.$router.push(goBack);
      }, timeout);
    }
    return {
      title,
      headerSubTitle,
      subDividerTitle,
      headerSubTitleType: headerSubTitleType || 'desc',
      status,
      statusTxt,
      subTitle,
      urlArr,
      goBack,
      pushUrl,
      exit,
      buttonTitle: buttonTitle || '返回',
      callback,
      timer,
    };
  },
  destroyed() {
    clearTimeout(this.timer);
  },
  methods: {
    ...mapActions('user', ['GetUserInfo']),

    async handleClick() {
      if (this.pushUrl) {
        // 个人实名认证 需要重新调用token接口
        if (this.getNewToken) {
          await this.GetUserInfo();
        }
        //跳转指定页面
        this.$router.push(this.pushUrl);
      } else if (this.exit) {
        //退出
        this.$store.dispatch('user/LogOut');
      } else if (this.callback) {
        //callback
        this.callback();
      } else {
        //返回上一页
        this.$router.go(this.goBack);
      }
      this.$rollTop();
    },
  },
};
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>
