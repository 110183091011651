import router, { resetRouter } from '@/router';
import { resetState } from '../index';
import {
  login,
  refreshToken,
  logout,
  getUserInfoByToken,
  switchOrgAndIdentity,
  resetPassword,
  getLoginInfo,
  getAdminInfo,
  getCaptcha,
  getAgreementFile,
} from '@/api/user';
import { getPicCaptcha, sendMsgAuthCode, getSlideCode } from '@/api/common';
import { setStorage, clearStorage } from '@/utils/storage';
import {
  getToken,
  setToken,
  removeToken,
  setRefreshToken,
  getRefreshToken,
  setSecret,
  setAppId,
  setOrgId,
  setUserInfo,
} from '@/utils/auth';
import { Message } from 'element-ui';

const defaultState = () => {
  return {
    token: '',
    isRefresh: false,
    name: '',
    email: '=',
    currentUser: {},
    captchaImgData: {
      capCode: '',
      capKey: '',
    },
    msgKey: '',
    loginInfo: {},
    slideVerifyCodeData: {
      cutoutImage: '',
      shadeImage: '',
      slideCodeKey: '',
      y: 0,
    }, // 图片滑块验证码
    menuTree: [],
    seqNo: null,
    temporaryData: {},
    registerFormData: {},
    captchaInfo: {},
    urlApi: '',
    indexRes: '',
  };
};

const mutations = {
  SET_CAPTCHA_IMG: (state, captcha) => {
    state.captchaImgData = captcha;
  },
  SET_MSG_KEY: (state, msgKey) => {
    state.msgKey = msgKey;
  },

  SET_TOKEN: (state, token) => {
    state.token = token;
  },

  SET_TOKENs: () => {},

  SET_NAME: (state, name) => {
    state.name = name;
  },

  SET_MENU_TREE: (state, menuTree) => {
    state.menuTree = menuTree;
  },

  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar;
  },

  SET_EMAIL: (state, email) => {
    state.email = email;
  },

  SET_CURRENTUSER: (state, currentUser) => {
    state.currentUser = currentUser;
  },
  SET_INDEXRES(state, indexRes) {
    state.indexRes = indexRes;
  },
  SET_SLIDE_VERIFY_CODE: (state, slideVerifyCodeData) => {
    state.slideVerifyCodeData = slideVerifyCodeData;
  },

  SET_LOGIN_INFO: (state, loginInfo) => {
    state.loginInfo = loginInfo;
  },

  SET_TEMPORARY_DATA: (state, temporaryData) => {
    state.temporaryData = temporaryData;
  },

  SET_REGISTER_FORM_DATA: (state, registerFormData) => {
    state.registerFormData = registerFormData;
  },

  SET_CAPTCHA_INFO: (state, captchaInfo) => {
    state.captchaInfo = captchaInfo;
  },

  SET_URL_API: (state, urlApi) => {
    state.urlApi = urlApi;
  },

  RESET_STATE: (state) => {
    Object.assign(state, defaultState());
  },
  SET_IS_REFRESH(state, data) {
    state.isRefresh = data;
  },
};
const actions = {
  /**
   * @description 获取平台服务协议
   */
  async getAgreementFile({ commit }) {
    const res = await getAgreementFile();
    commit('SET_URL_API', res.data?.url);
    return res;
  },

  /**
   * @description 获取图形验证码
   */
  async GetCaptchaImg({ commit }) {
    const { data } = await getPicCaptcha();
    if (data) {
      commit('SET_CAPTCHA_IMG', data);
    }
  },

  async sendMsgAuthCode({ commit }, param) {
    const res = await sendMsgAuthCode(param);
    if (res?.data?.msgKey) {
      Message.success('短信发送成功');
      commit('SET_MSG_KEY', res?.data?.msgKey);
    } else {
      commit('SET_MSG_KEY', '');
    }
    return res;
  },

  async Login({ commit }, userInfo) {
    const res = await login(userInfo);
    if (res?.data?.accessToken) {
      const { secret, accessToken, expiresIn, refreshToken, refreshTokenExpiresIn } = res.data;
      //  登录后保存token和secret
      setToken(accessToken, expiresIn);
      setRefreshToken(refreshToken, refreshTokenExpiresIn);
      setSecret(secret);
      commit('SET_TOKEN', accessToken);
    }
    return res;
  },

  /**
   * @description 获取登录信息
   */
  async getLoginInfo({ commit, dispatch }, payload) {
    const res = await getLoginInfo();
    if (res?.rspCode === '00000000') {
      const loginInfo = res.data;
      setAppId(loginInfo.appId);
      setOrgId(loginInfo.corpId);
      setUserInfo(loginInfo);
      setStorage('bussDate', loginInfo.timeMillis);
      setStorage('corpAuthType', loginInfo.corpAuthType); //  认证方式  1：人脸，2：ukey
      setStorage('approvalStatus', loginInfo.approvalStatus); //  企业实名认证状态 2：通过
      setStorage('userId', loginInfo.id); //  用户id
      commit('SET_LOGIN_INFO', loginInfo);
      if (payload?.goDashboard) {
        dispatch('getAdminInfo');
        router.replace('/dashboard');
      }
      return res;
    }
  },

  /**
   * @description 刷新token
   */
  getRefreshToken({ commit }) {
    return new Promise((resolve, reject) => {
      const parmas = {
        refreshToken: getRefreshToken(),
        clientId: process.env.VUE_APP_CLIENT_ID,
      };
      refreshToken(parmas)
        .then((res) => {
          if (res?.data) {
            const { accessToken, expiresIn, refreshToken, refreshTokenExpiresIn } = res.data;
            setToken(accessToken, expiresIn);
            setRefreshToken(refreshToken, refreshTokenExpiresIn);
            commit('SET_TOKEN', accessToken);
            commit('SET_IS_REFRESH', false);
            resolve(res.data);
          } else {
            reject(res);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   * @description 面客端管理首页
   */
  async getAdminInfo({ commit }) {
    const res = await getAdminInfo();
    commit('SET_INDEXRES', res.rspCode);
    if (res?.rspCode === '00000000') {
      commit('SET_CURRENTUSER', res.data);
    }
    return res;
  },

  async getSlideCode({ commit }) {
    const res = await getSlideCode();
    if (res?.data) {
      commit('SET_SLIDE_VERIFY_CODE', res.data);
    }
    return res;
  },

  async getCaptcha({ commit }, payload) {
    const res = await getCaptcha(payload);
    if (res?.data) {
      commit('SET_CAPTCHA_INFO', res.data);
    }
    return res;
  },

  async switchOrgAndIdentity({ commit }, params) {
    const { data } = await switchOrgAndIdentity(params);
    if (data?.token) {
      commit('SET_TOKEN', data.token);
    }
    return data;
  },

  // 用于获取一次性用户信息但不存储
  async GetUserInfoOnce({ state }) {
    if (!getToken() && state.token === '') {
      throw Error('token为空');
    } else {
      const response = await getUserInfoByToken();
      if (response?.rspCode === '00000000') {
        const { data } = response;
        if (!data) {
          throw Error('获取用户信息失败！');
        }
        return data;
      } else {
        return response;
      }
    }
  },

  // 自定义设置token缓存
  async setTokenValue({ commit }, token) {
    if (token) commit('SET_TOKEN', token);
  },

  ResetToken({ commit }) {
    removeToken();
    commit('SET_TOKEN', '');
    commit('permission/SET_COMMON_MENU_TREE', [], { root: true });
    commit('permission/SET_PROJECT_MENU_TREE', [], { root: true });
  },

  async LogOut({ dispatch, state }, payload) {
    if (!payload?.noRequest) {
      if (!getToken() && state.token === '') {
        throw Error('token为空');
      }
      await logout();
    }
    dispatch('ResetToken');
    clearStorage();
    resetRouter();
    resetState();
    router.push('/homePage');
  },

  async resetPassword({ commit }, payload) {
    commit('SET_TOKENs', '');
    return resetPassword({ ...payload });
  },
};

export default {
  namespaced: true,
  state: defaultState(),
  mutations,
  actions,
};
