// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Variables */
[data-v-60eafe6d]:export {
  menuText: #000;
  menuActiveText: #fff;
  subMenuActiveText: #f4f4f5;
  menuActiveBg: #1589cd;
  menuBg: #fff;
  menuHover: rgba(45, 153, 255, 0.3);
  subMenuBg: #fff;
  subMenuHover: rgba(45, 153, 255, 0.3);
  sideBarWidth: 200px;
  primaryColor: #1589cd;
  primaryFontColor: rgb(45, 153, 255);
  primaryColor_8: rgba(31, 113, 255, 0.08);
  globalColor: #e60012;
  defaultFontColor: rgb(20, 35, 69);
  tableHeaderBg: #fafafa;
  tableHeaderHeight: 40px;
  tableAlign: center;
  fontSize_14: 14px;
  fontSize_16: 16px;
  fontSize_18: 18px;
  fontSize_24: 24px;
}

/* Mixins */
.left-menu[data-v-60eafe6d] {
  position: relative;
  height: 64px;
  line-height: 64px;
  text-align: center;
  padding-left: 24px;
  overflow: hidden;
}
.sidebar-logo-link[data-v-60eafe6d] {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  line-height: 64px;
  text-decoration: none;
}
.sidebar-logo-link .dvider-line[data-v-60eafe6d] {
  background-color: #19283E;
  width: 1px;
  height: 22px;
  margin: 0 16px 0 16px;
}
.sidebar-logo-link .logo-name[data-v-60eafe6d] {
  color: #244063;
  font-size: 22px;
}
.sidebar-logo[data-v-60eafe6d] {
  width: auto;
  height: 48px;
  cursor: pointer;
}
.sidebar-title[data-v-60eafe6d] {
  display: inline-block;
  margin: 0;
  color: #1890ff;
  font-weight: 600;
  font-size: 22px;
  vertical-align: middle;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.sidebar-title-container[data-v-60eafe6d] {
  height: 49px;
  padding-top: 4px;
  display: flex;
  padding-left: 6px;
  justify-content: flex-start;
  align-items: center;
}
.sidebar-title-container .corp-change[data-v-60eafe6d] {
  margin-left: 32px;
  display: inline-block;
  background: rgba(191, 196, 206, 0.2);
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sidebar-title-container .corp-change .change[data-v-60eafe6d] {
  display: inline-block;
  width: 16px;
  height: auto;
}
.sidebar-title-container .container-title[data-v-60eafe6d] {
  margin: 0;
  font-size: 14px;
  color: rgb(20, 35, 69);
  line-height: 1;
}
.sidebar-title-container .container-title.clickable[data-v-60eafe6d] {
  pointer-events: all;
}
.sidebar-title-container .container-title .svg-icon[data-v-60eafe6d] {
  margin-left: 8px;
  font-size: 14px;
  color: #1589cd;
  cursor: pointer;
}
.sidebar-title-container .el-icon-user-solid[data-v-60eafe6d] {
  margin-left: 5px;
  color: #1589cd;
}
.sidebar-title-container .comment-right[data-v-60eafe6d] {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.sidebar-title-container .container-comment[data-v-60eafe6d] {
  margin: 8px 0 0;
  padding: 2px 8px;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #fff;
  background: linear-gradient(90deg, #057AFD 0%, #48B7FF 100%);
  border-radius: 3px;
}
.sidebar-title-container .change-corp[data-v-60eafe6d] {
  position: relative;
  width: 14px;
  height: 14px;
  margin-left: 8px;
  cursor: pointer;
  background-size: 14px auto;
  color: #1589cd;
  margin-top: 8px;
}
.sidebar-title-container .change-corp[data-v-60eafe6d] ::after {
  content: "";
  position: absolute;
  left: -5px;
  right: -5px;
  top: -5px;
  bottom: -5px;
}
.left-menu-homepage[data-v-60eafe6d] {
  padding-left: 0;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
