<template>
  <div class="page-title">
    <svg-icon v-if="icons" :icon-class="icons" />
    <span class="required" v-if="isRequired">*</span>
    <p>{{ title }}</p>
    <div class="round">
      <div class="line"></div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    icons: {
      type: String,
      default: 'sub_title',
    },
    title: String,
    isRequired: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.page-title {
  display: flex;
  justify-content: center;
  align-items: center;
  .required {
    color: #f00;
    font-size: 20px;
    height: 18px;
  }
  > svg {
    width: 14px;
    height: 11px;
    margin-right: 10px;
  }
  > p {
    color: $defaultFontColor;
    font-weight: 600;
    font-size: 15px;
  }
  > .round {
    border-top: 1px dashed #dcdfe6;
    flex: 1;
    display: flex;
    width: 100%;
    height: 2px;
    align-content: center;
    align-items: center;
    margin-left: 6px;
    .line {
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background: #dcdfe6;
      margin-top: -1px;
    }
  }
}
</style>
