var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-select",
    {
      ref: "select",
      class: _vm.item.class,
      staticStyle: { width: "100%" },
      style: _vm.item.value,
      attrs: {
        placeholder: _vm.item.placeholder,
        "allow-create": _vm.item.allowCreate,
        filterable: _vm.item.filterable,
        clearable: _vm.item.clearable,
        disabled: _vm.item.disabled,
        multiple: _vm.item.multiple,
        "multiple-limit": _vm.item.multipleLimit,
        "remote-method": _vm.item.remoteMethod || _vm.emptyFn,
        remote: _vm.item.remote,
        loading: _vm.item.loading,
        size: "medium",
      },
      on: { "remove-tag": _vm.removeTag, change: _vm.change, focus: _vm.focus },
      model: {
        value: _vm.formData[_vm.item.prop],
        callback: function ($$v) {
          _vm.$set(_vm.formData, _vm.item.prop, $$v)
        },
        expression: "formData[item.prop]",
      },
    },
    [
      _vm._l(_vm.options, function (option) {
        return [
          _c("el-option", {
            key: option[_vm.item.dictValue || "value"]
              ? option[_vm.item.dictValue || "value"]
              : option,
            style: _vm.item.maxWidth
              ? "max-width: calc(33.33vw - 140px)"
              : _vm.item.maxWidthCustom || "",
            attrs: {
              title: option[_vm.item.dictLabel || "label"]
                ? option[_vm.item.dictLabel || "label"]
                : option,
              label: option[_vm.item.dictLabel || "label"]
                ? option[_vm.item.dictLabel || "label"]
                : option,
              value: option[_vm.item.dictValue || "value"]
                ? option[_vm.item.dictValue || "value"]
                : option,
              disabled: option.disabled,
              size: "medium",
            },
          }),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }