// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Variables */
[data-v-eb18a17a]:export {
  menuText: #000;
  menuActiveText: #fff;
  subMenuActiveText: #f4f4f5;
  menuActiveBg: #1589cd;
  menuBg: #fff;
  menuHover: rgba(45, 153, 255, 0.3);
  subMenuBg: #fff;
  subMenuHover: rgba(45, 153, 255, 0.3);
  sideBarWidth: 200px;
  primaryColor: #1589cd;
  primaryFontColor: rgb(45, 153, 255);
  primaryColor_8: rgba(31, 113, 255, 0.08);
  globalColor: #e60012;
  defaultFontColor: rgb(20, 35, 69);
  tableHeaderBg: #fafafa;
  tableHeaderHeight: 40px;
  tableAlign: center;
  fontSize_14: 14px;
  fontSize_16: 16px;
  fontSize_18: 18px;
  fontSize_24: 24px;
}

/* Mixins */
[data-v-eb18a17a] .el-dialog__body {
  min-height: 150px;
  padding: 20px 20px;
}
[data-v-eb18a17a] .el-dialog__title {
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.84px;
  color: rgb(20, 35, 69) !important;
}
.wrap[data-v-eb18a17a] {
  width: 100%;
  min-height: 40px;
}
.wrap p[data-v-eb18a17a] {
  text-align: center;
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 14px;
  color: rgb(20, 35, 69);
  display: flex;
}
.wrap p img[data-v-eb18a17a] {
  width: 18px;
  height: 18px;
  margin-right: 8px;
}
.wrap p span[data-v-eb18a17a] {
  line-height: 1.6;
}
.wrap .content[data-v-eb18a17a] {
  margin-right: 18px;
}
.btnGroup[data-v-eb18a17a] {
  width: 100%;
  text-align: center;
  margin-top: 42px;
}
.btnGroup .svg[data-v-eb18a17a] {
  margin-right: 5px;
}
.btnGroup .el-button--primary[data-v-eb18a17a] {
  color: #ffffff;
  background-color: #1589cd;
  border-color: #1589cd;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
