// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Variables */
[data-v-58855b88]:export {
  menuText: #000;
  menuActiveText: #fff;
  subMenuActiveText: #f4f4f5;
  menuActiveBg: #1589cd;
  menuBg: #fff;
  menuHover: rgba(45, 153, 255, 0.3);
  subMenuBg: #fff;
  subMenuHover: rgba(45, 153, 255, 0.3);
  sideBarWidth: 200px;
  primaryColor: #1589cd;
  primaryFontColor: rgb(45, 153, 255);
  primaryColor_8: rgba(31, 113, 255, 0.08);
  globalColor: #e60012;
  defaultFontColor: rgb(20, 35, 69);
  tableHeaderBg: #fafafa;
  tableHeaderHeight: 40px;
  tableAlign: center;
  fontSize_14: 14px;
  fontSize_16: 16px;
  fontSize_18: 18px;
  fontSize_24: 24px;
}

/* Mixins */
.right-menu[data-v-58855b88] {
  float: right;
  height: 100%;
  line-height: 40px;
  padding-right: 24px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 14px !important;
}
.right-menu[data-v-58855b88]:focus {
  outline: none;
}
.right-menu .right-menu-item[data-v-58855b88] {
  display: inline-block;
  padding: 0 8px;
  font-size: 11px;
  vertical-align: text-bottom;
}
.right-menu .right-menu-item.hover-effect[data-v-58855b88] {
  cursor: pointer;
  transition: background-color 0.3s;
}
.right-menu .right-menu-item.hover-effect[data-v-58855b88]:hover {
  background: rgba(0, 0, 0, 0.25);
}
.right-menu[data-v-58855b88] .el-icon-switch-button {
  transform: rotate(90deg);
}
.right-menu .el-icon--right[data-v-58855b88] {
  margin-left: 0;
}
.top-right-button[data-v-58855b88] {
  font-size: 14px;
  background-color: transparent;
  color: #fff;
  padding: 5px 12px;
  border-color: #fff;
}
.top-right-button[data-v-58855b88]:hover {
  background-color: #fff;
  color: #1589cd;
}
.headerContainer .el-button--text[data-v-58855b88] {
  color: #fff;
}
.headerContainer .el-button--text[data-v-58855b88]:hover {
  color: #fff;
}
.headerContainer .right-menu-item[data-v-58855b88] {
  color: #fff;
  height: auto;
}
.headerContainer[data-v-58855b88] .el-badge__content {
  left: -15px !important;
  height: 14px;
  background-color: #fff;
  font-size: 14px;
  line-height: 15px !important;
}
.popover-button-check[data-v-58855b88] {
  display: block;
  width: 100%;
  height: 46px;
  color: rgba(0, 0, 0, 0.85);
}
.msgContent-content[data-v-58855b88] {
  margin-top: 0;
  line-height: 1.55;
  margin-bottom: 8px;
  font-size: 14px;
}
.msgContent-time[data-v-58855b88] {
  margin: 0 !important;
  font-size: 14px;
  color: rgb(20, 35, 69);
  text-align: right;
}
.msgContent-workflow-name[data-v-58855b88] {
  margin: 0 !important;
  color: rgb(20, 35, 69);
  font-size: 14px;
}
.tabs-default-first[data-v-58855b88] .el-tabs__active-bar {
  width: 100px !important;
  transform: translateX(22.5px) !important;
}
.tabs-default-second[data-v-58855b88] .el-tabs__active-bar {
  width: 100px !important;
  transform: translateX(211.5px) !important;
}
[data-v-58855b88] .el-button--text {
  color: #ffffff;
}
[data-v-58855b88] .el-button--text:hover {
  color: #ffffff;
}
[data-v-58855b88] .el-button--text span {
  font-size: 14px !important;
}
[data-v-58855b88] .el-tabs {
  min-width: 800px;
}
[data-v-58855b88] .el-tabs__nav-wrap::after {
  height: 1px;
}
[data-v-58855b88] .el-table__row {
  cursor: pointer;
}
[data-v-58855b88] .el-table__row td {
  vertical-align: top !important;
}
[data-v-58855b88] .el-tabs__item {
  width: 167px;
  height: 44px;
  line-height: 44px;
  text-align: center;
}
[data-v-58855b88] .top-svg-icon {
  width: 20px;
  height: 20px;
  vertical-align: initial;
}
[data-v-58855b88] .task,[data-v-58855b88] .message {
  cursor: pointer;
  margin: 0 5px;
}
[data-v-58855b88] .username {
  margin: 0 10px 0 5px;
  font-size: 12px;
  display: flex;
  align-items: center;
}
[data-v-58855b88] .username img {
  margin-right: 3px;
}
[data-v-58855b88] .logout {
  display: flex;
  align-items: center;
  font-size: 12px;
  cursor: pointer;
}
[data-v-58855b88] .logout .iconfont {
  margin-right: 3px;
}
.right-menu-homepage[data-v-58855b88] {
  padding-right: 23px;
}
.roll-name-tooltip[data-v-58855b88] {
  color: #fff;
  background: transparent;
  border: none;
  font-size: 14px;
}
.roll-name-tooltip[data-v-58855b88]:hover {
  color: #fff;
}
.roll-name-tooltip span[data-v-58855b88] {
  font-size: 14px !important;
}
[data-v-58855b88] .el-badge__conten,[data-v-58855b88] .el-badge__content.is-fixed {
  border: none;
  top: 10px;
  right: 56px;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
