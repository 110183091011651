<template>
  <div class="page-title">
    <svg-icon v-if="icons" :icon-class="icons" />
    <p>{{ title }}</p>
  </div>
</template>
<script>
export default {
  props: {
    icons: String,
    title: String,
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.page-title {
  display: flex;
  align-content: center;
  align-items: center;
  margin-bottom: 24px;
  padding-bottom: 14px;
  border-bottom: 1px solid #e6ebf5;
  > svg {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
  > p {
    color: $primaryFontColor;
    font-weight: 600;
    font-size: 16px;
  }
}
</style>
