<template>
  <div class="ellipsis" ref="root">
    <div ref="content">
      <el-tooltip
        class="label-desc"
        popper-class="ellipsis-tooltip"
        effect="dark"
        :content="text"
        v-if="showExtra"
        placement="top-start"
      >
        <span ref="node">
          {{ (targetCount > 0 && textVal.substring(0, targetCount)) || ''
          }}{{ (targetCount > 0 && textVal.length > targetCount && '...') || '' }}
        </span>
      </el-tooltip>
      <span ref="node" v-else>
        {{ (targetCount > 0 && textVal.substring(0, targetCount)) || ''
        }}{{ (targetCount > 0 && textVal.length > targetCount && '...') || '' }}
      </span>
      <div class="shadow" ref="shadowChildren" v-if="showExtra">{{ textVal }}</div>
      <div class="shadow" v-if="showExtra" ref="shadow">
        <span>{{ textVal }}</span>
      </div>
    </div>
  </div>
</template>

<script>
const isSupportLineClamp = document.body.style.webkitLineClamp !== undefined;

export default {
  name: 'Ellipsis',
  props: {
    lines: {
      type: Number,
      default: 0,
    },
    text: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      style: '',
      targetCount: 0,
      textVal: '',
      isSupportLineClamp,
      showExtra: true,
    };
  },

  mounted() {
    this.computeLine();

    this.$nextTick(() => {
      this.computeLine(true);
    });
  },

  methods: {
    computeLine(showExtra) {
      const lines = this.lines;
      const text = this.text;
      if (lines) {
        const { root, shadowChildren, shadow, content } = this.$refs;
        const lineHeight = parseInt(getComputedStyle(root).lineHeight, 10);
        const targetHeight = lines * lineHeight;
        content.style.height = `${targetHeight}px`;
        const totalHeight = shadowChildren.offsetHeight;
        const shadowNode = shadow.firstChild;

        if (totalHeight <= targetHeight) {
          this.textVal = text;
          this.targetCount = text.length;
          if (showExtra) {
            this.showExtra = false;
          }
          return;
        }

        // bisection
        const len = this.text.length;
        const mid = Math.ceil(len / 2);
        const count = this.bisection(targetHeight, mid, 0, len, text, shadowNode);
        this.textVal = text;
        this.targetCount = isSupportLineClamp ? count : count * 2 + 1;
      }
    },

    bisection(th, m, b, e, text, shadowNode) {
      const suffix = '...';
      let mid = m;
      let end = e;
      let begin = b;
      shadowNode.innerHTML = text.substring(0, mid) + suffix;
      let sh = shadowNode.offsetHeight;

      if (sh <= th) {
        shadowNode.innerHTML = text.substring(0, mid + 1) + suffix;
        sh = shadowNode.offsetHeight;
        if (sh > th || mid === begin) {
          return mid;
        }
        begin = mid;
        if (end - begin === 1) {
          mid = 1 + begin;
        } else {
          mid = Math.floor((end - begin) / 2) + begin;
        }
        return this.bisection(th, mid, begin, end, text, shadowNode);
      }
      if (mid - 1 < 0) {
        return mid;
      }
      shadowNode.innerHTML = text.substring(0, mid - 1) + suffix;
      sh = shadowNode.offsetHeight;
      if (sh <= th) {
        return mid - 1;
      }
      end = mid;
      mid = Math.floor((end - begin) / 2) + begin;
      return this.bisection(th, mid, begin, end, text, shadowNode);
    },
  },
};
</script>

<style lang="scss" scoped>
.ellipsis-index-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: initial;
  -webkit-box-orient: vertical;
  word-break: break-all;
}
.ellipsis {
  display: inline-block;
  max-width: 70%;
  overflow: hidden;
  word-break: break-all;
}

.lines {
  position: relative;
  .shadow {
    position: absolute;
    z-index: -999;
    display: block;
    color: transparent;
    opacity: 0;
  }
}

.lineClamp {
  position: relative;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
