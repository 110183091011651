var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "zxTag" },
    _vm._l(_vm.options, function (tag) {
      return _c(
        "el-tag",
        {
          key: tag.label,
          attrs: {
            type: _vm.item.type,
            closable: _vm.item.closable,
            "disable-transitions": _vm.item.disableTransitions,
            hit: _vm.item.hit,
            color: _vm.item.color,
            size: _vm.item.size,
            effect: _vm.item.effect,
          },
          on: {
            close: function ($event) {
              return _vm.close(tag)
            },
            click: function ($event) {
              return _vm.click(tag)
            },
          },
        },
        [_vm._v("\n    " + _vm._s(tag.label) + "\n  ")]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }