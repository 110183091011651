// 仓库管理 权限路由
const warehouseManagementRoutes = [
  // 仓库管理新增/编辑 权限路由
  {
    path: '/warehouseConfig/addOrEdit',
    component: (resolve) => require(['@/views/Storage/WarehouseManagement/warehouseConfig/addOrEdit'], resolve),
    hidden: false,
    meta: {
      title: '仓库管理新增/编辑',
      layout: 'ProductLayout',
      hidden: true,
      activeMenu: '/warehouseManagement',
      parentMenu: '/warehouseManagement/warehouseConfig',
      authority: ['NO-AUTHORITY'],
    },
  },
  // 仓库配置 关联设备 权限路由
  {
    path: '/warehouseConfig/relatedEquipment',
    component: (resolve) => require(['@/views/Storage/WarehouseManagement/warehouseConfig/relatedEquipment'], resolve),
    hidden: false,
    meta: {
      title: '仓库管理-关联设备',
      layout: 'ProductLayout',
      hidden: true,
      activeMenu: '/warehouseManagement',
      parentMenu: '/warehouseManagement/warehouseConfig',
      authority: ['NO-AUTHORITY'],
    },
  },
  // 仓库配置 历史截图 权限路由
  {
    path: '/warehouseConfig/positionHistory',
    component: (resolve) => require(['@/views/Storage/WarehouseManagement/warehouseConfig/positionHistory'], resolve),
    hidden: false,
    meta: {
      title: '仓库管理',
      layout: 'ProductLayout',
      hidden: true,
      activeMenu: '/warehouseManagement',
      parentMenu: '/warehouseManagement/warehouseConfig',
      authority: ['NO-AUTHORITY'],
    },
  },
  // 仓库配置 库栋 权限路由
  {
    path: '/storinglocationManagement/index',
    component: (resolve) => require(['@/views/Storage/WarehouseManagement/storinglocationManagement/index'], resolve),
    hidden: false,
    meta: {
      title: '仓库管理',
      layout: 'ProductLayout',
      hidden: true,
      activeMenu: '/warehouseManagement',
      parentMenu: '/warehouseManagement/warehouseConfig',
      authority: ['NO-AUTHORITY'],
    },
  },
  // 库栋管理新增/编辑
  {
    path: '/storinglocationManagement/addOrEdit',
    component: (resolve) => require(['@/views/Storage/WarehouseManagement/storinglocationManagement/addOrEdit'], resolve),
    hidden: false,
    meta: {
      title: '库栋管理新增/编辑',
      layout: 'ProductLayout',
      hidden: true,
      activeMenu: '/warehouseManagement',
      parentMenu: '/warehouseManagement/warehouseConfig',
      authority: ['NO-AUTHORITY'],
    },
  },
  // 库栋历史截图
  {
    path: '/storinglocationManagement/positionHistory',
    component: () =>
      import(
        /* webpackChunkName: "SuperviseCompanyAddorEdit" */ '@/views/Storage/WarehouseManagement/storinglocationManagement/positionHistory'
      ),
    hidden: false,
    meta: {
      title: '库栋历史截图',
      layout: 'ProductLayout',
      hidden: true,
      activeMenu: '/warehouseManagement',
      parentMenu: '/warehouseManagement/warehouseConfig',
      authority: ['NO-AUTHORITY'],
    },
  },
  // 库栋管理-关联设备
  {
    path: '/storinglocationManagement/relatedEquipment',
    component: () =>
      import(
        /* webpackChunkName: "SuperviseCompanyAddorEdit" */ '@/views/Storage/WarehouseManagement/storinglocationManagement/relatedEquipment'
      ),
    hidden: false,
    meta: {
      title: '库栋管理-关联设备',
      layout: 'ProductLayout',
      hidden: true,
      activeMenu: '/warehouseManagement',
      parentMenu: '/warehouseManagement/warehouseConfig',
      authority: ['NO-AUTHORITY'],
    },
  },

  // 新增/编辑存货企业
  {
    path: '/warehouseManagement/bailorManagement/addOrEdit',
    component: (resolve) => require(['@/views/Storage/WarehouseManagement/bailorManagement/addOrEdit'], resolve),
    hidden: false,
    meta: {
      title: '存货企业新增/编辑',
      layout: 'ProductLayout',
      hidden: true,
      activeMenu: '/warehouseManagement',
      parentMenu: '/warehouseManagement/bailorManagement',
      authority: ['NO-AUTHORITY'],
    },
  },
  // 关联仓库
  {
    path: '/warehouseManagement/bailorManagement/associatedWarehouse',
    component: (resolve) => require(['@/views/Storage/WarehouseManagement/bailorManagement/associatedWarehouse'], resolve),
    hidden: false,
    meta: {
      title: '关联仓库',
      layout: 'ProductLayout',
      hidden: true,
      activeMenu: '/warehouseManagement',
      parentMenu: '/warehouseManagement/bailorManagement',
      authority: ['NO-AUTHORITY'],
    },
  },

  //
  {
    path: '/palletManagement/addOrEdit',
    component: (resolve) => require(['@/views/Storage/WarehouseManagement/palletManagement/AddOrEdit.vue'], resolve),
    hidden: false,
    meta: {
      title: '托盘调整',
      layout: 'ProductLayout',
      hidden: true,
      activeMenu: '/warehouseManagement',
      parentMenu: '/warehouseManagement/palletManagement',
      authority: ['NO-AUTHORITY'],
    },
  },
  {
    path: '/palletManagement/detail',
    component: (resolve) => require(['@/views/Storage/WarehouseManagement/palletManagement/detail.vue'], resolve),
    hidden: false,
    meta: {
      title: '托盘调整',
      layout: 'ProductLayout',
      hidden: true,
      activeMenu: '/warehouseManagement',
      parentMenu: '/warehouseManagement/palletManagement',
      authority: ['NO-AUTHORITY'],
    },
  },
  // {
  //   path: "/warehouseManagement/IotDevicesManagement/addOrEdit",
  //   name: "IotDevicesManagementAdd",
  //   component: (resolve) => require(/* webpackChunkName: "IotDevicesManagement" */["@/views/Storage/WarehouseManagement/IotDevicesManagement/addOrEdit"], resolve),
  //   meta: {
  //     title: "设备管理新增",
  //     activeMenu: "/warehouseManagement",
  //     layout: 'ProductLayout',
  //     class: "menu-level-first",
  //     hidden: true,
  //     parentMenu: '/warehouseManagement/IotDevicesManagement',
  //     authority: ['NO-AUTHORITY'],
  //   },
  // },
  {
    path: '/inboundPlan/AddOrEdit',
    name: 'AddOrEdit',
    component: (resolve) => require(['@/views/Storage/InboundManagement/inboundPlan/AddOrEdit'], resolve),
    meta: {
      title: '新增入库计划',
      layout: 'ProductLayout',
      hidden: true,
      activeMenu: '/inboundManagement',
      parentMenu: '/inboundManagement/inboundPlan',
      authority: ['NO-AUTHORITY'],
    },
  },
  // 现场监控
  {
    path: '/inventoryManagement/inventoryDealer/monitoring',
    name: 'inventoryManagementMonitor',
    component: (resolve) =>
      require(['@/views/Storage/InventoryManagement/storageMange/inventoryDealer/monitoring'], resolve),
    meta: {
      title: '现场监控',
      layout: 'ProductLayout',
      hidden: true,
      activeMenu: '/inventoryManagement',
      parentMenu: '/inventoryManagement/monitoring',
      authority: ['NO-AUTHORITY'],
    },
  },
];

export default warehouseManagementRoutes;
