// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Variables */
[data-v-6b5dcbfb]:export {
  menuText: #000;
  menuActiveText: #fff;
  subMenuActiveText: #f4f4f5;
  menuActiveBg: #1589cd;
  menuBg: #fff;
  menuHover: rgba(45, 153, 255, 0.3);
  subMenuBg: #fff;
  subMenuHover: rgba(45, 153, 255, 0.3);
  sideBarWidth: 200px;
  primaryColor: #1589cd;
  primaryFontColor: rgb(45, 153, 255);
  primaryColor_8: rgba(31, 113, 255, 0.08);
  globalColor: #e60012;
  defaultFontColor: rgb(20, 35, 69);
  tableHeaderBg: #fafafa;
  tableHeaderHeight: 40px;
  tableAlign: center;
  fontSize_14: 14px;
  fontSize_16: 16px;
  fontSize_18: 18px;
  fontSize_24: 24px;
}

/* Mixins */
.custom-dialog .dialog-title[data-v-6b5dcbfb] {
  font-size: 18px;
  color: #17335B;
  line-height: 50px;
}
.custom-dialog .dialog-title-center[data-v-6b5dcbfb] {
  text-align: center;
}
.custom-dialog .dialog-footer-center[data-v-6b5dcbfb] {
  text-align: center;
}
[data-v-6b5dcbfb] .el-button--primary {
  color: #FFFFFF;
  background-color: #1589cd;
  border-color: #1589cd;
}
[data-v-6b5dcbfb] .el-dialog__header {
  border-bottom: 1px solid #e8e8e8;
  padding: 0 0 0 24px;
}
[data-v-6b5dcbfb] .el-dialog__body {
  padding: 24px;
}
[data-v-6b5dcbfb] .el-dialog__title {
  font-size: 18px;
  color: #1589cd;
  line-height: 50px;
}
[data-v-6b5dcbfb] .el-dialog__footer {
  padding: 0 24px 48px;
  text-align: center;
}
[data-v-6b5dcbfb] .el-button {
  height: 32px;
  line-height: 32px;
  padding: 0 20px;
  margin-left: 0;
}
[data-v-6b5dcbfb] .el-button:not(:last-child) {
  margin-right: 20px;
}
[data-v-6b5dcbfb] .el-dialog__headerbtn {
  font-size: 20px;
  top: 16px;
}
[data-v-6b5dcbfb] .el-dialog__close {
  color: rgba(0, 0, 0, 0.65);
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
