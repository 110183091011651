const beforeAuth = [
  {
    path: '/authing',
    name: 'Authing',
    component: () => import(/* webpackChunkName: 'toAuth' */ '@/views/BeforeAuthenticate/corpAuth/BeforeAuthCard.vue'),
    meta: { title: '未认证', class: 'menu-level-first', layout: 'Layout' },
  },
  {
    path: '/beforeAuthForm',
    name: 'BeforeAuthForm',
    component: () => import(/* webpackChunkName: 'toAuth' */ '@/views/BeforeAuthenticate/corpAuth/BeforeAuthForm.vue'),
    meta: { title: '企业实名认证', class: 'menu-level-first', layout: 'Layout' },
  },
  {
    path: '/authDetail',
    name: 'AuthDetail',
    component: () => import(/* webpackChunkName: 'toAuth' */ '@/views/BeforeAuthenticate/corpAuth/AuthDetailPage.vue'),
    meta: { title: '企业实名认证详情', class: 'menu-level-first', layout: 'Layout' },
  },
  {
    path: '/authBack',
    name: 'AuthBack',
    component: () => import(/* webpackChunkName: 'toAuth' */ '@/views/BeforeAuthenticate/corpAuth/AuthBackPage.vue'),
    meta: { title: '企业实名认证退回', class: 'menu-level-first', layout: 'Layout' },
  },
  {
    path: '/beforePersonAuth',
    name: 'BeforePersonAuth',
    component: () => import(/* webpackChunkName: 'toAuth' */ '@/views/BeforeAuthenticate/personAuth/BeforeAuth.vue'),
    meta: { title: '个人未实名认证', class: 'menu-level-first', layout: 'Layout' },
  },
  {
    path: '/personAuthForm',
    name: 'PersonAuthForm',
    component: () => import(/* webpackChunkName: 'toAuth' */ '@/views/BeforeAuthenticate/personAuth/PersonAuthForm.vue'),
    meta: { title: '个人实名认证', class: 'menu-level-first', layout: 'Layout' },
  },
  {
    path: '/agreementSigning',
    name: 'AgreementSigning',
    component: () => import(/* webpackChunkName: 'toAuth' */ '@/views/BeforeAuthenticate/protocolVerification/AgreementSigning.vue'),
    meta: { title: '协议签署', class: 'menu-level-first', layout: 'Layout' },
  },
]

export default beforeAuth;