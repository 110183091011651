// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Variables */
[data-v-616f8769]:export {
  menuText: #000;
  menuActiveText: #fff;
  subMenuActiveText: #f4f4f5;
  menuActiveBg: #1589cd;
  menuBg: #fff;
  menuHover: rgba(45, 153, 255, 0.3);
  subMenuBg: #fff;
  subMenuHover: rgba(45, 153, 255, 0.3);
  sideBarWidth: 200px;
  primaryColor: #1589cd;
  primaryFontColor: rgb(45, 153, 255);
  primaryColor_8: rgba(31, 113, 255, 0.08);
  globalColor: #e60012;
  defaultFontColor: rgb(20, 35, 69);
  tableHeaderBg: #fafafa;
  tableHeaderHeight: 40px;
  tableAlign: center;
  fontSize_14: 14px;
  fontSize_16: 16px;
  fontSize_18: 18px;
  fontSize_24: 24px;
}

/* Mixins */
.space-check-button[data-v-616f8769] .el-checkbox-button {
  margin-right: 10px;
}
.space-check-button[data-v-616f8769] .el-checkbox-button:first-child .el-checkbox-button__inner {
  border-left: 1px solid #F5F7FE;
}
.space-check-button[data-v-616f8769] .el-checkbox-button:first-child .is-checked .el-checkbox-button__inner {
  border-left: 1px solid #1589cd;
}
.space-check-button[data-v-616f8769] .el-checkbox-button:first-child.is-checked .el-checkbox-button__inner {
  border-left: 1px solid #1589cd;
}
.space-check-button[data-v-616f8769] .el-checkbox-button:hover .el-checkbox-button__inner {
  color: #1589cd;
  background: rgba(31, 113, 255, 0.2);
  border-color: transparent;
}
.space-check-button[data-v-616f8769] .el-checkbox-button__inner {
  padding: 9px 20px;
  border-radius: 4px;
  border: 1px solid #F5F7FE;
  background: #F5F7FE;
  color: rgb(20, 35, 69);
}
.space-check-button[data-v-616f8769] .el-checkbox-button.is-focus .el-checkbox-button__inner {
  border-radius: 4px;
}
.space-check-button[data-v-616f8769] .el-checkbox-button.is-checked .el-checkbox-button__inner {
  border-radius: 4px;
  background: transparent;
  color: #1589cd;
  border-top: 1px solid #1589cd;
  border-bottom: 1px solid #1589cd;
  border-right: 1px solid #1589cd;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
