import "/mnt/data/jenkins/home/workspace/hd-prod-w51-web-portal/node_modules/core-js/modules/es.array.iterator.js";
import "/mnt/data/jenkins/home/workspace/hd-prod-w51-web-portal/node_modules/core-js/modules/es.promise.js";
import "/mnt/data/jenkins/home/workspace/hd-prod-w51-web-portal/node_modules/core-js/modules/es.object.assign.js";
import "/mnt/data/jenkins/home/workspace/hd-prod-w51-web-portal/node_modules/core-js/modules/es.promise.finally.js";
import 'babel-polyfill';
import Vue from 'vue';
import Storage from 'vue-ls';
import Cookies from 'js-cookie';
import AOS from '@lily_li/aos';
import config from '@/defaultSettings.js';
import 'normalize.css';
import './registerServiceWorker';
import router from './router';
import store from './store';
import './icons';
import './permission';
import '@lily_li/aos/dist/aos.css';
import ElementUI from 'element-ui';
import '@/assets/styles/element-variables.scss';
import '@/assets/styles/index.scss'; // global css
import { formattedDate, numberFormatCnt, numberFormatRate, numberFormatAmt, selectDictLabel } from '@/utils/utils';
import theConfirm from './components/Confirm/confirm.js';
import Print from 'vue-print-nb';
import tool from "@/utils/tool";
Vue.use(tool);
import './global';
import App from './App.vue';
Vue.prototype.formattedDate = formattedDate;
Vue.prototype.numberFormatCnt = numberFormatCnt;
Vue.prototype.numberFormatRate = numberFormatRate;
Vue.prototype.numberFormatAmt = numberFormatAmt;
Vue.prototype.selectDictLabel = selectDictLabel;
AOS.init({
  container: '.home-container.el-scrollbar__wrap'
});
Vue.config.productionTip = false;
Vue.use(Storage, config.storageOptions);
Vue.use(Print);
Vue.use(ElementUI, {
  size: Cookies.get('size') || 'medium' // set element-ui default size
});
Vue.prototype.$theConfirm = theConfirm;
Vue.config.devtools = true;
new Vue({
  router: router,
  store: store,
  render: function render(h) {
    return h(App);
  }
}).$mount('#app');