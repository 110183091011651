import store from '@/store'

function authPermission(permission, permissions) {
  permissions = permissions?.length > 0 ? permissions : store.getters?.resouceCodes;
  if (permission === 'NO-AUTHORITY') {
    return true;
  }
  if (permission && permission.length > 0) {
    return permissions.some(v => {
      return v === permission
    })
  } else {
    return false
  }
}

export default {
  // 验证用户是否具备某权限
  hasPermi(permission, btnList) {
    return authPermission(permission, btnList);
  },

  // 验证用户是否含有指定权限，只需包含其中一个
  hasPermiOr(permissions, btnList) {
    return permissions.some(item => {
      return authPermission(item, btnList)
    })
  },
}
