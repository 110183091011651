export const isValidUsername = (str) => ['admin', 'editor'].indexOf(str.trim()) >= 0;

export const isExternal = (path) => /^(https?:|mailto:|tel:)/.test(path);

export const isArray = (arg) => {
  if (typeof Array.isArray === 'undefined') {
    return Object.prototype.toString.call(arg) === '[object Array]';
  }
  return Array.isArray(arg);
};

export function isNull(value) {
  if (value === '' || value === null || value === undefined) {
    return true;
  }
  return false;
}

export function isObject(object) {
  return object !== null && typeof object === 'object';
}

export function isEmptyObject(object) {
  return isObject(object) && Object.keys(object).length < 1;
}

/**
 * 判断2个值是否松散相等
 * @param {*} a
 * @param {*} b
 * @returns boolen
 */
export function looseEqual(a, b) {
  if (a === b) return true;
  const isObjectA = isObject(a);
  const isObjectB = isObject(b);
  if (isObjectA && isObjectB) {
    try {
      const isArrayA = Array.isArray(a);
      const isArrayB = Array.isArray(b);
      if (isArrayA && isArrayB) {
        return (
          a.length === b.length &&
          a.every((e, i) => {
            return looseEqual(e, b[i]);
          })
        );
      } else if (a instanceof Date && b instanceof Date) {
        return a.getTime() === b.getTime();
      } else if (!isArrayA && !isArrayB) {
        const keysA = Object.keys(a);
        const keysB = Object.keys(b);
        return (
          keysA.length === keysB.length &&
          keysA.every((key) => {
            return looseEqual(a[key], b[key]);
          })
        );
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  } else if (!isObjectA && !isObjectB) {
    return String(a) === String(b);
  } else {
    return false;
  }
}

export const isValidURL = (url) => {
  const reg =
    /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;
  return reg.test(url);
};

export function isMobilePhone(str) {
  const reg = /^1([358][0-9]|4[579]|66|7[0135678]|9[89])[0-9]{8}$/;
  return reg.test(str);
}

export function isCaptcha(str) {
  const reg = /^[0-9]+.?[0-9]*$/;
  return reg.test(str);
}

export function isNotNull(value) {
  if (value === '' || value === null || value === undefined) {
    return false;
  }
  return true;
}
// 正整数
export function isInterger(str) {
  if (str && String(str).charAt(0) === '-') {
    return false;
  }
  const reg = /^[1-9]\d*$/;
  return reg.test(str) && Number(str) !== 0;
}
// 正浮点数
export function isNumber(str) {
  if (str && String(str).charAt(0) === '-') {
    return false;
  }
  const reg1 = /^[1-9]\d*\.\d*$|^0\.\d*[1-9]\d*$/;
  const reg2 = /^[1-9]\d*$/;
  return (reg1.test(str) || reg2.test(str)) && Number(str) !== 0;
}
// 0 和正整数
export function isZeroOrInterger(str) {
  if (str && String(str).charAt(0) === '-') {
    return false;
  }
  const reg = /^[1-9]\d*$/;
  return reg.test(str) || Number(str) === 0;
}
// 0 和正浮点数
export function isZeroOrNumber(str) {
  if (str && String(str).charAt(0) === '-') {
    return false;
  }
  const reg1 = /^[1-9]\d*\.\d*$|^0\.\d*[1-9]\d*$/;
  const reg2 = /^[1-9]\d*$/;
  return reg1.test(str) || reg2.test(str) || Number(str) === 0;
}
// 校验整数位不能超过n位，小数位不能超过2位
export function isFourNumber(str, n) {
  const strs = String(str).split('.');
  if (strs[0].length > n) {
    return `整数位不得超过${n}位`;
  }
  if (strs[1] && strs[1].length > 2) {
    return '小数位不得超过2位';
  }
  return undefined;
}
// 校验不能大于n，小数位不能超过2位
export function isNumberBigN(str, n) {
  const strs = String(str).split('.');
  if (Number(str) > n) {
    return `不得大于${n}`;
  }
  if (strs[1] && strs[1].length > 2) {
    return '小数位不得超过2位';
  }
  return undefined;
}
// 校验整数不能超过8位
export function isEightNumber(str) {
  const strs = String(str).length;
  if (strs > 8) {
    return '整数不得超过8位';
  }
  return undefined;
}
// 校验整数值范围
export function isNumberSize(str, left, right) {
  if (left !== null && str < left) {
    return `需大于等于${left}`;
  }
  if (right !== null && str > right) {
    return `需小于等于${right}`;
  }

  return undefined;
}

// 统一社会信用代码
export function isCreditCode(str) {
  const reg1 = /^[0-9]{18}$/;
  const reg2 = /^(?![0-9]+$)(?![A-Z]+$)[0-9A-Z]{18}$/;
  const reg3 = /^\d{15}$/;
  return reg1.test(str) || reg2.test(str) || reg3.test(str);
}

// 发票号码
export function isInvoiceNum(str) {
  const reg1 = /^[0-9]{8}$/;
  return reg1.test(str);
}

// 身份证校验
export function idNoPattern(str) {
  // return /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9X]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}$)/.test(
  //   str
  // );
  return /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)/.test(str);
}

// 台湾来往大陆通行证
export function TWTrafficPermit(str) {
  return /^\d{8}$/.test(str);
}

// 港澳来往大陆通行证
export function HKTrafficPermit(str) {
  return /^[A-Z]{1}(?:\d{8}|\d{10})$/.test(str);
}

// 护照
export function passportPattern(str) {
  return /^(?!^[a-zA-Z]+$)[0-9a-zA-Z]{9}$/.test(str);
}

// 校验小数位不能超过n位, n>0
export function isNumberDecimalN(str, n) {
  const strs = String(str).split('.');
  if (strs[1] && strs[1].length > n) {
    return `小数位不得超过${n}位`;
  }
  return undefined;
}

/**
 * @description 校验企业名称
 */
export const validateCorpName = (rule, value, callback) => {
  const defaultTitle = rule?.title || '企业名称';
  if (!value) {
    callback(new Error(`请输入${defaultTitle}`));
  } else if (value.trim().includes(' ')) {
    callback(new Error(`请输入正确的${defaultTitle}`));
  } else if (value.trim().length > 50) {
    callback(new Error(`${defaultTitle}长度不超过50位`));
  } else {
    callback();
  }
};

/**
 * @description 校验非必填，长度和空格校验
 */
export const validateCommonRule = (rule, value, callback) => {
  const defaultTitle = rule?.title || '名称';
  const length = rule.length || 100;
  if (!rule.needSpace && value.trim().includes(' ')) {
    callback(new Error(`请输入正确的${defaultTitle}`));
  } else if (value.length > length) {
    callback(new Error(`${defaultTitle}长度不超过${length}位`));
  } else {
    callback();
  }
};

/**
 * @description 校验必填，长度和空格校验
 */
export const validateRequiredRule = (rule, value, callback) => {
  const defaultTitle = rule?.title || '名称';
  const length = rule.length || 100;
  if (!value) {
    callback(new Error(`请输入${defaultTitle}`));
  } else if (!rule.needSpace && value.trim().includes(' ')) {
    callback(new Error(`请输入正确的${defaultTitle}`));
  } else if (value.length > length) {
    callback(new Error(`${defaultTitle}长度不超过${length}位`));
  } else {
    callback();
  }
};

/**
 * @description 校验disabled输入框的特殊校验
 */
export const validateDisabledInputRule = (rule, value, callback) => {
  if (!value && rule.show) {
    callback(new Error(rule.message));
  } else {
    callback();
  }
};

/**
 * @description 姓名
 */
export const validateUserName = (rule, value, callback) => {
  const defaultTitle = rule?.title || '姓名';
  // eslint-disable-next-line no-control-regex
  const isChineseCharacters = value && value.match(/[\W]/g, '');
  if (!value) {
    callback(new Error(`请输入${defaultTitle}`));
  } else if (isChineseCharacters) {
    callback(new Error('只能输入数字或字母'));
  } else if (value.trim().includes(' ')) {
    callback(new Error(`请输入正确的${defaultTitle}`));
  } else if (value.trim().length > 50) {
    callback(new Error(`${defaultTitle}长度不超过50位`));
  } else {
    callback();
  }
};

export const validateUserNo = (rule, value, callback) => {
  const defaultTitle = rule?.title || '姓名';
  // eslint-disable-next-line no-control-regex
  const isChineseCharacters = value && value.match(/[\W]/g, '');
  if (isChineseCharacters) {
    callback(new Error('只能输入数字或字母'));
  } else if (value.trim().includes(' ')) {
    callback(new Error(`请输入正确的${defaultTitle}`));
  } else if (value.trim().length > 50) {
    callback(new Error(`${defaultTitle}长度不超过50位`));
  } else {
    callback();
  }
};
export const validateEmail = (rule, value, callback) => {
  var reg = /^([a-zA-Z0-9]+[_|-|.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|-|.]?)*[a-zA-Z0-9]+.[a-zA-Z]{2,3}$/gi;
  if (String(value).length > 100) {
    callback(new Error(`电子邮箱长度不超过100位`));
  } else if (String(value).length > 0 && !reg.test(value)) {
    callback(new Error(`请输入正确的电子邮箱`));
  } else {
    callback();
  }
};

/**
 * @description 统一社会信用代码
 */
export const validateCreditCode = (rule, value, callback) => {
  const defaultTitle = rule?.title || '统一社会信用代码';
  if (!value) {
    callback(new Error(`请输入${defaultTitle}`));
  } else if (value.includes(' ')) {
    callback(new Error(`请输入正确的${defaultTitle}`));
  } else if (!isCreditCode(value)) {
    callback(new Error(`格式输入错误`));
  } else {
    callback();
  }
};

/**
 * @description 发票号码
 */
export const validateCreditNum = (rule, value, callback) => {
  const defaultTitle = rule?.title || '发票号码';
  if (!value) {
    callback(new Error(`请输入${defaultTitle}`));
  } else if (value.includes(' ')) {
    callback(new Error(`请输入正确的${defaultTitle}`));
  } else if (!isInvoiceNum(value)) {
    callback(new Error(`发票号码为8位`));
  } else {
    callback();
  }
};

/**
 * @description 校验银行账号
 */
export const validateAccountNo = (rule, value, callback) => {
  const defaultTitle = rule?.title || '银行账号';
  if (!value) {
    callback(new Error(`请输入${defaultTitle}`));
  } else if (value.trim().includes(' ')) {
    callback(new Error(`请输入正确的${defaultTitle}`));
  } else if (!/^([1-9]{1})(\d{15}|\d{18})$/.test(value)) {
    callback(new Error(`${defaultTitle}为15~19位数字`));
  } else {
    callback();
  }
};

/**
 * @description 校验手机号
 */
export const validateMobilePhone = (rule, value, callback) => {
  const pattern = /1[0-9]\d{9}$/;
  const defaultTitle = rule?.title || '手机号码';
  const reg1 = new RegExp(/\s/);
  if (!value) {
    callback(new Error(`请输入${defaultTitle}`));
  } else if (value.trim().length < 11 || reg1.test(value)) {
    callback(new Error(`请输入正确的${defaultTitle}`));
  } else if (value.trim().length > 11) {
    callback(new Error(`${defaultTitle}不超过11位`));
  } else if (!value.trim().match(pattern)) {
    callback(new Error(`${defaultTitle}格式有误`));
  } else {
    callback();
  }
};

/**
 * @description 校验手机短信验证码
 */
export const validateMsgCode = (rule, value, callback) => {
  const pattern = /^[0-9]+$/;
  if (!value) {
    callback(new Error('请输入您收到的手机验证码'));
  } else if (value.trim().length !== 6) {
    callback(new Error('手机验证码长度有误'));
  } else if (!value.trim().match(pattern)) {
    callback(new Error('手机验证码只能输入数字'));
  } else {
    callback();
  }
};

/**
 * @description 图片验证码校验
 */
export const validatePicCaptcha = (rule, value, callback) => {
  const pattern = /^[0-9a-zA-Z]+$/;
  if (!value) {
    callback(new Error('请输入验证码'));
  } else if (value.trim().length !== 4) {
    callback(new Error('验证码长度有误'));
  } else if (!value.trim().match(pattern)) {
    callback(new Error('验证码只能输入数字或字母'));
  } else {
    callback();
  }
};

/**
 * @description 仓单编码校验
 */
export const binCodeCheck = (rule, value, callback) => {
  const pattern = /^[0-9a-zA-Z]+$/;
  if (!value) {
    callback(new Error('请输入仓单编码'));
  } else if (value.trim().length > 10) {
    callback(new Error('请输入1~10位数字或字母仓单编码'));
  } else if (!value.trim().match(pattern)) {
    callback(new Error('仓单编码只能输入数字或字母'));
  } else {
    callback();
  }
};

/**
 * @description 金额校验
 */
export const moneyCheck = (rule, value, callback) => {
  value = String(value);
  const pattern1 = /^0+(\d)/; // 不支持首位为0
  const pattern2 = /^(\d+\.\d\d\d).*$/; // 最多支持2位小数
  const pattern3 = /(\d{9})\d*/; // 最多支持8位整数
  const pattern4 = /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/; // 输入数字与小数点 格式不正确

  if (!value) {
    callback(new Error(rule?.title));
  } else if (Number(value) <= 0) {
    callback(new Error('请输入正数'));
  } else if (value.trim().match(pattern1)) {
    callback(new Error('不支持首位为0'));
  } else if (value.trim().match(pattern2)) {
    callback(new Error('最多支持2位小数'));
  } else if (value.trim().match(pattern3)) {
    callback(new Error('最多支持8位整数'));
  } else if (!value.trim().match(pattern4)) {
    callback(new Error('格式不正确'));
  } else {
    callback();
  }
};

/**
 * @description 校验密码
 */
export const validatePassword = (rule, value, callback) => {
  const reg = /((^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$])(?=.*\d)[\da-zA-Z!@#$]{8,16}$))/;
  // eslint-disable-next-line no-control-regex
  // const isChineseCharacters = value && value.match(/^[^!@#$]/g,'');
  const defaultTitle = rule?.title || '密码';
  if (!value) {
    callback(`请输入${defaultTitle}`);
  } else if (value && !reg.test(value)) {
    callback(' 请输入8-16个由大小写字母、数字和（!@#$）中任意字符组成的密码');
  } else if (value.indexOf(' ') !== -1) {
    callback('请勿输入空格');
  } else {
    callback();
  }
};

/**
 * @description 校验新密码
 */
export const validateNewPassword = (rule, value, callback) => {
  const reg = /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,16}$/;
  // eslint-disable-next-line no-control-regex
  // const isChineseCharacters = value && value.match(/^[^!@#$]/g, '');
  if (!value) {
    callback('请输入新密码');
  } else if (value && !reg.test(value)) {
    callback('请输入8~16位数字、字母和（!@#$）字符组合密码');
    // } else if (isChineseCharacters) {
    //   callback('请输入8~16位数字、字母和（!@#$）字符组合密码');
  } else if (value.indexOf(' ') !== -1) {
    callback('请勿输入空格');
  } else {
    callback();
  }
};
export const validateSencondNewPassword = (rule, value, callback) => {
  const reg = /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,16}$/;
  // eslint-disable-next-line no-control-regex
  const isChineseCharacters = value && value.match(/^[^!@#$]/g, '');
  if (!value) {
    callback('请重复输入新密码');
  } else if (value && !reg.test(value)) {
    callback('密码必须为8~16位数字、字母和特殊字符组合密码');
  } else if (isChineseCharacters) {
    callback('请输入8~16位数字、字母和（!@#$）字符组合密码');
  } else if (value.indexOf(' ') !== -1) {
    callback('请勿输入空格');
  } else {
    callback();
  }
};
export const validateUUID = (rule, value, callback) => {
  const reg = /^[0-9a-f]{32}$/;
  if (!value) {
    callback('请输入机构编码');
  } else if (value && !reg.test(value)) {
    callback('必须为32位数字和字母组合编码');
  } else {
    callback();
  }
};
/**
 * @description 正整数不包括 0
 */
export const positiveInteger = (rule, value, callback) => {
  value = String(value);
  const pattern = /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/;
  const defaultTitle = rule?.title;
  if (!value) {
    callback(new Error(`请输入${defaultTitle}`));
  } else if (Number(value) <= 0) {
    callback(new Error(`请输入正数`));
  } else if (!value.trim().match(pattern)) {
    callback(new Error(`请输入正数`));
  } else {
    callback();
  }
};
// 正整数包阔 两位小数 最大限制 八位整数 与 两位小数
export const isZeroOrInterger1 = (rule, value, callback) => {
  value = String(value);
  const arr = value.split('.');
  const pattern = /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/;
  if (value == 'null' || !value || value == 'undefined') {
    callback();
  } else {
    if (Number(value) <= 0) {
      callback(new Error(`请输入正数`));
    } else if (!value.trim().match(pattern)) {
      callback(new Error(`最大支持输入8位整数加2位小数，不支持首位为0`));
    } else if (arr[0]?.length > 8 || arr[1]?.length > 2) {
      callback(new Error(`最大支持输入8位整数加2位小数`));
    } else {
      callback();
    }
  }
};
// 正整数包阔 两位小数
export const floatingPointNumber = (rule, value, callback) => {
  value = String(value);
  const pattern = /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/;
  const defaultTitle = rule?.title;
  if (!value || value === 'undefined') {
    callback(new Error(`请输入${defaultTitle}`));
  } else if (Number(value) <= 0) {
    callback(new Error(`请输入正数`));
  } else if (!value.trim().match(pattern)) {
    callback(new Error(`只能输入正数和两位小数`));
  } else {
    callback();
  }
};
// 正整数
export const floatingPointNumber1 = (rule, value, callback) => {
  value = String(value);
  const pattern = /^[1-9]\d*$/;
  // const defaultTitle = rule?.title;
  if (!value) {
    callback();
  } else if (Number(value) <= 0) {
    callback(new Error(`请输入正整数`));
  } else if (!value.trim().match(pattern)) {
    callback(new Error(`只能输入正整数`));
  } else {
    callback();
  }
};

// 正整数包阔 两位小数
export const floatingPointNumber2 = (rule, value, callback) => {
  value = String(value);
  const pattern = /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/;
  if (Number(value) <= 0) {
    callback(new Error(`请输入正数`));
  } else if (!value.trim().match(pattern)) {
    callback(new Error(`只能输入正数和两位小数`));
  } else {
    callback();
  }
};

//金额
export function validateZeroOrNumber(rule, value, callback) {
  const reg1 = /^[1-9]\d*\.\d*$|^0\.\d*[1-9]\d*$/;
  const reg2 = /^[1-9]\d*$/;
  const defaultTitle = rule?.title;
  if (!value) {
    callback(new Error(`请输入${defaultTitle}`));
  } else if (Number(value) <= 0) {
    callback(new Error(`请输入正整数且大于0`));
  } else if (!(reg1.test(value) || reg2.test(value))) {
    callback(new Error(`请输入正整数`));
  } else {
    callback();
  }
}
// 输入比例
export const depositPaymentScale = (rule, value, callback) => {
  value = String(value);
  const pattern = /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/;
  const defaultTitle = rule?.title;
  if (!value) {
    callback(new Error(`请输入${defaultTitle}`));
  } else if (Number(value) < 0 || Number(value) > 100) {
    callback(new Error(`请输入0-100之间的数`));
  } else if (!value.trim().match(pattern)) {
    callback(new Error(`请保留两位小数`));
  } else {
    callback();
  }
};
export function validateZeroOrNumbe(rule, value, callback) {
  value = String(value);
  const pattern = /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/;
  const defaultTitle = rule?.title;
  if (!value) {
    callback(new Error(`请输入${defaultTitle}`));
  } else if (Number(value) <= 0) {
    callback(new Error(`只能输入大于0的正整数`));
  } else if (!value.trim().match(pattern)) {
    callback(new Error(`${defaultTitle}只能输入数字`));
  } else {
    callback();
  }
}
// 身份证校验封装
export function validateIdNo(rule, value, callback) {
  const message = rule?.message;
  console.log(idNoPattern(value), '555555');
  if (!idNoPattern(value)) {
    callback(new Error(`${message}`));
  } else {
    callback();
  }
}
