import Vue from 'vue';
import Vuex from 'vuex';
import getters from './getters';
import createLoadingPlugin from '@/utils/vuexLoading';

Vue.use(Vuex);

// 注意：modules文件夹里面以及子文件里面的js文件名不能重复

// https://webpack.js.org/guides/dependency-management/#requirecontext
const modulesFiles = require.context('./modules', true, /\.js$/);

// you do not need `import app from './modules/app'`
// it will auto require all vuex module from modules file
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  // set './app.js' => 'app'
  const modulePathStr = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1');
  const moduleName = modulePathStr.split('/').pop();
  const value = modulesFiles(modulePath);
  modules[moduleName] = value.default;
  return modules;
}, {});

const store = new Vuex.Store({
  plugins: [createLoadingPlugin()],
  modules,
  getters,
});

// 重置state
export const resetState = () => {
  Object.keys(modules).forEach((key) => {
    const { mutations } = modules[key] || {};
    const { RESET_STATE } = mutations;
    if (RESET_STATE) {
      store.commit(`${key}/RESET_STATE`);
    }
  });
  store.replaceState(Object.assign(store.state));
};

export default store;
