// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Variables */
[data-v-aa533c32]:export {
  menuText: #000;
  menuActiveText: #fff;
  subMenuActiveText: #f4f4f5;
  menuActiveBg: #1589cd;
  menuBg: #fff;
  menuHover: rgba(45, 153, 255, 0.3);
  subMenuBg: #fff;
  subMenuHover: rgba(45, 153, 255, 0.3);
  sideBarWidth: 200px;
  primaryColor: #1589cd;
  primaryFontColor: rgb(45, 153, 255);
  primaryColor_8: rgba(31, 113, 255, 0.08);
  globalColor: #e60012;
  defaultFontColor: rgb(20, 35, 69);
  tableHeaderBg: #fafafa;
  tableHeaderHeight: 40px;
  tableAlign: center;
  fontSize_14: 14px;
  fontSize_16: 16px;
  fontSize_18: 18px;
  fontSize_24: 24px;
}

/* Mixins */
.home-layout .top-fixed[data-v-aa533c32] {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
  background-color: #fff;
}
.home-layout .top-navbar[data-v-aa533c32] {
  width: 100%;
  min-width: 1000px;
  height: 52px;
  background-color: rgba(31, 113, 255, 0.7);
}
.home-layout .navbar[data-v-aa533c32] {
  width: 100%;
  min-width: 1000px;
  height: 64px;
  overflow: hidden;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  z-index: 99;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.home-layout .navbar.horizontalNavbar[data-v-aa533c32] {
  min-height: 64px;
}
.home-layout .navbar .home-layout-nav[data-v-aa533c32] {
  width: 95%;
  margin: 0 auto;
  display: flex;
  align-items: center;
}
.home-layout .navbar .home-layout-nav .nav-right-menu[data-v-aa533c32] {
  flex: 2;
  text-align: right;
  display: flex;
  justify-content: flex-end;
}
.home-layout[data-v-aa533c32] .animate__animated {
  animation-duration: 500ms;
}
.home-layout[data-v-aa533c32] .el-loading-mask {
  max-width: 100vw;
  max-height: 100vh;
}
.el-scrollbar[data-v-aa533c32] {
  height: 100vh;
  width: 100%;
  position: relative;
  overflow: hidden;
}
.el-scrollbar[data-v-aa533c32] .home-container {
  overflow-x: hidden;
  overflow-y: auto;
}
.el-scrollbar .main-section[data-v-aa533c32] {
  min-height: calc(100vh - 250px);
}
.fixed-footer[data-v-aa533c32] {
  position: absolute;
  bottom: 0px;
  width: 100%;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
