// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Variables */
[data-v-4964f2e0]:export {
  menuText: #000;
  menuActiveText: #fff;
  subMenuActiveText: #f4f4f5;
  menuActiveBg: #1589cd;
  menuBg: #fff;
  menuHover: rgba(45, 153, 255, 0.3);
  subMenuBg: #fff;
  subMenuHover: rgba(45, 153, 255, 0.3);
  sideBarWidth: 200px;
  primaryColor: #1589cd;
  primaryFontColor: rgb(45, 153, 255);
  primaryColor_8: rgba(31, 113, 255, 0.08);
  globalColor: #e60012;
  defaultFontColor: rgb(20, 35, 69);
  tableHeaderBg: #fafafa;
  tableHeaderHeight: 40px;
  tableAlign: center;
  fontSize_14: 14px;
  fontSize_16: 16px;
  fontSize_18: 18px;
  fontSize_24: 24px;
}

/* Mixins */
.container .input_span p[data-v-4964f2e0] {
  font-size: 14px;
}
.container .tips[data-v-4964f2e0] {
  font-size: 14px;
}
.container .tips-caps-lock[data-v-4964f2e0] {
  font-size: 14px;
  color: rgb(250, 173, 20);
  padding-top: 5px;
}
#inputValue[data-v-4964f2e0] {
  width: 240px;
  margin-left: 20px;
  padding-left: 10px;
  border-radius: 3px;
}
.input_span span[data-v-4964f2e0] {
  display: inline-block;
  width: 80px;
  height: 10px;
  background: #eee;
  line-height: 20px;
}
#one[data-v-4964f2e0] {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-right: 0px solid;
}
#two[data-v-4964f2e0] {
  border-left: 0px solid;
  border-right: 0px solid;
}
#three[data-v-4964f2e0] {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-left: 0px solid;
}
#font[data-v-4964f2e0] {
  font-size: 14px;
}
#font span[data-v-4964f2e0]:nth-child(1) {
  color: red;
  margin-left: 40px;
}
#font span[data-v-4964f2e0]:nth-child(2) {
  color: orange;
  margin: 0 60px;
}
#font span[data-v-4964f2e0]:nth-child(3) {
  color: #00d1b2;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
