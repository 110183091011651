// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Variables */
[data-v-4ace4340]:export {
  menuText: #000;
  menuActiveText: #fff;
  subMenuActiveText: #f4f4f5;
  menuActiveBg: #1589cd;
  menuBg: #fff;
  menuHover: rgba(45, 153, 255, 0.3);
  subMenuBg: #fff;
  subMenuHover: rgba(45, 153, 255, 0.3);
  sideBarWidth: 200px;
  primaryColor: #1589cd;
  primaryFontColor: rgb(45, 153, 255);
  primaryColor_8: rgba(31, 113, 255, 0.08);
  globalColor: #e60012;
  defaultFontColor: rgb(20, 35, 69);
  tableHeaderBg: #fafafa;
  tableHeaderHeight: 40px;
  tableAlign: center;
  fontSize_14: 14px;
  fontSize_16: 16px;
  fontSize_18: 18px;
  fontSize_24: 24px;
}

/* Mixins */
.navbar-container .top-navbar[data-v-4ace4340] {
  width: 100%;
  min-width: 1000px;
  background-color: rgba(31, 113, 255, 0.7);
}
.navbar-container .navbar[data-v-4ace4340] {
  width: 100%;
  min-width: 1000px;
  height: 64px;
  overflow: hidden;
  position: relative;
  background: #fff;
  box-shadow: 0 2px 18px 0 rgba(0, 0, 0, 0.2);
  z-index: 99;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.navbar-container .navbar.horizontalNavbar[data-v-4ace4340] {
  min-height: 64px;
}
.navbar-container .navbar .hamburger-container[data-v-4ace4340] {
  height: 100%;
  float: left;
  padding: 0 24px;
  cursor: pointer;
  transition: background-color 0.3s;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  align-items: center;
}
.navbar-container .navbar .hamburger-container[data-v-4ace4340]:hover {
  background: rgba(0, 0, 0, 0.025);
}
.navbar-container .sidebarLogoFade-enter-active[data-v-4ace4340] {
  transition: opacity 1.5s;
}
.navbar-container .sidebarLogoFade-enter[data-v-4ace4340],
.navbar-container .sidebarLogoFade-leave-to[data-v-4ace4340] {
  opacity: 0;
}
.navbar-container-homepage .home-page-nav[data-v-4ace4340] {
  width: 100%;
  margin: 0 24px;
  display: flex;
  align-items: center;
}
.navbar-container-homepage .home-page-nav .nav-left-nav[data-v-4ace4340] {
  flex: 1;
}
.navbar-container-homepage .home-page-nav .nav-right-menu[data-v-4ace4340] {
  text-align: right;
  display: flex;
  justify-content: flex-end;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
