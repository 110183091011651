var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      ref: "slider-verify-dialog",
      attrs: {
        "custom-class": "slider-verify-dialog",
        title: _vm.dialogTitle,
        visible: _vm.visible,
        width: "320px",
        "close-on-click-modal": false,
        "before-close": _vm.handleClose,
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _vm.imgurl && _vm.miniimgurl
        ? _c("SlideVerify", {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            ref: "slideblock",
            attrs: {
              imgurl: _vm.imgurl,
              miniimgurl: _vm.miniimgurl,
              blockY: _vm.blockY,
              verifyStatus: _vm.verifyStatus,
            },
            on: {
              success: _vm.onSuccess,
              fail: _vm.onFail,
              parentHandleSubmit: _vm.handleSubmit,
              resetImg: _vm.resetImg,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.msg
        ? _c(
            "div",
            {
              staticClass: "verify-msg",
              class: {
                "msg-success": _vm.verifyStatus === 1,
                "msg-error": _vm.verifyStatus !== 1,
              },
            },
            [_vm._v("\n    " + _vm._s(_vm.msg) + "\n  ")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }