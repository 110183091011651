// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Variables */
[data-v-33ec43fc]:export {
  menuText: #000;
  menuActiveText: #fff;
  subMenuActiveText: #f4f4f5;
  menuActiveBg: #1589cd;
  menuBg: #fff;
  menuHover: rgba(45, 153, 255, 0.3);
  subMenuBg: #fff;
  subMenuHover: rgba(45, 153, 255, 0.3);
  sideBarWidth: 200px;
  primaryColor: #1589cd;
  primaryFontColor: rgb(45, 153, 255);
  primaryColor_8: rgba(31, 113, 255, 0.08);
  globalColor: #e60012;
  defaultFontColor: rgb(20, 35, 69);
  tableHeaderBg: #fafafa;
  tableHeaderHeight: 40px;
  tableAlign: center;
  fontSize_14: 14px;
  fontSize_16: 16px;
  fontSize_18: 18px;
  fontSize_24: 24px;
}

/* Mixins */
.has-logo .el-scrollbar[data-v-33ec43fc] {
  height: calc(100vh - 104px);
  margin-right: 16px;
}
.has-logo .el-menu--collapse[data-v-33ec43fc] {
  min-width: 80px;
  width: 100%;
  border-right: none;
}
.has-logo[data-v-33ec43fc] .el-menu {
  transition: all 0.3s ease 0.15s;
  border-right: none;
}
.sidebar-container[data-v-33ec43fc] .horizontal-collapse-transition {
  transition: 0s width ease-in-out, 0s padding-left ease-in-out, 0s padding-right ease-in-out;
}
.sidebar-container[data-v-33ec43fc] .el-menu {
  background-color: #fff;
}
.sidebar-container[data-v-33ec43fc] .el-menu-item {
  color: #000;
}
.sidebar-container[data-v-33ec43fc] .scrollbar-wrapper {
  padding: 12px 0;
  background-color: #fff;
  overflow-x: hidden;
}
.sidebar-container[data-v-33ec43fc] .el-scrollbar__view {
  height: 100%;
}
.sidebar-container[data-v-33ec43fc] .el-scrollbar__bar.is-vertical {
  right: 0px;
}
.sidebar-container[data-v-33ec43fc] .el-scrollbar__bar.is-horizontal {
  display: none;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
