<template>
  <div class="top-navbar-constainer" :class="{ 'top-navbar-constainer-homepage': !notHomePage }">
    <div class="top-navbar-left">
      <img class="logo" src="@/assets/logo.png" />
      <span>{{ settings.title }}</span>
    </div>
    <div class="top-navbar-right">
      <TopNavbarRight />
    </div>
  </div>
</template>

<script>
import settings from '@/defaultSettings';
import { mapState, mapActions } from 'vuex';
import TopNavbarRight from '../TopNavbarRight';
// import { systemParamCodes } from '@/utils/enums';

export default {
  name: 'TopNavbar',
  components: {
    TopNavbarRight,
  },
  data() {
    return {
      settings,
    };
  },
  computed: {
    ...mapState('common', ['platformPhone']),
    notHomePage() {
      return this.$router.currentRoute?.meta?.routeType !== 'HomeRoute';
    },
  },
  // mounted() {
  //   if (!this.platformPhone?.paramValue) {
  //     this.getPlatformPhone({
  //       paramCode: systemParamCodes.telephoneNum,
  //     });
  //   }
  // },
  methods: {
    ...mapActions('common', ['getPlatformPhone']),
  },
};
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>
