// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Variables */
[data-v-4dfc2f9c]:export {
  menuText: #000;
  menuActiveText: #fff;
  subMenuActiveText: #f4f4f5;
  menuActiveBg: #1589cd;
  menuBg: #fff;
  menuHover: rgba(45, 153, 255, 0.3);
  subMenuBg: #fff;
  subMenuHover: rgba(45, 153, 255, 0.3);
  sideBarWidth: 200px;
  primaryColor: #1589cd;
  primaryFontColor: rgb(45, 153, 255);
  primaryColor_8: rgba(31, 113, 255, 0.08);
  globalColor: #e60012;
  defaultFontColor: rgb(20, 35, 69);
  tableHeaderBg: #fafafa;
  tableHeaderHeight: 40px;
  tableAlign: center;
  fontSize_14: 14px;
  fontSize_16: 16px;
  fontSize_18: 18px;
  fontSize_24: 24px;
}

/* Mixins */
.svg-icon[data-v-4dfc2f9c] {
  margin-right: 9px;
  margin-left: 4px;
}
.el-menu-item[data-v-4dfc2f9c] {
  padding: 0 16px;
  height: 40px;
  line-height: 40px;
}
.el-menu-item[data-v-4dfc2f9c]:hover {
  color: #1589cd !important;
}
.el-menu-item.is-active[data-v-4dfc2f9c] {
  color: #fff !important;
  background-color: #1589cd;
}
[data-v-4dfc2f9c] .el-submenu__icon-arrow {
  font-size: 14px;
  font-weight: bold;
  vertical-align: middle !important;
}
[data-v-4dfc2f9c] .el-submenu__title {
  padding: 0 16px;
  height: 40px;
  line-height: 40px;
}
[data-v-4dfc2f9c] .el-submenu__title:hover {
  color: #fff !important;
}
[data-v-4dfc2f9c] .el-submenu.is-active .el-submenu__title {
  color: #fff !important;
}
[data-v-4dfc2f9c] .el-submenu.is-active .el-submenu__icon-arrow {
  color: #fff;
}
[data-v-4dfc2f9c] .menu-level-second .svg-icon {
  margin-left: 8px;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
