<template>
  <el-link
    class="zxLink"
    :class="`${item.class} ${!item.href ? 'disabled' : ''}`"
    :style="item.style"
    :type="item.type"
    :target="item.target"
    :href="item.href"
    :underline="item.underline ? item.underline : false"
    :disabled="item.showHash && !formData[item.prop]"
  >
    <span v-if="item.showHash">
      <span v-if="formData[item.prop]"> 成功上链<i class="el-icon-link"></i> </span>
      <span v-else>未上链</span>
    </span>
    <span v-else>
      {{ (item.formatter && item.formatter(formData[item.prop])) || formData[item.prop] || '暂无数据' }}
    </span>
  </el-link>
</template>

<script type="text/ecmascript-6">
import mixins from '../mixins';

export default {
  name: 'zxLink',
  mixins: [mixins],
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {},
  methods: {},
  activated() {},
  mounted() {},
  created() {},
};
</script>

<style scoped lang="scss">
.zxLink {
  word-break: break-all;

  &.disabled {
    pointer-events: none;
  }
}
</style>
