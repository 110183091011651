var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    {
      staticClass: "right-menu",
      class: [
        { headerContainer: _vm.menuType === 1 },
        { "right-menu-homepage": !_vm.notHomePage },
      ],
      attrs: { id: "right-menu" },
    },
    [
      Object.keys(_vm.loginInfo).length !== 0
        ? [
            _c("el-badge", { attrs: { value: _vm.taskTotal, max: 99 } }, [
              _c(
                "div",
                {
                  staticClass: "task",
                  on: {
                    click: function ($event) {
                      return _vm.$router.push("/workbench/list")
                    },
                  },
                },
                [_c("i", { staticClass: "el-icon-s-order" }), _vm._v("待办")]
              ),
            ]),
            _vm._v(" "),
            _c("el-badge", { attrs: { value: _vm.messageTotal, max: 99 } }, [
              _c(
                "div",
                {
                  staticClass: "message",
                  on: {
                    click: function ($event) {
                      return _vm.$router.push("/messageCenter/list")
                    },
                  },
                },
                [
                  _c("i", { staticClass: "el-icon-message-solid" }),
                  _vm._v("消息"),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "username" }, [
              _c("img", {
                staticClass: "iconfont top-svg-icon",
                attrs: {
                  src: require("@/assets/dashboard/icon_head.png"),
                  alt: "",
                },
              }),
              _vm._v(
                "您好，" +
                  _vm._s(_vm.loginInfo && _vm.loginInfo.nickname) +
                  "\n    "
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "logout", on: { click: _vm.logout } },
              [
                _c("svg-icon", {
                  staticClass: "iconfont",
                  attrs: { "icon-class": "logout" },
                }),
                _vm._v("\n      退出\n    "),
              ],
              1
            ),
          ]
        : [
            _c(
              "el-button",
              {
                staticClass: "top-right-button",
                attrs: { round: "" },
                on: {
                  click: function ($event) {
                    return _vm.$router.push("/registerPage")
                  },
                },
              },
              [_vm._v("注册")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticClass: "top-right-button",
                attrs: { round: "" },
                on: {
                  click: function ($event) {
                    return _vm.$router.push("/login")
                  },
                },
              },
              [_vm._v("登录")]
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }