var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("el-time-picker", {
    staticStyle: { width: "100%" },
    attrs: {
      "is-range": _vm.item.isRange,
      clearable: true,
      placeholder: _vm.item.placeholder,
      "picker-options": _vm.item.pickerOptions,
      "value-format": _vm.item.valueFormat,
      align: _vm.item.align,
      editable: _vm.item.editable,
      "arrow-control": _vm.item.arrowControl,
      disabled: _vm.item.disabled,
      "range-separator": _vm.item.rangeSeparator,
      "start-placeholder": _vm.item.startPlaceholder
        ? _vm.item.startPlaceholder
        : "开始日期",
      "end-placeholder": _vm.item.endPlaceholder
        ? _vm.item.endPlaceholder
        : "结束日期",
    },
    on: { change: _vm.change },
    model: {
      value: _vm.formData[_vm.item.prop],
      callback: function ($$v) {
        _vm.$set(_vm.formData, _vm.item.prop, $$v)
      },
      expression: "formData[item.prop]",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }