import request from '@/utils/request';

// 待办任务
export function fetchTaskList(params) {
  return request({
    url: '/workflow/task/select-wait-handle',
    method: 'post',
    data: params,
  });
}

// 我发起的
export function fetchMeStartList(params) {
  return request({
    url: '/workflow/task/select-start-flow-page',
    method: 'post',
    data: params,
  });
}

// 已办任务
export function fetchTaskFinishList(params) {
  return request({
    url: '/workflow/task/select-handled-page',
    method: 'post',
    data: params,
  });
}

//获取页面跳转路径
export async function getPageUrl(params) {
  return request('/portal/processPage/getPageUrl', {
    method: 'POST',
    data: params,
  });
}

// 我的通知消息列表
export function fetchMessageList(params) {
  return request({
    url: '/base/notice/message/list',
    method: 'post',
    data: params,
  });
}

// 修改消息为已读
export function fethchMsgUpdate(params) {
  return request({
    url: `/idspbase/notice/message/update/msg`,
    method: 'get',
    params: {
      msgId: params.msgId,
    },
  });
}

/**
 * @description: 工作流待办详情
 * @param {
 *  taskId: string         // 任务id
 * } params
 * @return {
 *  rspCode： string,
 *  rspDesc: string,
 *  data: <Object></Object>
 * }
 */
export async function fetchTaskDetailById(params) {
  return request('/workflow/task/fetchTaskDetailById', {
    method: 'GET',
    params,
  });
}

/**
 * @description: 工作审批历史
 * @param {
 *  taskId: string         // 任务id
 * } params
 * @return {
 *  rspCode： string,
 *  rspDesc: string,
 *  data: <Object></Object>
 * }
 */
export async function fetchHisDetailById(params) {
  return request('/workflow/instance/his-detail', {
    method: 'POST',
    data: params,
  });
}

// 审核-订单信息查询
export function getQueryFlowOrder(data) {
  return request({
    url: `/agency/order/queryFlowOrder`,
    method: 'post',
    data,
  });
}

//支付信息查询
export function getOrderPaymentInfo(data) {
  return request({
    url: `/agency/order-procure/depositPayInfo`,
    method: 'post',
    data,
  });
}

//客户支付定金
export function saveCustomerPayDeposit(data) {
  return request({
    url: `/agency/order-procure/customerPayDeposit`,
    method: 'post',
    data,
  });
}

//附件信息-新增
export function saveAttachmentInfo(data) {
  return request({
    url: `/agency/documentAttachment/batchSaveAttachmentInfo`,
    method: 'post',
    data,
  });
}

//审批历史查询

export function fetHisDetail(data) {
  return request({
    url: `/workflow/instance/his-detail`,
    method: 'post',
    data,
  });
}

//提单信息查询

export function getQueryByDeliveryId(data) {
  return request({
    url: `/agency/deliveryOrder/queryByDeliveryId`,
    method: 'post',
    data,
  });
}

//融资申请

export function getFinancingProcessPush(data) {
  return request({
    url: `/agency/financeApply/financingProcessPush`,
    method: 'post',
    data,
  });
}

//订单余额支付
export function getBalancepay(data) {
  return request({
    url: `/agency/orderBalancePayment/pay`,
    method: 'post',
    data,
  });
}

//
//查询融资信息
export function getQueryFinanceInfo(data) {
  return request({
    url: `/agency/financeApply/queryFinanceInfo`,
    method: 'post',
    data,
  });
}

//查询融资信息
export function saveResubmit(data) {
  return request({
    url: `/agency/selfFundingPayment/resubmit`,
    method: 'post',
    data,
  });
}

//查询融资信息
export function getBalancePays(data) {
  return request({
    url: `/agency/selfFundingPayment/getByDeliveryCode`,
    method: 'post',
    data,
  });
}

//查询水发账户查询余额支付
export function getInquiry(data) {
  return request({
    url: `/agency/paymentAccountManagement/shuifaAccountInquiry`,
    method: 'post',
    data,
  });
}

//经销商账户查询--余额支付
export async function resellerAccountInquiries(params) {
  return request('/agency/paymentAccountManagement/resellerAccountInquiries', {
    method: 'POST',
    data: params,
  });
}

//根据订单状态查询当前支付状态
export async function getByOrderCode(params) {
  return request('/agency/orderBalancePayment/getByDeliveryCode', {
    method: 'POST',
    data: params,
  });
}

//审批详情-查询
export async function getCorpCustomerDetail(params) {
  return request('/agency/authCorpAdmission/corpCustomerDetail ', {
    method: 'POST',
    data: params,
  });
}

//提交客户准入申请
export async function submitAuthCorpAdmission(params) {
  return request('/agency/authCorpAdmission/submitAuthCorpAdmission', {
    method: 'POST',
    data: params,
  });
}

//莱商融资申请--融资产品详情

export async function getFinanceInfo(params) {
  return request('/agency/financeApply/getFinanceInfo', {
    method: 'POST',
    data: params,
  });
}

//莱商融资申请--担保企业名称列表
export async function getEnterpriseList(params) {
  return request('/agency/financeApply/getGuaranteeEnterprise', {
    method: 'POST',
    data: params,
  });
}

//莱商融资申请--经销商账户查询
export async function getAccountInquiries(params) {
  return request('/agency/paymentAccountManagement/accountInquiries', {
    method: 'POST',
    data: params,
  });
}

//莱商融资申请--获取提单信息
export async function getDevlieryInfo(params) {
  return request('/agency/financeApply/getDevlieryInfo', {
    method: 'POST',
    data: params,
  });
}

//莱商融资申请--获取提单信息
export async function submitProcessToBank(params) {
  return request('/agency/financeApply/submitProcessToBank', {
    method: 'POST',
    data: params,
  });
}

//财报--查询最新财报
export async function getQueryLast(params) {
  return request('/agency/financialReports/queryFinancialReportsByPid', {
    method: 'POST',
    data: params,
  });
}

//财报--查询最新财报
export async function updateFinancialReport(params) {
  return request('/agency/financialReports/updateFinancialReport', {
    method: 'POST',
    data: params,
  });
}

// 水发账户查询
export async function shuifaAccountInquiry(params) {
  return request('/agency/paymentAccountManagement/shuifaAccountInquiry', {
    method: 'POST',
    data: params,
  });
}

//根据提单编码只获取提单信息-查询
export async function queryDeliveryCode(params) {
  return request('/agency/deliveryOrder/queryByDeliveryId', {
    method: 'POST',
    data: params,
  });
}

//
export async function submitPay(params) {
  return request('/agency/orderBalancePayment/pay', {
    method: 'POST',
    data: params,
  });
}

//
export async function getByDeliveryCode(params) {
  return request('/agency/selfFundingPayment/getByDeliveryCode', {
    method: 'POST',
    data: params,
  });
}

//融资详情查询
export async function queryFinanceDetail(params) {
  return request('/agency/financeApply/queryFinanceDetail', {
    method: 'POST',
    data: params,
  });
}

// 根据提单code获取提单详细信息不包含附件信息-查询
export async function queryDeliveryInfo(params) {
  return request('/agency/deliveryOrder/queryDeliveryInfo', {
    method: 'POST',
    data: params,
  });
}

// 融资申请失败确认详情查询
export async function getFinancingDetails(params) {
  return request('/agency/financeApply/financingApplicationFailureDetails', {
    method: 'POST',
    data: params,
  });
}

// 融资申请失败结束流程
export async function finishProcess(params) {
  return request('/agency/financeApply/finishProcess', {
    method: 'POST',
    data: params,
  });
}

// 到货确认详情信息-查询
export async function getDeliveryDetail(params) {
  return request('/agency/arrivalConfirm/getDetail', {
    method: 'POST',
    data: params,
  });
}

// 到港转仓单流程节点推进-编辑
export async function getDeliveryApprove(params) {
  return request('/agency/arrivalPortConvertWarehouse/approve', {
    method: 'POST',
    data: params,
  });
}

//

// 尾款支付--余额支付信息
export async function balancePaymentShow(params) {
  return request('/agency/paymentAccountManagement/balancePaymentShow', {
    method: 'POST',
    data: params,
  });
}

//获取个人准入详情-查询
export async function getPersonalCustomerDetail(params) {
  return request('/agency/personalAdmission/personalCustomerDetail', {
    method: 'POST',
    data: params,
  });
}

//提交个人客户准入申请-新增
export async function submitAuthPersonalAdmission(params) {
  return request('/agency/personalAdmission/submitAuthPersonalAdmission', {
    method: 'POST',
    data: params,
  });
}
