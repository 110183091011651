var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.item.option && Object.keys(_vm.item.option).length !== 0
        ? [
            _c(
              "el-checkbox",
              {
                style: _vm.item.option.style,
                attrs: { disabled: _vm.item.option.disabled },
                on: { change: _vm.change },
                model: {
                  value: _vm.formData[_vm.item.prop],
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, _vm.item.prop, $$v)
                  },
                  expression: "formData[item.prop]",
                },
              },
              [
                _vm.item.option.type === "html"
                  ? _c("div", {
                      domProps: { innerHTML: _vm._s(_vm.item.option.label) },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.checkLabelClick.apply(null, arguments)
                        },
                      },
                    })
                  : _c(
                      "span",
                      {
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            return _vm.checkLabelClick.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.item.option.label
                              ? _vm.item.option.label
                              : _vm.item.option
                          )
                        ),
                      ]
                    ),
              ]
            ),
          ]
        : _c(
            "el-checkbox-group",
            {
              attrs: { min: _vm.item.min, max: _vm.item.max },
              model: {
                value: _vm.formData[_vm.item.prop],
                callback: function ($$v) {
                  _vm.$set(_vm.formData, _vm.item.prop, $$v)
                },
                expression: "formData[item.prop]",
              },
            },
            _vm._l(_vm.options, function (option) {
              return _c(
                "el-checkbox",
                {
                  key: option.value ? option.value : option,
                  style: _vm.item.style || option.style,
                  attrs: {
                    label: option.value ? option.value : option,
                    disabled: _vm.item.disabled || option.disabled,
                  },
                  on: { change: _vm.change },
                },
                [
                  option.type === "html"
                    ? _c("div", {
                        domProps: { innerHTML: _vm._s(option.label) },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            return _vm.checkLabelClick.apply(null, arguments)
                          },
                        },
                      })
                    : _c(
                        "span",
                        {
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.checkLabelClick.apply(null, arguments)
                            },
                          },
                        },
                        [_vm._v(_vm._s(option.label ? option.label : option))]
                      ),
                ]
              )
            }),
            1
          ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }