var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("footer", [
    _c("span", [_vm._v(_vm._s(_vm.settings.footerCopyright))]),
    _vm._v(" "),
    _c("span", [
      _vm._v("\n    ICP备案号：\n    "),
      _c(
        "a",
        { attrs: { href: "https://beian.miit.gov.cn", target: "_blank" } },
        [_vm._v(" " + _vm._s(_vm.settings.icp) + " ")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }