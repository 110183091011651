import defaultImgUrl from '../assets/defaultImage.png';

/**
 * 常规枚举集合
 */

//  协议阅读倒计时
export const countdown = 5;

// 搜索栏form表单常用col span
export const customFormItemSpan = {
  commonSpan: 6,
};

// 审批结果
export const taskResultType = {
  'Y': '同意',
  'R': '退回上一步',
  'O': '退回发起人',
  'J': '驳回',
  'W': '撤回'
}

//企业客户管理-附件类型
export const fileType = [
  { label: '营业执照', value: '1' },
  { label: '企业财报', value: '2' },
  { label: '其他', value: '9' },
  { label: '销售合同', value: '8' },
];
//订单状态
export const orderType = {
  1: "草稿", 2: "定金支付", 3: "融资申请", 4: "融资申请审核"
  // 1:'待确认',2:'代采申请审批中',3:'已作废',4:'代采申请已通过',5:'定金待支付',6:'定金支付审核中',7:'定金支付审核已通过',8:'余款待支付',9:'余款支付审批中',10:'余款支付审批已通过'
};

// 表单状态类枚举 状态小于等于3个字的需要使用tag
export const TagsType = {
  success: 'success', // 启用 | 是
  info: 'info', // 未生效
  warning: 'warning', // 暂无其他用处
  danger: 'danger', // 禁用 | 否
  processing: '', // 处理中

  PROCESSING: '', // 处理中
  EFFECTIVE: 'success', // 已生效
  REJECTED: 'danger', //已拒绝

  Y: 'success', // 启用
  N: 'danger', // 禁用
};

// 单独的form-item的label width
export const formItemLabelWidth = {
  length_4: '68px',
};

// table fixed 宽度
export const tableFixedWidth = {
  tiny: 80, // 极小号的table宽度
  small: 120, // 小一号的table宽度
  medium: 140, // 常规的table宽度
  large: 160, // 大号的table宽度
  xl: 180, // 加大号的
  xxl: 200, // 加加大号的
  huge: 240, // 巨大的table宽度
  // rightmenu 2两个文字， 4四个文字， 最多三个菜单，按照字数选择组合宽度
  rightmenu2: 120, // 一个按钮
  rightmenu22: 180, // 两个按钮
  rightmenu222: 240, // 三个按钮
  rightmenu4: 150,
  rightmenu42: 220,
  rightmenu422: 280,
  rightmenu442: 300,

  // 下面的不需要了， 后端改完后下面的删除
  left: 220, // fixed=left 宽度
  right: 220, // fixed=right 宽度
  w174: 174,
  w99: 99,
  w79: 79,
  w119: 119,
  w159: 159,
  w618: 618,
  w100: 100,
  w120: 120,
  w140: 140,
  w160: 160,
  w180: 180,
};

// 系统参数编码
export const systemParamCodes = {
  bussDate: 'BUSS_DATE',
  telephoneNum: 'TELEPHONE',
};

// 管理员证件类型 [1-身份证/2-台湾来往大陆通行证/3-港澳来往大陆通行证/4-护照]
export const adminIdType = [
  { label: '中国大陆身份证', value: 1 },
  { label: '台湾来往大陆通行证', value: 2 },
  { label: '港澳来往大陆通行证', value: 3 },
  { label: '护照', value: 4 },
];

export const adminIdAction = {
  1: '/server/api/pvbank/thirdparty/ocr/idCard',
  2: '/server/api/pvbank/thirdparty/ocr/taiwanCompatriot',
  3: '/server/api/pvbank/thirdparty/ocr/trafficPermit',
  4: '/server/api/pvbank/thirdparty/ocr/passport',
};

// 管理员证件类型 [1-身份证/2-台湾来往大陆通行证/3-港澳来往大陆通行证/4-护照]
export const adminIdTypeExample = {
  1: {
    front: 'idCardFront',
    back: 'idCardBack',
  },
  2: {
    front: 'TWTrafficFront',
    back: 'TWTrafficBack',
  },
  3: {
    front: 'HKTrafficFront',
    back: 'HKTrafficBack',
  },
  4: {
    front: 'passport',
    back: 'passport',
  },
};

// 企业类型
export const idType = {
  CENTER_ENTERPRISE: '核心企业',
  CHAIN_ENTERPRISE: '链属企业',
  SUPERVISE_ENTERPRISE: '监管企业',
  STORAGE_ENTERPRISE: '仓储企业',
  DEALER_ENTERPRISE: '经销商',// 字段需根据api信息修改
  FINANCIAL_ENTERPRISE: '金融机构',
};

// 短信模板编号
export const smsTemplateCode = {
  loginSms: 'SMS0002', // 登录-发送短信
  registSms: 'SMS0001', // 注册-发送短信
  newPassSms: 'SMS0004', // 忘记密码-发送短信
  passAmendSms: 'SMS0010', // 修改初始化密码
  checkMobileSms: 'SMS0084', // 短信认证
  newPhoneSms: 'SMS0015', //修改手机号码
  changePhoneSms: 'SMS0003', //修改手机号码
  personSms: 'SMS0005', // 个人实名认证
  legalAuthenticateSms: 'SMS0006', // 企业法人实名认证
  userManagementSms: 'SMS0025', // 变更管理员
  unpledgeSms: 'SMS0005'// 解质押短信验证码
};

// 用户管理-认证状态
export const IDENTY_FLAG = {
  N: '未认证',
  Y: '已认证',
};

// 用户管理-用户状态/用户状态描述
export const USER_STATUS = {
  A: '正常',
  N: '新建',
  L: '锁定',
  M: '锁定',
};

// 用户管理-启用标志
export const USE_FLAG = {
  N: '禁用',
  Y: '启用',
};

// 用户管理-是否管理员
export const IS_ADMIN = {
  N: '否',
  Y: '是',
};

// 业务进度详情
export const progressConfig = [
  {
    processKey: 'CORP_AUTHENTICATE_PROCESS', // 企业身份认证
    pathname: '/queryCenter/serviceProgress/corpAuthenticateProcess',
  },
  {
    processKey: 'CORP_CHANGE_ADMIN_PROCESS', // 企业变更管理员
    pathname: '/queryCenter/serviceProgress/corpChangeAdminProcess',
  },
  {
    processKey: 'ADD_CHAIN_CORP_PROCESS', // 新增产业链白名单
    pathname: '/queryCenter/serviceProgress/chainEnterpriseAdd',
  },
  {
    processKey: 'MAINTAIN_CHAIN_CORP_PROCESS', // 修改产业链白名单
    pathname: '/queryCenter/serviceProgress/chainEnterpriseUpdate',
  },
  {
    processKey: 'ADD_CONTRACT_PROCESS', // 金融产品申请提交
    pathname: '/queryCenter/serviceProgress/cooperationRelationApplyProcess',
  },
  {
    processKey: 'ADD_ACCOUNT_PROCESS', // 新增账户管理审批通过
    pathname: '/queryCenter/serviceProgress/addOrUpdateAccount',
  },
  {
    processKey: 'MAINTAIN_ACCOUNT_PROCESS', // 修改账户管理审批拒绝
    pathname: '/queryCenter/serviceProgress/addOrUpdateAccount',
  },
  {
    processKey: 'ARRIVAL_CONFIRM_PROCESS', // 到货
    pathname: '/comprehensiveQuery/serviceProgressQuery/arrivalNoticeChainDetail',
  },
  {
    processKey: 'PICKUP_PROCESS', // 提货
    pathname: '/comprehensiveQuery/serviceProgressQuery/detail',
    params: {
      fromType: 'businessProgress',
    },
  },
  {
    processKey: 'SEND_GOODS_PICKUP_PROCESS', // 提货--发货模式
    pathname: '/comprehensiveQuery/serviceProgressQuery/detail',
    params: {
      fromType: 'businessProgress',
    },
  },
  {
    processKey: 'LOAN_REPAY_PROCESS', // 还款申请
    pathname: '/queryCenter/serviceProgress/loanRepayApply',
  },
  {
    processKey: 'LOAN_APPLY_PROCESS', // 融资申请
    pathname: '/queryCenter/serviceProgress/financialApplyDetail',
    params: {
      fromType: 'businessProgress',
    },
  },
  {
    processKey: 'LOAN_APPLY_FOR_POOL_PROCESS', // 融资申请-聚池通
    pathname: '/comprehensiveQuery/serviceProgress/financialApplyDetailNew',
  },
  {
    processKey: 'ASSET_INTO_POOL_PROCESS', // 资产入池
    pathname: '/comprehensiveQuery/serviceProgressQuery/enterPoolApprovalDetail',
  },
  {
    processKey: 'OUT_POOL_FOR_CORP_PROCESS', // 资产出池
    pathname: '/comprehensiveQuery/serviceProgressQuery/leavePoolApprovalDetail',
  },
  {
    processKey: 'TRANSFER_OUT_BALANCE_PROCESS', // 尾款转出详情
    pathname: '/comprehensiveQuery/serviceProgressQuery/transferOutDetail',
  },
  {
    processKey: 'MAINTAIN_BIND_ACCOUNT_PROCESS', // 维护绑定账户详情
    pathname: '/comprehensiveQuery/serviceProgressQuery/defendBindAccountDetail',
  },
  {
    processKey: 'WRITE_OFF_ACCOUNTS_RECEIVABLE_PROCESS', // 应收账款核销
    pathname: '/comprehensiveQuery/serviceProgress/accountReceivableDetail',
  },
];

// 工作台处理formKey
export const formKeyConfig = [
  {
    formKey: 'CORP_AUTHENTICATE_BACK_ADJUST', // 企业身份认证
    pathname: '/enterpriseManagement/companyAuthenticate',
  },
  {
    formKey: 'ADD_PRODUCT_CONTRACT_PROCESS_APPROVAL', // 金融产品申请
    pathname: '/workbench/cooperationRelationApplyApprove',
  },
  {
    formKey: 'ADD_CONTRACT_SIGN_FOR_CHAINCORP', // 协议签约(R)
    pathname: '/workbench/agreeSignManagement',
  },
  {
    formKey: 'ADD_CONTRACT_SIGN_FOR_CHAINCORP_APPROVAL', // 协议签约审核(R)
    pathname: '/workbench/agreeSignManagementApprove',
  },
  {
    formKey: 'ADD_CONTRACT_SIGN_FOR_CORECORP', // 协议签约审核(h)
    pathname: '/workbench/agreeSignManagement',
  },
  {
    formKey: 'ADD_CONTRACT_SIGN_FOR_CORECORP_APPROVAL', // 协议签约审核(h)
    pathname: '/workbench/agreeSignManagementApprove',
  },
  {
    formKey: 'ADD_CONTRACT_SIGN_FOR_SUPERVISECORP', // 协议签约审核(J)
    pathname: '/workbench/agreeSignManagement',
  },
  {
    formKey: 'ADD_CONTRACT_SIGN_FOR_SUPERVISECORP_APPROVAL', // 协议签约审核(J)
    pathname: '/workbench/agreeSignManagementApprove',
  },
  {
    formKey: 'ADD_CUST_ACCOUNT_PROCESS_APPROVAL', // 新增账户审批
    pathname: '/workbench/addOrUpdateAccountApprove',
  },
  {
    formKey: 'MAINTAIN_CUST_ACCOUNT_PROCESS_APPROVAL', // 修改账户审批
    pathname: '/workbench/addOrUpdateAccountApprove',
  },
  {
    formKey: 'ADD_CHAIN_CORP_APPROVAL', // 新增产业链白名单
    pathname: '/workbench/industryChainMgt/addApproval',
  },
  {
    formKey: 'MAINTAIN_CHAIN_CORP_APPROVAL', // 修改产业链白名单
    pathname: '/workbench/industryChainMgt/updateApproval',
  },
  {
    formKey: 'ARRIVAL_CONFIRM_FOR_LOAN_SUBMIT', // 到货确认-链属企业
    pathname: '/workbench/arrivalNotice',
  },
  {
    formKey: 'ARRIVAL_CONFIRM_FOR_LOAN_BACK', // 到货退回-链属企业
    pathname: '/workbench/arrivalNotice',
  },
  {
    formKey: 'ARRIVAL_CONFIRM_FOR_LOAN_APPROVAL', // 到货审核-链属企业
    pathname: '/workbench/arrivalNotice',
  },
  {
    formKey: 'ARRIVAL_CONFIRM_FOR_SUPERVISE_SUBMIT', // 到货确认-监管企业
    pathname: '/workbench/arrivalNotice',
  },
  {
    formKey: 'ARRIVAL_CONFIRM_FOR_SUPERVISE_BACK', // 到货退回-监管企业
    pathname: '/workbench/arrivalNotice',
  },
  {
    formKey: 'ARRIVAL_CONFIRM_FOR_SUPERVISE_APPROVAL', // 到货审核-监管企业
    pathname: '/workbench/arrivalNotice',
  },
  {
    formKey: 'LOAN_APPLY_CORP_APPROVAL', // 融资申请审批-链属企业
    pathname: '/workbench/financialMgt/approval',
  },
  {
    formKey: 'LOAN_APPLY_CORP_RETURN', // 融资申请审批退回-链属企业（融资申请维护）
    pathname: '/workbench/financialMgt/approvalReturn',
  },
  {
    formKey: 'PICKUP_APPLY_APPROVAL', // 提货审核-链属企业
    pathname: '/workbench/takeDeliveryProcess/approval',
    params: {
      fromType: 'RApprove',
    },
  },
  {
    formKey: 'PICKUP_APPLY_RETURN', // 提货退回-链属企业
    pathname: '/workbench/takeDeliveryProcess/approvalReturn',
    params: {
      fromType: 'RApproveReturn',
    },
  },
  {
    formKey: 'PICKUP_APPLY_CORE_AFFIRM', // 提货确认-核心企业
    pathname: '/workbench/takeDeliveryProcess/HAffirm',
    params: {
      fromType: 'HConfirm',
    },
  },
  {
    formKey: 'PICKUP_APPLY_TAKE_STOCK', // 提货出库-核心企业
    pathname: '/workbench/takeDeliveryProcess/HOutOfStock',
    params: {
      fromType: 'HOutOfStock',
    },
  },
  {
    formKey: 'PICKUP_APPLY_TAKE_STOCK_APPROVAL', // 出库审核-核心企业
    pathname: '/workbench/takeDeliveryProcess/HApprove',
    params: {
      fromType: 'HApprove',
    },
  },
  {
    formKey: 'PICKUP_APPLY_TAKE_STOCK_RETURN', // 提货出库维护-核心企业
    pathname: '/workbench/takeDeliveryProcess/HApproveReturn',
    params: {
      fromType: 'HApproveReturn',
    },
  },
  {
    formKey: 'PICKUP_APPLY_AFFIRM', // 提货确认-监管企业
    pathname: '/workbench/takeDeliveryProcess/affirm',
    params: {
      fromType: 'JConfirm',
    },
  },
  {
    formKey: 'PICKUP_APPLY_AFFIRM_APPROVAL', // 提货确认审核-监管企业
    pathname: '/workbench/takeDeliveryProcess/affirmApproval',
    params: {
      fromType: 'JApprove',
    },
  },
  {
    formKey: 'PICKUP_APPLY_AFFIRM_RETURN', // 提货确认退回-监管企业
    pathname: '/workbench/takeDeliveryProcess/affirmReturn',
    params: {
      fromType: 'JApproveReturn',
    },
  },
  {
    formKey: 'LOAN_REPAY_APPROVAL', // 还款审核
    pathname: '/workbench/loanRepayApplyApprove',
  },
  {
    formKey: 'LOAN_REPAY_BACK_ADJUST', // 还款退回-还款申请维护
    pathname: '/workbench/LoanRepayApplyBack',
  },

  {
    formKey: 'LOAN_APPLY_FOR_POOL_AUDIT', // 融资申请审批-链属企业-聚池通
    pathname: '/workbench/financialMgt/approvalNew',
  },
  {
    formKey: 'LOAN_APPLY_FOR_POOL_RETURN', // 融资申请审批退回-链属企业（融资申请维护）-聚池通
    pathname: '/workbench/financialMgt/approvalReturnNew',
  },
  {
    formKey: 'LOAN_APPLY_FOR_POOL_LOAN_APPLY', // 放款申请-聚池通
    pathname: '/workbench/financialMgt/loanApply',
  },
  {
    formKey: 'LOAN_APPLY_FOR_POOL_LOAN_AUDIT', // 放款申请审批-聚池通
    pathname: '/workbench/financialMgt/loanApply/approval',
  },
  {
    formKey: 'INTO_POOL_APPROVAL', // 入池审核
    pathname: '/workbench/enterPoolApproval',
  },
  {
    formKey: 'INTO_POOL_BUYER_CONFIRM', // 入池审核
    pathname: '/workbench/enterPoolConfirm',
  },
  {
    formKey: 'INTO_POOL_BUYER_APPROVAL', // 入池审核
    pathname: '/workbench/enterPoolConfirm',
  },
  {
    formKey: 'OUT_POOL_FOR_CORP_APPROVAL', // 出池审核
    pathname: '/workbench/leaveApproval',
  },
  {
    formKey: 'WRITE_OFF_ACCOUNTS_RECEIVABLE_CORP_APPROVAL', // 应收账款核销审核
    pathname: '/workbench/accountReceivable',
  },
  {
    formKey: 'TRANSFER_OUT_BALANCE_PROCESS_APPROVAL', // 尾款转出审核
    pathname: '/workbench/transferOutApply',
  },
  {
    formKey: 'ADD_CONTRACT_BIND_ACCOUNT', // 绑定账户
    pathname: '/workbench/bindAccount',
  },
  {
    formKey: 'ADD_CONTRACT_BIND_ACCOUNT_APPROVAL', // 绑定账户审核
    pathname: '/workbench/bindAccountApply',
  },
  {
    formKey: 'MAINTAIN_BIND_ACCOUNT_PROCESS_APPROVAL', // 维护绑定账户审核
    pathname: '/workbench/defendBindAccountApply',
  },
];

//
export const coreProductStatus = {
  1: '已申请',
  2: '申请中',
  3: '未申请',
};

// 融资信息-逾期状态
export const DUE_FLAG = {
  N: '未逾期',
  Y: '已逾期',
};

// 融资信息-结清状态
export const END_FLAG = {
  N: '未结清',
  Y: '已结清',
};

// 图片的默认base64
export const defaultImg = defaultImgUrl;

// 还款-tabs菜单
export const loanRepayTabs = [
  {
    key: '基本信息',
    value: '1',
  },
  {
    key: '附件信息',
    value: '2',
  },
];

// 融资管理-还款申请-逾期状态
export const dueFlagList = {
  N: '未认证',
  Y: '已认证',
};

// flag
export const flagType = {
  Y: '是',
  N: '否',
};
export const flagTypeList = [
  { label: '是', value: 'Y' },
  { label: '否', value: 'N' },
];

/**
 * @description 聚X通产品类型区分标识
 * PRODUCT_JX: 聚信通
 * PRODUCT_PREPAYMENT：聚销通
 * PRODUCT_WAREHOUSE: 聚仓通
 * PRODUCT_POOL: 聚池通
 * PRODUCT_FACTOR: 聚账通
 */
export const PRODUCT_FLAG = {
  JXT0002022: 'PRODUCT_JX',
  JXT0012022: 'PRODUCT_PREPAYMENT',
  JXT0022022: 'PRODUCT_WAREHOUSE',
  CPBH2022003: 'PRODUCT_POOL',
  JXT0042022: 'PRODUCT_FACTOR',
};

/**
 * @description 聚X通流程节点区分标识
 */
export const PROCESS_FLAG = {
  LOAN_APPLY_FOR_VOUCHER_PROCESS: 'PRODUCT_JX', // 融资申请-聚信
  LOAN_APPLY_FOR_PREPAYMENT_PROCESS: 'PRODUCT_PREPAYMENT', // 融资申请-聚销
  LOAN_APPLY_FOR_STOCK_PROCESS: 'PRODUCT_WAREHOUSE', // 融资申请-聚仓
  LOAN_APPLY_FOR_POOL_PROCESS: 'PRODUCT_POOL', // 融资申请-聚池
  LOAN_APPLY_FOR_FACTOR_PROCESS: 'PRODUCT_FACTOR', // 融资申请-聚账
};

// 账户管理-账户类型
export const ACCOUNT_TYPE_LIST = [
  { label: '一般账户', value: 'Y' },
  { label: '监管账户', value: 'F' },
];

// 账户管理-账户类型
export const ACCOUNT_TYPE = {
  Y: '一般账户',
  F: '监管账户',
};

// 账户管理-账户类型
export const PRODUCT_TYPE = {
  RECEIVABLE: '应收类',
  PREPAY: '预付类',
  STOCK: '存货类',
};

// 融资申请-融资状态
export const FINANCE_STATUS = {
  FINANCE_APPLY: '0',
  FINANCE_ACCEPT: "1",
  FURTHER_INFORMATION: "2",
  FINANCE_APPROVE: "3",
  APPLY_REJECT: "4",
  WAIT_SIGN: "5",
  SIGN_APPROVE: "6",
  REJECT_SIGN: "7",
  LOANING: "8",
  LOAN_DONE: "9",
  LOAN_FAILURE: "10",
  LOAN_CANCEL: "11",
};
// 融资申请-融资状态
export const FINANCE_STATUS_LIST = [
  { label: '融资申请', value: '0' },
  { label: '融资受理', value: '1' },
  { label: '补充资料', value: '2' },
  { label: '融资申批', value: '3' },
  { label: '申请拒绝', value: '4' },
  { label: '待签约', value: '5' },
  { label: '签约审核', value: '6' },
  { label: '拒绝签约', value: '7' },
  { label: '放款中', value: '8' },
  { label: '放款完成', value: '9' },
  { label: '放款失败', value: '10' },
  { label: '放款作废', value: '11' },
]
