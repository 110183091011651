<template>
  <div class="identity-dialog-container">
    <Dialog
      ref="dialog"
      className="select-identity-dialog"
      :dialogWidth="identityDialogWidth"
      :appendToBody="true"
      :cancelButton="dialogCancelButton.text"
      :confirmButton="dialogConfirmButton.text"
      title="企业身份选择"
      :showClose="isNotNull(initCorpIdentity)"
      :cancelButtonEvent="dialogCancelButton.event"
      :confirmButtonEvent="dialogConfirmButton.event"
      @event="handleDialogEvent"
      :closeOnClickModal="false"
      :closeOnPressEscape="false"
    >
      <div slot="dialogBody">
        <el-row :gutter="0">
          <el-col v-for="item in corpIdentitys" :key="item" :span="24 / corpIdentitys.length">
            <el-card
              class="select-identity-card"
              :class="{ currentCorpIdentityChecked: item === currentCorpIdentity }"
              shadow="hover"
              @click.native="handleSelectIdentity(item)"
            >
              <svg-icon class="identity-icon" :icon-class="item" />
              <div class="identity-name">{{ idType[item] }}</div>
            </el-card>
          </el-col>
        </el-row>
      </div>
    </Dialog>
  </div>
</template>

<script>
import { Dialog } from '@/components';
import { getToken, getUserInfo } from '@/utils/auth';
import { idType } from '@/utils/enums';
import { Message } from 'element-ui';
import { setStorage } from '@/utils/storage';
import { getStorage } from '@/utils/storage';
import { isNotNull } from '@/utils/validate';
import { mapActions } from 'vuex';
export default {
  name: 'IdentityDialog',
  components: {
    Dialog,
  },
  props: {
    afterConfirm: {
      type: Function,
      default: null,
    },
  },

  data() {
    return {
      idType,
      // loginInfo: getUserInfo(),
      corpIdentitys: [], // 企业身份列表
      currentCorpIdentity: getStorage('currentCorpIdentity'), // 当前所选的企业身份
      initCorpIdentity: getStorage('currentCorpIdentity'), //  初始企业身份
      dialogCancelButton: {
        text: '',
        event: '',
      },
      roleArr: [],
      dialogConfirmButton: {
        text: '',
        event: '',
      },
    };
  },

  computed: {
    identityDialogWidth() {
      let width = 1000;
      switch (this.corpIdentitys.length) {
        case 1:
          width = 400;
          break;
        case 2:
          width = 600;
          break;
        case 3:
        case 4:
          width = 1000;
          break;

        default:
          width = 1000;
          break;
      }
      return width + 'px';
    },
  },

  methods: {
    ...mapActions('user', ['getAdminInfo', 'getLoginInfo']),
    isNotNull,
    //  打开弹框，获取企业类型列表，选择企业类型，存入cookie，关闭弹框
    async open() {
      await getUserInfo();
      this.corpIdentitys = [];
      if (getToken()) {
        let loginInfo = await this.getAdminInfo();
        setStorage('rspCode', loginInfo.rspCode);
        this.corpIdentitys = loginInfo?.data?.userIdentity;
        if (this.corpIdentitys.length > 1) {
          this.$refs.dialog && this.$refs.dialog.open();
          this.dialogCancelButton = {
            event: '',
            text: '',
          };
          this.dialogConfirmButton = {
            event: 'confirm',
            icon: 'submitTo',
            text: '提交',
          };
        } else if (this.corpIdentitys.length === 1) {
          // 单个企业，一个企业身份
          this.currentCorpIdentity = this.corpIdentitys[0];
          this.dialogCancelButton = {
            event: '',
            text: '',
          };
          this.dialogConfirmButton = {
            event: '',
            text: '',
          };
          this.handleDialogEvent({ prop: 'confirm' });
        }
      }
    },

    handleSelectIdentity(item) {
      this.currentCorpIdentity = item;
    },

    handleDialogEvent(params) {
      const { prop } = params;

      switch (prop) {
        case 'confirm':
          if (!this.currentCorpIdentity) {
            return Message.error('请选择企业身份！');
          }
          setStorage('currentCorpIdentity', this.currentCorpIdentity);
          this.$refs.dialog && this.$refs.dialog.close();
          if (this.afterConfirm) {
            this.afterConfirm(this.currentCorpIdentity);
          }
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>
