// 融资
const financingManagement = [
  // 融资申请
  {
    path: '/financingManagement/applications/add',
    name: 'financingManagement',
    component: (resolve) => require(['@/views/WarehouseReceipt/FinancingManagement/application/addList.vue'], resolve),
    meta: {
      title: '融资申请新增',
      layout: 'ProductLayout',
      hidden: true,
      activeMenu: '/financingManagement',
      parentMenu: '/financingManagement/applications',
      authority: ['NO-AUTHORITY'],
    },
  },
  {
    path: '/financingManagement/applications/edit',
    name: 'financingManagement',
    component: (resolve) => require(['@/views/WarehouseReceipt/FinancingManagement/application/edit.vue'], resolve),
    meta: {
      title: '融资申请编辑',
      layout: 'ProductLayout',
      hidden: true,
      activeMenu: '/financingManagement',
      parentMenu: '/financingManagement/applications',
      authority: ['NO-AUTHORITY'],
    },
  },
  // 融资申请
  {
    path: '/financingManagement/deliveryRequest/addOrEdit',
    name: 'financingManagement',
    component: (resolve) =>
      require(['@/views/WarehouseReceipt/FinancingManagement/deliveryRequest/addList.vue'], resolve),
    meta: {
      title: '融资申请新增',
      layout: 'ProductLayout',
      hidden: true,
      activeMenu: '/financingManagement',
      parentMenu: '/financingManagement/deliveryRequest',
      authority: ['NO-AUTHORITY'],
    },
  },
  // 现场监控-仓单
  {
    path: '/financingManagement/applications/monitoring',
    component: (resolve) =>
      require(['@/views/WarehouseReceipt/WarehouseReceiptManagement/issuance/monitoring'], resolve),
    meta: {
      title: '现场监控',
      layout: 'ProductLayout',
      hidden: true,
      activeMenu: '/financingManagement',
      parentMenu: '/financingManagement/applications',
      authority: ['NO-AUTHORITY'],
    },
  },
  // 现场监控-仓单
  {
    path: '/financingManagement/information/monitoring',
    component: (resolve) =>
      require(['@/views/WarehouseReceipt/WarehouseReceiptManagement/issuance/monitoring'], resolve),
    meta: {
      title: '现场监控',
      layout: 'ProductLayout',
      hidden: true,
      activeMenu: '/financingManagement',
      parentMenu: '/financingManagement/information',
      authority: ['NO-AUTHORITY'],
    },
  },
   // 现场监控--融资
   {
    path: "/financingManagement/issuance/monitoring",
    component: (resolve) =>require(["@/views/WarehouseReceipt/FinancingManagement/issuance/monitoring",], resolve),
    meta: {
      title: '现场监控',
      layout: 'ProductLayout',
      hidden: true,
      activeMenu: '/financingManagement',
      parentMenu: '/financingManagement/information',
      authority: ['NO-AUTHORITY'],
    },
  },
  // 申请解除质押
  {
    path: "/financingManagement/unPledge",
    component: (resolve) => require([ "@/views/WarehouseReceipt/FinancingManagement/unPledge/index.vue",], resolve),
    hidden: false,
    meta: {
      title: "解除质押",
      hidden: true,
      layout: 'ProductLayout',
      activeMenu: "/financingManagement",
      parentMenu: '/financingManagement/information',
      authority: ["NO-AUTHORITY"],
    },
  }

];

export default financingManagement;
