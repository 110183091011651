var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", {
    ref: "pdfView",
    staticClass: "pdfContent",
    style: `width:100%; height: ${_vm.isFile && "600px"}; margin-bottom: 24px`,
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }