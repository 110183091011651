// 库存管理及入库管理 权限路由
const warehouseReceiptManagement = [
    // 仓单开立 开立申请
    {
      path: "/warehouseReceiptManagement/issuance/openApplication",
      component: (resolve) =>require(["@/views/WarehouseReceipt/WarehouseReceiptManagement/issuance/openApplication",], resolve),
      meta: {
        title: '开立申请',
        layout: 'ProductLayout',
        hidden: true,
        activeMenu: '/warehouseReceiptManagement',
        parentMenu: '/warehouseReceiptManagement/issuance',
        authority: ['NO-AUTHORITY'],
      },
    },
    // 仓单转让 转让申请
    {
      path: "/warehouseReceiptManagement/transference/transferApplication",
      component: (resolve) =>require(["@/views/WarehouseReceipt/WarehouseReceiptManagement/transference/transferApplication",], resolve),
      meta: {
        title: '转让申请',
        layout: 'ProductLayout',
        hidden: true,
        activeMenu: '/warehouseReceiptManagement',
        parentMenu: '/warehouseReceiptManagement/transference',
        authority: ['NO-AUTHORITY'],
      },
    },
    // 仓单开立 开立申请
    {
      path: "/warehouseReceiptManagement/issuance/warehouseCancellation",
      component: (resolve) =>require(["@/views/WarehouseReceipt/WarehouseReceiptManagement/issuance/warehouseCancellation",], resolve),
      meta: {
        title: '仓单注销',
        layout: 'ProductLayout',
        hidden: true,
        activeMenu: '/warehouseReceiptManagement',
        parentMenu: '/warehouseReceiptManagement/issuance',
        authority: ['NO-AUTHORITY'],
      },
    },
    // 现场监控
    {
      path: "/warehouseReceiptManagement/issuance/monitoring",
      component: (resolve) =>require(["@/views/WarehouseReceipt/WarehouseReceiptManagement/issuance/monitoring",], resolve),
      meta: {
        title: '现场监控',
        layout: 'ProductLayout',
        hidden: true,
        activeMenu: '/warehouseReceiptManagement',
        parentMenu: '/warehouseReceiptManagement/issuance',
        authority: ['NO-AUTHORITY'],
      },
    },
  ];

  export default warehouseReceiptManagement;
