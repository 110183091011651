<template>
  <el-dialog
    custom-class="slider-verify-dialog"
    ref="slider-verify-dialog"
    :title="dialogTitle"
    :visible.sync="visible"
    width="320px"
    :close-on-click-modal="false"
    :before-close="handleClose"
    append-to-body
  >
    <SlideVerify
      v-if="imgurl && miniimgurl"
      ref="slideblock"
      v-loading="loading"
      :imgurl="imgurl"
      :miniimgurl="miniimgurl"
      :blockY="blockY"
      :verifyStatus="verifyStatus"
      @success="onSuccess"
      @fail="onFail"
      @parentHandleSubmit="handleSubmit"
      @resetImg="resetImg"
    ></SlideVerify>
    <div class="verify-msg" :class="{ 'msg-success': verifyStatus === 1, 'msg-error': verifyStatus !== 1 }" v-if="msg">
      {{ msg }}
    </div>
  </el-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import SlideVerify from './components/SlideVerify.vue';

export default {
  name: 'SliderVerifyDialog',
  components: {
    SlideVerify,
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
      required: true,
    },
    dialogTitle: {
      type: String,
      default: '滑块验证',
    },
    text: {
      type: String,
      default: '向右滑动',
    },
    // 0 未验证 1: success 2：fail 3：非人工操作
    verifyStatus: {
      type: Number,
      default: 0,
    },
    verifyDesc: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      msg: '',
      visible: false,
      loading: false,
    };
  },

  computed: {
    ...mapState('user', ['slideVerifyCodeData']),

    miniimgurl() {
      return 'data:image/png;base64,' + this.slideVerifyCodeData.cutoutImage;
    },

    imgurl() {
      return 'data:image/png;base64,' + this.slideVerifyCodeData.shadeImage;
    },

    blockY() {
      return Number(this.slideVerifyCodeData.y);
    },
  },

  watch: {
    dialogVisible(value) {
      this.visible = value;
      if (this.visible) {
        this.resetImg();
      }
    },
    verifyStatus(value) {
      this.msg = this.verifyDesc;
      switch (value) {
        case 0:
          this.msg = '';
          break;
        case 1:
          this.msg = '校验通过';
          break;
        case 2:
          setTimeout(() => {
            this.onFail();
          }, 1500);
          break;
        case 3:
          this.msg = '非人工操作';
          break;

        default:
          this.msg = '';
          break;
      }
    },
  },

  methods: {
    ...mapActions('user', ['getSlideCode']),

    onSuccess(xWidth) {
      // this.msg = '检验通过';
      this.$emit('sliderVerifySuccess', xWidth);
    },
    onFail() {
      // this.msg = '校验失败';
      this.resetImg();
      this.$emit('sliderVerifyFailed', false);
    },
    onRefresh() {
      this.msg = '';
      this.$refs.slideblock && this.$refs.slideblock.reset();
    },
    onAgain() {
      this.onRefresh();
    },
    handleClose() {
      this.$emit('closeVerify');
    },
    async resetImg() {
      this.msg = '';
      this.loading = true;
      const res = await this.getSlideCode();
      if (res.rspCode === '00000000') {
        setTimeout(async () => {
          await this.onRefresh();
          this.loading = false;
        }, 1000);
      }
    },
    handleSubmit(xWidth) {
      this.onSuccess(xWidth);
    },
  },
};
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>
