import axios from 'axios';
import router from '@/router';
import store from '@/store';
import whiteurl from './whiteurl.json';
import domMessage from './messageOnce.js';
import { Message, Notification } from 'element-ui';
import { encryptReq, decryptRes } from '@/utils/utils';
import { getToken, getRefreshToken, getAppId, getOrgId } from '@/utils/auth';
import { getStorage } from '@/utils/storage';

// new 对象实例
const messageOnce = new domMessage();

const codeMessage = {
  200: '服务器成功返回请求的数据。',
  201: '新建或修改数据成功。',
  202: '一个请求已经进入后台排队（异步任务）。',
  204: '删除数据成功。',
  400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
  401: '用户没有权限（令牌、用户名、密码错误）。',
  403: '用户得到授权，但是访问是被禁止的。',
  404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
  406: '请求的格式不可得。',
  410: '请求的资源被永久删除，且不会再得到的。',
  422: '当创建一个对象时，发生一个验证错误。',
  500: '服务器发生错误，请检查服务器。',
  502: '网关错误。',
  503: '服务不可用，服务器暂时过载或维护。',
  504: '网关超时。',
};

function isIncludeUrl(urlArr, url) {
  return urlArr.some((item) => {
    return url.indexOf(item) >= 0;
  });
}

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // timeout: 5000,
  // withCredentials: true // send cookies when cross-domain requests
  withCredentials: false, // 不发送cookie
});

// Request interceptors
service.interceptors.request.use(
  (config) => {
    const token = getToken();
    const refreshToken = getRefreshToken();
    if (config.url.endsWith('/oauth/web/token') || config.url.endsWith('/oauth/web/refresh')) {
      // 登录接口
      return Promise.resolve(config);
    }
    config.headers['Clientid'] = process.env.VUE_APP_CLIENT_ID;
    if (getAppId()) {
      config.headers['Appid'] = getAppId() || '';
    }
    // 获取子应用菜单，覆盖面客端应用id
    if (config.interceptor?.appList && getStorage('appInfo')) {
      config.headers['Appid'] = getStorage('appInfo')?.appId || '';
    }
    if (getOrgId()) {
      config.headers['Orgid'] = getOrgId() || '';
    }
    if (getStorage('currentCorpIdentity')) {
      config.headers['Orgidentity'] = getStorage('currentCorpIdentity') || '';
    }
    if (process.env.VUE_APP_ENCRYPTION == 1) {
      if (!isIncludeUrl(whiteurl.noEncryptReqUrl, config.url) && config.method === 'post') {
        if (!(config.data instanceof FormData)) {
          const initialData = config.data;
          // 报文加密
          config.data = encryptReq(initialData);
        }
      } else if (!isIncludeUrl(whiteurl.noEncryptReqUrl, config.url) && config.method === 'get') {
        if (config.params) {
          const initialData = { ...config.params };
          config.params = encryptReq(initialData);
        }
      }
    }
    let WhiteList = whiteurl.signUrl;
    let reqUrl = config.url; //可以直接获取到当前请求的url地址
    if (WhiteList.includes(reqUrl)) {
      if (window.SignBody && typeof window.SignBody == 'function') {
        //判断请求路径是否为需要签名，如果需要的话对报文进行签名得出sign值
        //这里的白名单需要和ags里的相互对应
        const sign = window.SignBody(config.data.body);
        console.log('BODY：  ' + config.data.body);
        console.log('sign：  ' + sign);
        config.headers.sign = sign;
      } else {
        alert('请插入UKEY');
      }
    }
    if (token) {
      config.headers['Authorization'] = token;
      return Promise.resolve(config);
    }
    if (refreshToken) {
      // token刷新
      return refreshTokenHandle(config);
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Response interceptors
service.interceptors.response.use(
  (response) => {
    const config = response.config;
    let res = { ...response.data };
    const hostname = window.location.hostname;
    if (process.env.VUE_APP_ENCRYPTION === 1) {
      if (!isIncludeUrl(whiteurl.noEncryptResUrl, config.url)) {
        // 报文解密
        res = decryptRes(res);
        if (hostname === 'localhost') {
          // sit环境或本地打印加密日志
          console.log(`响应加密${config.url}`, res);
        }
      } else {
        if (hostname === 'localhost') {
          console.log(`响应非${config.url}`, res);
        }
      }
    }
    if (config.url === '/portal/auth/user/logout') {
      return res;
    }
    const code = res.rspCode || '00000000';
    if (code === '00000011' || code === '00000024') {
      if (code === '00000011') {
        res.msg = '登录超时，请重新登录';
      }
      messageOnce.warning({
        message: res.msg || response.rspDesc,
        type: 'error',
      });
      store.dispatch('user/LogOut', { noRequest: true });
    } else if (code === '00000003' || code === '00000022') {
      Message({
        message: res.rspDesc || 'Error',
        type: 'error',
        duration: 5 * 1000,
      });
      router.push('/403');
    } else if (response && response.data instanceof Blob) {
      const obj = {
        fileContent: response.data,
        fileName: response.headers['content-disposition']
          ? decodeURIComponent(response.headers['content-disposition'].split('=')[1])
          : '',
      };
      return Promise.resolve(obj);
    } else if (code !== '00000000') {
      Message({
        message: res.rspDesc || 'Error',
        type: 'error',
        duration: 5 * 1000,
      });
    }
    return res;
  },
  (error) => {
    console.log(error, 'error');
    const status = error.response.status;
    const url = error.response.config.url;
    const rspDesc = error.response.data.rspDesc;
    const statusText = error.response.statusText;
    const errortext = codeMessage[status] || statusText;
    const errorMsg = rspDesc ? `请求错误 ${status}: ${rspDesc}` : `请求错误 ${status}: ${url}, ${errortext}`;

    Notification.error({
      title: 'error',
      message: errorMsg,
      duration: 5 * 1000,
      showClose: false,
    });

    return Promise.reject(error);
  }
);

function refreshTokenHandle(config) {
  if (!store.getters.isRefresh) {
    store.commit('user/SET_IS_REFRESH', true);
    return new Promise((resolve, reject) => {
      store
        .dispatch('user/getRefreshToken')
        .then((data) => {
          store.commit('user/SET_IS_REFRESH', false);
          config.headers['Authorization'] = data.accessToken;
          resolve(config);
        })
        .catch((error) => {
          reject(error);
        });
    });
  } else {
    setTimeout(() => {
      if (getToken()) {
        config.headers['Authorization'] = getToken();
        return new Promise((resolve) => {
          resolve(config);
        });
      }
    }, 3000);
  }
}

export default service;
