<template>
  <ul class="jj-timeline">
    <li
      class="jj-timeline-item"
      ref="newsListItem"
      v-for="item in newsList"
      :key="item.id"
      @click="() => handleClick(item)"
      data-aos="fade-up"
      v-infinite-scroll='load'
    >
      <div class="jj-timeline-item__timestamp">{{ item.createDate }}</div>
      <div class="jj-timeline-item__line">
        <div class="jj-timeline-item__tail_first"></div>
        <div class="jj-timeline-item__tail_last"></div>
        <div class="jj-timeline-item__node"></div>
      </div>
      <div class="jj-timeline-item__content">
        <div class="jj-timeline-item__body">
          <img :src="item.coverImgUrl" alt="" />
          <div class="jj-timeline-item__body_con">
            <h3>{{ item.newsTitle }}</h3>
            <p v-html="item.newsContent"></p>
          </div>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
import { getPdfOrImageConfig } from '@/utils/utils';

export default {
  name: 'TimeLine',
  props: {
    newsList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  filters: {
    imgrUrl(item) {
      let url = '';
      if (item.coverImageId) {
        url = getPdfOrImageConfig(item.coverImageId, true);
      } else if (item.coverImage) {
        url = `data:image/png;base64,${item.coverImage}`;
      }
      return url;
    },
  },
  methods: {
    handleClick(item) {
      this.$emit('goDetail', item);
    },
    load(){
      this.$emit('getNextPage');
    }
  },
};
</script>

<style lang="scss" scoped>
@keyframes antStatusProcessing {
  0% {
    transform: scale(0.8);
    opacity: 0.5;
  }

  100% {
    transform: scale(2.4);
    opacity: 0;
  }
}
.jj-timeline-item {
  height: 270px;
  display: flex;
  transition-delay: 200ms;
  &:nth-of-type(1) {
    .jj-timeline-item__tail_first {
      display: none;
    }
  }
  &:nth-last-of-type(1) {
    .jj-timeline-item__tail_last {
      display: none;
    }
  }
  &:hover {
    cursor: pointer;
    .jj-timeline-item__timestamp {
      color: $primaryColor;
    }
    .jj-timeline-item__node {
      background-color: $primaryColor;

      &::after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 1px solid $primaryColor;
        border-radius: 50%;
        animation: antStatusProcessing 1.2s ease-in-out infinite;
        content: '';
        box-sizing: border-box;
      }
    }
    .jj-timeline-item__body {
      box-shadow: 0 0 20px $primaryColor_24;
      h3 {
        color: $primaryColor;
      }
    }
  }
}
.jj-timeline-item__timestamp {
  font-size: $fontSize_14;
  color: $grayFontColor;
  display: flex;
  align-items: center;
  transition-duration: 300ms;
}
.jj-timeline-item__line {
  width: 20px;
  margin: 0 7px;
  position: relative;
  display: flex;
  align-items: center;
  .jj-timeline-item__tail_first {
    position: absolute;
    top: 0;
    left: 9px;
    height: 50%;
    width: 2px;
    background-color: $primaryColor_24;
  }
  .jj-timeline-item__tail_last {
    position: absolute;
    bottom: 0;
    left: 9px;
    height: 50%;
    width: 2px;
    background-color: $primaryColor_24;
  }
  .jj-timeline-item__node {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid $primaryColor_24;
    box-sizing: border-box;
    background-color: #fff;
    z-index: 99;
    transition-duration: 300ms;
    position: relative;
  }
}
.jj-timeline-item__content {
  display: flex;
  align-items: center;
  .jj-timeline-item__body {
    height: 240px;
    margin-left: 72px;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 30px;
    display: flex;
    transition-duration: 300ms;
    img {
      width: 300px;
      height: 180px;
    }
    .jj-timeline-item__body_con {
      width: 690px;
      margin-left: 30px;
    }
    h3 {
      font-size: $fontSize_24;
      color: $defaultFontColor;
      line-height: 1em;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    p {
      padding-top: 20px;
      height: 156px;
      box-sizing: border-box;
      font-size: $fontSize_14;
      color: $grayFontColor;
      line-height: 1.75em;
      overflow-wrap: break-word;
      display: -webkit-box;
      overflow: hidden;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 5;
    }
  }
}
</style>
