var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("el-color-picker", {
    attrs: {
      disabled: _vm.item.disabled,
      size: _vm.item.size,
      "color-format": _vm.item.colorFormat,
      predefine: _vm.item.predefine,
      "show-alpha": _vm.item.showAlpha,
    },
    on: { change: _vm.change },
    model: {
      value: _vm.formData[_vm.item.prop],
      callback: function ($$v) {
        _vm.$set(_vm.formData, _vm.item.prop, $$v)
      },
      expression: "formData[item.prop]",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }