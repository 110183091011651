// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Variables */
[data-v-7febec70]:export {
  menuText: #000;
  menuActiveText: #fff;
  subMenuActiveText: #f4f4f5;
  menuActiveBg: #1589cd;
  menuBg: #fff;
  menuHover: rgba(45, 153, 255, 0.3);
  subMenuBg: #fff;
  subMenuHover: rgba(45, 153, 255, 0.3);
  sideBarWidth: 200px;
  primaryColor: #1589cd;
  primaryFontColor: rgb(45, 153, 255);
  primaryColor_8: rgba(31, 113, 255, 0.08);
  globalColor: #e60012;
  defaultFontColor: rgb(20, 35, 69);
  tableHeaderBg: #fafafa;
  tableHeaderHeight: 40px;
  tableAlign: center;
  fontSize_14: 14px;
  fontSize_16: 16px;
  fontSize_18: 18px;
  fontSize_24: 24px;
}

/* Mixins */
.el-menu-item[data-v-7febec70] {
  float: left;
  height: 40px;
  line-height: 40px;
  padding: 0 16px;
  height: 40px;
  line-height: 40px;
  border-radius: 20px;
}
.el-menu-item[data-v-7febec70]:hover:not(.is-disabled) {
  background: transparent;
}
.el-menu-item.is-active[data-v-7febec70] {
  font-weight: bold;
  border-radius: 20px;
}
.el-menu-item.is-disabled[data-v-7febec70] {
  font-weight: normal;
}
.el-submenu__title[data-v-7febec70] {
  background-color: transparent;
}
.horizontal-container[data-v-7febec70] {
  width: 100%;
  flex: 1;
  float: left;
  margin-right: 19px;
}
.horizontal-container a[data-v-7febec70] {
  text-decoration: none;
}
.menu-level-second[data-v-7febec70] {
  height: 40px !important;
  font-size: 14px;
  font-weight: normal;
  color: rgb(20, 35, 69) !important;
  line-height: 40px !important;
}
.menu-level-second[data-v-7febec70]:not(:last-child) {
  margin-bottom: 8px;
}
.menu-level-second[data-v-7febec70]:last-child {
  margin-bottom: 4px;
}
.menu-level-second[data-v-7febec70]:first-child {
  margin-top: 4px;
}
.menu-level-second.el-submenu.is-active[data-v-7febec70] {
  border-radius: 0;
  background: linear-gradient(to right, #fff, rgba(31, 113, 255, 0.1));
}
.menu-level-second .el-submenu__title > span[data-v-7febec70] {
  color: rgb(20, 35, 69) !important;
  font-size: 14px !important;
}
.menu-level-second:hover .el-submenu__title > span[data-v-7febec70] {
  color: #ffffff !important;
  font-size: 14px !important;
}
.menu-level-second.is-active[data-v-7febec70]:not(.is-disabled) {
  border-radius: 0;
  font-weight: bold;
  color: rgb(255, 255, 255) !important;
  background: linear-gradient(to right, #fff, rgba(31, 113, 255, 0.1));
}
.menu-level-second.is-active:not(.is-disabled) .el-submenu__title > span[data-v-7febec70] {
  color: rgb(255, 255, 255) !important;
  font-size: 14px !important;
}
.menu-level-second.is-active:not(.is-disabled) .svg-icon[data-v-7febec70] {
  color: #1589cd;
}
.menu-level-second.is-active[data-v-7febec70]:not(.is-disabled).el-submenu__icon-arrow {
  color: #1589cd !important;
}
.menu-level-other[data-v-7febec70] {
  height: 32px !important;
  font-size: 14px;
  font-weight: normal;
  color: rgb(20, 35, 69) !important;
  line-height: 32px !important;
}
.menu-level-other .el-submenu__title > span[data-v-7febec70] {
  font-size: 14px !important;
}
.menu-level-other .svg-icon[data-v-7febec70] {
  color: rgb(20, 35, 69) !important;
}
.menu-level-other[data-v-7febec70]:not(:last-child) {
  margin-bottom: 4px;
}
.menu-level-other.el-submenu.is-active[data-v-7febec70] {
  border-radius: 0;
  background: linear-gradient(to right, #fff, rgba(31, 113, 255, 0.1));
}
.menu-level-other:hover .svg-icon[data-v-7febec70] {
  color: #1589cd !important;
}
.menu-level-other.is-active[data-v-7febec70] {
  border-radius: 0;
  font-weight: bold;
  color: rgb(255, 255, 255) !important;
  background: linear-gradient(to right, #fff, rgba(31, 113, 255, 0.1));
}
.menu-level-other.is-active .svg-icon[data-v-7febec70] {
  color: #1589cd !important;
}
[data-v-7febec70] .el-icon-arrow-down {
  display: none;
}
[data-v-7febec70] .el-submenu__title {
  padding: 0 16px;
  height: 40px;
  line-height: 40px;
  background: transparent !important;
  border-radius: 20px;
}
[data-v-7febec70] .el-submenu__title:hover {
  color: rgb(255, 255, 255);
  background: transparent;
}
[data-v-7febec70] .el-submenu.is-active .el-submenu__title {
  border-radius: 20px;
  color: rgb(255, 255, 255);
  font-weight: bold;
}
[data-v-7febec70] .svg-icon {
  margin-right: 12px;
}
.menu-level-first[data-v-7febec70] {
  font-size: 14px;
  margin-right: 4px;
  color: rgb(20, 35, 69) !important;
  border-radius: 20px !important;
}
.menu-level-first.is-active[data-v-7febec70] {
  color: rgb(255, 255, 255) !important;
  border-radius: 20px;
  background: #1589cd !important;
}
.menu-level-first.is-disabled[data-v-7febec70] {
  color: rgb(20, 35, 69) !important;
  border-radius: 0 !important;
  background: transparent !important;
}
.menu-level-first .el-submenu__title[data-v-7febec70],
.menu-level-first .svg-icon[data-v-7febec70],
.menu-level-first span[data-v-7febec70] {
  font-size: 14px;
}
.menu-level-first[data-v-7febec70] .el-submenu__title {
  color: rgb(20, 35, 69);
}
.right-menu-item-homepage[data-v-7febec70] {
  margin-right: 0;
}
.right-menu-item-homepage[data-v-7febec70] .el-menu-item {
  padding: 0;
  margin: 0 16px;
  font-size: 14px;
  font-weight: normal;
  border-radius: 0;
}
.right-menu-item-homepage[data-v-7febec70] .el-menu-item:hover {
  background-color: #1589cd;
}
.right-menu-item-homepage[data-v-7febec70] .el-menu-item:last-child {
  margin-right: 0;
}
.right-menu-item-homepage[data-v-7febec70] .el-menu-item.is-active {
  color: #fff !important;
  border-radius: 0 !important;
  border-bottom: 2px solid #244063;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
