// const year = new Date().getFullYear();
const version = 'v1.0';
const prefix = 'protal';
const defaultSettings = {
  navTheme: 'dark', // theme for nav menu
  primaryColor: '#ff6e00',
  layout: 'sidemenu', // nav menu position: sidemenu or topmenu
  contentWidth: 'Fluid', // layout of content: Fluid or Fixed, only works when layout is topmenu
  fixedHeader: true, // sticky header
  autoHideHeader: false, // auto hide header
  fixSiderbar: true, // sticky siderbar
  menu: {},
  title: '济宁国投数字产融服务平台',
  pwa: true,
  // Your custom iconfont Symbol script Url
  // eg：//at.alicdn.com/t/font_1039637_btcrd5co4w.js
  // 注意：如果需要图标多色，Iconfont 图标项目里要进行批量去色处理
  // Usage: https://github.com/ant-design/ant-design-pro/pull/3517
  iconfontUrl: '',
  version: version,
  projectPrefix: prefix, // 用于本地存储等数据存储使用的字段前缀 企业端protal  运营端 operate
  menuType: 2, // 0 顶部导航栏 1左侧导航栏 2顶部+左侧导航栏

  storageOptions: {
    namespace: `${prefix}-`, // key prefix
    name: 'ls', // name variable Vue.[ls] or this.[$ls],
    storage: 'session', // storage name session, local, memory
  },
  routeWhiteList: [
    '/403',
    '/authing',
    '/authDetail',
    '/authBack',
    '/beforeAuthForm',
    '/updateNewUserPassword',
    '/beforePersonAuth',
    '/personAuthForm',
    '/registerPage',
    '/prompt',
    '/sign',
    '/faceUrlPage',
    '/ukeyRegister',
    '/auth-redirect',
    '/newPassword',
    '/homePage',
    '/serviceFinance',
    '/platformDynamic',
    '/platformDynamic/list',
    '/platformDynamic/detail',
    '/helpCenter',
    '/aboutUs',
    '/agreementSigning',
  ],
  footerCopyright: `山东金服数字化科技有限公司 © 2024 版权所有`,
  icp: "鲁ICP备2024102660号",
};

export default defaultSettings;
