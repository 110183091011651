import { fetchOrgStatus, fetchOrgInfo } from '@/api/BeforeAuthProcess/beforeAuthCard';

const defaultState = () => {
  return {
    statusInfo: {},
    orgInfo: {}
  }
}

const mutations = {
  SET_STATUS_INFO: (state, statusInfo) => {
    state.statusInfo = statusInfo;
  },

  SET_ORG_INFO: (state, orgInfo) => {
    state.orgInfo = orgInfo;
  },

  RESET_STATE: (state) => {
    Object.assign(state, defaultState());
  },
}

const actions = {
  /**
   * @description 企业实名认证状态查询
   */
  async fetchOrgStatus({ commit }) {
    const { data, rspCode } = await fetchOrgStatus();
    if (rspCode === '00000000') {
      commit('SET_STATUS_INFO', data);
    }
  },

  /**
   * @description 企业实名认证状态查询
   */
  async fetchOrgInfo({ commit }) {
    const { data, rspCode } = await fetchOrgInfo();
    if (rspCode === '00000000') {
      commit('SET_ORG_INFO', data);
    }
  },
}

export default {
  namespaced: true,
  state: defaultState(),
  mutations,
  actions,
};