var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    { staticClass: "jj-timeline" },
    _vm._l(_vm.newsList, function (item) {
      return _c(
        "li",
        {
          directives: [
            {
              name: "infinite-scroll",
              rawName: "v-infinite-scroll",
              value: _vm.load,
              expression: "load",
            },
          ],
          key: item.id,
          ref: "newsListItem",
          refInFor: true,
          staticClass: "jj-timeline-item",
          attrs: { "data-aos": "fade-up" },
          on: { click: () => _vm.handleClick(item) },
        },
        [
          _c("div", { staticClass: "jj-timeline-item__timestamp" }, [
            _vm._v(_vm._s(item.createDate)),
          ]),
          _vm._v(" "),
          _vm._m(0, true),
          _vm._v(" "),
          _c("div", { staticClass: "jj-timeline-item__content" }, [
            _c("div", { staticClass: "jj-timeline-item__body" }, [
              _c("img", { attrs: { src: item.coverImgUrl, alt: "" } }),
              _vm._v(" "),
              _c("div", { staticClass: "jj-timeline-item__body_con" }, [
                _c("h3", [_vm._v(_vm._s(item.newsTitle))]),
                _vm._v(" "),
                _c("p", { domProps: { innerHTML: _vm._s(item.newsContent) } }),
              ]),
            ]),
          ]),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "jj-timeline-item__line" }, [
      _c("div", { staticClass: "jj-timeline-item__tail_first" }),
      _vm._v(" "),
      _c("div", { staticClass: "jj-timeline-item__tail_last" }),
      _vm._v(" "),
      _c("div", { staticClass: "jj-timeline-item__node" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }