// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Variables */
[data-v-c10d6fe6]:export {
  menuText: #000;
  menuActiveText: #fff;
  subMenuActiveText: #f4f4f5;
  menuActiveBg: #1589cd;
  menuBg: #fff;
  menuHover: rgba(45, 153, 255, 0.3);
  subMenuBg: #fff;
  subMenuHover: rgba(45, 153, 255, 0.3);
  sideBarWidth: 200px;
  primaryColor: #1589cd;
  primaryFontColor: rgb(45, 153, 255);
  primaryColor_8: rgba(31, 113, 255, 0.08);
  globalColor: #e60012;
  defaultFontColor: rgb(20, 35, 69);
  tableHeaderBg: #fafafa;
  tableHeaderHeight: 40px;
  tableAlign: center;
  fontSize_14: 14px;
  fontSize_16: 16px;
  fontSize_18: 18px;
  fontSize_24: 24px;
}

/* Mixins */
.app-wrapper[data-v-c10d6fe6] {
  position: relative;
  padding-top: 132px;
  height: 100%;
}
.app-wrapper .fixed-header[data-v-c10d6fe6] {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 2000;
  width: 100%;
  transition: width 0.28s;
}
.app-wrapper .main-container[data-v-c10d6fe6] {
  margin-left: 200px;
  width: calc(100% - 200px - 16px);
  background-color: #F5F7FE;
  transition: margin-left 0.28s;
  -webkit-transition: margin-left 0.28s;
  -moz-transition: margin-left 0.28s;
  -ms-transition: margin-left 0.28s;
  -o-transition: margin-left 0.28s;
}
.app-wrapper .sidebar-container[data-v-c10d6fe6] {
  transition: width 0.28s;
  width: 200px !important;
  position: fixed;
  font-size: 0px;
  top: 116px;
  bottom: 0;
  left: 0;
  z-index: 1001;
  overflow: hidden;
}
.hideSidebar .main-container[data-v-c10d6fe6] {
  margin-left: 80px;
}
.hideSidebar .sidebar-container[data-v-c10d6fe6] {
  width: 80px !important;
}
.hideSidebar[data-v-c10d6fe6] .el-tooltip {
  padding: 0 !important;
}
.hideSidebar[data-v-c10d6fe6] .el-submenu__title {
  padding: 0 !important;
}
.hideSidebar[data-v-c10d6fe6] .el-submenu__icon-arrow {
  visibility: hidden;
}
.moreHeader[data-v-c10d6fe6] {
  padding-top: 116px;
}
.moreHeader .sidebar-container[data-v-c10d6fe6] {
  top: 132px;
}
.moreHeader .main-container[data-v-c10d6fe6] {
  margin-left: 0;
}
.withoutAnimation .main-container[data-v-c10d6fe6],
.withoutAnimation .sidebar-container[data-v-c10d6fe6] {
  transition: none;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
