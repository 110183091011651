// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
/* Variables */
[data-v-1cd32194]:export {
  menuText: #000;
  menuActiveText: #fff;
  subMenuActiveText: #f4f4f5;
  menuActiveBg: #1589cd;
  menuBg: #fff;
  menuHover: rgba(45, 153, 255, 0.3);
  subMenuBg: #fff;
  subMenuHover: rgba(45, 153, 255, 0.3);
  sideBarWidth: 200px;
  primaryColor: #1589cd;
  primaryFontColor: rgb(45, 153, 255);
  primaryColor_8: rgba(31, 113, 255, 0.08);
  globalColor: #e60012;
  defaultFontColor: rgb(20, 35, 69);
  tableHeaderBg: #fafafa;
  tableHeaderHeight: 40px;
  tableAlign: center;
  fontSize_14: 14px;
  fontSize_16: 16px;
  fontSize_18: 18px;
  fontSize_24: 24px;
}

/* Mixins */
.customization-card[data-v-1cd32194] {
  display: flex;
  flex-direction: column;
  background-color: #fff;
}
.customization-card[data-v-1cd32194]:first-child:last-child {
  min-height: calc(100vh - 178px - 48px) !important;
}
.customization-card:first-child:last-child :nth-child(2).body-wrap[data-v-1cd32194] {
  min-height: calc(100vh - 178px - 48px - 48px - 48px - 24px) !important;
}
.customization-card[data-v-1cd32194]:not(:first-child) {
  margin-top: 16px;
}
.customization-card[data-v-1cd32194]:not(:first-child):last-child .el-form .el-row:first-child:last-child .el-form-item {
  margin-bottom: 0 !important;
}
.customization-card .header-wrap[data-v-1cd32194] {
  height: 48px;
  flex-shrink: 0;
  background-color: #fff;
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  border-radius: 4px;
  border-bottom: 1px solid #EDF0F4;
}
.customization-card .header-wrap .header-title[data-v-1cd32194] {
  flex: 1;
  font-size: 16px;
  color: #244063;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.customization-card .header-wrap .sub-title[data-v-1cd32194] {
  flex: 1;
  text-align: right;
  padding: 3px 0;
  color: #1589cd;
}
.customization-card .body-wrap[data-v-1cd32194] {
  position: relative;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  /* flex:1 改变成flex-grow: 1 */
  background-color: #fff;
  border-radius: 4px;
  overflow: hidden;
}
.customization-card .el-card__body[data-v-1cd32194] {
  border-radius: 4px;
}
.customization-card .body-flex[data-v-1cd32194] {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex: 1;
  background-color: #fff;
  padding: 24px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
}
.customization-card .divider-title[data-v-1cd32194] {
  font-size: 16px;
  color: rgb(20, 35, 69);
  font-weight: bold;
}
.customization-card .el-divider--horizontal[data-v-1cd32194] {
  margin: 12px 0 15px;
  background-color: #e8e8e8;
}
.customization-card .show-img-container[data-v-1cd32194] {
  position: absolute;
  top: -80px;
  right: -32px;
  z-index: 9;
}
.customization-card .divider-title-right[data-v-1cd32194] {
  float: right;
  font-size: 14px;
  color: #1589cd;
  cursor: pointer;
}
.customization-card .divider-title-right.text[data-v-1cd32194] {
  cursor: default;
  color: rgb(20, 35, 69);
}
.customization-card .divider-title-right.text[data-v-1cd32194]:active {
  opacity: 1;
}
.customization-card .divider-title-right[data-v-1cd32194]:active {
  opacity: 0.6;
}
.customization-card svg[data-v-1cd32194] {
  width: 4px;
  height: 16px;
  border-radius: 4px;
}
.smsTemplate[data-v-1cd32194] {
  width: 100%;
  height: auto;
  overflow-x: hidden;
}
.toopSty[data-v-1cd32194] {
  line-height: 20px;
  max-width: 300px;
}
.smsTemplate[data-v-1cd32194] {
  width: 200px;
  height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
