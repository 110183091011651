// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Variables */
[data-v-f4835c60]:export {
  menuText: #000;
  menuActiveText: #fff;
  subMenuActiveText: #f4f4f5;
  menuActiveBg: #1589cd;
  menuBg: #fff;
  menuHover: rgba(45, 153, 255, 0.3);
  subMenuBg: #fff;
  subMenuHover: rgba(45, 153, 255, 0.3);
  sideBarWidth: 200px;
  primaryColor: #1589cd;
  primaryFontColor: rgb(45, 153, 255);
  primaryColor_8: rgba(31, 113, 255, 0.08);
  globalColor: #e60012;
  defaultFontColor: rgb(20, 35, 69);
  tableHeaderBg: #fafafa;
  tableHeaderHeight: 40px;
  tableAlign: center;
  fontSize_14: 14px;
  fontSize_16: 16px;
  fontSize_18: 18px;
  fontSize_24: 24px;
}

/* Mixins */
.app-main-footer[data-v-f4835c60] {
  height: 58px;
  line-height: 35px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.45);
  background: #F5F7FE;
  padding-bottom: 16px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.app-main-footer span[data-v-f4835c60] {
  display: flex;
  margin: 0 10px;
  align-items: center;
}
.app-main-footer a[data-v-f4835c60] {
  color: rgba(0, 0, 0, 0.45);
  text-decoration: none;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
