import request from '@/utils/request';

/**
 * @description 个人实名认证基本信息提交
 * @return
 */
export const corpUserSaveBaseInfo = (data) => {
  return request('/auth/corpUserApply/saveBaseInfo', { method: 'POST', data });
};

/**
 * @description 个人实名认证选择认证方式
 * @return
 */
export const corpUserCheckAuthMethod = (data) => {
  return request('/auth/corpUser/checkAuthMethod', { method: 'POST', data });
};

/**
 * @description 企业实名认证详情查询
 * @return
 */
export const corpSelectByOrgId = (data) => {
  return request('/portal/corp/info/corp-detail', { method: 'POST', data });
};

/**
 * @description 企业详情快照查询
 * @return
 */
export const corpSelectByTxNo = (data) => {
  return request('/auth/corpApply/selectByTxNo', { method: 'POST', data });
};

/**
 * @description 企业实名认证信息提交
 * @return
 */
export const corpSaveBaseInfo = (data) => {
  return request('/auth/corpApply/saveBaseInfo', { method: 'POST', data });
};

/**
 * @description 企业实名认证管理员信息提交
 * @return
 */
export const corpSupplementAdminInfo = (data) => {
  return request('/auth/corpUserApply/supplementAdminInfo', { method: 'POST', data });
};

/**
 * @description 企业实名认证对公账户信息提交
 * @return
 */
export const corpPubAcctSave = (data) => {
  return request('/auth/corpPubAcctApply/save', { method: 'POST', data });
};

/**
 * @description 企业实名认证选择认证方式
 * @return
 */
export const corpCheckAuthMethod = (data) => {
  return request('/auth/corpApply/checkAuthMethod', { method: 'POST', data });
};

/**
 * @description 企业实名认证流程提交
 * @return
 */
export const corpAuthenticate = (data) => {
  return request('/auth/corpApply/authenticate', { method: 'POST', data });
};

/**
 * @description 人脸识别
 * @return
 */
export const fetchFaceIdentity = (params) => {
  return request('/thirdparty/certification/faceIdentity', { method: 'GET', params });
};

/**
 * @description 人脸识别(不需要创建个人账户)
 * @return
 */
export const faceIdentityWithoutCreateAccount = (data) => {
  return request('/thirdparty/certification/faceIdentityWithoutCreateAccount', { method: 'POST', data });
};

/**
 * @description 人脸识别结果查询
 * @return
 */
export const queryIdentityResult = (data) => {
  return request('/thirdparty/certification/queryIdentityResult', { method: 'POST', data });
};

/**
 * @description 更新企业工商数据
 * @return
 */
export const queryByNameOrCreditNo = (data) => {
  return request('/thirdparty/qxbBainOrg/selectByNameOrCreditNo', { method: 'POST', data });
};

/**
 * @description ukey验证
 * @return
 */
export const checkUKeyCert = (data) => {
  return request('/thirdparty/checkCert/checkUKeyCert', { method: 'POST', data });
};

/**
 * @description 运营商3要素实名认证
 * @return
 */
export const telecom3Factors = (data) => {
  return request('/thirdparty/certification/telecom3Factors', { method: 'POST', data });
};

/**
 * @description 运营商3要素核身认证
 * @return
 */
export const telecom3FactorsWithoutCreateAccount = (data) => {
  return request('/thirdparty/certification/telecom3FactorsWithoutCreateAccount', { method: 'POST', data });
};

/**
 * @description 发送短信验证码
 * @return
 */
export const sendAuthCode = (data) => {
  return request('/thirdparty/certification/sendAuthCode', { method: 'POST', data });
};

/**
 * @description 银行卡四要素实名认证
 * @return
 */
export const bankCard4Factors = (data) => {
  return request('/thirdparty/certification/bankCard4Factors', { method: 'POST', data });
};

/**
 * @description 银行卡四要素核身认证
 * @return
 */
export const bankCard4FactorsWithoutCreateAccount = (data) => {
  return request('/thirdparty/certification/bankCard4FactorsWithoutCreateAccount', { method: 'POST', data });
};

/**
 * @description 查询核身认证结果
 * @return
 */
export const queryIdentityResultByIdNo = (data) => {
  return request('/thirdparty/certification/queryIdentityResultByIdNo', { method: 'POST', data });
};
