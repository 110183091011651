/** form二次封装 noShow 设置该条是否展示 noFormItem 设置是否有label needToast
form校验是否需要Toast提示 canDraggable 是否可拖拽排序*/
<template>
  <div>
    <div v-if="dividerTitle">
      <span class="divider-title">{{ dividerTitle }}</span>
      <el-divider></el-divider>
    </div>
    <el-form
      ref="elForm"
      :model="formData"
      :label-suffix="labelSuffix"
      :status-icon="statusIcon"
      :size="size"
      :disabled="disabled"
      :inline="isInline"
      :label-width="labelWidth"
      :label-position="labelPosition"
      :rules="rules"
    >
      <template>
        <el-row
          v-for="(items, index) in formCols"
          :key="index"
          :gutter="formVerticalRowGutter"
        >
          <template v-for="(item, itemIndex) in items">
            <el-col
              :span="item.span ? item.span : customFormItemSpan.commonSpan"
              :key="item.prop"
              :offset="item.offset"
              v-show="!item.noShow"
              :style="(!item.noShowItemStyle && item.style) || ''"
              @click.native="formItemClick(item)"
              @dblclick.native="formItemDbClick(item)"
            >
              <el-form-item
                :class="item.itemClass ? item.itemClass : ''"
                :label="item.noFormItem ? '' : item.label"
                :prop="item.prop"
                :label-width="item.noFormItem ? '0' : item.labelWidth"
              >
                <span
                  slot="label"
                  v-if="!!item.slotLabel && typeof item.slotLabel === 'string'"
                  v-html="item.slotLabel"
                  @click="formSlotClick(item)"
                ></span>
                <el-popconfirm
                  trigger="click"
                  :tabindex="itemIndex"
                  v-if="item.showPopConfirm"
                  :append-to-body="true"
                  :confirm-button-text="item.confirmText || '确定'"
                  :cancel-button-text="item.cancelText || '取消'"
                  icon="el-icon-info"
                  icon-color="#1f71ff"
                  placement="top-end"
                  :title="item.title"
                  @confirm="item.handle(item, 'popConfirm')"
                  @cancel="item.handle(item, 'popCancel')"
                >
                  <el-button
                    slot="reference"
                    :disabled="
                      item.disabled ||
                      (item.isDisabled && item.isDisabled(item))
                    "
                    :type="item.type"
                    :size="size"
                    :icon="item.icon"
                  >
                    {{ item.label }}
                  </el-button>
                </el-popconfirm>
                <slot
                  v-else-if="item.eType === 'slot'"
                  :name="item.slotName"
                  :data="{ ...item, ...formData }"
                ></slot>
                <span v-else-if="item.eType === 'Text'">
                  {{
                    item.type === "amount" ? numberFormatAmt(formData[item.prop]) : ""
                  }}
                  {{
                    item.type === "count" ? numberFormatCnt(formData[item.prop]) : ""
                  }}
                  {{
                    item.type === "rate" ? numberFormatRate(formData[item.prop]) : ""
                  }}
                  {{
                    !item.type
                      ? (item.formatter && item.formatter(formData)) ||
                        formData[item.prop]
                      : ""
                  }}
                </span>
                <m-element
                  v-else
                  :item="item"
                  :form-data="formData"
                  @selectEvent="selectEvent"
                  @event="event"
                ></m-element>
              </el-form-item>
            </el-col>
            <el-col
              :span="
                item.span
                  ? 24 - item.span * items[0].length
                  : 24 - customFormItemSpan.commonSpan * items[0].length
              "
              :key="itemIndex"
              v-if="
                index === 0 &&
                itemIndex === formCols[0].length - 1 &&
                showFormLineButtons
              "
            >
              <el-button
                v-for="(btnItem, btnIndex) in formLineButtons"
                :key="btnIndex"
                :type="btnItem.type"
                :icon="btnItem.icon"
                @click="event(btnItem)"
                :class="btnItem.span && 'right'"
                :size="btnItem.size"
                :loading="btnItem.span ? loading : false"
              >
                {{ btnItem.label }}
              </el-button>
              <el-button
                @click="handleMoreButton"
                type="text"
                v-if="
                  formLineButtons.length !== 0 &&
                  slice(formCols, 1, formCols.length - 1).length > 0
                "
              >
                {{ showMoreFilter ? "收起" : "展开" }}
                <i
                  :class="
                    showMoreFilter ? 'el-icon-arrow-up' : 'el-icon-arrow-down'
                  "
                ></i>
              </el-button>
            </el-col>
          </template>
        </el-row>
      </template>
    </el-form>
  </div>
</template>

<script type="text/ecmascript-6">
import mElement from "./components";
import { slice } from "lodash";
import { debounce } from "@/utils/utils";
import { customFormItemSpan } from "@/utils/enums";
import { Message } from "element-ui";
import customFormMixin from "@/mixins/customFormMixin";

export default {
  name: "FormComponent",
  mixins: [customFormMixin],
  components: {
    mElement,
  },
  data() {
    return {
      slice,
      customFormItemSpan,
      showMoreFilter: false,
    };
  },
  created() {
    this.setNoShow(this.moreFilterProp, true);
  },
  methods: {
    // 展开收起
    async handleMoreButton() {
      const flag = this.showMoreFilter;
      await this.setNoShow(this.moreFilterProp, flag);
      this.showMoreFilter = !this.showMoreFilter;
      this.$emit("event", {prop: "showMoreFilter", value: !this.showMoreFilter});
    },
    //提交校验
    submit() {
      return new Promise((resolve) => {
        this.$refs["elForm"].validate((valid, message) => {
          if (valid) {
            resolve(valid);
          } else if (this.needToast) {
            Message({
              type: "error",
              message: message[Object.keys(message)[0]][0].message
                ? message[Object.keys(message)[0]][0].message
                : "校验失败",
            });
          }
        });
      });
    },
    //重置
    reset() {
      this.formCols.forEach((items) => {
        items.forEach((item) => {
          if (item.eType === "Check" || item.eType === "CheckButton") {
            this.formData[item.prop].length = 0;
          } else {
            delete this.formData[item.prop];
          }
        });
      });
      this.$refs["elForm"].resetFields();
    },
    formSlotClick(item) {
      // 自定义label，不支持点击
      if (item.disabledClick) return;
      this.$emit("formSlotClick", item);
    },
    formItemClick(item) {
      this.$emit("formItemClick", item);
    },
    formItemDbClick(item) {
      this.$emit("formItemDbClick", item);
    },

    // 下拉框不需要防抖
    selectEvent(params) {
      this.$emit("event", params);
    },

    //所有change以及click事件
    event: debounce(function (params) {
      this.$emit("event", params);
      if (params.prop === "submit") {
        this.submit().then((res) => {
          this.$emit("submit", res);
        });
      } else if (params.prop === "reset") {
        this.reset();
        this.$emit("reset");
      }
    }, 500),

    //设置是否展示
    setNoShow(props, value) {
      if (props.length !== 0) {
        props.forEach((item) => {
          this.setNewValue(item, "noShow", value);
        });
      }
    },

    //设置radio checkbox options值
    setOptions(prop, value) {
      this.setNewValue(prop, "options", value);
    },

    /**
     * 改变二维数组中某个值
     * @param prop        数组中唯一标识值
     * @param key         需要修改的字段
     * @param value        需要修改的值
     */
    setNewValue(prop, key, value) {
      let two = -1;
      let one = this.formCols.findIndex((item) => {
        let iIndex = item.findIndex((iItem) => {
          return iItem.prop === prop;
        });

        if (iIndex !== -1) {
          two = iIndex;
          return true;
        }
      });
      this.$nextTick(() => {
        if (one === -1 || two === -1) {
          console.log("找不到要设置的字段");
          return;
        }
        this.$set(this.formCols[one][two], key, value);
      });
    },

    /**
     * 清楚校验
     * @param prop  数组中唯一标识值  Array | String
     */
    clearValidate(prop) {
      if (prop) {
        this.$refs["elForm"] && this.$refs["elForm"].clearValidate(prop);
      } else {
        this.$refs["elForm"] && this.$refs["elForm"].clearValidate();
      }
    },

    /**
     * 对整个表单进行重置，将所有字段值重置为初始值并移除校验结果
     */
    resetFields() {
      this.$refs["elForm"] && this.$refs["elForm"].resetFields();
    },

    /**
     * 	对部分表单字段进行校验的方法
     * @param prop  数组中唯一标识值  Array | String
     */
    validateField(prop) {
      return new Promise((resolve) => {
        if (prop) {
          this.$refs["elForm"] &&
            this.$refs["elForm"].validateField(prop, (errorMessage) => {
              if (errorMessage) {
                resolve(errorMessage);
              } else {
                resolve("");
              }
            });
        } else {
          this.$refs["elForm"] &&
            this.$refs["elForm"].validateField((errorMessage) => {
              if (errorMessage) {
                resolve(errorMessage);
              } else {
                resolve("");
              }
            });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>
<style>
.chosen {
  background-color: #eaffea !important;
}
.right {
  float: right;
  margin-right: 0 !important;
}
</style>
