var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-wrapper", class: _vm.classObj },
    [
      _c(
        "div",
        { staticClass: "fixed-header" },
        [_c("TopNavbar"), _vm._v(" "), _c("Navbar")],
        1
      ),
      _vm._v(" "),
      _vm.menuType !== 1
        ? _c("Sidebar", { staticClass: "sidebar-container" })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "main-container" }, [_c("AppMain")], 1),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }