var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._l(_vm.navMenus, function (navMenu) {
        return [
          !navMenu?.meta.showChildren && navMenu.meta && !navMenu?.meta.hidden
            ? _c(
                "el-menu-item",
                {
                  key: navMenu.path,
                  class: navMenu.meta.class,
                  attrs: { data: navMenu, index: navMenu.path },
                },
                [
                  _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                    _vm._v(_vm._s(navMenu.meta.title)),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          navMenu.children && navMenu.meta && navMenu?.meta.showChildren
            ? _c(
                "el-submenu",
                {
                  key: navMenu.path,
                  class: navMenu.meta.class,
                  attrs: { data: navMenu, index: navMenu.path },
                },
                [
                  _c("template", { slot: "title" }, [
                    _c("span", [_vm._v(_vm._s(navMenu.meta.title))]),
                  ]),
                  _vm._v(" "),
                  _c("MenuItem", { attrs: { navMenus: navMenu.children } }),
                ],
                2
              )
            : _vm._e(),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }