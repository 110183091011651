var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showStep || _vm.showAlert
    ? _c(
        "div",
        { class: ["authenticate-card", _vm.className, _vm.isIEClassName] },
        [
          _c(
            "el-card",
            { staticClass: "el-card__body_bg", attrs: { shadow: "hover" } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: _vm.stepSpan, offset: _vm.stepOffset } },
                    [
                      _vm.showHeaderAlert
                        ? _c("el-alert", {
                            attrs: {
                              title: _vm.alertTitle,
                              type: _vm.alertType,
                              description: _vm.alertDescription,
                              "show-icon": "",
                            },
                            on: { close: _vm.handleAlertClose },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.showStep
                        ? _c(
                            "div",
                            { staticClass: "steps-wrap" },
                            [
                              _c("CustomSteps", {
                                attrs: {
                                  stepsList: _vm.stepsList,
                                  current: _vm.current,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.hideAlert
                        ? _c("el-alert", {
                            attrs: {
                              title: _vm.alertTitle,
                              type: _vm.alertType,
                              description: _vm.alertDescription,
                              "show-icon": "",
                            },
                            on: { close: _vm.handleAlertClose },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.showImg
                    ? _c("el-col", { attrs: { span: 4 } }, [
                        _c("img", {
                          attrs: { src: _vm.todoList[_vm.alertCardStyle] },
                        }),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _vm._t("authenticateBody"),
            ],
            2
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }