var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("CustomCard", {
    attrs: {
      dividerTitle: "进度信息",
      labelPosition: "top",
      showFormLineButtons: false,
      tabHidden: false,
      formLineButtons: [],
      formData: _vm.formData,
      formCols: _vm.formCols,
    },
    on: { event: _vm.watchAllEvent },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }