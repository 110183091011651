/** * create by Songsong_Yu on 2021-02-01 10:37 * 类注释： * 备注： */
<template>
  <div :class="item.class" :style="item.style">
    {{
      (item.formatter && item.formatter(formData[item.prop])) ||
      formData[item.prop] ||
      (formData[item.prop] === null || formData[item.prop] === '' ? '暂无数据' : formData[item.prop])
    }}
  </div>
</template>

<script type="text/ecmascript-6">
import mixins from '../mixins';

export default {
  name: 'zxSpan',
  mixins: [mixins],
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {},
  methods: {},
  activated() {},
  mounted() {},
  created() {},
};
</script>

<style scoped lang="scss">
div {
  white-space: nowrap;
}
</style>
