import orderManagementRoutes from './orderManagementRoutes';
import financingManagementRoutes from './financingManagementRoutes';
import warehouseManagementRoutes from './warehouseManagementRoutes';
import riskMonitoringRoutes from './riskMonitoringRoutes';
import basicInfoManagementRoutes from './basicInfoManagementRoutes';

export default {
  orderManagementRoutes,
  financingManagementRoutes,
  warehouseManagementRoutes,
  riskMonitoringRoutes,
  basicInfoManagementRoutes,
};
