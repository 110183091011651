var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "father", style: _vm.item.style, on: { click: _vm.click } },
    [
      !_vm.formData[_vm.item.prop].includes(".pdf")
        ? _c("el-image", {
            ref: "authImg",
            staticClass: "img",
            style: _vm.item.style || "height:150px",
            attrs: {
              src: _vm.imgSrc,
              fit: _vm.item.fit,
              alt: _vm.item.alt,
              size: _vm.item.size,
              lazy: _vm.item.lazy,
            },
          })
        : _c(
            "div",
            [
              _vm.isIEBrowser
                ? _c(
                    "el-button",
                    { attrs: { type: "text", onclick: _vm.isDownLoadFn } },
                    [_vm._v(" 下载 ")]
                  )
                : _c(
                    "div",
                    [
                      _c("PDFView", {
                        staticStyle: { width: "100%", height: "200px" },
                        attrs: {
                          pdfId: _vm.formData[_vm.item.prop],
                          width: "100%",
                        },
                      }),
                    ],
                    1
                  ),
            ],
            1
          ),
      _vm._v(" "),
      !_vm.isIEBrowser
        ? _c("div", { staticClass: "mySon" }, [_vm._v("点击查看大图")])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }