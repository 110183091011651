var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "custom-steps custom-steps-horizontal custom-steps-label-horizontal",
    },
    _vm._l(_vm.stepsList, function (item, index) {
      return _c(
        "div",
        {
          key: index,
          staticClass: "custom-steps-item",
          class: [
            `custom-steps-item-${_vm.stepsList.length}`,
            {
              "custom-steps-item-process": _vm.current === item.value,
              "custom-steps-item-finish": _vm.current > item.value,
            },
          ],
        },
        [
          _c("div", { staticClass: "custom-steps-item-container" }, [
            _c("div", { staticClass: "custom-steps-item-tail" }),
            _vm._v(" "),
            _c("div", { staticClass: "custom-steps-item-icon" }, [
              _vm.current > item.value
                ? _c("span", { staticClass: "custom-steps-icon" }, [
                    _c(
                      "i",
                      {
                        staticClass:
                          "anticon anticon-check custom-steps-finish-icon",
                        attrs: { "aria-label": "图标: check" },
                      },
                      [
                        _c(
                          "svg",
                          {
                            attrs: {
                              viewBox: "64 64 896 896",
                              "data-icon": "check",
                              width: "1em",
                              height: "1em",
                              fill: "currentColor",
                              "aria-hidden": "true",
                              focusable: "false",
                            },
                          },
                          [
                            _c("path", {
                              attrs: {
                                d: "M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 0 0-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                  ])
                : _c("span", { staticClass: "custom-steps-icon" }, [
                    _vm._v("\n          " + _vm._s(item.value) + "\n        "),
                  ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "custom-steps-item-content" }, [
              _c("div", { staticClass: "custom-steps-item-title" }, [
                _vm._v(_vm._s(item.key)),
              ]),
            ]),
          ]),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }