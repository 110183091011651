var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "has-logo" },
    [
      _c(
        "el-scrollbar",
        { attrs: { "wrap-class": "scrollbar-wrapper" } },
        [
          _c(
            "el-menu",
            {
              attrs: {
                "default-active": _vm.activeMenu,
                collapse: _vm.isCollapse,
                "default-openeds": _vm.defaultOpeneds,
                "unique-opened": true,
                "collapse-transition": false,
                mode: "vertical",
                router: "",
              },
            },
            [_c("MenuItem", { attrs: { navMenus: _vm.menuData } })],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }