var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: {
        "horizontal-container": true,
        "first-level": _vm.isFirstLevel,
        "right-menu-item-homepage": !_vm.notHomePage,
      },
    },
    [
      _vm._l(_vm.navMenus, function (navMenu) {
        return [
          !navMenu.children && navMenu.meta
            ? _c(
                "el-menu-item",
                {
                  key: navMenu.name,
                  class: _vm.showPopClass
                    ? navMenu.meta.popClass
                    : navMenu.meta.class,
                  attrs: { data: navMenu, index: navMenu.path },
                },
                [
                  navMenu.meta.icon
                    ? _c("svg-icon", {
                        attrs: { "icon-class": navMenu.meta.icon },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "header-menu-item-txt",
                      attrs: { slot: "title" },
                      slot: "title",
                    },
                    [_vm._v(_vm._s(navMenu.meta.title))]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          navMenu.children && navMenu.meta
            ? _c(
                "el-submenu",
                {
                  key: navMenu.name,
                  class: _vm.showPopClass
                    ? navMenu.meta.popClass
                    : navMenu.meta.class,
                  attrs: {
                    "popper-append-to-body": false,
                    data: navMenu,
                    index: navMenu.path,
                  },
                },
                [
                  _c(
                    "template",
                    { slot: "title" },
                    [
                      navMenu.meta.icon
                        ? _c("svg-icon", {
                            attrs: { "icon-class": navMenu.meta.icon },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(navMenu.meta.title))]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("MenuItem", {
                    attrs: {
                      navMenus: navMenu.children,
                      showPopClass: _vm.showPopClass,
                    },
                  }),
                ],
                2
              )
            : _vm._e(),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }