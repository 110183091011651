<template>
  <el-tabs :value="current" @tab-click="handleClick">
    <el-tab-pane v-for="item in tabs" :label="item.key" :key="item.value" :name="item.value" />
  </el-tabs>
</template>

<script>
export default {
  name: 'ElTabsComponent',
  props: {
    current: {
      type: String,
      default: '暂无数据',
    },
    tabs: {
      type: Array,
      require: true,
      default: () => [],
    },
    dangers: {
      type: Array,
      default: () => ['1'],
    },
    insertCard: {
      type: Boolean,
      default: true,
    },
    className: {
      type: String,
      default: '',
    },
  },
  methods: {
    handleClick(val) {
      this.$emit('tabsClick', val.name);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
