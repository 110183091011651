var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CustomCard",
    {
      attrs: {
        cardClassName: "result-card",
        headerTitle: _vm.title,
        tabHidden: false,
        formHidden: false,
        bodyHidden: false,
        formLineButtons: [],
        subTitle: _vm.headerSubTitle,
        type: _vm.headerSubTitleType,
      },
    },
    [
      _c("div", { staticClass: "body-wrap" }, [
        _c(
          "div",
          { staticClass: "sub-title-wrap" },
          [
            _vm.subDividerTitle
              ? _c(
                  "span",
                  { staticClass: "sub-title-right", attrs: { type: "text" } },
                  [
                    _vm._v(
                      "\n        " + _vm._s(_vm.subDividerTitle) + "\n      "
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.subDividerTitle ? _c("el-divider") : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "result-content-wrap" },
          [
            _c(
              "el-result",
              [
                _c(
                  "template",
                  { slot: "icon" },
                  [
                    _c("svg-icon", {
                      attrs: {
                        "icon-class": _vm.status,
                        className: `icon-${_vm.status}`,
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "template",
                  { slot: "extra" },
                  [
                    _c(
                      "el-row",
                      [
                        _c("el-col", [
                          _c("div", { staticClass: "tipTitle" }, [
                            _vm._v(_vm._s(_vm.statusTxt)),
                          ]),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-row",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !!_vm.subTitle,
                            expression: "!!subTitle",
                          },
                        ],
                      },
                      [
                        _c("el-col", [
                          _c("div", {
                            staticClass: "txtMsg",
                            domProps: { innerHTML: _vm._s(_vm.subTitle) },
                          }),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-row",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.urlArr && _vm.urlArr.length > 0,
                            expression: "urlArr && urlArr.length > 0",
                          },
                        ],
                      },
                      [
                        _c("el-col", [
                          _c(
                            "div",
                            { staticClass: "subtitle" },
                            [
                              _vm._v(
                                "\n                预览\n                "
                              ),
                              _vm._l(_vm.urlArr, function (item, index) {
                                return _c(
                                  "span",
                                  {
                                    key: index,
                                    staticClass: "link",
                                    on: {
                                      click: function ($event) {
                                        return _vm.$router.push(item.url)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(item.txt) +
                                        "\n                "
                                    ),
                                  ]
                                )
                              }),
                            ],
                            2
                          ),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-row",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !!_vm.goBack,
                            expression: "!!goBack",
                          },
                        ],
                      },
                      [
                        _c(
                          "el-col",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "medium" },
                                on: { click: _vm.handleClick },
                              },
                              [_vm._v(_vm._s(_vm.buttonTitle))]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }