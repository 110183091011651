var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "identity-dialog-container" },
    [
      _c(
        "Dialog",
        {
          ref: "dialog",
          attrs: {
            className: "select-identity-dialog",
            dialogWidth: _vm.identityDialogWidth,
            appendToBody: true,
            cancelButton: _vm.dialogCancelButton.text,
            confirmButton: _vm.dialogConfirmButton.text,
            title: "企业身份选择",
            showClose: _vm.isNotNull(_vm.initCorpIdentity),
            cancelButtonEvent: _vm.dialogCancelButton.event,
            confirmButtonEvent: _vm.dialogConfirmButton.event,
            closeOnClickModal: false,
            closeOnPressEscape: false,
          },
          on: { event: _vm.handleDialogEvent },
        },
        [
          _c(
            "div",
            { attrs: { slot: "dialogBody" }, slot: "dialogBody" },
            [
              _c(
                "el-row",
                { attrs: { gutter: 0 } },
                _vm._l(_vm.corpIdentitys, function (item) {
                  return _c(
                    "el-col",
                    {
                      key: item,
                      attrs: { span: 24 / _vm.corpIdentitys.length },
                    },
                    [
                      _c(
                        "el-card",
                        {
                          staticClass: "select-identity-card",
                          class: {
                            currentCorpIdentityChecked:
                              item === _vm.currentCorpIdentity,
                          },
                          attrs: { shadow: "hover" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.handleSelectIdentity(item)
                            },
                          },
                        },
                        [
                          _c("svg-icon", {
                            staticClass: "identity-icon",
                            attrs: { "icon-class": item },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "identity-name" }, [
                            _vm._v(_vm._s(_vm.idType[item])),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }