export default {
  props: {
    // 是否启用分页选中保留状态  如果要支持动态序号，可以通过 seq-config={startIndex} 属性设置起始值
    // 如果要支持保留选中状态，可以通过设置 checkbox-config 的 reserve 属性
    //  启用 reserve 功能需要有 row-id 唯一主键，可以通过调用 getCheckboxReserveRecords 方法获取获取已保留选中的行数据
    // row-id 已废弃，被 row-config.keyField 替换
    form: {
      type: Boolean,
      default: false,
    },
    maxHeight: {
      type: [Number, String],
      default: "auto",
    },
    tableForm: {
      type: Object,
      default: () => {
        return { tableData: [] };
      },
    },
    reserve: {
      type: Boolean,
      default: false,
    },
    tableId: {
      type: String,
      default: '',
    },
    // showOverflowTooltip 是否添加表格内容过长时显示tooltip
    showOverflowTooltip: {
      type: Boolean,
      default: true,
    },
    // row标识
    rowKey: {
      type: [String, Number],
      default: '',
    },
    // 树形结构props
    treeProps: {
      type: Object,
      default: () => { },
    },
    sheetStyle: {
      type: Object,
      required: false,
      default: () => {
        return { type: '', align: '', justify: 'start' };
      },
    },
    span: {
      type: Number,
      default: 21,
      required: false,
    },
    tabHidden: {
      type: Boolean,
      default: true,
      required: false,
    },
    bordered: {
      type: Boolean,
      default: false,
      required: false,
    },
    amountArr:{ type: Array, default: () => ['重量','数量']},
    //是否清除全部
    clearAllselect: { type: Boolean, default: false },
    //是否可以勾选
    selectable: { type: Function, default: () => true },
    //触发选择列表
    setChangeSelectList: { type: Array, default: () => [] },
    //点击页码后是否滚到顶端
    needToTop: { type: Boolean, default: true },
    // 表格型号：mini,medium,small
    size: { type: String, default: "small" },
    //边框
    isBorder: { type: Boolean, default: false },
    //是否为斑马纹 table
    stripe: { type: Boolean, default: false },
    //列的宽度是否自撑开
    fit: { type: Boolean, default: true },
    //是否显示表头
    showHeader: { type: Boolean, default: true },
    //空数据时显示的文本内容
    emptyText: { type: String, default: '暂无数据' },
    //是否在表尾显示合计行
    showSummary: { type: Boolean, default: false },
    //合计行第一列的文本
    sumText: { type: String, default: '合计' },
    //表头样式
    headerCellStyle: {
      type: Object,
      default: () => {
        return {
          background: '#fafafa',
          color: 'rgba(0, 0, 0, 0.85)',
          fontSize: '14px',
          height: '40px',
          // textAlign: 'center',
        };
      },
    },
    cellBodyStyle: {
      type: Object,
      default: () => {
        return {
          textAlign: 'center',
        };
      },
    },
    // 是否显示表格索引
    isIndex: { type: Boolean, default: false },
    // 序号名称label宽度
    indexLabelWidth: { type: String, default: '60px' },
    //序号名称label
    indexLabel: { type: String, default: '序号' },
    showSearchHeader: { type: Boolean, default: false },
    //加载数据
    loadData: { type: Function, default: null },
    //是否单选
    isSingle: { type: Boolean, default: false },
    //id
    id: { type: String, default: 'table' },
    //loading
    loading: { type: Boolean, default: false },
    // 表格数据
    tableData: { type: Array, default: () => [] },
    // 表格列配置
    tableCols: { type: Array, default: () => [] },
    // 是否显示表格复选框
    isSelection: { type: Boolean, default: false },
    // 表格复选框的宽度
    tableSelectionWidth: { type: String, default: '80px' },
    //设置选中行
    defaultSelections: { type: Array, default: () => [] },
    //是否默认加载数据
    isLoadData: { type: Boolean, default: false },
    //设置行方法
    tableRowClassNames: { type: Function, default: null },
    // 鼠标移入
    cellMouseEnter: { type: Function, default: () => null },
    // 鼠标移出
    cellMouseLeave: { type: Function, default: () => null },
    // 是否展示分页
    isPagination: { type: Boolean, default: false },
    // 分页数据
    pagination: {
      type: Object,
      default: () => {
        return { pageSize: 10, pageNum: 1, total: 0 };
      },
    },
    //分页list
    pageSizeList: {
      type: Array,
      default: () => [10, 20, 50, 100],
    },
    //分页展示
    layout: { type: String, default: 'total,prev,pager,next,sizes,jumper' },
    //分页展示
    vxeLayout: { type: Array, default: () => ['PrevPage', 'JumpNumber', 'NextPage', 'FullJump', 'Sizes', 'Total'] },

    // 用于标识重新渲染dom的key
    renderTableKey: {
      type: Number,
      default: 0,
    },

    // 用于table所在的组件的标题
    cardHeader: {
      type: String,
      default: '',
    },

    // 仅对 type=selection 的列有效，类型为 Boolean，为 true 则会在数据更新之后保留之前选中的数据（需指定 row-key）
    reserveSelection: {
      type: Boolean,
      default: false,
    },
  },
};
