import { fetchProtocol, getCommFileByIds } from '@/api/common';
import { getPdfOrImageConfig } from '@/utils/utils';

const defaultState = () => {
  return {
    fileList: [],
    commFileList: [],
    pagination: {
      pageSize: 10,
      current: 1,
      total: 0,
    },
  };
};
export const state = defaultState();

const actions = {
  // 查询协议信息
  async getContractList({ commit }, payload) {
    const { data } = await fetchProtocol(payload);
    if (data) {
      const { records, total } = data;
      let fileList = records || [];
      const fileIds = fileList?.map((item) => item.contractFileId);

      if (fileIds?.length > 0) {
        const fileNamesRes = await getCommFileByIds({ fileIds });
        // 查询到协议名称信息fileNamesRes混入到协议列表fileList中
        const list = fileList.map((item) => {
          const file = fileNamesRes.data.find((v) => v.fileId === item.contractFileId);
          return { ...item, fileName: file.fileName, fileId: file.fileId, ...getPdfOrImageConfig(file) };
        });
        fileList = list;
      }
      const resData = { records: fileList, total };
      commit('SET_FILE_LIST', resData);
      return resData;
    }
  },
};

const mutations = {
  // 查询协议信息   bussId查询时返回值没有records,非分页接口，total为0，不展示分页
  SET_FILE_LIST(state, data) {
    state.fileList = data?.records || data || [];
    if (data?.records) {
      state.pagination.total = data.total ? Number(data.total) : 0;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
