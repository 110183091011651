<template>
  <ul class="pro-list" id="pro-list">
    <li
      class="pro-list-item"
      v-for="(item, key) in proList"
      :key="item.id"
      data-aos="fade-up"
      :data-aos-delay="(key % 3) * 200"
    >
      <img :src="item | imgUrl" />
      <h6>{{ item.productName }}</h6>
      <div class="pro-list-item-wrap">
        <el-scrollbar wrap-class="pro-list-item-container" ref="scrollProListItemRef">
          <p class="pro-list-item-p" v-html="item.productDesc"></p>
        </el-scrollbar>
      </div>
    </li>
  </ul>
</template>

<script>
import defaultImgUrl from '@/assets/home/juxin.png';
import { getPdfOrImageConfig } from '@/utils/utils';

export default {
  props: {
    proList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      defaultImgUrl,
    };
  },
  filters: {
    imgUrl(item) {
      let url = '';
      if (item.productImageId) {
        url = getPdfOrImageConfig(item.productImageId, true);
      } else if (item.productImage) {
        url = `data:image/png;base64,${item.productImage}`;
      } else {
        url = defaultImgUrl;
      }
      return url;
    },
  },
};
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>
