var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.item.class, style: _vm.item.style }, [
    _vm._v(
      "\n  " +
        _vm._s(
          (_vm.item.formatter &&
            _vm.item.formatter(_vm.formData[_vm.item.prop])) ||
            _vm.formData[_vm.item.prop] ||
            (_vm.formData[_vm.item.prop] === null ||
            _vm.formData[_vm.item.prop] === ""
              ? "暂无数据"
              : _vm.formData[_vm.item.prop])
        ) +
        "\n"
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }