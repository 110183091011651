import request from '@/utils/request';

/**
 * @description 企业实名认证状态查询
 * @return
 */
export const fetchOrgStatus = (data) => {
    return request('/portal/auth/corp/verify/status ', { method: 'POST', data });
};

/**
 * @description 企业实名认证信息查询
 * @return
 */
 export const fetchOrgInfo = (data) => {
    return request('/portal/auth/corp/verify/info ', { method: 'POST', data });
};