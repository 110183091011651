// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Variables */
[data-v-6a0ac0a0]:export {
  menuText: #000;
  menuActiveText: #fff;
  subMenuActiveText: #f4f4f5;
  menuActiveBg: #1589cd;
  menuBg: #fff;
  menuHover: rgba(45, 153, 255, 0.3);
  subMenuBg: #fff;
  subMenuHover: rgba(45, 153, 255, 0.3);
  sideBarWidth: 200px;
  primaryColor: #1589cd;
  primaryFontColor: rgb(45, 153, 255);
  primaryColor_8: rgba(31, 113, 255, 0.08);
  globalColor: #e60012;
  defaultFontColor: rgb(20, 35, 69);
  tableHeaderBg: #fafafa;
  tableHeaderHeight: 40px;
  tableAlign: center;
  fontSize_14: 14px;
  fontSize_16: 16px;
  fontSize_18: 18px;
  fontSize_24: 24px;
}

/* Mixins */
.page-el-scrollbar[data-v-6a0ac0a0] {
  height: calc(100vh - 90px);
  width: 100%;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
}
.page-el-scrollbar .app-main-container[data-v-6a0ac0a0] {
  min-height: calc(100vh - 52px - 64px - 16px - 100px);
  background: #ffffff;
  padding: 16px;
}
.page-el-scrollbar[data-v-6a0ac0a0] .main-container {
  overflow-x: hidden;
  overflow-y: auto;
}
.moreHeader .el-scrollbar[data-v-6a0ac0a0] {
  height: calc(100vh - 120px);
}
.moreHeader .app-main-container[data-v-6a0ac0a0] {
  min-height: calc(100vh - 200px) !important;
  width: calc(100vw - 48px);
}
.moreHeader .app-main-container.page-home-container[data-v-6a0ac0a0] {
  min-height: calc(100vh - 178px) !important;
  padding: 0;
  width: 100vw;
}
.go-back-container[data-v-6a0ac0a0] {
  position: fixed;
  width: 40px;
  height: 40px;
  background: rgba(31, 113, 255, 0.5);
  border-radius: 4px;
  right: 24px;
  bottom: 58px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 20px;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  cursor: pointer;
}
.go-back-container[data-v-6a0ac0a0]:hover {
  background: #1589cd;
}
.container-wrap[data-v-6a0ac0a0] {
  width: 100%;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
