<template>
  <div>
    <template v-for="navMenu in navMenus">
      <!-- 最后一级菜单 -->
      <el-menu-item
        v-if="!navMenu?.meta.showChildren && navMenu.meta && !navMenu?.meta.hidden"
        :class="navMenu.meta.class"
        :key="navMenu.path"
        :data="navMenu"
        :index="navMenu.path"
      >
        <!-- <svg-icon v-if="navMenu.meta.icon" :icon-class="navMenu.meta.icon" /> -->
        <span slot="title">{{ navMenu.meta.title }}</span>
      </el-menu-item>

      <!-- 此菜单下还有子菜单 -->
      <el-submenu
        v-if="navMenu.children && navMenu.meta && navMenu?.meta.showChildren"
        :class="navMenu.meta.class"
        :key="navMenu.path"
        :data="navMenu"
        :index="navMenu.path"
      >
        <template slot="title">
          <!-- <svg-icon v-if="navMenu.meta.icon" :icon-class="navMenu.meta.icon" /> -->
          <span>{{ navMenu.meta.title }}</span>
        </template>
        <!-- 递归 -->
        <MenuItem :navMenus="navMenu.children"></MenuItem>
      </el-submenu>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'MenuItem',
  props: {
    basePath: {
      type: String,
      default: '',
    },
    navMenus: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    ...mapGetters(['sidebar']),

    sidebar() {
      return this.sidebar;
    },
  },
};
</script>

<style lang="scss" scoped>
@import './MenuItem.scss';
</style>
