// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/authenticate/unTodo.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../assets/authenticate/approve.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../../assets/authenticate/success.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../../assets/authenticate/rollback.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Variables */
[data-v-4a7c6bf8]:export {
  menuText: #000;
  menuActiveText: #fff;
  subMenuActiveText: #f4f4f5;
  menuActiveBg: #1589cd;
  menuBg: #fff;
  menuHover: rgba(45, 153, 255, 0.3);
  subMenuBg: #fff;
  subMenuHover: rgba(45, 153, 255, 0.3);
  sideBarWidth: 200px;
  primaryColor: #1589cd;
  primaryFontColor: rgb(45, 153, 255);
  primaryColor_8: rgba(31, 113, 255, 0.08);
  globalColor: #e60012;
  defaultFontColor: rgb(20, 35, 69);
  tableHeaderBg: #fafafa;
  tableHeaderHeight: 40px;
  tableAlign: center;
  fontSize_14: 14px;
  fontSize_16: 16px;
  fontSize_18: 18px;
  fontSize_24: 24px;
}

/* Mixins */
.authenticate-card[data-v-4a7c6bf8] {
  margin-bottom: 24px;
  flex-shrink: 0;
}
.authenticate-card .el-card__body_bg[data-v-4a7c6bf8] {
  min-height: 184px;
}
.authenticate-card.only-alert-card .el-card__body_bg[data-v-4a7c6bf8] {
  min-height: 136px;
}
.authenticate-card.only-step-card[data-v-4a7c6bf8] {
  margin-bottom: 0;
}
.authenticate-card.only-step-card .el-card__body_bg[data-v-4a7c6bf8] {
  min-height: 112px;
}
.authenticate-card.only-step-card .steps-wrap[data-v-4a7c6bf8] {
  padding: 20px 0;
}
.authenticate-card.authenticate-common-container[data-v-4a7c6bf8] {
  margin-bottom: 0;
}
.authenticate-card.authenticate-common-container .el-card[data-v-4a7c6bf8] {
  min-height: calc(100vh - 178px - 48px - 48px - 28px) !important;
}
.authenticate-card .el-col[data-v-4a7c6bf8]:last-child {
  position: relative;
}
.authenticate-card .el-col:last-child img[data-v-4a7c6bf8] {
  position: absolute;
  top: -80px;
  right: -32px;
}
.authenticate-card .steps-wrap[data-v-4a7c6bf8] {
  padding: 20px 0 30px 0;
}
.authenticate-card .el-alert--info[data-v-4a7c6bf8] {
  background-color: #e8f1ff;
  color: rgb(20, 35, 69);
  border: 1px solid #1589cd;
  padding: 23px 16px 26px;
  align-items: flex-start;
  margin: 27px 0px 35px;
}
.authenticate-card .el-alert--error[data-v-4a7c6bf8] {
  border: 1px solid red;
  padding: 23px 16px 26px;
  align-items: flex-start;
  margin: 0;
}
.authenticate-card .person-card-box[data-v-4a7c6bf8] {
  margin-bottom: 16px;
}
.authenticate-card .person-card-box.todo[data-v-4a7c6bf8] {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) top right no-repeat, #fff;
  background-size: auto 184px;
}
.authenticate-card .person-card-box.approve[data-v-4a7c6bf8] {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) top right no-repeat, #fff;
  background-size: auto 184px;
}
.authenticate-card .person-card-box.success[data-v-4a7c6bf8] {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) top right no-repeat, #fff;
  background-size: auto 184px;
}
.authenticate-card .person-card-box.rollback[data-v-4a7c6bf8] {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) top right no-repeat, #fff;
  background-size: auto 184px;
}
.authenticate-card[data-v-4a7c6bf8] .el-icon-info {
  color: #1589cd;
}
.authenticate-card[data-v-4a7c6bf8] .el-alert__title {
  font-size: 18px;
  margin: 15px 0 10px;
  font-weight: 400;
}
.authenticate-card[data-v-4a7c6bf8] .el-alert__description {
  margin-top: 10px;
  color: rgb(20, 35, 69) !important;
  font-size: 14px;
}
.authenticate-card[data-v-4a7c6bf8] .el-icon-close {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.45) !important;
}
.ie-authenticate-card .el-col-24[data-v-4a7c6bf8] .custom-steps-item {
  flex: none;
}
.ie-authenticate-card .el-col-24[data-v-4a7c6bf8] .custom-steps-item:last-child {
  width: 110px;
  margin-right: 0px;
}
.ie-authenticate-card .el-col-24[data-v-4a7c6bf8] .custom-steps-item:last-child .custom-steps-item-title {
  padding-right: 0px;
}
.ie-authenticate-card .el-col-24[data-v-4a7c6bf8] .custom-steps-item-2 {
  width: calc(100vw - 110px - 112px);
}
.ie-authenticate-card .el-col-24[data-v-4a7c6bf8] .custom-steps-item-3 {
  width: calc((100vw - 110px - 112px) / 2);
}
.ie-authenticate-card .el-col-24[data-v-4a7c6bf8] .custom-steps-item-4 {
  width: calc((100vw - 110px - 132px) / 3);
}
.ie-authenticate-card .el-col-24[data-v-4a7c6bf8] .custom-steps-item-5 {
  width: calc((100vw - 110px - 142px) / 4);
}
.ie-authenticate-card .el-col-20[data-v-4a7c6bf8] .custom-steps-item {
  flex: none;
}
.ie-authenticate-card .el-col-20[data-v-4a7c6bf8] .custom-steps-item:last-child {
  width: 140px;
  margin-right: 0px;
}
.ie-authenticate-card .el-col-20[data-v-4a7c6bf8] .custom-steps-item:last-child .custom-steps-item-title {
  padding-right: 0px;
}
.ie-authenticate-card .el-col-20[data-v-4a7c6bf8] .custom-steps-item-2 {
  width: calc(100vw - 140px - 112px);
}
.ie-authenticate-card .el-col-20[data-v-4a7c6bf8] .custom-steps-item-3 {
  width: calc(((100vw - 140px) * 0.8333333 - 128px) / 2);
}
.ie-authenticate-card .el-col-20[data-v-4a7c6bf8] .custom-steps-item-4 {
  width: calc(((100vw - 140px) * 0.8333333 - 148px) / 3);
}
.ie-authenticate-card .el-col-20[data-v-4a7c6bf8] .custom-steps-item-5 {
  width: calc(((100vw - 140px) * 0.8333333 - 148px) / 4);
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
