import { selectByToken, updateUserHeader } from '@/api/ManagementCenter/personsetting';
import { adminIdType } from '@/utils/enums';

const defaultState = () => {
  return {
    personData: {
      userName: '',
      mobilePhone: '',
      idType: '',
      idNo: '',
      corpIdentityList: [],
      lastLoginDate: '',
      loginArea: '',
      headFileId: '',
      headTifId: '',
      id: '',
      officeAddr: '',
      roleName: '',
      orgName: '',
    },

    newFromData: {
      img: '',
      bannerImageId: '1485584490965651458',
      bannerImageTifId: '1485584491485745154',
    },
  };
};
export const state = defaultState();

const mutations = {
  PERSON_SETTING(state, payload) {
    const { corpUser, roleList, currCorp } = payload?.data;
    state.personData.corpIdentityList = [];
    state.personData.roleName = '';
    state.personData.userName = corpUser?.userName;
    state.personData.mobilePhone = corpUser?.mobilePhone;
    state.personData.idType = adminIdType.find((e) => e.value === corpUser?.idType)?.label;
    state.personData.officeAddr = currCorp.officeAddr;
    state.personData.orgName = currCorp.orgName;
    state.personData.idNo = corpUser?.idNo;
    state.personData.id = corpUser?.id;
    state.personData.loginArea = corpUser?.loginArea;
    state.personData.headFileId = corpUser?.headFileId;
    state.personData.headTifId = corpUser?.headTifId;
    roleList.forEach((item, index) => {
      if (roleList.length > 1 && index + 1 !== roleList.length) {
        state.personData.roleName += item.roleName + '、';
      } else {
        state.personData.roleName += item.roleName;
      }
    });
    currCorp.corpIdentityList.forEach((e) => {
      if (e === 'H') {
        state.personData.corpIdentityList.push({ name: '核心企业', color: '' });
      } else if (e === 'R') {
        state.personData.corpIdentityList.push({ name: '链属企业', color: '' });
      } else if (e === 'J') {
        state.personData.corpIdentityList.push({ name: '监管企业', color: '' });
      }
    });
    state.personData.lastLoginDate = corpUser?.lastLoginDate;
  },

  RESET_STATE: (state) => {
    Object.assign(state, defaultState());
  },
};

const actions = {
  async selectByToken({ commit }) {
    const res = await selectByToken();
    commit('PERSON_SETTING', res);
  },
  async updateUserHeader(_, payload) {
    return await updateUserHeader({ ...payload });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
