import request from '@/utils/request';

// 上传附件
export function fetchUpload(data, params) {
  return request({
    url: params.fetchUrl || '/base/param/file-management/app/upload-file',
    method: 'POST',
    data,
  });
}

// 查询文件元数据
export function fetchFilesMeta(params) {
  return request({
    url: '/thirdparty/file/file-attachment/inner/get-file-metas',
    method: 'get',
    params,
  });
}

// 查询文件元数据
export function fetchProfileFiles(data) {
  return request({
    url: '/thirdparty/profile/management/list',
    method: 'post',
    data,
  });
}

/**
 * @description 查询字典内容
 * @return {*}
 */
export function fetchDictItem(dictCode) {
  return request('/portal/param/param-dict-data/items', {
    method: 'POST',
    data: { dictCode: dictCode },
  });
}

// 查询多个字段编码的字典数据
export function fetchSysDictItems(dictCode) {
  return request({
    url: `/base/param/sys-dict/getItemsByCodes`,
    method: 'post',
    data: { dictCodes: dictCode },
  });
}

export function getOutline(data) {
  return request({
    url: `/agency/retrospective/outline`,
    method: 'post',
    data,
  });
}

export function getMainanddetail(data) {
  return request({
    url: `/agency/retrospective/mainanddetail`,
    method: 'post',
    data,
  });
}

export function getStorageCorp(data) {
  return request({
    url: `/base/admin/uas/auth-corp/getStorageCorp`,
    method: 'post',
    data,
  });
}

// 预览pdf
export function fetchShowPDF(params) {
  return request({
    url: '/thirdparty/file/file-attachment/show-pdf-byte',
    method: 'get',
    params,
  });
}
// 文件下载
export function fetchDownload(params) {
  return request({
    url: `/thirdparty/file/file-attachment/file-info`,
    method: 'get',
    params,
  });
}
/**
 * @description 查询平台基础参数
 * @param { 系统参数编码 paramCode string 必输 BUSS_DATE-业务日期  TELEPHONE-客服电话 }
 * @return {*}
 */
export const selectByParamCode = (data) => {
  return request('/param/systemParam/selectByParamCode', {
    method: 'GET',
    params: data,
  });
};

/**
 * @description 根據图片验证码
 * @return {*}
 */
export const getPicCaptcha = () => {
  return request('/comm/noAuth/getCapCode');
};

/**
 * @description 短信发送校验验证码
 * @return {*}
 */
export const sendMsgAuthCode = (data) => {
  return request('/thirdparty/noauth/send-sms', {
    method: 'POST',
    data,
  });
};

/**
 * @description 短信认证
 * @return {*}
 */
export const checkMobileMsg = (data) => {
  return request('/auth/corpUser/checkMobileMsg', {
    method: 'POST',
    data,
  });
};

/**
 * @description 获取图片滑块验证码
 * @return {*}
 */
export const getSlideCode = () => {
  return request('/comm/noAuth/getSlideCode');
};

/**
 * @description 查询所属行业列表
 * @return {*}
 */
export const getAllIndustryList = () => {
  return request('/param/indy/selectAll');
};

/**
 * @description 行业管理 列表树查询
 * @return {*}
 */
export const getAllIndustryTree = () => {
  return request('/param/indy/selectListTree', {
    method: 'POST',
  });
};

/**
 * @description 查询所属省份列表
 * @return {*}
 */
export const getAllProvinceList = () => {
  return request('/param/area/selectProvince');
};

/**
 * @description 根据省份查询市
 * @return {*}
 */
export const getCityListByPid = (params) => {
  return request('/param/area/selectByPid', {
    method: 'GET',
    params,
  });
};

/**
 * @description 查询银行网点列表
 * @return {*}
 */
export const getBankBranchList = (data) => {
  return request('/param/bankBranch/selectList', {
    method: 'POST',
    data,
  });
};

/**
 * @description 批量查询文件
 * @return {*}
 */
export const getCommFileByIds = (data) => {
  return request('/comm/file/selectScanFileList', {
    method: 'POST',
    data,
  });
};

/**
 * @description 查询公告
 * @return {*}
 */
export const getSystemNotice = (params) => {
  return request('/param/systemNotice/selectPublished', {
    method: 'GET',
    params,
  });
};

/**
 * @description 查询核心企业产品列表
 * @return {*}
 */
export const getCoreProductList = (data) => {
  return request('/param/finProduct/selectListForCore', {
    method: 'POST',
    data,
  });
};

/**
 * @description 查询链属企业产品列表
 * @return {*}
 */
export const getChainProductList = (data) => {
  return request('/general/custContractBaseApply/selectListForChain', {
    method: 'POST',
    data,
  });
};

/**
 * @description 根据产品获取菜单
 * @return {*}
 */
export const getMenuTree = (data) => {
  return request('/auth/resource/selectMenuTreeByProductCode', {
    method: 'POST',
    data,
  });
};

/**
 * @description 附件列表快照查询
 * @return {*}
 */
export async function fetchFileListByTxNo(data) {
  return request('/comm/attachFileApply/selectByTxNo', {
    method: 'POST',
    data,
  });
}

export async function selectListByBussId(data) {
  return request('/comm/attachFile/selectListByBussId', {
    method: 'POST',
    data,
  });
}

/**
 * @description 附件列表查询
 * @return {*}
 */
export async function fetchFileList(data) {
  return request('/comm/attachFile/selectList', {
    method: 'POST',
    data,
  });
}

/**
 *
 * @returns 查询银行网点列表
 */
export const bankBranchSelectPage = (data) => {
  return request('/param/bankBranch/selectPage', {
    method: 'POST',
    data,
  });
};

/**
 * @description 产品列表(已生效)查询
 * @params {生效标识 effectStatus String 非必输 生效状态标识，默认查询已生效，N/已失效}
 * @return {*}
 */
export async function getProductList(data) {
  return request('/general/productBase/selectProductList', {
    method: 'POST',
    data,
  });
}

/**
 * @description 查询数据字典下拉列表
 * @params {
 * dictionaryCodeList: 字典编码列表 [String]
 * 字符串数组:
 * K0001-附件类型,客户信息附件类型
 * K0002-附件类型,下拉框为"其它"
 * K0003-担任职务
 * K0004-监管方式
 * K0005-信贷业务品种
 * K0006-还款方式
 * K0007-出账方式
 * K0008-公司类型
 * K0009-应收类-计算依据
 * K0010-预付类-计算依据
 * K0011-存贷类-计算依据
 * }
 * @return {*}
 */
export const getSelectByDictionaryCodeList = (data) => {
  return request('/param/dictionaryData/selectByDictionaryCodeList', {
    method: 'POST',
    data,
  });
};

/**
 * @description 协议列表查询
 * @return {*}
 */
export async function fetchProtocol(data) {
  return request('/general/contract/selectContract', {
    method: 'POST',
    data,
  });
}

export const fetchGoodsList = (data) => {
  return request('/thirdparty/t3/client/oq100008', {
    method: 'POST',
    data,
  });
};

/**
 *
 * @returns 穿透列表
 */
export const oq100015 = (data) => {
  return request('/thirdparty/t3/client/oq100015', {
    method: 'POST',
    data,
  });
};

/**
 *
 * @returns 穿透详情
 */
export const oq100033 = (data) => {
  return request('/thirdparty/t3/client/oq100033', {
    method: 'POST',
    data,
  });
};

/**
 *
 * @returns 聚销-协议tab列表查询
 * @params {txNo: ''}
 */
export const selectContractAppyListByTxNo = (data) => {
  return request('/general/contractApply/listByTxNo', {
    method: 'POST',
    data,
  });
};

/**
 * @description 查询警示信息
 * @return {*}
 */
export const selectRiskInfo = (data) => {
  return request('/thirdparty/risk/riskInfoList', {
    method: 'POST',
    data,
  });
};

/**
 * @description 通用文件下载
 * @return {*}
 */
export const downloadFile = (data) => {
  return request('/thirdparty/file/file-attachment/file-info', {
    method: 'GET',
    params: data,
    // responseType: 'blob'
  });
};

/**
 * @description 获取当前业务时间（时间戳）
 * @return {*}
 */
export const getTimeMillis = () => {
  return request('/portal/oauth/web/timeMillis', {
    method: 'POST',
  });
};

//  查询文件管理列表
export const fileManagementList = (data) => {
  return request('/thirdparty/profile/management/list', {
    method: 'POST',
    data,
  });
};

// 上传图片
export function uploadImage(data) {
  return request({
    url: `/api/thirdparty/file/file-attachment/upload-file`,
    method: 'post',
    data,
  });
}

//
export function getProvinceList(data) {
  return request({
    url: `/base/param/param-area/select/province`,
    method: 'post',
    data,
  });
}

// 获取根据省份id查询市区
export function getCityList(data) {
  return request({
    url: `/base/param/param-area/select/by-pid`,
    method: 'post',
    data,
  });
}

// 上链状态查询
export function getQrcodeUrl(data) {
  return request({
    url: `/mychain/chain/getQrcodeUrl`,
    method: 'post',
    data,
  });
}
