// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.pagination-container[data-v-72233bcd] {
  background: #fff;
  padding: 32px 16px;
}
.pagination-container.hidden[data-v-72233bcd] {
  display: none;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
