// 库存管理及入库管理 权限路由
const inboundManagementRoutes = [
  // 库存管理
  {
    path: '/warehouseManagement/historyInventory',
    name: 'historyInventory',
    component: (resolve) => require(['@/views/Storage/InventoryManagement/storageMange/historyInventory/index'], resolve),
    meta: {
      title: '库存历史管理',
      layout: 'ProductLayout',
      hidden: true,
      activeMenu: '/inventoryManagement',
      parentMenu: '/inventoryManagement/inventory',
      authority: ['NO-AUTHORITY'],
    },
  },
  // 入库管理 新增/编辑
  {
    path: '/inboundPlan/AddOrEdit',
    name: 'AddOrEdit',
    component: (resolve) => require(['@/views/Storage/InboundManagement/inboundPlan/AddOrEdit'], resolve),
    meta: {
      title: '新增入库计划',
      layout: 'ProductLayout',
      hidden: true,
      activeMenu: '/inboundManagement',
      parentMenu: '/inboundManagement/inboundPlan',
      authority: ['NO-AUTHORITY'],
    },
  },
  // 出库管理 新增/编辑
  {
    path: '/outboundPlan/AddOrEdit',
    name: 'AddOrEdit',
    component: (resolve) => require(['@/views/Storage/OutboundManagement/outboundPlan/AddOrEdit'], resolve),
    meta: {
      title: '新增出库计划',
      layout: 'ProductLayout',
      hidden: true,
      activeMenu: '/outboundManagement',
      parentMenu: '/outboundManagement/outboundPlan',
      authority: ['NO-AUTHORITY'],
    },
  },
  // 入库清单 新增/编辑/复制
  {
    path: "/inboundList/iboundItem",
    component: (resolve) =>require(["@/views/Storage/InboundManagement/inboundList/iboundItem",], resolve),
    meta: { 
      title: '入库清单新增/编辑',
      layout: 'ProductLayout',
      hidden: true,
      activeMenu: '/inboundManagement',
      parentMenu: '/inboundManagement/inboundList',
      authority: ['NO-AUTHORITY'],
    },
  },
    // 出库管理 新增/编辑
    {
      path: '/outboundOrder/addboundList',
      name: 'AddOrEdit',
      component: (resolve) => require(['@/views/Storage/OutboundManagement/outboundOrder/addboundList'], resolve),
      meta: {
        title: '新增出库单',
        layout: 'ProductLayout',
        hidden: true,
        activeMenu: '/outboundManagement',
        parentMenu: '/outboundManagement/outboundOrder',
        authority: ['NO-AUTHORITY'],
      },
    },
    {
      path: "/inboundOrder/addListItem",
      component: (resolve) =>require(["@/views/Storage/InboundManagement/inboundOrder/addInboundList",], resolve),
      meta: { 
        title: '入库清单新增',
        layout: 'ProductLayout',
        hidden: true,
        activeMenu: '/inboundManagement',
        parentMenu: '/inboundManagement/inboundOrder',
        authority: ['NO-AUTHORITY'],
      },
    },
    {
      path: "/relocationManagement/addList",
      component: (resolve) =>require(["@/views/Storage/InventoryManagement/relocationManagement/addList",], resolve),
      meta: { 
        title: '新增移库',
        layout: 'ProductLayout',
        hidden: true,
        activeMenu: '/inventoryManagement',
        parentMenu: '/inventoryManagement/relocationManagement',
        authority: ['NO-AUTHORITY'],
      },
    },
];

export default inboundManagementRoutes;
