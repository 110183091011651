var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.isLoading,
          expression: "isLoading",
        },
      ],
      ref: "scrollMenuRef",
      staticClass: "page-el-scrollbar",
    },
    [
      _c(
        "section",
        {
          ref: "appMainRef",
          class: [
            "app-main-container",
            { "page-home-container": _vm.isPageHome },
          ],
        },
        [
          _c(
            "div",
            { staticClass: "container-wrap" },
            [
              _c(
                "transition",
                {
                  attrs: { name: "fade-transform", mode: "out-in" },
                  on: {
                    "before-leave": _vm.beforeLeave,
                    "before-enter": _vm.beforeEnter,
                    "after-enter": _vm.afterEnter,
                  },
                },
                [_c("router-view", { key: _vm.key })],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("Footer"),
      _vm._v(" "),
      _c(
        "el-backtop",
        {
          staticClass: "go-back-container",
          attrs: {
            target: ".page-el-scrollbar",
            bottom: 58,
            right: 24,
            "visibility-height": 30,
          },
        },
        [_c("svg-icon", { attrs: { "icon-class": "goBack" } })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }