<template>
  <div :class="{ 'horizontal-container': true, 'first-level': isFirstLevel, 'right-menu-item-homepage': !notHomePage }">
    <template v-for="navMenu in navMenus">
      <!-- 最后一级菜单 -->
      <el-menu-item
        v-if="!navMenu.children && navMenu.meta"
        :class="showPopClass ? navMenu.meta.popClass : navMenu.meta.class"
        :key="navMenu.name"
        :data="navMenu"
        :index="navMenu.path"
      >
        <svg-icon v-if="navMenu.meta.icon" :icon-class="navMenu.meta.icon" />
        <span slot="title" class="header-menu-item-txt">{{ navMenu.meta.title }}</span>
      </el-menu-item>

      <!-- 此菜单下还有子菜单 -->
      <el-submenu
        v-if="navMenu.children && navMenu.meta"
        :popper-append-to-body="false"
        :class="showPopClass ? navMenu.meta.popClass : navMenu.meta.class"
        :key="navMenu.name"
        :data="navMenu"
        :index="navMenu.path"
      >
        <template slot="title">
          <svg-icon v-if="navMenu.meta.icon" :icon-class="navMenu.meta.icon" />
          <span>{{ navMenu.meta.title }}</span>
        </template>
        <!-- 递归 -->
        <MenuItem :navMenus="navMenu.children" :showPopClass="showPopClass"></MenuItem>
      </el-submenu>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import settings from '@/defaultSettings';
import { getToken } from '@/utils/auth';

const whiteList = settings.routeWhiteList;

export default {
  name: 'MenuItem',
  props: {
    isFirstLevel: {
      type: Boolean,
      default: true,
    },
    basePath: {
      type: String,
      default: '',
    },
    showPopClass: {
      type: Boolean,
      default: false,
    },
    navMenus: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    ...mapGetters(['sidebar']),
    ...mapState('user', ['currentUser']),

    sidebar() {
      return this.sidebar;
    },

    notHomePage() {
      return this.$router.currentRoute?.meta?.routeType !== 'HomeRoute';
    },
  },
  methods: {
    menuDisabled(menu) {
      const { currCorp, corpUser } = this.currentUser || {};
      const { approvalStatus } = currCorp || {};
      const { identityFlag, pwdForceChange, isAdmin } = corpUser || {};
      if (pwdForceChange === 'Y') {
        return !whiteList.includes(menu.path);
      }

      if (getToken()) {
        whiteList.push('/managementCenter/personAuthenticate');
      }

      return !(
        (approvalStatus === 150 && isAdmin === 'Y') ||
        (isAdmin !== 'Y' && identityFlag === 'Y') ||
        whiteList.includes(menu.path)
      );
    },
    subMenuDisabled(menu) {
      const menuChildren = menu.children;
      const res = menuChildren.some((m) => !this.menuDisabled(m));
      return !res;
    },
  },
};
</script>

<style lang="scss" scoped>
@import './MenuItem.scss';
</style>
