<template>
  <el-select
    ref="select"
    style="width: 100%"
    v-model="formData[item.prop]"
    :placeholder="item.placeholder"
    :class="item.class"
    :style="item.value"
    :allow-create="item.allowCreate"
    :filterable="item.filterable"
    :clearable="item.clearable"
    :disabled="item.disabled"
    :multiple="item.multiple"
    :multiple-limit="item.multipleLimit"
    :remote-method="item.remoteMethod || emptyFn"
    :remote="item.remote"
    :loading="item.loading"
    @remove-tag="removeTag"
    size="medium"
    @change="change"
    @focus="focus"
  >
    <template v-for="option in options">
      <el-option
        :title="
          option[item.dictLabel || 'label']
            ? option[item.dictLabel || 'label']
            : option
        "
        :key="
          option[item.dictValue || 'value']
            ? option[item.dictValue || 'value']
            : option
        "
        :label="
          option[item.dictLabel || 'label']
            ? option[item.dictLabel || 'label']
            : option
        "
        :value="
          option[item.dictValue || 'value']
            ? option[item.dictValue || 'value']
            : option
        "
        :disabled="option.disabled"
        :style="
          item.maxWidth
            ? 'max-width: calc(33.33vw - 140px)'
            : item.maxWidthCustom || ''
        "
        size="medium"
      >
      </el-option>
    </template>
  </el-select>
</template>

<script type="text/ecmascript-6">
import mixins from "../mixins";

export default {
  name: "mElSelect",
  mixins: [mixins],
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {
    options() {
      if (this.item.options instanceof Array) {
        return this.item.options;
      } else {
        let list = this.item.options?.split(",");
        if (list?.length > 0) {
          return list.map((item) => {
            return { value: item, label: item };
          });
        }
        return [];
      }
    },
  },
  methods: {
    removeTag(value) {
      this.selectEvent({
        type: "removeTag",
        prop: this.item.prop,
        value: this.formData[this.item.prop],
        tags: value,
      });
    },
    change() {
      this.selectEvent({
        type: "change",
        prop: this.item.prop,
        value: this.formData[this.item.prop],
      });
    },
    focus() {
      this.selectEvent({
        type: "focus",
        prop: this.item.prop,
        value: this.formData[this.item.prop],
      });
    },
    blur(e) {
      this.selectEvent({
        type: "visible-change",
        prop: this.item.prop,
        status: e,
        value: this.formData[this.item.prop],
      });
    },
    emptyFn() {},
  },
  activated() {},
  mounted() {},
  created() {},
};
</script>

<style scoped lang="scss">
::v-deep {
  .el-select__tags-text {
    font-size: $fontSize_14;
  }
}
</style>
