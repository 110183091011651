<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="60%"
      top="10vh"
      custom-class="dialogSty"
      :before-close="cancel"
      :append-to-body="true"
      :destroy-on-close="true"
    >
      <div class="content" v-loading="loading">
        <UploadFileList :formData="formSupport" :item="uploadSupport" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { fetchFilesMeta, fetchProfileFiles } from '@/api/common';
import UploadFileList from '@/components/Upload/fileList.vue';
import { getPdfOrImageConfig } from '@/utils/utils';

export default {
  components: {
    UploadFileList,
  },
  props: {
    dialogShow: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    commentBusinessId: {
      type: String,
      default: () => {
        return '';
      },
    },
    title: {
      type: String,
      default: () => {
        return '附件';
      },
    },
  },
  data() {
    return {
      dialogVisible: false,
      loading: false,
      formSupport: {
        fileId: [],
      },
      uploadSupport: {
        isPreview: true,
        prop: 'fileId',
        limit: 10,
        fileType: [
          {
            label: '图片',
            value: 'image',
            fileSize: 10,
            buttonTips: '请上传JPG、JPEG、PNG格式，大小10M以内',
            supportFileFormat: ['.jpg', '.jpeg', '.png'],
          },
          {
            label: '视频',
            value: 'video',
            fileSize: 50,
            supportFileFormat: ['.mov', '.mp4', '.avi'],
            buttonTips: '请上传MOV、MP4、AVI格式，大小50M以内',
          },
          {
            label: '文件',
            value: 'file',
            fileSize: 50,
            supportFileFormat: ['.doc', '.docx', '.pdf'],
            buttonTips: '请上传DOC、DOCX、PDF格式，大小10M以内',
          },
        ],
        rules: { fileId: [{ required: false, message: '请上传附件' }] },
        fileList: [],
      },
    };
  },
  watch: {
    dialogShow: {
      handler: function (newVal) {
        if (newVal) {
          this.dialogVisible = newVal;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    console.log(this.commentBusinessId, '...............');
    fetchProfileFiles({ businessTypeCode: 'workflow', businessId: this.commentBusinessId, tag: '1' }).then((result) => {
      if (result.rspCode === '00000000' && result.data?.length > 0 && result.data[0].fileTypeRspDto?.length > 0) {
        this.uploadSupport.fileList = result.data[0].fileTypeRspDto[0].fileAttachmentRspDtoList?.map((item) => {
          return {
            fileSuffix: item.fileSuffix,
            fileId: item.fileId,
            name: item.originName,
            url: getPdfOrImageConfig(item.fileId),
          };
        });
      }
    });
    // this.commentBusinessId = this.commentBusinessId || '';
    // if (this.invoiceUrl?.length > 0) {
    //   this.queryFileInfo(this.invoiceUrl[this.active - 1]).then((result) => {
    //     this.$set(this.fileList, this.active - 1, result[0]);
    //   });
    // }
  },
  methods: {
    // 查询附件详情
    queryFileInfo(fileIds) {
      return new Promise((resolve, reject) => {
        if (fileIds) {
          this.loading = true;
          fetchFilesMeta({ fileIds })
            .then((res) => {
              if (res?.data?.length > 0) {
                console.log(res, 'resresres');
                const arr = res.data.map((item) => {
                  const { fileSuffix } = item;
                  // if (fileSuffix === "pdf") {
                  const fileUrl = item.url;
                  item.url = fileUrl;
                  // } else {
                  //   const imageUrl = base64ToUrl(
                  //     item.fileBase64Content,
                  //     fileSuffix
                  //   );
                  //   item.url = imageUrl;
                  // }
                  return {
                    fileSuffix,
                    url: item.url,
                    name: item.fileName,
                  };
                });
                resolve(arr);
              } else {
                reject('暂无附件信息！');
              }
            })
            .catch(() => {
              reject('暂无附件信息！');
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          reject('暂无附件信息！');
        }
      });
    },

    privewImgFn(item) {
      this.imgPath = item;
      this.$nextTick(() => {
        this.$refs.previewImage.clickHandler();
      });
    },

    changeCarousel(index) {
      this.active = index + 1;
      this.$nextTick(() => {
        if (!this.fileList[this.active - 1]) {
          this.queryFileInfo(this.invoiceUrl[this.active - 1]).then((result) => {
            this.$set(this.fileList, this.active - 1, result[0]);
          });
        }
      });
    },

    cancel() {
      this.$listeners.cancel();
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .el-dialog .el-dialog__body {
  height: 72vh !important;
  overflow: hidden;
  display: flex;
}
.content {
  flex: 1;
  overflow-y: auto;
}
</style>
