import {
  corpUserSaveBaseInfo,
  corpUserCheckAuthMethod,
  corpSaveBaseInfo,
  corpSupplementAdminInfo,
  corpPubAcctSave,
  corpCheckAuthMethod,
  corpAuthenticate,
  corpSelectByOrgId,
  corpSelectByTxNo,
  fetchFaceIdentity,
  faceIdentityWithoutCreateAccount,
  queryIdentityResult,
  queryByNameOrCreditNo,
  checkUKeyCert,
  telecom3Factors,
  telecom3FactorsWithoutCreateAccount,
  sendAuthCode,
  bankCard4Factors,
  bankCard4FactorsWithoutCreateAccount,
  queryIdentityResultByIdNo,
} from '@/api/ManagementCenter/authenticate';
import {
  sendMsgAuthCode,
  getAllIndustryList,
  getAllIndustryTree,
  getAllProvinceList,
  getCityListByPid,
  checkMobileMsg,
  getBankBranchList,
  getCommFileByIds,
} from '@/api/common';
import { getPdfOrImageConfig } from '@/utils/utils';
import { Message } from 'element-ui';

const defaultState = () => {
  return {
    msgKey: '',
    personTxNo: '',
    allIndustryList: [],
    allProvinceList: [],
    cityListByPid: [],
    branchList: [],
    corpDetail: {},
    corpByTxNoDetail: {},
    corpTxNo: '',
    commFileList: [],
    nameOrCreditNoDetail: {},
    dkFlowId: '',
    allIndustryTree: [],
    flowObj: {},
  };
};
export const state = defaultState();

const actions = {
  // 个人实名认证基本信息提交
  async corpUserSaveBaseInfo({ commit }, payload) {
    const response = await corpUserSaveBaseInfo(payload);
    if (response.rspCode === '00000000') {
      commit('SET_PERSONTXNO', response?.data);
      return true;
    }
  },
  // 个人实名认证选择认证方式
  async corpUserCheckAuthMethod(_, payload) {
    const response = await corpUserCheckAuthMethod(payload);
    if (response.rspCode === '00000000') {
      return true;
    }
  },
  // 个人实名人脸识别
  async fetchFaceIdentity(_, payload) {
    const { data } = (await fetchFaceIdentity(payload)) || {};
    if (data) return data;
  },
  // 个人实名人脸识别结果查询
  async queryIdentityResult(_, payload) {
    const { data } = (await queryIdentityResult(payload)) || {};
    if (data) return data;
  },
  // 短信发送校验验证码
  async sendMsgAuthCode({ commit }, param) {
    const res = await sendMsgAuthCode(param);
    if (res?.data?.msgKey) {
      Message.success('短信发送成功');
      commit('SET_VERSIONCODE', res?.data);
    } else {
      commit('SET_VERSIONCODE', {});
    }
    return res;
  },
  // 清空短信验证码
  async clearMsgAuthCode({ commit }) {
    commit('SET_VERSIONCODE', {});
  },
  // 短信认证
  async checkMobileMsg(_, param) {
    const response = await checkMobileMsg(param);
    if (response.rspCode === '00000000') {
      return true;
    }
  },

  // ukey验证
  async checkUKeyCert(_, param) {
    const response = await checkUKeyCert(param);
    if (response.rspCode === '00000000') {
      !response?.data && Message.success('认证失败');
      return response?.data;
    }
  },

  // 运营商3要素实名认证
  async telecom3Factors({ commit }, param) {
    const response = await telecom3Factors(param);
    if (response.rspCode === '00000000') {
      commit('SET_FLOWOBJ', response?.data);
      return true;
    }
  },
  // 运营商3要素核身认证
  async telecom3FactorsWithoutCreateAccount({ commit }, param) {
    const response = await telecom3FactorsWithoutCreateAccount(param);
    if (response.rspCode === '00000000') {
      commit('SET_FLOWOBJ', response?.data);
      return true;
    }
  },
  // 发送短信验证码
  async sendAuthCode(_, param) {
    const response = await sendAuthCode(param);
    if (response.rspCode === '00000000') {
      return true;
    }
  },
  // 银行卡四要素实名认证
  async bankCard4Factors({ commit }, param) {
    const response = await bankCard4Factors(param);
    if (response.rspCode === '00000000') {
      commit('SET_FLOWOBJ', response?.data);
      return true;
    }
  },
  // 银行卡四要素实名认证
  async bankCard4FactorsWithoutCreateAccount({ commit }, param) {
    const response = await bankCard4FactorsWithoutCreateAccount(param);
    if (response.rspCode === '00000000') {
      commit('SET_FLOWOBJ', response?.data);
      return true;
    }
  },
  // 查询核身认证结果
  async queryIdentityResultByIdNo(_, param) {
    const response = await queryIdentityResultByIdNo(param);
    if (response.rspCode === '00000000') {
      return response?.data;
    }
  },

  // 企业实名认证详情查询
  async corpSelectByOrgId({ commit }, payload) {
    const { data } = (await corpSelectByOrgId(payload)) || {};
    commit('SET_CORPDETAIL', data);
  },
  // 企业详情快照查询
  async corpSelectByTxNo({ commit }, payload) {
    const { data } = (await corpSelectByTxNo(payload)) || {};
    commit('SET_CORPBYTXNODETAIL', data);
  },
  // 法人实名人脸识别
  async faceIdentityWithoutCreateAccount(_, payload) {
    const { data } = (await faceIdentityWithoutCreateAccount(payload)) || {};
    if (data) return data;
  },
  // 企业实名认证信息提交
  async corpSaveBaseInfo({ commit }, payload) {
    const response = await corpSaveBaseInfo(payload);
    if (response.rspCode === '00000000') {
      commit('SET_CORPTXNO', response?.data);
      return true;
    }
  },
  // 企业实名认证管理员信息提交
  async corpSupplementAdminInfo(_, payload) {
    const response = await corpSupplementAdminInfo(payload);
    if (response.rspCode === '00000000') {
      return true;
    }
  },
  // 企业实名认证对公账户信息提交
  async corpPubAcctSave({ commit }, payload) {
    const response = await corpPubAcctSave(payload);
    if (response.rspCode === '00000000') {
      commit('SET_DKFLOWID', response?.data?.dkFlowId);
      return true;
    }
  },
  // 企业实名认证选择认证方式
  async corpCheckAuthMethod(_, payload) {
    const response = await corpCheckAuthMethod(payload);
    if (response.rspCode === '00000000') {
      return true;
    }
  },
  // 企业实名认证流程提交
  async corpAuthenticate(_, payload) {
    const response = await corpAuthenticate(payload);
    if (response.rspCode === '00000000') {
      return response?.data;
    }
  },
  // 查询所属行业列表
  async getAllIndustryList({ commit }) {
    const res = await getAllIndustryList();
    commit('SET_ALLINDUSTRYLIST', res);
  },
  // 查询所属行业列表
  async getAllIndustryTree({ commit }) {
    const res = await getAllIndustryTree();
    commit('SET_ALLINDUSTRYTREE', res);
  },
  // 查询所属省份列表
  async getAllProvinceList({ commit }) {
    const res = await getAllProvinceList();
    commit('SET_ALLPROVINCELIST', res);
  },
  // 根据省份查询市
  async getCityListByPid({ commit }, payload) {
    const res = await getCityListByPid(payload);
    commit('SET_CITYLISTBYPID', res);
  },
  // 查询银行网点列表
  async getBankBranchList({ commit }, payload) {
    const res = await getBankBranchList(payload);
    commit('SET_BranchList', { ...res, ...payload });
  },
  // 批量查询文件
  async getCommFileByIds({ commit }, payload) {
    const { data } = (await getCommFileByIds(payload)) || {};
    commit('SET_COMMFILELIST', data);
  },
  // 更新企业工商数据
  async queryByNameOrCreditNo({ commit }, payload) {
    const { data } = (await queryByNameOrCreditNo(payload)) || {};
    commit('SET_NAMEORCREDITNODETAIL', data);
  },
};

const filterTreeData = (list) => {
  return list.map((item) => {
    const menu = {};
    menu.value = item.id;
    menu.label = item.indyName;
    if (item?.children?.length > 0) {
      menu.children = filterTreeData(item.children);
    }
    return menu;
  });
};

const mutations = {
  // 个人实名认证申请编号
  SET_PERSONTXNO(state, payload) {
    state.personTxNo = payload;
  },
  // 短信验证码
  SET_VERSIONCODE(state, payload) {
    state.msgKey = payload?.msgKey;
  },
  // 行业列表
  SET_ALLINDUSTRYLIST(state, payload) {
    state.allIndustryList = payload?.data?.map((item) => {
      return { label: item.indyName, value: item.id };
    });
  },
  // 行业列表
  SET_ALLINDUSTRYTREE(state, payload) {
    state.allIndustryTree = filterTreeData(payload?.data || []);
  },
  // 所属省份列表
  SET_ALLPROVINCELIST(state, payload) {
    state.allProvinceList = payload?.data?.map((item) => {
      return { label: item.areaName, value: item.id };
    });
  },
  // 省份查询市
  SET_CITYLISTBYPID(state, payload) {
    state.cityListByPid = payload?.data?.map((item) => {
      return { label: item.areaName, value: item.id };
    });
  },
  // 查询银行网点列表
  SET_BranchList(state, payload) {
    state.branchList =
      payload?.data?.slice(0, payload?.size || 20).map((item) => {
        return { label: item.bankName, value: item.id, bankBranchNo: item.bankNo };
      }) || [];
  },
  // 企业实名认证详情查询
  SET_CORPDETAIL(state, payload) {
    state.corpDetail = payload;
  },
  // 企业详情查询
  SET_CORPBYTXNODETAIL(state, payload) {
    state.corpByTxNoDetail = payload;
  },
  // 企业实名认证详情查询
  SET_DKFLOWID(state, payload) {
    state.dkFlowId = payload;
  },
  // 企业txNo
  SET_CORPTXNO(state, payload) {
    state.corpTxNo = payload?.txNo;
  },
  // 批量查询文件
  SET_COMMFILELIST(state, payload) {
    state.commFileList = payload?.map((item) => {
      return { name: item.fileName, id: item.fileId, ...getPdfOrImageConfig(item) };
    });
  },
  // 更新企业工商数据
  SET_NAMEORCREDITNODETAIL(state, payload) {
    state.nameOrCreditNoDetail = payload;
  },
  // 设置flowId
  SET_FLOWOBJ(state, payload) {
    state.flowObj = payload;
  },

  RESET_STATE: (state) => {
    Object.assign(state, defaultState());
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
