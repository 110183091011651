import request from '@/utils/request';

/**
 * @description 登录
 * @return {*}
 */
export const login = (data) => {
  return request('/portal/oauth/web/login', {
    method: 'POST',
    data,
  });
};

/**
 * @description 登录
 * @return {*}
 */
export const refreshToken = (data) => {
  return request('/portal/oauth/web/refresh', {
    method: 'POST',
    data,
  });
};

/**
 * @description 根據token獲取用戶信息
 * @return {*}
 */
export const getUserInfoByToken = () => {
  return request('/auth/corpUser/selectByToken');
};

/**
 * @description 登出
 * @return {*}
 */
export const logout = () => {
  return request('/portal/auth/user/logout', {
    method: 'POST',
  });
};

/**
 * @description 切换企业及企业身份
 * @param {
 * 企业机构id orgId number 必输
 * 企业身份 corpIdentity string 必输 [H-核心企业/R-融资企业/J-监管企业]
 * }
 * @return {token}
 */
export const switchOrgAndIdentity = (data) => {
  return request('/auth/corpUser/switchOrgAndIdentity', {
    method: 'POST',
    data,
  });
};

/**
 * @description 忘记密码-提交
 * @return {token}
 */
export const resetPassword = (data) => {
  return request('/portal/oauth/web/resetPassword', {
    method: 'POST',
    data,
  });
};

/**
 * @description 是否管理员同法人
 */
export const fetchSameLegal = (data) => {
  return request('/portal/oauth/web/sameLegal', {
    method: 'POST',
    data,
  });
};

/**
 * @description 修改初始密码-提交
 * @return {token}
 */
export const updatePasswordForNewUser = (data) => {
  return request('/portal/auth/user/first-upd-pwd', {
    method: 'POST',
    data,
  });
};

// 企业发送注册提交
export function fetchEnterpriseRegisterCheck(data) {
  return request('/portal/auth/corp/web/register', {
    method: 'post',
    data,
  });
}
/**
 * @description 企业注册
 */
export const fetchEnterpriseRegister = (data) => {
  return request('/portal/auth/corp/web/register/result', {
    method: 'POST',
    data,
  });
};

// 个人发送注册提交
export function fetchPersonRegisterCheck(data) {
  return request('/portal/oauth/web/face/register/check', {
    method: 'post',
    data,
  });
}
/**
 * @description 个人注册
 */
export const fetchPersonRegister = (data) => {
  return request('/portal/oauth/web/face/register', {
    method: 'POST',
    data,
  });
};

/**
 * @description 人脸认证结果查询新
 */
export const queryIdentity = (data) => {
  return request('/thirdparty/fasc/auth/query-identity', {
    method: 'POST',
    data,
  });
};
/**
 * @description 人脸认证结果查询新
 */
export const queryCorpAuthResult = (data) => {
  return request('/thirdparty/fasc/auth/queryCorpAuthResult', {
    method: 'POST',
    data,
  });
};

/**
 * @description 更新人脸认证状态
 */
export const addAuthResult = (data) => {
  return request('/thirdparty/fasc/auth/addAuthResult', {
    method: 'POST',
    data,
  });
};

/**
 * @description 更新人脸认证状态
 */
export const addCorpAuthResult = (data) => {
  return request('/thirdparty/fasc/auth/addCorpAuthResult', {
    method: 'POST',
    data,
  });
};

/**
 * @description 用户登录之后系统-用户信息
 */
export const getLoginInfo = (data) => {
  return request('/portal/auth/user/system-info', {
    method: 'POST',
    data,
  });
};

/**
 * @description 面客端首页查询企业及登录人信息
 */
export const getAdminInfo = (data) => {
  return request('/portal/corp/info/index', {
    method: 'POST',
    data,
  });
};

/**
 * @description 获取图形验证码
 */
export const getCaptcha = (data) => {
  return request('/portal/oauth/web/captcha/get', {
    method: 'POST',
    data,
  });
};

/**
 * @description 获取平台服务协议
 */
export const getAgreementFile = (data) => {
  return request('/portal/oauth/web/agreement', {
    method: 'POST',
    data,
  });
};

/**
 * @description ukey认证注册
 */
export const submitUkeyRegister = (data) => {
  return request('/portal/oauth/web/ukey/register', {
    method: 'POST',
    data,
  });
};

/**
 * @description 资源列表
 */
export const fetchResourceList = (data) => {
  return request('/portal/uas/auth-app-resource/list', {
    method: 'POST',
    data,
    interceptor: {
      appList: true,
    },
  });
};
