var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page" },
    [
      _vm.pagination.total > 0
        ? _c("el-pagination", {
            staticStyle: { "text-align": "right", "font-size": "14px" },
            attrs: {
              background: "",
              layout: _vm.layout,
              "page-size": _vm.pagination.pageSize,
              "current-page": _vm.currentPage,
              "page-sizes": _vm.pageSizeList,
              total: _vm.pagination.total,
            },
            on: {
              "current-change": _vm.handleCurrentChange,
              "size-change": _vm.handleSizeChange,
              "update:currentPage": function ($event) {
                _vm.currentPage = $event
              },
              "update:current-page": function ($event) {
                _vm.currentPage = $event
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }