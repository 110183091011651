<template>
  <el-upload
    ref="uploadFile"
    :class="
      item.multiple
        ? ''
        : ['upload-content', item.class || '', noneImg && 'disUoloadSty']
    "
    action="#"
    :headers="item.headers"
    :multiple="item.multiple"
    :data="item.data"
    :name="item.name"
    :drag="item.drag"
    :accept="item.accept"
    :auto-upload="!item.notAutoUpload"
    :disabled="item.disabled"
    :limit="item.limit || 5"
    :list-type="item.listType"
    :file-list="
      (fileList && fileList.length > 0) || showFileList
        ? fileList
        : item.fileList
    "
    :show-file-list="item.showFileList"
    :on-preview="
      !item.previewDisabled ? handlePictureCardPreview : defaultFunction
    "
    :on-remove="remove"
    :on-change="handleChange"
    :on-exceed="handleExceed"
    :http-request="
      (file, fileList) => {
        return uploadSectionFile(file, fileList);
      }
    "
  >
    <template v-if="item.showButton">
      <el-button
        :size="item.size || 'medium'"
        type="primary"
        :loading="this.loading"
        ><i class="el-icon-upload2 el-icon--left" v-if="item.showButtonIcon"></i
        >{{ item.showButtonTitle || "点击上传" }}</el-button
      >
      <p v-if="item.buttonTips">{{ item.buttonTips }}</p>
    </template>
    <template v-else>
      <i class="el-icon-upload"></i>
      <div class="el-upload__tip" v-if="item.tip">
        {{
          item.tipDesc ||
          "点击或将文件拖拽到这里上传\n支持JPG、JPEG、PNG、PDF格式"
        }}
      </div>
      <div class="el-upload__text" v-else>
        将文件拖到此处，或<em>点击上传</em>
      </div>
    </template>

    <ExampleDialog
      v-if="dialogImageUrl || fileUrl"
      ref="exampleDialog"
      :src="dialogImageUrl"
      :fileUrl="fileUrl"
      :isFile="false"
    />
  </el-upload>
</template>

<script type="text/ecmascript-6">
import mixins from "../mixins";
// pdf默认图片
import idCardFront from "@/assets/image/defaultPdf.png";
import { ExampleDialog } from "@/components";
import { fetchFilesMeta, fetchUpload } from "@/api/common";
import { isFileType, getPdfOrImageConfig } from "@/utils/utils";

export default {
  mixins: [mixins],
  name: "zxUpload",
  computed: {},
  components: {
    ExampleDialog,
  },
  data() {
    return {
      disabled: false,
      dialogImageUrl: "",
      fileUrl: "",
      defaultFunction: () => {},
      fileList: [],
      showFileList: false,
      loading: false,
      noneImg: false,
    };
  },
  watch: {
    "item.prop": {
      handler(val) {
        if (Array.isArray(this.formData[val])) {
          if (this.formData[val]?.length === 0 && this.fileList?.length > 0) {
            this.fileList = [];
          }
        } else if (!this.formData[val] && this.fileList?.length > 0) {
          this.fileList = [];
        }
      },
      deep: true,
    },
    "item.fileList"(val) {
      this.noneImg = val?.length >= this.item.limit;
    },
    fileList(val) {
      this.noneImg = val?.length >= this.item.limit;
    },
  },
  methods: {
    uploadSectionFile(file) {
      const fileRaw = file.file;
      const isLt10M = fileRaw.size / 1024 / 1024 <= (this.item.fileSize || 10);
      if (!isFileType(fileRaw, this.item.supportFileFormat)) {
        this.$message.error(this.item.errorTip || "上传文件格式不正确");
        this.removeFailFile(file);
        return false;
      }
      if (!isLt10M) {
        this.$message.error(
          this.item.errorTip ||
            ` 上传的文件大小不能超过${this.item.fileSize || 10}M!`
        );
        this.removeFailFile(file);
        return false;
      }
      const data = {
        fetchUrl: this.item.action || "",
      };
      const formData = new FormData();
      // 文件对象
      formData.append("file", fileRaw);
      formData.append("fileName", fileRaw.name);
      this.loading = true;
      fetchUpload(formData, data)
        .then((res) => {
          if (res.rspCode === "00000000") {
            this.handleGetFileList(res, fileRaw?.name);
            this.formData[this.item.prop] = res.data?.fileId;
            this.mixinEvent({
              type: "uploadSuccess",
              prop: this.item.prop,
              value: {
                ...res?.data,
                name: fileRaw?.name,
                fileName: fileRaw?.name,
              },
              response: res.data,
              fileList: this.fileList,
            });
          } else {
            this.removeFailFile(file);
            this.showFileList = true;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    remove(file, fileList) {
      this.fileList = fileList;
      this.formData[this.item.prop] = undefined;
      this.mixinEvent({
        type: "uploadRemove",
        prop: this.item.prop,
        value: this.formData[this.item.prop],
        fileList,
      });
    },

    // 处理PDF的默认
    handleGetFileList(res, fileName) {
      const { fileSuffix, fileId } = res?.data || {};
      // 处理PDF
      if (fileSuffix === "pdf" || fileName.endsWith(".pdf")) {
        this.fileList.push({
          fileSuffix,
          fileId,
          name: fileName,
          url: idCardFront,
          response: res,
        });
      } else {
        this.fileList.push({
          fileSuffix,
          fileId,
          name: fileName,
          url: getPdfOrImageConfig(fileId),
          response: res,
        });
      }
      console.log(this.fileList);
    },

    async handlePictureCardPreview(file) {
      if (file.fileId) {
        let obj = file;
        if (!file.fileSuffix) {
          const res = await fetchFilesMeta({ fileIds: file.fileId });
          if (res.rspCode === "00000000") {
            obj = res.data[0];
          }
        }
        if (obj.fileSuffix === "pdf") {
          const fileUrl = obj.fileId;
          this.dialogImageUrl = undefined;
          this.fileUrl = fileUrl;
        } else {
          const imageUrl = getPdfOrImageConfig(obj.fileId);
          this.fileUrl = undefined;
          this.dialogImageUrl = imageUrl;
        }
      } else {
        const { raw } = file;
        const url = URL.createObjectURL(raw);
        if (raw.type === "application/pdf") {
          this.dialogImageUrl = undefined;
          this.fileUrl = url;
        } else {
          this.fileUrl = undefined;
          this.dialogImageUrl = url;
        }
      }
      this.$nextTick(() => {
        this.$refs.exampleDialog.open();
      });
    },

    handleExceed(file) {
      this.$message.error(`最多上传${this.item.limit}个文件`);
    },

    handleChange(file, fileList) {
      if (file.status === "fail") {
        this.$store.dispatch("user/LogOut");
        return;
      }

      // 不通过上传组件的接口上传，通过另一个按钮继续上传
      if (this.item.notAutoUpload) {
        const isLt10M = file.size / 1024 / 1024 <= (this.item.fileSize || 10);
        if (this.$refs.uploadFile.uploadFiles.length > this.item.limit) {
          this.$message.error(`上传文件已超过上限${this.item.limit}，请调整`);
          this.removeFailFile(file);
          return false;
        }
        if (!isLt10M) {
          this.$message.error(
            `上传的文件大小不能超过${this.item.fileSize || 10}M!`
          );
          this.removeFailFile(file);
          return isLt10M;
        }
        if (!isFileType(file, this.item.supportFileFormat)) {
          this.$message.error(this.item.errorTip || "上传文件格式不正确");
          this.removeFailFile(file);
          return false;
        }
        this.formData[this.item.prop] = file;
        this.mixinEvent({
          type: "uploadSuccess",
          prop: this.item.prop,
          value: this.formData[this.item.prop],
          response: file?.response,
          fileList,
        });
      }
    },

    // 去除文件列表失败文件
    removeFailFile(file) {
      let uid = file.uid;
      let idx = this.$refs.uploadFile.uploadFiles.findIndex(
        (item) => item.uid === uid
      ); // 去除文件列表失败文件（uploadFiles为el-upload中的ref值）
      this.$refs.uploadFile.uploadFiles.splice(idx, 1); // 去除文件列表失败文件
    },
  },
};
</script>

<style scoped lang="scss">
.el-upload {
  display: inline-block;
  text-align: center;
  cursor: pointer;
  outline: none;
}

.upload-button {
  ::v-deep {
    .el-upload {
      text-align: left;
    }
  }
}

::v-deep {
  .el-dialog__body {
    // height: 600px;
    display: flex;
    justify-content: center;
  }
  .el-upload-dragger {
    background-color: #fff;
    border-radius: 6px;
    box-sizing: border-box;

    text-align: center;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    width: 260px;
    height: 100%;
  }
  .el-icon-upload {
    font-size: 78px;
    color: #c0c4cc;
    margin: 10px 0;
    line-height: 52px;
    color: $primaryColor_50;
  }
  .el-upload--picture-card {
    border: none;
  }
  .el-upload__tip {
    font-size: 12px;
    line-height: 20px;
    color: $grayFontColor;
    white-space: pre-wrap;
    text-align: center;
  }

  .el-upload-dragger ~ .el-upload__files {
    border-top: 1px solid #dcdfe6;
    margin-top: 7px;
    padding-top: 5px;
  }

  .el-upload-dragger .el-upload__text {
    color: #606266;
    font-size: 14px;
    text-align: center;
  }

  .el-upload-dragger .el-upload__text em {
    color: #409eff;
    font-style: normal;
  }

  .el-upload-dragger:hover {
    border-color: #409eff;
  }

  .el-upload-dragger.is-dragover {
    background-color: rgba(32, 159, 255, 0.06);
    border: 2px dashed #409eff;
  }
}

.common-picture-card {
  width: 260px;
  height: 100%;
  border-radius: 6px;
  overflow: hidden;

  &:hover {
    border-color: #409eff;
  }

  ::v-deep {
    .el-upload__tip {
      margin-top: -50px;
    }
    .el-upload__text {
      margin-top: -100px;
    }

    .el-upload-list__item {
      width: 260px;
      height: 160px;
    }

    .el-upload-list__item-status-label {
      display: none;
    }

    .el-form-item__error {
      margin-top: 5px;
    }
  }
}
.common-picture {
  width: 260px;
  height: 100%;
  border-radius: 6px;
  overflow: hidden;

  &:hover {
    border-color: #409eff;
  }

  ::v-deep {
    .el-upload__tip {
      margin-top: -50px;
    }
    .el-upload__text {
      margin-top: -100px;
    }
    // .el-upload-list__item.is-success,
    // .el-upload-list__item-actions {
    //   width: 300px;
    //   height: 260px;
    // }

    .el-upload-list__item-status-label {
      display: none;
    }

    .el-upload-list__item {
      width: 260px;
      height: 160px;
    }

    .el-form-item__error {
      margin-top: 5px;
    }
    .el-upload-dragger {
      background-color: #fff;
      border-radius: 6px;
      box-sizing: border-box;

      text-align: center;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
  }
}
.disUoloadSty {
  ::v-deep {
    .el-upload--picture-card {
      display: none !important; /* 上传按钮隐藏 */
    }
  }
}
</style>
