var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "scrollHomeRef", staticClass: "home-container" }, [
    _c(
      "div",
      { staticClass: "home-layout" },
      [
        _c(
          "div",
          {
            staticClass: "top-fixed",
            style: {
              boxShadow: _vm.visible
                ? "0 2px 18px 0 rgba(0, 0, 0, 0.20)"
                : "unset",
            },
          },
          [
            _c("section", { staticClass: "navbar horizontalNavbar" }, [
              _c("div", { staticClass: "home-layout-nav" }, [
                _c("div", { staticClass: "nav-left-nav" }, [_c("LeftNav")], 1),
              ]),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "section",
          { staticClass: "main-section" },
          [
            _c(
              "transition",
              {
                attrs: { name: "fade-transform", mode: "out-in" },
                on: { "after-enter": _vm.afterEnter },
              },
              [_c("keep-alive", [_c("router-view")], 1)],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("Copyright"),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }