var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "customization-card", class: _vm.cardClassName },
    [
      _vm.headerTitle
        ? _c("div", { class: _vm.headerClass }, [
            _c("span", { staticClass: "header-title" }, [
              _vm._v(_vm._s(_vm.headerTitle)),
            ]),
            _vm._v(" "),
            _vm.subTitle && _vm.type === "desc"
              ? _c("span", { staticClass: "sub-title" }, [
                  _vm._v(_vm._s(_vm.subTitle)),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm._t("default"),
      _vm._v(" "),
      _c("div", { staticClass: "divider-hr" }),
      _vm._v(" "),
      _vm._t("bodySlot"),
      _vm._v(" "),
      _c("div", { staticClass: "divider-dr" }),
      _vm._v(" "),
      _c("CustomForm", {
        ref: "customForm",
        attrs: {
          formRowGutter: _vm.formRowGutter,
          isInline: _vm.isInline,
          formData: _vm.formData,
          formCols: _vm.formCols,
          moreFilterProp: _vm.moreFilterProp,
          "label-suffix": _vm.labelSuffix,
          "status-icon": _vm.statusIcon,
          size: _vm.size,
          disabled: _vm.disabled,
          inline: _vm.isInline,
          "label-width": _vm.labelWidth,
          "label-position": _vm.labelPosition,
          rules: _vm.rules,
          needToast: _vm.needToast,
          showFormLineButtons: _vm.showFormLineButtons,
          formLineBtnAuth: _vm.formLineBtnAuth,
          loading: _vm.loading,
        },
        on: { event: _vm.event, submit: _vm.submit },
        nativeOn: {
          submit: function ($event) {
            $event.preventDefault()
          },
        },
      }),
      _vm._v(" "),
      _vm._t("popConFirmDisabled"),
      _vm._v(" "),
      _vm.tabHidden
        ? _c("CustomTableComponent", {
            attrs: {
              rowKey: _vm.rowKey,
              renderTableKey: _vm.renderTableKey,
              tableData: _vm.tableData,
              clearAllselect: _vm.clearAllselect,
              setChangeSelectList: _vm.setChangeSelectList,
              tableSelectionWidth: _vm.tableSelectionWidth,
              tableCols: _vm.tableCols,
              isSelection: _vm.isSelection,
              defaultSelections: _vm.defaultSelections,
              loading: _vm.loading,
              layout: _vm.layout,
              isBorder: _vm.isBorder,
              showOverflowTooltip: _vm.showOverflowTooltip,
              isIndex: _vm.isIndex,
              indexLabel: _vm.indexLabel,
              indexLabelWidth: _vm.indexLabelWidth,
              selectable: _vm.selectable,
            },
            on: {
              select: _vm.select,
              selectAll: _vm.selectAll,
              getAllSelect: _vm.getAllSelect,
              cellClick: _vm.cellClick,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isPagination
        ? _c("zx-page", {
            attrs: {
              pagination: _vm.pagination,
              pageSizeList: _vm.pageSizeList,
              layout: _vm.layout,
              needToTop: _vm.needToTop,
            },
            on: { refresh: _vm.refresh },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }