import { selectCorpPage, corpSave, corpUpdate, corpDetailById } from '@/api/ManagementCenter/businessManagement';
import { getCommFileByIds } from '@/api/common';
import { corpSelectByOrgId, corpSelectByTxNo } from '@/api/ManagementCenter/authenticate';
import { getPdfOrImageConfig } from '@/utils/utils';

const defaultState = () => {
  return {
    corpList: [],
    total: 0,
    corpDetail: {},
    commFileList: [],
    corpByTxNoDetail: {},
  };
};
export const state = defaultState();

const actions = {
  // 企业列表
  async selectCorpPage({ commit }, payload) {
    const { data } = (await selectCorpPage(payload)) || {};
    commit('SET_CORPLIST', data);
  },
  // 企业新增
  async corpSave(_, payload) {
    const response = (await corpSave(payload)) || {};
    if (response.rspCode === '00000000') {
      return true;
    }
  },
  // 企业修改
  async corpUpdate(_, payload) {
    const response = (await corpUpdate(payload)) || {};
    if (response.rspCode === '00000000') {
      return true;
    }
  },
  // 企业详情
  async corpDetailById({ commit }, payload) {
    const { data } = (await corpDetailById(payload)) || {};
    commit('SET_CORPDETAIL', data);
  },

  // 企业实名认证详情查询
  async corpSelectByOrgId({ commit }, payload) {
    const { data } = (await corpSelectByOrgId(payload)) || {};
    commit('SET_CORPDETAIL', data);
  },
  // 企业详情快照查询
  async corpSelectByTxNo({ commit }, payload) {
    const { data } = (await corpSelectByTxNo(payload)) || {};
    commit('SET_CORPBYTXNODETAIL', data);
  },
  // 批量查询文件
  async getCommFileByIds({ commit }, payload) {
    const { data } = (await getCommFileByIds(payload)) || {};
    commit('SET_COMMFILELIST', data);
  },
};

const mutations = {
  // 企业列表
  SET_CORPLIST(state, payload) {
    state.corpList = payload?.records || [];
    state.total = Number(payload?.total);
  },
  // 企业实名认证详情查询
  SET_CORPDETAIL(state, payload) {
    state.corpDetail = payload;
  },
  // 企业详情查询
  SET_CORPBYTXNODETAIL(state, payload) {
    state.corpByTxNoDetail = payload;
  },
  // 批量查询文件
  SET_COMMFILELIST(state, payload) {
    state.commFileList = payload?.map((item) => {
      return { name: item.fileName, id: item.fileId, ...getPdfOrImageConfig(item) };
    });
  },

  RESET_STATE: (state) => {
    Object.assign(state, defaultState());
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
