import request from '@/utils/request'

// 我的消息
export function fetchMessageList(params) {
  return request({
    url: '/notifyhub/message/page',
    method: 'post',
    data: params
  })
}

// 标记已读
export function fetchReadFlag(params) {
  return request({
    url: '/notifyhub/message/readFlag',
    method: 'post',
    data: params
  })
}
