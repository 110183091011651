var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "customization-card" },
    [
      !_vm.dividerTitleHidden
        ? _c("CommonTitle", {
            staticClass: "commonTitle",
            attrs: {
              icons: _vm.dividerIcon,
              title: _vm.dividerTitle || _vm.$route.meta?.title,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm._t("default"),
      _vm._v(" "),
      _vm.bodyHidden
        ? _c(
            "div",
            { class: _vm.contentName },
            [
              _vm._t("bodyHeaderSlot"),
              _vm._v(" "),
              _vm._t("bodySlot"),
              _vm._v(" "),
              _c(
                "el-row",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.formHidden,
                      expression: "formHidden",
                    },
                  ],
                  attrs: {
                    type: _vm.sheetStyle.type,
                    align: _vm.sheetStyle.align,
                    justify: _vm.sheetStyle.justify,
                    gutter: 5,
                  },
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "CustomForm",
                        {
                          ref: "customForm",
                          attrs: {
                            formRowGutter: _vm.formRowGutter,
                            isInline: _vm.isInline,
                            formData: _vm.formData,
                            formCols: _vm.formCols,
                            moreFilterProp: _vm.moreFilterProp,
                            "label-suffix": _vm.labelSuffix,
                            "status-icon": _vm.statusIcon,
                            size: _vm.size,
                            disabled: _vm.disabled,
                            inline: _vm.isInline,
                            "label-width": _vm.labelWidth,
                            "label-position": _vm.labelPosition,
                            rules: _vm.rules,
                            needToast: _vm.needToast,
                            formLineButtons: _vm.formLineButtons,
                            showFormLineButtons: _vm.showFormLineButtons,
                            formLineBtnAuth: _vm.formLineBtnAuth,
                            isSelection: _vm.isSelection,
                          },
                          on: {
                            event: _vm.event,
                            submit: _vm.submit,
                            formSlotClick: _vm.formSlotClick,
                          },
                          nativeOn: {
                            submit: function ($event) {
                              $event.preventDefault()
                            },
                          },
                          scopedSlots: _vm._u(
                            [
                              _vm._l(_vm.formSlotList, function (item) {
                                return {
                                  key: item.slotName,
                                  fn: function ({ data }) {
                                    return [
                                      _vm._t(item.slotName, null, {
                                        data: { ...data },
                                      }),
                                    ]
                                  },
                                }
                              }),
                            ],
                            null,
                            true
                          ),
                        },
                        [
                          _vm._v(" "),
                          _c(
                            "template",
                            { slot: "selectParentRes" },
                            [_vm._t("selectParentRes")],
                            2
                          ),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _vm._t("popConFirmDisabled"),
                    ],
                    2
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm._t("summary"),
              _vm._v(" "),
              _c("CustomTable", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.tabHidden,
                    expression: "tabHidden",
                  },
                ],
                ref: "customTable",
                staticClass: "customTable",
                attrs: {
                  lazy: _vm.lazy,
                  rowKey: _vm.rowKey,
                  tableData: _vm.tableData,
                  clearAllselect: _vm.clearAllselect,
                  setChangeSelectList: _vm.setChangeSelectList,
                  tableSelectionWidth: _vm.tableSelectionWidth,
                  tableCols: _vm.tableCols,
                  isSelection: _vm.isSelection,
                  pagination: _vm.pagination,
                  isPagination: _vm.isPagination,
                  expandRowKeys: _vm.expandRowKeys,
                  loading: _vm.loading,
                  loadData: _vm.loadData,
                  layout: _vm.layout,
                  isBorder: _vm.isBorder,
                  pageSizeList: _vm.pageSizeList,
                  showOverflowTooltip: _vm.showOverflowTooltip,
                  defaultExpandAll: _vm.defaultExpandAll,
                },
                on: {
                  getload: _vm.getload,
                  event: _vm.event,
                  select: _vm.select,
                  selectAll: _vm.selectAll,
                  getAllSelect: _vm.getAllSelect,
                  sortChange: _vm.sortChange,
                },
                scopedSlots: _vm._u(
                  [
                    _vm._l(_vm.tableSlotList, function (item) {
                      return {
                        key: item.slotName,
                        fn: function ({ data }) {
                          return [
                            _vm._t(item.slotName, null, { data: { ...data } }),
                          ]
                        },
                      }
                    }),
                  ],
                  null,
                  true
                ),
              }),
              _vm._v(" "),
              _vm._t("FooterSlot"),
            ],
            2
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }