var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CustomTable",
    {
      attrs: {
        cardHeader: _vm.cardHeader,
        tableData: _vm.tableFileList,
        tableCols: _vm.tableCols,
        loading: _vm.loading,
        isPagination: true,
        pagination: _vm.pagination,
        loadData: _vm.handleSearch,
      },
    },
    [
      _c(
        "template",
        { slot: "FooterSlot" },
        [
          _c("ExampleDialog", {
            ref: "exampleDialog",
            attrs: {
              className: "agreement-read-dialog",
              src: _vm.src,
              pdfId: _vm.pdfId,
              pdfIdList: _vm.pdfIdList,
              showFooter: _vm.type !== "detail",
              isRead: _vm.list && _vm.list.length > 0,
              confirmButton: _vm.confirmButton,
              defaultTitle: _vm.defaultTitle,
              confirmButtonDisabled: _vm.confirmButtonDisabled,
            },
            on: { submit: _vm.readSubmit, isReadFn: _vm.changeReadStatus },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }