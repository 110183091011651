// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Variables */
[data-v-ae207458]:export {
  menuText: #000;
  menuActiveText: #fff;
  subMenuActiveText: #f4f4f5;
  menuActiveBg: #1589cd;
  menuBg: #fff;
  menuHover: rgba(45, 153, 255, 0.3);
  subMenuBg: #fff;
  subMenuHover: rgba(45, 153, 255, 0.3);
  sideBarWidth: 200px;
  primaryColor: #1589cd;
  primaryFontColor: rgb(45, 153, 255);
  primaryColor_8: rgba(31, 113, 255, 0.08);
  globalColor: #e60012;
  defaultFontColor: rgb(20, 35, 69);
  tableHeaderBg: #fafafa;
  tableHeaderHeight: 40px;
  tableAlign: center;
  fontSize_14: 14px;
  fontSize_16: 16px;
  fontSize_18: 18px;
  fontSize_24: 24px;
}

/* Mixins */
@keyframes antStatusProcessing-ae207458 {
0% {
    transform: scale(0.8);
    opacity: 0.5;
}
100% {
    transform: scale(2.4);
    opacity: 0;
}
}
.jj-timeline-item[data-v-ae207458] {
  height: 270px;
  display: flex;
  transition-delay: 200ms;
}
.jj-timeline-item:nth-of-type(1) .jj-timeline-item__tail_first[data-v-ae207458] {
  display: none;
}
.jj-timeline-item:nth-last-of-type(1) .jj-timeline-item__tail_last[data-v-ae207458] {
  display: none;
}
.jj-timeline-item[data-v-ae207458]:hover {
  cursor: pointer;
}
.jj-timeline-item:hover .jj-timeline-item__timestamp[data-v-ae207458] {
  color: #1589cd;
}
.jj-timeline-item:hover .jj-timeline-item__node[data-v-ae207458] {
  background-color: #1589cd;
}
.jj-timeline-item:hover .jj-timeline-item__node[data-v-ae207458]::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #1589cd;
  border-radius: 50%;
  animation: antStatusProcessing-ae207458 1.2s ease-in-out infinite;
  content: "";
  box-sizing: border-box;
}
.jj-timeline-item:hover .jj-timeline-item__body[data-v-ae207458] {
  box-shadow: 0 0 20px rgba(31, 113, 255, 0.24);
}
.jj-timeline-item:hover .jj-timeline-item__body h3[data-v-ae207458] {
  color: #1589cd;
}
.jj-timeline-item__timestamp[data-v-ae207458] {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.65);
  display: flex;
  align-items: center;
  transition-duration: 300ms;
}
.jj-timeline-item__line[data-v-ae207458] {
  width: 20px;
  margin: 0 7px;
  position: relative;
  display: flex;
  align-items: center;
}
.jj-timeline-item__line .jj-timeline-item__tail_first[data-v-ae207458] {
  position: absolute;
  top: 0;
  left: 9px;
  height: 50%;
  width: 2px;
  background-color: rgba(31, 113, 255, 0.24);
}
.jj-timeline-item__line .jj-timeline-item__tail_last[data-v-ae207458] {
  position: absolute;
  bottom: 0;
  left: 9px;
  height: 50%;
  width: 2px;
  background-color: rgba(31, 113, 255, 0.24);
}
.jj-timeline-item__line .jj-timeline-item__node[data-v-ae207458] {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid rgba(31, 113, 255, 0.24);
  box-sizing: border-box;
  background-color: #fff;
  z-index: 99;
  transition-duration: 300ms;
  position: relative;
}
.jj-timeline-item__content[data-v-ae207458] {
  display: flex;
  align-items: center;
}
.jj-timeline-item__content .jj-timeline-item__body[data-v-ae207458] {
  height: 240px;
  margin-left: 72px;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 30px;
  display: flex;
  transition-duration: 300ms;
}
.jj-timeline-item__content .jj-timeline-item__body img[data-v-ae207458] {
  width: 300px;
  height: 180px;
}
.jj-timeline-item__content .jj-timeline-item__body .jj-timeline-item__body_con[data-v-ae207458] {
  width: 690px;
  margin-left: 30px;
}
.jj-timeline-item__content .jj-timeline-item__body h3[data-v-ae207458] {
  font-size: 24px;
  color: rgb(20, 35, 69);
  line-height: 1em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.jj-timeline-item__content .jj-timeline-item__body p[data-v-ae207458] {
  padding-top: 20px;
  height: 156px;
  box-sizing: border-box;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.65);
  line-height: 1.75em;
  overflow-wrap: break-word;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
