import { getStorage, setStorage, removeStorage } from './storage'

const TokenKey = 'Token'
const RefreshToken = 'Refresh-Token'
const UserInfoType = 'User-Info'
const AppId = 'App-Id'
const OrgId = 'Org-Id'
const BankCodeKey = 'Bank-Login-Code'
const ClientIdKey = 'Client-Id'
const UnifiedPlatformInfoKey = 'Unified-Platform-Info'
const Secret = 'Secret-Id'

/**
 * token
 */
export function getToken() {
  return getStorage(TokenKey)
}
export function setToken(token, expires) {
  const inFifteenMinutes = expires * 1000;
  if (inFifteenMinutes) {
    return setStorage(TokenKey, token, inFifteenMinutes)
  }
  return setStorage(TokenKey, token)
}
export function removeToken() {
  removeStorage(TokenKey)
}


/**
 * 保存refresh_token
 * @param {Object} token
 */
export function setRefreshToken(token, expires) {
  const inFifteenMinutes = expires * 1000;
  if (inFifteenMinutes) {
    return setStorage(RefreshToken, token, inFifteenMinutes)
  }
  return setStorage(RefreshToken, token)
}
export function getRefreshToken() {
  return getStorage(RefreshToken)
}
export function removeRefreshToken() {
  removeStorage(RefreshToken)
}

/**
 * 保存Corp-Id
 * @param {Object} id
 */
export function setAppId(id) {
  setStorage(AppId, id)
}
export function getAppId() {
  return getStorage(AppId)
}
export function removeAppId() {
  removeStorage(AppId)
}

/**
 * 保存Org-Id
 * @param {Object} id
 */
export function setOrgId(id) {
  setStorage(OrgId, id)
}
export function getOrgId() {
  return getStorage(OrgId)
}
export function removeOrgId() {
  removeStorage(OrgId)
}

/**
 * 用户信息
 */
export function setUserInfo(userInfo) {
  setStorage(UserInfoType, userInfo, 7 * 24 * 60 * 60 * 1000)
}
export function getUserInfo() {
  return getStorage(UserInfoType)
}
export function removeUserInfo() {
  removeStorage(UserInfoType)
}

/**
 * Bank-Login-Code
 */
export function getBankCode() {
  return getStorage(BankCodeKey)
}
export function setBankCode(code) {
  setStorage(BankCodeKey, code)
}
export function removeBankCode() {
  removeStorage(BankCodeKey)
}

/**
 * Clientid缓存
 */
export function setClientId(clientId) {
  setStorage(ClientIdKey, clientId)
}
export function getClientId() {
  return getStorage(ClientIdKey)
}
export function removeClientId() {
  removeStorage(ClientIdKey)
}

/**
 * 统一平台登录信息缓存
 */
export function setUnifiedPlatformInfo(unifiedPlatformInfo) {
  setStorage(UnifiedPlatformInfoKey, unifiedPlatformInfo)
}
export function getUnifiedPlatformInfo() {
  return getStorage(UnifiedPlatformInfoKey)
}
export function removeUnifiedPlatformInfo() {
  removeStorage(UnifiedPlatformInfoKey)
}

/**
 * 密钥缓存
 */
export function setSecret(clientId) {
  setStorage(Secret, clientId)
}
export function getSecret() {
  return getStorage(Secret) || ''
}
export function removeSecret() {
  removeStorage(Secret)
}