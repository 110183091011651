import Vue from 'vue';
import {
  selectByParamCode,
  // getMenuTree,
  getSelectByDictionaryCodeList,
  getCommFileByIds,
  oq100015,
  oq100033,
  selectRiskInfo,
} from '@/api/common';
import { setStorage } from '@/utils/storage';
import { isEmptyObject } from '@/utils/validate';
import { getPdfOrImageConfig } from '@/utils/utils';

const defaultState = () => {
  return {
    systemParamData: {
      paramCode: '', // 参数编码
      paramName: '', // 机构名称
      paramValue: [], // 参数值
    },
    productMenuTree: [],
    dictionaryData: {}, // 数据字典返回后处理的数据，ex：{k006: [], k007: []}
    platformPhone: {
      paramValue: '',
    },
    commFileList: [],
    throughList: [], // 穿透列表
    throughDetail: {}, // 穿透详情
    refreshKey: 0,
    uploadFileData: {}, // 根据Url下载并保存文件
    identityList: [],
    signStatus: '',
    currCorpIdentityName: '',
    outboundOrder:'',
    inboundOrder:'',
    relocationManagement:''
  };
};
export const state = defaultState();

const mutations = {
  SET_REFRSH_DATA(state, data) {
    const { key, value } = data;
    Vue.set(state, [key], value);
  },
  SET_CURRCORPIDENTITY_NAME(state, data) {
    state.currCorpIdentityName = data;
  },
  SET_SYSTEM_PARAM_DATA(state, data) {
    state.systemParamData = data;
    setStorage('systemParamData', data.paramValue);
  },
  SET_PRODUCT_MENU_TREE(state, menuTree) {
    state.productMenuTree = menuTree;
  },
  SET_SIGNTLOADING(state, signLoading) {
    console.log('VUEX的signLoading', signLoading);
    state.signLoading = signLoading;
  },
  RESET_STATE: (state) => {
    Object.assign(state, defaultState());
  },
  SET_DICCODELIST(state, data) {
    Object.keys(data).forEach((key) => {
      data[key] = data[key]?.map((item) => {
        return {
          ...item,
          label: item.dataFieldName,
          value: item.dataFieldValue,
        };
      });
    });
    state.dictionaryData = data || {};
  },
  GET_PLATFORM_PHONE(state, data) {
    state.platformPhone = data;
  },

  // 批量查询文件
  SET_COMMFILELIST(state, payload) {
    state.commFileList = payload?.map((item) => {
      return { name: item.fileName, id: item.fileId, ...getPdfOrImageConfig(item) };
    });
  },

  // 穿透列表
  SAVE_THROUGH_LIST(state, payload) {
    state.throughList = payload;
  },

  // 穿透详情
  SAVE_THROUGH_DETAIL(state, payload) {
    state.throughDetail = payload;
  },

  // 刷新页面
  SAVE_REFRESH_KEY(state) {
    state.refreshKey = state.refreshKey + 1;
  },

  // 根据Url下载并保存文件
  SET_UPLOADFILE_BYURL(state, data) {
    state.uploadFileData = data;
  },
  SET_IDENTITYLIST(state, data) {
    state.identityList = data;
  },
};

const actions = {
  refreshView({ commit }, payload) {
    commit('SET_REFRSH_DATA', payload);
  },
  async selectByParamCode({ commit }, payload) {
    const { data } = await selectByParamCode(payload);
    if (data) {
      commit('SET_SYSTEM_PARAM_DATA', data);
      return data;
    }
  },

  // 获取产品菜单
  // async getProductMenuTree({ commit }, payload) {
  //   const response = await getMenuTree(payload);
  //   if (response && response.rspCode === '00000000') {
  //     const { data } = response;
  //     if (!data) {
  //       throw Error('获取菜单失败！');
  //     }
  //     const { menuTree } = data;
  //     if (menuTree?.length === 0) {
  //       throw Error('获取菜单失败！');
  //     }
  //     commit('SET_PRODUCT_MENU_TREE', menuTree);
  //     return data;
  //   } else {
  //     throw Error('获取菜单失败！');
  //   }
  // },

  // 数据字典查询
  async getSelectByDictionaryCodeList({ commit }) {
    let res = {
      rspCode: '00000000',
      data: state.dictionaryData,
    };
    // 数据有，不会重查
    if (isEmptyObject(state.dictionaryData)) {
      res = await getSelectByDictionaryCodeList({
        dictionaryCodeList: 'K0001,K0002,K0003,K0004,K0005,K0006,K0007,K0008,K0009,K0010,K0011,K0012,K0013,K0014,K0015',
      });
      commit('SET_DICCODELIST', res?.data || {});
    }

    return res;
  },
  async getPlatformPhone({ commit }, payload) {
    const { data } = await selectByParamCode(payload);
    if (data) {
      commit('GET_PLATFORM_PHONE', data);
      return data;
    }
  },

  // 批量查询文件
  async getCommFileByIds({ commit }, payload) {
    const { data } = (await getCommFileByIds(payload)) || {};
    commit('SET_COMMFILELIST', data);
  },

  // 穿透列表
  async oq100015({ commit }, payload) {
    const response = (await oq100015(payload)) || {};
    commit('SAVE_THROUGH_LIST', response.data);
    return response.data;
  },

  // 穿透详情
  async oq100033({ commit }, payload) {
    const response = (await oq100033(payload)) || {};
    if (response?.rspCode === '00000000') {
      commit('SAVE_THROUGH_DETAIL', response?.data || {});
    }
  },

  // 刷新页面
  refreshPageKey({ commit }) {
    commit('SAVE_REFRESH_KEY');
  },

  // 预警信息
  async selectRiskInfo(_, payload) {
    const response = (await selectRiskInfo(payload)) || {};
    return response;
  },

  //角色列表
  getIdentity({ commit }, data) {
    commit('SET_IDENTITYLIST', data);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
