export default {
  props: {
    formData: {},
    item: { type: Object, default: {} },
  },
  methods: {
    mixinEvent(params) {
      this.$emit('event', params);
    },
  },
};
