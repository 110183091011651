var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "top-navbar-constainer",
      class: { "top-navbar-constainer-homepage": !_vm.notHomePage },
    },
    [
      _c("div", { staticClass: "top-navbar-left" }, [
        _c("img", {
          staticClass: "logo",
          attrs: { src: require("@/assets/logo.png") },
        }),
        _vm._v(" "),
        _c("span", [_vm._v(_vm._s(_vm.settings.title))]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "top-navbar-right" }, [_c("TopNavbarRight")], 1),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }