<template>
  <el-dialog
    :visible="isShow"
    :width="width"
    :height="height"
    @close="close"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :title="text.title || '提示'"
  >
    <div class="wrap">
      <p class="content"><img :src="img" v-if="!text.showImg" text /><span v-html="text.msg"></span></p>
      <!-- <p v-if="text.hasTips">您确定继续吗？</p> -->
    </div>

    <div class="btnGroup">
      <el-button icon="" size="medium" @click="close()" v-if="text.btn.no" class="close">
        {{ text.btn.no }}
      </el-button>
      <el-button type="primary" size="medium" @click="ok()" v-if="text.btn.ok">
        {{ text.btn.ok }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
export default {
  data() {
    return {
      isShow: true,
      width: '380px',
      height: '200px',
      text: {
        type: '提示',
        msg: '确定删除此条信息？',
        hasTips: true,
        btn: {
          ok: '确定',
          no: '取消',
        },
      },
      img: require('@/assets/image/deleteIcon.png'),
    };
  },
  methods: {
    close() {
      console.log('关闭');
    },
    ok() {
      console.log('确定');
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-dialog .el-dialog__header {
  // height: 50px;
}
::v-deep .el-dialog__body {
  min-height: 150px;
  padding: 20px 20px;
}
::v-deep .el-dialog__title {
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.84px;
  color: $defaultFontColor !important;
}
.wrap {
  width: 100%;
  min-height: 40px;
  p {
    text-align: center;
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 14px;
    color: $defaultFontColor;
    display: flex;
    // justify-content: center;
    img {
      width: 18px;
      height: 18px;
      margin-right: 8px;
    }
    span {
      line-height: 1.6;
    }
  }
  .content {
    margin-right: 18px;
  }
}
.btnGroup {
  width: 100%;
  text-align: center;
  margin-top: 42px;
  .close {
    // margin-left: 40px;
  }
  .svg {
    margin-right: 5px;
  }
  .el-button--primary {
    color: #ffffff;
    background-color: $primaryColor;
    border-color: $primaryColor;
  }
}
</style>
