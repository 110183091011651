<template>
  <div ref="scrollMenuRef" class="page-el-scrollbar" v-loading="isLoading">
    <section :class="['app-main-container', { 'page-home-container': isPageHome }]" ref="appMainRef">
      <div class="container-wrap">
        <transition
          name="fade-transform"
          mode="out-in"
          v-on:before-leave="beforeLeave"
          v-on:before-enter="beforeEnter"
          v-on:after-enter="afterEnter"
        >
          <router-view :key="key" />
        </transition>
      </div>
    </section>
    <Footer />
    <el-backtop class="go-back-container" target=".page-el-scrollbar" :bottom="58" :right="24" :visibility-height="30">
      <svg-icon icon-class="goBack" />
    </el-backtop>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Footer from './FooterComponent.vue';

export default {
  name: 'AppMain',
  components: {
    Footer,
  },
  data() {
    return {
      isLoading: false,
      routeLoading: true,
    };
  },
  computed: {
    ...mapState('common', ['refreshKey']),

    key() {
      return this.$route.path;
    },
    isPageHome() {
      return this.$route.path === '/dashboard';
    },
  },
  watch: {
    refreshKey() {
      this.routeLoading = false;
      this.$nextTick(() => {
        this.routeLoading = true;
      });
    },
  },
  methods: {
    // 返回顶部
    goBack() {},

    beforeLeave: function () {
      this.isLoading = true;
    },
    beforeEnter: function () {
      this.isLoading = true;
    },
    afterEnter: function (el) {
      // 管理台路由切换滚动到页面顶端
      document.querySelector('.page-el-scrollbar') && (document.querySelector('.page-el-scrollbar').scrollTop = 0);
      if (el) {
        this.isLoading = false;
      } else {
        setTimeout(() => {
          this.isLoading = false;
        }, 5000);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.page-el-scrollbar {
  height: calc(100vh - 90px);
  width: 100%;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;

  .app-main-container {
    // 64 一级菜单高度
    // 58 底部footer高度
    // 48 上下padding 高度
    min-height: calc(100vh - $topNavBarHeight - $topMenuBarHeight - $componentsSpace - 100px);
    background: $whitePageBgColor;
    padding: $componentsSpace;
  }
  ::v-deep .main-container {
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.moreHeader {
  .el-scrollbar {
    height: calc(100vh - 120px);
  }
  // 64 一级菜单高度
  // 58 底部footer高度
  // 40 服务热线高度
  // 48 上下padding 高度
  .app-main-container {
    min-height: calc(100vh - 200px) !important;
    width: calc(100vw - 48px);

    // 首页特殊处理
    &.page-home-container {
      min-height: calc(100vh - 178px) !important;
      padding: 0;
      width: 100vw;
    }
  }
}

// 返回顶部
.go-back-container {
  position: fixed;
  width: 40px;
  height: 40px;
  background: $primaryColor_50;
  border-radius: 4px;
  right: 24px;
  bottom: 58px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 20px;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  cursor: pointer;
  &:hover {
    background: $primaryColor;
  }
}
.container-wrap {
  width: 100%;
}
</style>
