// 融资管理
const financingmanagementRoutes = [
  // 经销商
  {
    path: '/financingManagement/financingList/detail',
    name: 'financingDetails',
    component: () =>
      import(
        /* webpackChunkName: "financingManagement" */ '@/views/Agency/Dealer/FinancingManagement/financingList/detail.vue'
      ),
    meta: {
      title: '融资信息详情',
      icon: 'dashboard',
      activeMenu: '/financingManagement',
      parentMenu: '/financingManagement/financingList',
      layout: 'ProductLayout',
      corpIdentity: ['DEALER_ENTERPRISE'],
      authority: ['NO-AUTHORITY'],
    },
  },
  // 融资申请详情
  {
    path: '/financingManagement/financingApplication/detail',
    name: 'financingDetails',
    component: () =>
      import(
        /* webpackChunkName: "financingManagement" */ '@/views/Agency/Dealer/FinancingManagement/financingApplication/detail.vue'
      ),
    meta: {
      title: '融资申请详情',
      icon: 'dashboard',
      activeMenu: '/financingManagement',
      parentMenu: '/financingManagement/financingApplication',
      layout: 'ProductLayout',
      corpIdentity: ['DEALER_ENTERPRISE'],
      authority: ['NO-AUTHORITY'],
    },
  },
  // 提货申请详情
  {
    path: '/financingManagement/deliveryRequest/deliveryApplicationDetails',
    name: 'deliveryApplicationDetails',
    component: () =>
      import(
        /* webpackChunkName: "financingManagement" */ '@/views/Agency/Dealer/FinancingManagement/deliveryRequest/detailsDocument/deliveryApplicationDetails.vue'
      ),
    meta: {
      title: '提货申请详情',
      icon: 'dashboard',
      activeMenu: '/financingManagement',
      parentMenu: '/financingManagement/deliveryRequest',
      layout: 'ProductLayout',
      corpIdentity: ['DEALER_ENTERPRISE'],
      authority: ['NO-AUTHORITY'],
    },
  },
  // 提货申请 详情
  {
    path: '/financingManagement/deliveryRequest/indexDetails',
    name: 'indexDetails',
    component: () =>
      import(
        /* webpackChunkName: "financingManagement" */ '@/views/Agency/Dealer/FinancingManagement/deliveryRequest/requestDocument/detail.vue'
      ),
    meta: {
      title: '提货申请详情',
      icon: 'dashboard',
      activeMenu: '/financingManagement',
      parentMenu: '/financingManagement/deliveryRequest',
      layout: 'ProductLayout',
      corpIdentity: ['DEALER_ENTERPRISE'],
      authority: ['NO-AUTHORITY'],
    },
  },
  // 发起提货申请
  {
    path: '/financingManagement/deliveryRequest/initiatePickupRequest',
    name: 'initiatePickupRequest',
    component: () =>
      import(
        /* webpackChunkName: "financingManagement" */ '@/views/Agency/Dealer/FinancingManagement/deliveryRequest/initiatePickupRequest.vue'
      ),
    meta: {
      title: '提货申请',
      icon: 'dashboard',
      activeMenu: '/financingManagement',
      parentMenu: '/financingManagement/deliveryRequest',
      layout: 'ProductLayout',
      corpIdentity: ['DEALER_ENTERPRISE'],
      authority: ['NO-AUTHORITY'],
    },
  },
  // 还款申请 融资详情
  {
    path: '/financingManagement/repaymentApplication/deliveryApplicationDetails',
    name: 'deliveryApplicationDetails',
    component: () =>
      import(
        /* webpackChunkName: "financingManagement" */ '@/views/Agency/Dealer/FinancingManagement/repaymentApplication/detailsDocument/deliveryApplicationDetails.vue'
      ),
    meta: {
      title: '融资详情',
      icon: 'dashboard',
      activeMenu: '/financingManagement',
      parentMenu: '/financingManagement/repaymentApplication',
      layout: 'ProductLayout',
      corpIdentity: ['DEALER_ENTERPRISE'],
      authority: ['NO-AUTHORITY'],
    },
  },
  // 还款申请 详情
  {
    path: '/financingManagement/repaymentApplication/indexDetails',
    name: 'indexDetails',
    component: () =>
      import(
        /* webpackChunkName: "financingManagement" */ '@/views/Agency/Dealer/FinancingManagement/repaymentApplication/requestDocument/detail.vue'
      ),
    meta: {
      title: '还款申请',
      icon: 'dashboard',
      activeMenu: '/financingManagement',
      parentMenu: '/financingManagement/repaymentApplication',
      layout: 'ProductLayout',
      corpIdentity: ['DEALER_ENTERPRISE'],
      authority: ['NO-AUTHORITY'],
    },
  },
  // 发起还款申请
  {
    path: '/financingManagement/repaymentApplication/initiatePickupRequest',
    name: 'initiatePickupRequest',
    component: () =>
      import(
        /* webpackChunkName: "financingManagement" */ '@/views/Agency/Dealer/FinancingManagement/repaymentApplication/initiatePickupRequest.vue'
      ),
    meta: {
      title: '还款申请',
      icon: 'dashboard',
      activeMenu: '/financingManagement',
      parentMenu: '/financingManagement/repaymentApplication',
      layout: 'ProductLayout',
      corpIdentity: ['DEALER_ENTERPRISE'],
      authority: ['NO-AUTHORITY'],
    },
  },
  {
    path: '/financingManagement/additionalGuarantee/financingDetails',
    name: 'financingDetails',
    component: () =>
      import(
        /* webpackChunkName: "financingManagement" */ '@/views/Agency/Dealer/FinancingManagement/additionalGuarantee/detail.vue'
      ),
    meta: {
      title: '追加担保详情',
      icon: 'dashboard',
      activeMenu: '/financingManagement',
      parentMenu: '/financingManagement/additionalGuarantee',
      layout: 'ProductLayout',
      corpIdentity: ['DEALER_ENTERPRISE'],
      authority: ['NO-AUTHORITY'],
    },
  },

  // 金融机构
  {
    path: '/financingManagement/financingApplication/detail',
    name: 'financialBanksDetails',
    component: () =>
      import(
        /* webpackChunkName: "financingManagement" */ '@/views/Agency/Financial/FinancingManagement/financingApplication/detail.vue'
      ),
    meta: {
      title: '融资申请详情',
      icon: 'dashboard',
      activeMenu: '/financingManagement',
      parentMenu: '/financingManagement/financingApplication',
      layout: 'ProductLayout',
      corpIdentity: ['FINANCIAL_ENTERPRISE'],
      authority: ['NO-AUTHORITY'],
    },
  },
  {
    path: '/financingManagement/financingList/detail',
    name: 'financialBanksDetails',
    component: () =>
      import(
        /* webpackChunkName: "financingManagement" */ '@/views/Agency/Financial/FinancingManagement/financingList/detail.vue'
      ),
    meta: {
      title: '融资详情',
      icon: 'dashboard',
      activeMenu: '/financingManagement',
      parentMenu: '/financingManagement/financingList',
      layout: 'ProductLayout',
      corpIdentity: ['FINANCIAL_ENTERPRISE'],
      authority: ['NO-AUTHORITY'],
    },
  },
  {
    path: '/financingManagement/repaymentRegistration/detail',
    name: 'repaymentRegistrationDetails',
    component: () =>
      import(
        /* webpackChunkName: "financingManagement" */ '@/views/Agency/Financial/FinancingManagement/repaymentRegistration/detail.vue'
      ),
    meta: {
      title: '融资详情',
      icon: 'dashboard',
      activeMenu: '/financingManagement',
      parentMenu: '/financingManagement/repaymentRegistration',
      layout: 'ProductLayout',
      corpIdentity: ['FINANCIAL_ENTERPRISE'],
      authority: ['NO-AUTHORITY'],
    },
  },
  {
    path: '/financingManagement/repaymentRegistration/register',
    name: 'repaymentRegistrationDetails',
    component: () =>
      import(
        /* webpackChunkName: "financingManagement" */ '@/views/Agency/Financial/FinancingManagement/repaymentRegistration/register.vue'
      ),
    meta: {
      title: '还款登记',
      icon: 'dashboard',
      activeMenu: '/financingManagement',
      parentMenu: '/financingManagement/repaymentRegistration',
      layout: 'ProductLayout',
      corpIdentity: ['FINANCIAL_ENTERPRISE'],
      authority: ['NO-AUTHORITY'],
    },
  },
];

export default financingmanagementRoutes;
