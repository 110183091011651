var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    {
      staticClass: "navbar-container",
      class: { "navbar-container-homepage": !_vm.notHomePage },
    },
    [
      _vm.menuType === 0
        ? _c(
            "section",
            { staticClass: "navbar" },
            [_c("LeftNav"), _vm._v(" "), _c("TopNavbarRight")],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("section", [
        _c(
          "section",
          { staticClass: "navbar horizontalNavbar" },
          [
            !_vm.notHomePage
              ? _c("div", { staticClass: "home-page-nav" }, [
                  _c(
                    "div",
                    { staticClass: "nav-left-nav" },
                    [_c("LeftNav")],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "nav-right-menu" },
                    [_c("RightMenu")],
                    1
                  ),
                ])
              : [_c("LeftNav"), _vm._v(" "), _c("RightMenu")],
          ],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }