<template>
  <el-empty :description="description"></el-empty>
</template>

<script>
export default {
  name: 'Empty',
  props: {
    description: {
      type: String,
      default: '暂无数据',
    },
  },
};
</script>

<style lang="scss" scoped></style>
