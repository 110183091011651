// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Variables */
[data-v-2a9b4f39]:export {
  menuText: #000;
  menuActiveText: #fff;
  subMenuActiveText: #f4f4f5;
  menuActiveBg: #1589cd;
  menuBg: #fff;
  menuHover: rgba(45, 153, 255, 0.3);
  subMenuBg: #fff;
  subMenuHover: rgba(45, 153, 255, 0.3);
  sideBarWidth: 200px;
  primaryColor: #1589cd;
  primaryFontColor: rgb(45, 153, 255);
  primaryColor_8: rgba(31, 113, 255, 0.08);
  globalColor: #e60012;
  defaultFontColor: rgb(20, 35, 69);
  tableHeaderBg: #fafafa;
  tableHeaderHeight: 40px;
  tableAlign: center;
  fontSize_14: 14px;
  fontSize_16: 16px;
  fontSize_18: 18px;
  fontSize_24: 24px;
}

/* Mixins */
*[data-v-2a9b4f39],[data-v-2a9b4f39]:after,[data-v-2a9b4f39]:before {
  box-sizing: border-box;
}
.custom-steps[data-v-2a9b4f39] {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: "tnum";
  display: flex;
  width: 100%;
  font-size: 0;
}
.custom-steps-item[data-v-2a9b4f39] {
  position: relative;
  display: inline-block;
  flex: 1;
  overflow: hidden;
  vertical-align: top;
}
.custom-steps-item[data-v-2a9b4f39]:last-child {
  flex: unset;
}
.custom-steps-horizontal:not(.custom-steps-label-vertical) .custom-steps-item[data-v-2a9b4f39] {
  margin-right: 16px;
  white-space: nowrap;
}
.custom-steps-item-container[data-v-2a9b4f39] {
  outline: none;
  display: flex;
}
.custom-steps-item-tail[data-v-2a9b4f39] {
  position: absolute;
  top: 12px;
  left: 0;
  width: 100%;
  padding: 0 10px;
}
.custom-steps-horizontal:not(.custom-steps-label-vertical) .custom-steps-item-tail[data-v-2a9b4f39] {
  display: none;
}
.custom-steps-item-icon[data-v-2a9b4f39] {
  width: 32px;
  height: 32px;
  margin-right: 8px;
  font-size: 16px;
  line-height: 32px;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 32px;
  transition: background-color 0.3s, border-color 0.3s;
}
.custom-steps-item-content[data-v-2a9b4f39],
.custom-steps-item-icon[data-v-2a9b4f39] {
  display: inline-block;
  vertical-align: top;
}
.custom-steps-item-content[data-v-2a9b4f39],
.custom-steps-item-icon[data-v-2a9b4f39] {
  display: inline-block;
  vertical-align: top;
}
.custom-steps-horizontal:not(.custom-steps-label-vertical) .custom-steps-item-description[data-v-2a9b4f39] {
  max-width: 140px;
  white-space: normal;
}
.custom-steps-item-description[data-v-2a9b4f39],
.custom-steps-item-subtitle[data-v-2a9b4f39] {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.custom-steps-item-title[data-v-2a9b4f39] {
  position: relative;
  display: inline-block;
  padding-right: 16px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 32px;
}
.custom-steps-item-title[data-v-2a9b4f39]:after {
  position: absolute;
  top: 16px;
  left: 100%;
  display: block;
  width: 9999px;
  height: 1px;
  background: #e8e8e8;
  content: "";
}
.custom-steps-item:last-child .custom-steps-item-title[data-v-2a9b4f39]:after {
  display: none;
}
.custom-steps-item-process .custom-steps-item-icon[data-v-2a9b4f39] {
  background: #1589cd;
  border-color: #1589cd;
  color: #fff;
}
.custom-steps-item-process .custom-steps-item-title[data-v-2a9b4f39] {
  color: rgb(20, 35, 69);
  font-weight: bold;
}
.custom-steps-item-finish .custom-steps-item-icon[data-v-2a9b4f39] {
  background-color: #fff;
  border-color: #1589cd;
  color: #1589cd;
}
.custom-steps-item-finish > .custom-steps-item-container > .custom-steps-item-content > .custom-steps-item-description[data-v-2a9b4f39] {
  color: rgba(0, 0, 0, 0.45);
}
.custom-steps-item-finish > .custom-steps-item-container > .custom-steps-item-content > .custom-steps-item-title[data-v-2a9b4f39] {
  color: rgba(0, 0, 0, 0.65);
}
.custom-steps-item-finish > .custom-steps-item-container > .custom-steps-item-content > .custom-steps-item-title[data-v-2a9b4f39]:after {
  background-color: #1589cd;
}
.custom-steps-item-finish > .custom-steps-item-container > .custom-steps-item-content > .custom-steps-item-title[data-v-2a9b4f39] {
  color: rgb(20, 35, 69);
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
