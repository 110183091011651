import axios from 'axios';
const getConfig = () => {
  return new Promise((resolve) => {
    axios
      .get(`/config.json`, {
        params: {
          _t: new Date().getTime(),
        },
      })
      .then((res) => {
        resolve(res.data);
      });
  });
};

export async function isNewVersion() {
  const config = await getConfig();
  let newVersion = config.version;
  let oldVersion = localStorage.getItem("VERSION");
  console.log(newVersion, oldVersion, "VERSIONVERSIONVERSION");
  let isUpdated = oldVersion !== newVersion || !oldVersion;
  if (isUpdated) {
    // 如果version不一致，则清除本地基础数据
    localStorage.setItem("VERSION", newVersion);
    window.location.reload();
  }
  return isUpdated;
}
