<template>
  <div :class="classObj" class="app-wrapper">
    <div class="fixed-header">
      <TopNavbar />
      <Navbar />
    </div>
    <Sidebar v-if="menuType !== 1" class="sidebar-container" />
    <div class="main-container">
      <AppMain />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { AppMain, Navbar, Sidebar } from './components';
import settings from '@/defaultSettings';
import TopNavbar from './components/Navbar/TopNavbar/index.vue';

export default {
  name: 'Layout',
  components: {
    AppMain,
    Navbar,
    Sidebar,
    TopNavbar,
  },
  provide() {
    return {
      menuListType: 'productMenu',
    };
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['sidebar', 'Device']),

    menuType() {
      return settings.menuType;
    },

    classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.Device !== 'Desktop',
        moreHeader: settings.menuType === 1,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import './ProductLayout.scss';
</style>
