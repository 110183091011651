<template>
  <transition name="fade-transform" mode="out-in">
    <keep-alive>
      <router-view />
    </keep-alive>
  </transition>
</template>

<script>
export default {
  name: 'DefaultLayout',
  components: {},
  computed: {
    key() {
      return this.$route.path;
    },
  },
};
</script>
