var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { height: "0" } },
    [
      _vm.fileUrl || _vm.isScreenFull
        ? _c(
            "Dialog",
            {
              ref: "exampleDialog",
              attrs: {
                showFooter: false,
                appendToBody: true,
                destroyOnClose: true,
                title: _vm.fileUrl ? "预览" : "",
              },
              on: { event: _vm.eventChange },
            },
            [
              _c(
                "div",
                {
                  ref: "fullImgWrapper",
                  attrs: { slot: "dialogBody" },
                  slot: "dialogBody",
                },
                [
                  _vm.fileUrl && _vm.show
                    ? _c("PDFView", {
                        attrs: {
                          fileUrl: _vm.fileUrl,
                          isFile: _vm.isFile,
                          width: "952px",
                        },
                      })
                    : _c("img", {
                        staticStyle: { "margin-top": "25px" },
                        attrs: {
                          width: "100%",
                          src: _vm.src || _vm.idMap[_vm.type],
                          alt: "",
                        },
                        on: {
                          click: function ($event) {
                            _vm.isScreenFull && _vm.onScreenFull()
                          },
                        },
                      }),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("el-image", {
        ref: "previewImage",
        staticStyle: { width: "0px", height: "0px" },
        attrs: {
          src: _vm.src || _vm.idMap[_vm.type],
          "preview-src-list": [_vm.src || _vm.idMap[_vm.type]],
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }