
export default {
  props: {
    formData: { type: Object, default: null },
    formCols: { type: Array, default: () => [[]] },
    border: { type: Boolean, default: false },
    column: { type: Number, default: 3 },
    direction: { type: String, default: 'horizontal' }, //vertical / horizontal
    size: { type: String, default: null }, //medium / small / mini
    title: { type: String, default: null },
    extra: { type: String, default: null },
    colon: { type: Boolean, default: true }, //是否显示冒号
    labelClassName:{ type: String, default: null },
    contentClassName:{ type: String, default: null },
    labelStyle:{ type: Object, default: null },
    contentStyle:{ type: Object, default: null },
  },
};
