import { fetchAdminInfo, authCorpFace, submitAuthCorp, orgImgOcr, idCardOcr } from '@/api/BeforeAuthProcess/beforeAuthForm';
import { downloadFile } from '@/api/common';

const defaultState = () => {
  return {
    authResult: {}
  }
}

const mutations = {

  SET_AUTH_RESULT: (state, authResult) => {
    state.authResult = authResult;
  },

  RESET_STATE: (state) => {
    Object.assign(state, defaultState());
  },
}

const actions = {
  /**
   * @description 企业实名认证状态查询
   */
  async fetchAdminInfo() {
    const { data, rspCode } = await fetchAdminInfo();
    if (rspCode === '00000000') {
      return data
    }
  },
  /**
   * @description 企业实名认证-人脸认证
   */
  async authCorpFace({ commit }, params) {
    const res = await authCorpFace(params);
    if (res?.rspCode === '00000000') {
      commit('SET_AUTH_RESULT', res?.data)
      return res;
    }
  },
  /**
     * @description 企业实名认证-人脸认证
     */
  async submitAuthCorp(_, params) {
    const { data, rspCode } = await submitAuthCorp(params);
    if (rspCode === '00000000') {
      return data;
    }
  },

  /**
     * @description 营业执照-ocr识别
     */
  async orgImgOcr(_, params) {
    const { data, rspCode } = await orgImgOcr(params);
    if (rspCode === '00000000') {
      return data;
    }
  },

  /**
     * @description 身份证-ocr识别
     */
  async idCardOcr(_, params) {
    const { data, rspCode } = await idCardOcr(params);
    if (rspCode === '00000000') {
      return data;
    }
  },

  /**
       * @description 根据fileid获取文件
       */
  async downloadImgFile(_, params) {
    const res = await downloadFile(params);
    return res?.data;

  },

}

export default {
  namespaced: true,
  state: defaultState(),
  mutations,
  actions,
};