import { constantRoutes, asyncRoutes, projectAsyncRoutes } from '@/router';
import { concat } from 'lodash';
import auth from '@/utils/permission';
import { fetchResourceList } from '@/api/user';
import { filterAsyncRoutes } from '@/utils/utils';
import { getStorage } from '@/utils/storage';

// 动态路由遍历，验证是否具备权限
function filterDynamicRoutes(routes, btnList) {
  const res = [];
  routes.forEach((route) => {
    if (route?.meta?.authority) {
      const { authority, corpIdentity } = route.meta;
      if (
        auth.hasPermiOr(authority, btnList) &&
        auth.hasPermiOr(corpIdentity || ['FINANCIAL_ENTERPRISE', 'STORAGE_ENTERPRISE', 'DEALER_ENTERPRISE'], [
          getStorage('currentCorpIdentity'),
        ])
      ) {
        res.push(route);
      }
    }
  });
  return res;
}

/**
 * 把嵌套数组转成平铺数组
 * @param data <Array>
 * @param childName <String> 子集节点的名称
 * @return newData <Array>
 */
const flatTree = (data, childName = 'children') => {
  if (!Array.isArray(data)) {
    return [];
  }
  return data.reduce((prev, curt) => {
    // 有子节点的话把子节点作为一级节点递归遍历
    const childs = curt[childName]?.length ? flatTree(curt[childName]) : [];
    /* eslint-disable no-unused-vars */
    let { children, ...data } = curt;
    return [...prev, data, ...childs];
  }, []);
};

const defaultState = () => {
  return {
    routes: [],
    projectMenuTree: [],
    commonMenuTree: [],
    resouceCodes: [],
  };
};

const state = defaultState();

const mutations = {
  SET_ROUTES: (state, routes) => {
    state.routes = concat(constantRoutes, routes);
  },

  SET_COMMON_MENU_TREE: (state, menuTree) => {
    state.commonMenuTree = menuTree;
  },

  SET_PROJECT_MENU_TREE: (state, menuTree) => {
    state.projectMenuTree = menuTree;
  },

  SET_RESOURCE_CODES: (state, resouceCodes) => {
    state.resouceCodes = resouceCodes;
  },
};

const actions = {
  /**
   * 获取资源列表
   */
  async getResourceList({ commit }) {
    const res = await fetchResourceList();
    let route = [];
    const { resourceList, authList } = res?.data || {};
    if (res.rspCode === '00000000' && resourceList?.length > 0) {
      let btnList = [];
      authList != null &&
        authList.forEach((item) => {
          return btnList.push(item.perms);
        });
      const list = filterAsyncRoutes(resourceList, {});
      list.push({ path: '*', redirect: '/404', hidden: true });
      route = flatTree(list).concat(filterDynamicRoutes(asyncRoutes, btnList));

      commit('SET_COMMON_MENU_TREE', list);
      commit('SET_ROUTES', route);
      commit('SET_RESOURCE_CODES', btnList);
    }
    return { accessRoutes: route, resourceList, rspCode: res.rspCode };
  },

  /**
   * 获取项目资源菜单
   */
  async getProjectResourceList({ commit }) {
    const res = await fetchResourceList();
    let route = [];
    const { resourceList, authList } = res?.data || {};
    if (res.rspCode === '00000000' && resourceList?.length > 0) {
      let btnList = [];
      authList != null &&
        authList.forEach((item) => {
          return btnList.push(item.perms);
        });
      const list = filterAsyncRoutes(resourceList);
      list.push({ path: '*', redirect: '/404', hidden: true });
      route = flatTree(list).concat(filterDynamicRoutes(projectAsyncRoutes, btnList));
      commit('SET_PROJECT_MENU_TREE', list);
      commit('SET_ROUTES', route);
      commit('SET_RESOURCE_CODES', btnList);
    }
    return { accessRoutes: route, resourceList, rspCode: res.rspCode };
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
