import Vue from 'vue';
import VueRouter from 'vue-router';
import homeRoutes from './modules/homeRoutes';
import beforeAuth from './modules/beforeAuthRoutes';
import messageCenterRoutes from './modules/messageCenterRoutes';
import workbenchRoutes from './modules/workBenchRoutes';
// import financingmanagementRoutes from './modules/financingmanagementRoutes';
// import warehousemanagementRoutes from './modules/warehousemanagementRoutes';
import storageRoutes from './modules/storage/index';
import warehouseReceiptRoutes from './modules/warehouseReceipt/index';
import agencyRoutes from './modules/agency/index';
// https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject);
  } else {
    return originalPush.call(
      this,
      location,
      () => {},
      () => {}
    );
  }
};

Vue.use(VueRouter);

/*
  name:'router-name'             the name field is required when using <keep-alive>, it should also match its component's name property
                                 detail see : https://vuejs.org/v2/guide/components-dynamic-async.html#keep-alive-with-Dynamic-Components
  redirect:                      if set to 'noredirect', no redirect action will be trigger when clicking the breadcrumb
  meta: {
    authority                    权限字段
    title: 'title'               路由显示名称
    icon: 'svg-name'             菜单icon
    hidden: true                 是否显示在菜单栏上 (default is false)
    alwaysShow: true             是否总是显示根路由在菜单上 (default is false)，false时，当根菜单只有一个子菜单时直接显示子菜单
    breadcrumb: false            是否在面包屑菜单上显示当前路由 (default is true)
    noCache: true                if true, the page will not be cached (default is false)
    affix: true                  if true, the tag will affix in the tags-view
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
    parentMenu: '/example'       二级页面所对应的菜单path
    authority: 'MY-ADMIN'        自定义权限测试使用
    hasChilds: true,             添加在根菜单之外的（从第二级菜单开始）其他菜单meta中，表示当前菜单是否有需要显示在子集菜单的路由元素， 默认为false或者不添加.
    class: String                为当前菜单添加class， 第一级和第二级菜单类名分别设置，第三极包括以后的子菜单，类名为同一个 menu-level-first menu-level-second menu-level-other
  }
*/

export const constantRoutes = [
  {
    path: '/',
    redirect: '/dashboard',
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard/index.vue'),
    meta: {
      title: '概览页',
      icon: 'dashboard',
      layout: 'Layout',
      class: 'menu-level-first',
    },
  },
  {
    path: '/redirect',
    meta: { hidden: true, layout: 'Layout' },
    component: () => import(/* webpackChunkName: "redirect" */ '@/views/Redirect/index.vue'),
  },
  {
    path: '/updateNewUserPassword',
    name: 'UpdateNewUserPassword',
    component: () => import(/* webpackChunkName: "updateNewUserPassword" */ '@/views/UpdateNewUserPassword/index.vue'),
    meta: {
      hidden: true,
      layout: 'Layout',
    },
  },
  ...homeRoutes,
  ...beforeAuth,
  ...messageCenterRoutes,
  ...workbenchRoutes,
  {
    path: '/404',
    component: () => import(/* webpackChunkName: "404page" */ '@/views/ErrorPage/404.vue'),
    meta: {
      hidden: true,
      layout: 'Layout',
    },
  },
  {
    path: '/403',
    component: () => import(/* webpackChunkName: "404page" */ '@/views/ErrorPage/403.vue'),
    meta: {
      hidden: true,
      layout: 'Layout',
    },
  },
  {
    path: '/500',
    component: () => import(/* webpackChunkName: "500page" */ '@/views/ErrorPage/500.vue'),
    meta: {
      hidden: true,
      layout: 'Layout',
    },
  },
];

// 首页路由
export const asyncRoutes = [];

// 项目路由
export const projectAsyncRoutes = [
  ...asyncRoutes,
  // ...orderManagementRoutes,
  // ...financingmanagementRoutes,
  // ...warehousemanagementRoutes,
  // 代理采购
  ...agencyRoutes.orderManagementRoutes,
  ...agencyRoutes.financingManagementRoutes,
  ...agencyRoutes.warehouseManagementRoutes,
  ...agencyRoutes.riskMonitoringRoutes,
  ...agencyRoutes.basicInfoManagementRoutes,

  //仓储
  ...storageRoutes.commodityManagementRoutes,
  ...storageRoutes.inboundManagementRoutes,
  ...storageRoutes.warehouseManagementRoutes,

  // 电子仓单
  ...warehouseReceiptRoutes.financingManagement,
  ...warehouseReceiptRoutes.warehouseReceiptManagement,
  ...warehouseReceiptRoutes.billingManagement
];

const createRouter = () =>
  new VueRouter({
    scrollBehavior: (to, from, savedPosition) => {
      if (savedPosition) {
        return savedPosition;
      } else {
        return { x: 0, y: 0 };
      }
    },
    mode: 'history',
    base: process.env.BASE_URL,
    routes: constantRoutes,
  });

const router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

export default router;
