
export default {
  props: {
    //是否可拖拽排序
    canDraggable: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    needToast: { type: Boolean, default: false },
    labelPosition: { type: String, default: 'left' },
    formData: { type: Object, default: null },
    rules: { type: Object, default: null },
    formCols: { type: Array, default: () => [[]] },
    // 需要手动隐藏和显示的form item的prop标识(例如：搜索栏展开收起所展示的内容prop标识)
    moreFilterProp: { type: Array, default: () => [] },
    isInline: { type: Boolean, default: false },
    statusIcon: { type: Boolean, default: false },
    size: { type: String, default: 'medium' },
    labelSuffix: { type: String, default: '' },
    labelWidth: { type: String },
    formRowGutter: { type: Number, default: 60, required: false },
    formVerticalRowGutter: { type: Number, default: 30, required: false },
    slotLabel: { type: String, default: '' }, // 是否自定义label
    alertCardStyle: { type: String, default: '' }, // 企业认证状态
    /*
    调用zxUpload组件时，传入上传文件的类型，便于格式校验。
    type：image(图片) / zip (zip压缩包) / document (文本doc docx pdf) / jarPackage (jar包)
    */
    uploadFileType: { type: String, default: 'image' },
    dividerTitle: {
      type: String,
      default: '',
      required: false,
    },
    formLineButtons: {
      type: Array,
      default: () => [
        { label: '查询', value: '查询', prop: 'search', type: 'primary', icon:"el-icon-search"},
        { label: '重置', value: '重置', prop: 'reset', type: 'default',icon:"el-icon-refresh-left" },
      ],
      require: false,
    },
    //loading
    loading: { type: Boolean, default: false },
    // 是否显示form表单右侧的搜索等按钮
    showFormLineButtons: { type: Boolean, default: true },
    // 搜索和重置等按钮的formFilter权限
    formLineBtnAuth: { type: String, default: '' },
  },
  // methods: {
  //   formLineButtons() {
  //     return store.getters.resouceCodes.includes(this.formLineBtnAuth) || this.formLineBtnAuth === 'no-authority'
  //       ? [
  //         { label: '搜索', value: '搜索', prop: 'search', type: 'primary' },
  //         { label: '重置', value: '重置', prop: 'reset', type: 'default' },
  //       ]
  //       : [];
  //   },
  // },
};
