/** * create by Songsong_Yu on 2021-12-14 18:18 * 类注释： * 备注： */
<template>
  <div>
    <!-- 多选有 1 个 -->
    <template v-if="item.option && Object.keys(item.option).length !== 0">
      <el-checkbox
        v-model="formData[item.prop]"
        :disabled="item.option.disabled"
        :style="item.option.style"
        @change="change"
      >
        <div v-html="item.option.label" v-if="item.option.type === 'html'" @click.stop.prevent="checkLabelClick"></div>
        <span v-else @click.stop.prevent="checkLabelClick">{{
          item.option.label ? item.option.label : item.option
        }}</span>
      </el-checkbox>
    </template>
    <!-- 多选有>= 1 个 -->
    <el-checkbox-group v-model="formData[item.prop]" :min="item.min" :max="item.max" v-else>
      <el-checkbox
        v-for="option in options"
        :key="option.value ? option.value : option"
        :label="option.value ? option.value : option"
        :disabled="item.disabled || option.disabled"
        :style="item.style || option.style"
        @change="change"
      >
        <div v-html="option.label" v-if="option.type === 'html'" @click.stop.prevent="checkLabelClick"></div>
        <span v-else @click.stop.prevent="checkLabelClick">{{ option.label ? option.label : option }}</span>
      </el-checkbox>
    </el-checkbox-group>
  </div>
</template>

<script type="text/ecmascript-6">
import mixins from '../mixins'

export default {
  components: {},
  mixins: [mixins],
  props: {},
  data() {
    return {}
  },
  watch: {},
  computed: {
    options() {
      if (this.item.options instanceof Array) {
        return this.item.options
      } else {
        let list = this.item.options.split(',')
        return list.map(item => {
          return {value: item, label: item}
        })
      }
    },
  },
  methods: {
    change() {
      this.mixinEvent({
        type: 'change',
        prop: this.item.prop,
        value: this.formData[this.item.prop]
      })
    },

    checkLabelClick() {
       this.mixinEvent({
        type: 'checkLabelClick',
        prop: this.item.prop,
        value: this.formData[this.item.prop]
      })
    },
  },
  activated() {
  },
  mounted() {
  },
  created() {
    if (!this.item.option && !this.formData[this.item.prop]) {
      this.$set(this.formData, this.item.prop, [])
    }
  }
}
</script>

<style scoped lang="scss"></style>
