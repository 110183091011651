<template>
  <div class="left-menu" :class="{ 'left-menu-homepage': !notHomePage }">
    <transition name="sidebarLogoFade" v-if="notHomePage">
      <div class="sidebar-logo-link">
        <img class="sidebar-logo" src="@/assets/image/company_logo.png" />
        <section class="sidebar-title-container" v-if="menuType === 2">
          <div>
            <p :class="['container-title', disabled ? '' : 'clickable']">
              <span>{{ corpName }}</span>
            </p>
            <div class="comment-right" v-if="loginInfo?.sourceType === '1'">
              <p :class="['container-comment', currClass]">{{ currCorpIdentityName }}</p>
            </div>
          </div>
          <div>
            <!-- v-if="showChange" -->
            <span v-if="showChange" @click="changeCorp" class="corp-change">
              <img class="change" src="@/assets/dashboard/icon_qiye_qiehuan.png" alt=""
            /></span>
          </div>
        </section>
        <h1 class="sidebar-title" v-else>
          {{ corpName }}
        </h1>
      </div>
    </transition>
    <div class="sidebar-logo-link" v-else>
      <img class="sidebar-logo" src="@/assets/logo.png" />
      <div class="dvider-line"></div>
      <div class="logo-name">{{ title }}</div>
    </div>
    <IdentityDialog ref="identityDialogRef" :afterConfirm="afterConfirm" />
  </div>
</template>

<script>
import { getStorage } from '@/utils/storage';
import { mapActions, mapMutations } from 'vuex';
import { getUserInfo } from '@/utils/auth';
import settings from '@/defaultSettings';
import IdentityDialog from '@/components/IdentityDialog/index.vue';
import { fetchDictItem } from '@/api/common';
import { selectDictLabel, debounce } from '@/utils/utils';
export default {
  name: 'LeftNav',
  inject: ['reload'],
  components: {
    IdentityDialog,
  },
  data() {
    return {
      loginInfo: getUserInfo(),
      dictList: [],
      // currentCorpIdentity: getStorage('currentCorpIdentity'),
    };
  },
  mounted() {
    this.getDictItem();
  },
  computed: {
    menuType() {
      return settings.menuType;
    },
    title() {
      return settings.title;
    },
    corpName() {
      return this.loginInfo?.corpName;
    },
    currCorpIdentityName() {
      const users = selectDictLabel(this.dictList, getStorage('currentCorpIdentity'));
      return users || '暂无数据';
    },
    notHomePage() {
      return this.$router.currentRoute?.meta?.routeType !== 'HomeRoute';
    },
    currClass() {
      return this.loginInfo?.corpIdentities[0] + '_class';
    },
    disabled() {
      const { identityFlag, approvalStatus, pwdForceChange } = this.loginInfo;
      if (pwdForceChange === 'Y' || approvalStatus === 2 || identityFlag === 'N') {
        return true;
      }
      return false;
    },
    showChange() {
      const { identityFlag, approvalStatus, corpIdentities } = this.loginInfo || {};
      if (approvalStatus === 2 && identityFlag === 'Y' && corpIdentities?.length > 1) {
        return true;
      }
      return false;
    },
  },
  watch: {
    currCorpIdentityName: {
      immediate: true,
      handler(val) {
        if (val !== '暂无数据') {
          this.SET_CURRCORPIDENTITY_NAME(val);
        }
      },
    },
  },
  methods: {
    ...mapActions('user', ['getLoginInfo', 'getAdminInfo']),
    ...mapActions('common', ['getIdentity']),
    ...mapMutations('common', ['SET_CURRCORPIDENTITY_NAME']),
    // 调用字典接口 防抖
    getDictItem: debounce(async function () {
      if (!this.loginInfo?.corpId) {
        return;
      }
      const res = await fetchDictItem('corp_identity');
      this.dictList = res?.data || [];
      this.getIdentity(res?.data || []);
    }, 200),

    async changeCorp() {
      let res = await this.getAdminInfo();
      const { rspCode, data } = res;
      if (rspCode === '00000000') {
        let { userIdentity } = data;
        if (userIdentity?.length > 1) {
          setTimeout(() => {
            this.$refs.identityDialogRef && this.$refs.identityDialogRef.open();
          }, 200);
        }
      }
    },

    // handleClick() {
    //   const { approvalStatus, identityFlag } = this.loginInfo;
    //   if (approvalStatus === 2 || identityFlag === 'Y') {
    //     this.$router.push('/');
    //   }
    // },

    async afterConfirm() {
      const res = await this.getLoginInfo();
      if (res?.rspCode === '00000000') {
        this.$router.push('/dashboard');
        this.reload();
        // history.replaceState(null, null, location.origin + '/dashboard');
        // window.location.reload();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>
