// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../assets/move/icon_light.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Variables */
:export {
  menuText: #000;
  menuActiveText: #fff;
  subMenuActiveText: #f4f4f5;
  menuActiveBg: #1589cd;
  menuBg: #fff;
  menuHover: rgba(45, 153, 255, 0.3);
  subMenuBg: #fff;
  subMenuHover: rgba(45, 153, 255, 0.3);
  sideBarWidth: 200px;
  primaryColor: #1589cd;
  primaryFontColor: rgb(45, 153, 255);
  primaryColor_8: rgba(31, 113, 255, 0.08);
  globalColor: #e60012;
  defaultFontColor: rgb(20, 35, 69);
  tableHeaderBg: #fafafa;
  tableHeaderHeight: 40px;
  tableAlign: center;
  fontSize_14: 14px;
  fontSize_16: 16px;
  fontSize_18: 18px;
  fontSize_24: 24px;
}

/* Mixins */
.sliderVerifyCaptcha .block {
  position: absolute;
  left: 0;
  top: 0;
}
.sliderVerifyCaptcha .sliderPanel {
  background-color: #ffffff;
  width: 280px;
  height: 180px;
  overflow: hidden;
}
.sliderVerifyCaptcha:hover .sliderPanel {
  opacity: 1;
  visibility: visible;
}
.sliderVerifyCaptcha .sliderContainer {
  position: relative;
  text-align: center;
  width: 280px;
  height: 40px;
  line-height: 40px;
  background: #f7f9fa;
  color: #45494c;
  border: 1px solid #e4e7eb;
}
.sliderVerifyCaptcha .sliderContainer_active .slider {
  height: 38px;
  top: -1px;
  border: 1px solid #1589cd;
}
.sliderVerifyCaptcha .sliderContainer_active .sliderMask {
  height: 38px;
  border-width: 1px;
}
.sliderVerifyCaptcha .sliderContainer_success .slider {
  height: 38px;
  top: -1px;
  border: 1px solid #52ccba;
  background-color: #52ccba !important;
}
.sliderVerifyCaptcha .sliderContainer_success .sliderMask {
  height: 38px;
  border: 1px solid #52ccba;
  background-color: #d2f4ef;
}
.sliderVerifyCaptcha .sliderContainer_success .sliderIcon {
  background-position: 0 0 !important;
}
.sliderVerifyCaptcha .sliderContainer_fail .slider {
  height: 38px;
  top: -1px;
  border: 1px solid #f57a7a;
  background-color: #f57a7a !important;
}
.sliderVerifyCaptcha .sliderContainer_fail .sliderMask {
  height: 38px;
  border: 1px solid #f57a7a;
  background-color: #fce1e1;
}
.sliderVerifyCaptcha .sliderContainer_fail .sliderIcon {
  background-position: 0 -83px !important;
}
.sliderVerifyCaptcha .sliderContainer_active .sliderText,
.sliderVerifyCaptcha .sliderContainer_success .sliderText,
.sliderVerifyCaptcha .sliderContainer_fail .sliderText {
  display: none;
}
.sliderVerifyCaptcha .sliderMask {
  position: absolute;
  left: 0;
  top: 0;
  height: 40px;
  border: 0 solid #1589cd;
  background: #d1e9fe;
}
.sliderVerifyCaptcha .slider {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  background: #fff;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  transition: background 0.2s linear;
}
.sliderVerifyCaptcha .slider:hover {
  background: #1589cd;
}
.sliderVerifyCaptcha .slider:hover .sliderIcon {
  background-position: 0 -13px;
}
.sliderVerifyCaptcha .sliderIcon {
  position: absolute;
  top: 15px;
  left: 13px;
  width: 14px;
  height: 10px;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) 0 -26px;
  background-size: 34px 471px;
}
.sliderVerifyCaptcha .refreshIcon {
  position: absolute;
  right: 0;
  top: 0;
  width: 34px;
  height: 34px;
  cursor: pointer;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) 0 -437px;
  background-size: 34px 471px;
  transition: transform 1s linear;
  -webkit-transition: transform 1s linear;
  -moz-transition: transform 1s linear;
  -ms-transition: transform 1s linear;
  -o-transition: transform 1s linear;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
