// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Variables */
[data-v-e1ad8054]:export {
  menuText: #000;
  menuActiveText: #fff;
  subMenuActiveText: #f4f4f5;
  menuActiveBg: #1589cd;
  menuBg: #fff;
  menuHover: rgba(45, 153, 255, 0.3);
  subMenuBg: #fff;
  subMenuHover: rgba(45, 153, 255, 0.3);
  sideBarWidth: 200px;
  primaryColor: #1589cd;
  primaryFontColor: rgb(45, 153, 255);
  primaryColor_8: rgba(31, 113, 255, 0.08);
  globalColor: #e60012;
  defaultFontColor: rgb(20, 35, 69);
  tableHeaderBg: #fafafa;
  tableHeaderHeight: 40px;
  tableAlign: center;
  fontSize_14: 14px;
  fontSize_16: 16px;
  fontSize_18: 18px;
  fontSize_24: 24px;
}

/* Mixins */
.top-navbar-constainer[data-v-e1ad8054] {
  width: 100%;
  height: 52px;
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 14px;
  background-color: #142041;
}
.top-navbar-constainer .top-navbar-left[data-v-e1ad8054] {
  flex: 2;
  padding-left: 27px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.top-navbar-constainer .top-navbar-left .logo[data-v-e1ad8054] {
  width: 32px;
  height: auto;
}
.top-navbar-constainer .top-navbar-left span[data-v-e1ad8054] {
  margin-left: 4px;
  font-size: 18px;
  font-weight: bolder;
}
.top-navbar-constainer .top-navbar-right[data-v-e1ad8054] {
  flex: 4;
}
.top-navbar-constainer-homepage[data-v-e1ad8054] {
  width: 100%;
  margin: 0 auto;
}
.top-navbar-constainer-homepage .top-navbar-left[data-v-e1ad8054] {
  padding-left: 27px;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
