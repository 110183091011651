var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    {
      staticClass: "header-right-menu",
      class: {
        "header-right-menu-homepage": !_vm.notHomePage,
        "header-right-menu-dashboard": _vm.notHomePage,
      },
    },
    [
      _c(
        "el-menu",
        {
          key: `menu${_vm.count}`,
          attrs: {
            "default-active": _vm.activeMenu,
            "unique-opened": true,
            "collapse-transition": false,
            mode: "horizontal",
          },
          on: { select: _vm.handleSelect },
        },
        [
          _c("MenuItem", {
            attrs: { navMenus: _vm.menuData, showPopClass: _vm.showPopClass },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }