<template>
  <section class="navbar-container" :class="{ 'navbar-container-homepage': !notHomePage }">
    <!-- 运营端 -->
    <section class="navbar" v-if="menuType === 0">
      <LeftNav />
      <TopNavbarRight />
    </section>

    <!-- 门户系统 -->
    <section>
      <section class="navbar horizontalNavbar">
        <div class="home-page-nav" v-if="!notHomePage">
          <div class="nav-left-nav"><LeftNav /></div>
          <div class="nav-right-menu"><RightMenu /></div>
        </div>
        <template v-else>
          <LeftNav />
          <RightMenu />
        </template>
      </section>
    </section>
  </section>
</template>

<script>
import settings from '@/defaultSettings';
import LeftNav from './LeftNav';
import RightMenu from './RightMenu';
export default {
  name: 'Navbar',
  components: {
    LeftNav,
    RightMenu,
  },
  data() {
    return {};
  },
  computed: {
    menuType() {
      return settings.menuType;
    },
    notHomePage() {
      return this.$router.currentRoute?.meta?.routeType !== 'HomeRoute';
    },
  },
};
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>
