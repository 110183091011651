import {
  getHomeBannerList,
  // getPartnerList,
  getProServiceList,
  getNewsList,
  getQuestionList,
  getNewsDetail,
} from '@/api/home';
// import { formatArray } from '@/utils/utils';
import { default as moment } from 'dayjs';
import defaultImgUrl from '@/assets/home/banner01.png';

const state = {
  bannerList: [],
  partnerList: [],
  proServiceList: [],
  newsList: [],
  newsListObj: {},
  questionList: [],
  newsDetail: {},
};

const mutations = {
  SET_BANNER_LIST: (state, data) => {
    if (data && data.length > 0) {
      state.bannerList = data;
    } else {
      state.bannerList = [
        {
          defaultImgUrl: defaultImgUrl,
        },
      ];
    }
  },
  SET_PARTNER_LIST: (state, data) => {
    state.partnerList = data;
  },
  SET_PRO_SERVICE_LIST: (state, data) => {
    state.proServiceList = data;
  },
  SET_NEWS_LIST: (state, data) => {
    state.newsList = data.map((item) => {
      return {
        ...item,
        createDate: moment(item.publishDate).format('YYYY.MM.DD'),
      };
    });
  },
  SET_NEWS_LIST_PAGE: (state, data) => {
    state.newsListObj = data;
  },
  SET_NEWS_RESRET_PAGE: (state) => {
    state.newsList = [];
    state.newsListObj = {};
  },
  SET_NEWS_DETAIL: (state, data) => {
    const { id, newsTitle } = data?.nextNews || ''
    const { id: preId, newsTitle: preNewsTitle } = data?.prevNews || '';
    state.newsDetail = {
      ...data.newsDetail,
      nextId: id,
      nextNewsTitle: newsTitle,
      preNewsTitle,
      preId
    };
  },
  SET_QUESTION_LIST: (state, data) => {
    state.questionList = data;
  },
  CLEAR_NEWS_DETAIL: (state) => {
    state.newsDetail = {};
  },
};
const actions = {
  /**
   * @description 获取首页banner
   */
  async GetHomeBannerList({ commit }, callback) {
    const { data } = await getHomeBannerList();
    commit('SET_BANNER_LIST', data);
    callback && callback();
  },
  /**
   * @description 获取首页合作伙伴
   */
  // async GetPartnerList({ commit }, callback) {
  //   const { data } = await getPartnerList();
  //   commit('SET_PARTNER_LIST', formatArray(data, 5));
  //   callback && callback();
  // },
  /**
   * @description 获取产业金融列表
   */
  async GetProServiceList({ commit }, callback) {
    const { data } = await getProServiceList();
    commit('SET_PRO_SERVICE_LIST', data);
    callback && callback();
  },
  /**
   * @description 获取平台动态列表
   */
  async GetNewsList({ commit }, params) {
    const res = await getNewsList(params);
    if (res?.rspCode === '00000000') {
      const { data } = res;
      commit('SET_NEWS_LIST', [...state.newsList, ...data.records]);
      commit('SET_NEWS_LIST_PAGE', data)
    }

    params?.callback();
  },
  /**
   * @description 获取文章详情
   */
  async GetNewsDetail({ commit }, params) {
    commit('CLEAR_NEWS_DETAIL');
    const { callback, ...rest } = params;
    const res = await getNewsDetail(rest);
    if (res?.rspCode === '00000000') {
      commit('SET_NEWS_DETAIL', res?.data);
    }
    callback && callback();
  },
  /**
   * @description 获取常见问题列表
   */
  async GetQuestionList({ commit }, callback) {
    const { data } = await getQuestionList();
    commit('SET_QUESTION_LIST', data);
    callback && callback();
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
