<template>
  <el-descriptions
    class="descriptions"
    :title="title"
    :extra="extra"
    :column="column"
    :border="border"
    :direction="direction"
    :size="size"
    :colon="colon"
    :labelClassName="labelClassName"
    :contentClassName="contentClassName"
    :labelStyle="labelStyle"
    :contentStyle="contentStyle"
  >
    <template v-for="item in formCols">
      <el-descriptions-item v-if="!item.noShow" :key="item.prop" v-bind="item">
        <slot v-if="item.eType === 'slot'" :name="item.slotName" :data="{ formData }"></slot>
        <span
          v-else-if="item.type === 'img' && formData[item.prop]"
          class="imageCode"
          @click="getImage(formData[item.prop])"
        >
          查看
        </span>
        <span v-else-if="item.stateList" :title="selectDictLabel(item.stateList, formData[item.prop])">{{
          selectDictLabel(item.stateList, formData[item.prop])
        }}</span>
        <span v-else>
          {{ item.type === 'amount' ? numberFormatAmt(formData[item.prop]) : '' }}
          {{ item.type === 'count' ? numberFormatCnt(formData[item.prop]) : '' }}
          {{ item.type === 'rate' ? numberFormatRate(formData[item.prop]) : '' }}
          {{ !item.type ? (item.formatter && item.formatter(formData)) || formData[item.prop] : '' }}
        </span>
      </el-descriptions-item>
    </template>
  </el-descriptions>
</template>
<script type="text/ecmascript-6">
import customDsecriptionsMixin from '@/mixins/customDsecriptionsMixin';
export default {
  name: 'zxTable',
  mixins: [customDsecriptionsMixin],
  methods: {
    getImage(id) {
      this.$emit('event', { id });
    },
  },
};
</script>
<style lang="scss" scoped>
@import './index.scss';
</style>
