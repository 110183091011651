var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "left-menu",
      class: { "left-menu-homepage": !_vm.notHomePage },
    },
    [
      _vm.notHomePage
        ? _c("transition", { attrs: { name: "sidebarLogoFade" } }, [
            _c("div", { staticClass: "sidebar-logo-link" }, [
              _c("img", {
                staticClass: "sidebar-logo",
                attrs: { src: require("@/assets/image/company_logo.png") },
              }),
              _vm._v(" "),
              _vm.menuType === 2
                ? _c("section", { staticClass: "sidebar-title-container" }, [
                    _c("div", [
                      _c(
                        "p",
                        {
                          class: [
                            "container-title",
                            _vm.disabled ? "" : "clickable",
                          ],
                        },
                        [_c("span", [_vm._v(_vm._s(_vm.corpName))])]
                      ),
                      _vm._v(" "),
                      _vm.loginInfo?.sourceType === "1"
                        ? _c("div", { staticClass: "comment-right" }, [
                            _c(
                              "p",
                              { class: ["container-comment", _vm.currClass] },
                              [_vm._v(_vm._s(_vm.currCorpIdentityName))]
                            ),
                          ])
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _vm.showChange
                        ? _c(
                            "span",
                            {
                              staticClass: "corp-change",
                              on: { click: _vm.changeCorp },
                            },
                            [
                              _c("img", {
                                staticClass: "change",
                                attrs: {
                                  src: require("@/assets/dashboard/icon_qiye_qiehuan.png"),
                                  alt: "",
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                    ]),
                  ])
                : _c("h1", { staticClass: "sidebar-title" }, [
                    _vm._v("\n        " + _vm._s(_vm.corpName) + "\n      "),
                  ]),
            ]),
          ])
        : _c("div", { staticClass: "sidebar-logo-link" }, [
            _c("img", {
              staticClass: "sidebar-logo",
              attrs: { src: require("@/assets/logo.png") },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "dvider-line" }),
            _vm._v(" "),
            _c("div", { staticClass: "logo-name" }, [
              _vm._v(_vm._s(_vm.title)),
            ]),
          ]),
      _vm._v(" "),
      _c("IdentityDialog", {
        ref: "identityDialogRef",
        attrs: { afterConfirm: _vm.afterConfirm },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }