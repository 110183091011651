import { getSidebarStatus, getSize, setSidebarStatus, setSize } from '@/utils/cookie';

const defaultState = () => {
  return {
    device: 'app',
    Device: 'Desktop', // Desktop | Mobile
    sidebar: {
      opened: getSidebarStatus() !== 'closed',
      withoutAnimation: true,
    },
    size: getSize() || 'medium',
    menuIndex: '0', // 顶部导航栏点击的索引，对应左侧数据
    scrollHeight: 0,
    offsetHeight: 0,
    scrollTop: 0,
  };
};
const state = defaultState();

const mutations = {
  TOGGLE_SIDEBAR(state, withoutAnimation) {
    state.sidebar.opened = !state.sidebar.opened;
    state.sidebar.withoutAnimation = withoutAnimation;
    if (state.sidebar.opened) {
      setSidebarStatus('opened');
    } else {
      setSidebarStatus('closed');
    }
  },

  CLOSE_SIDEBAR(state, withoutAnimation) {
    state.sidebar.opened = false;
    state.sidebar.withoutAnimation = withoutAnimation;
    setSidebarStatus('closed');
  },

  TOGGLE_DEVICE(state, device) {
    state.device = device;
  },

  SET_SIZE(state, size) {
    state.size = size;
    setSize(state.size);
  },

  SET_MENUINDEX(state, menuIndex) {
    state.menuIndex = menuIndex;
  },
  SET_DYNAMICSCROLL(state, obj) {
    state.scrollHeight = obj.scrollHeight;
    state.offsetHeight = obj.offsetHeight;
    state.scrollTop = obj.scrollTop;
  },

  RESET_STATE: (state) => {
    Object.assign(state, defaultState());
  },
};
const actions = {
  ToggleSideBar({ commit }, withoutAnimation) {
    commit('TOGGLE_SIDEBAR', withoutAnimation);
  },

  CloseSideBar({ commit }, withoutAnimation) {
    commit('CLOSE_SIDEBAR', withoutAnimation);
  },

  ToggleDevice({ commit }, device) {
    commit('TOGGLE_DEVICE', device);
  },

  SetSize({ commit }, size) {
    commit('SET_SIZE', size);
  },

  SetMenuIndex({ commit }, menuIndex) {
    commit('SET_MENUINDEX', menuIndex);
  },
  SetDynamicScroll({ commit }, obj) {
    commit('SET_DYNAMICSCROLL', obj);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
