var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(`zx${_vm.item.eType}`, {
    tag: "component",
    attrs: { item: _vm.item, "form-data": _vm.formData },
    on: { event: _vm.event, selectEvent: _vm.selectEvent },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }