/** * create by Songsong_Yu on 2021-12-14 17:56 * 类注释： * 备注： */
<template>
  <el-timeline :style="item.style">
    <el-timeline-item
      v-for="(itemOption, index) in item.option || []"
      :key="index"
      type="primary"
      color="'#009b4d'"
      size="large"
    >
      <span>{{ itemOption.endTime && getEndTime(itemOption.endTime) }}</span>
      <span style="margin-left: 10px">{{ itemOption.taskName }}</span>
      <span style="margin-left: 10px">{{ getUserName(itemOption) }}</span>
      <span style="margin-left: 10px">{{ getStatus(itemOption, item) }}</span>
      <span style="margin-left: 10px">{{ getDesc(itemOption) }}</span>
    </el-timeline-item>
  </el-timeline>
</template>

<script type="text/ecmascript-6">
import mixins from '../mixins';

export default {
  components: {},
  mixins: [mixins],
  props: {},
  data() {
    const loanApprovalStatusMap = {
      2: '通过',
      7: '通过',
      9: '通过',
      11: '通过',
      13: '通过',
      120: '通过',
      130: '通过',
      5: '退回',
      4: '退回',
      ADD_CONTRACT_PROCESS: {
        530: '通过',
        560: '拒绝',
      },
      ADD_CONTRACT_SIGN_PROCESS: {
        210: '签约', // 企业端-协议签约手动签约R
        310: '签约', // 企业端-协议签约手动签约H
        410: '签约', // 企业端-协议签约手动签约J
        220: '拒绝签约', // 企业端-协议签约手动拒绝签约R
        320: '拒绝签约', // 企业端-协议签约手动拒绝签约H
        420: '拒绝签约', // 企业端-协议签约手动拒绝签约J
        230: '通过', // 企业端-协议签约审核通过R
        330: '通过', // 企业端-协议签约审核通过H
        430: '通过', // 企业端-协议签约审核通过J
        240: '拒绝', // 企业端-协议签约审核拒绝R/账户管理流程拒绝
        340: '拒绝', // 企业端-协议签约审核拒绝H
        440: '拒绝', // 企业端-协议签约审核拒绝J/账户管理流程拒绝
        // 运营端
        560: '失败', // 自动签约失败
        120: '通过', // 新增协议签约审批通过  ok
        130: '退回', // 新增协议签约审批退回  ok
        140: '提交', // 新增协议签约退回-提交  ok
        150: '作废', // 新增协议签约退回--作废  ok
        510: '签约', // 银行方-协议签约手动签约
        520: '拒绝签约', // 银行方-协议签约手动拒绝签约
        530: '通过', // 银行方-协议签约审批通过
        540: '拒绝', // 银行方-协议签约审批拒绝
      },

      // 新增链属企业审批
      ADD_CHAIN_CORP_PROCESS: {
        110: '提交',
        130: '通过',
        150: '拒绝',
      },

      // 修改产业链白名单审批
      MAINTAIN_CHAIN_CORP_PROCESS: {
        210: '提交',
        230: '通过',
        250: '拒绝',
      },
      //到货确认
      ARRIVAL_CONFIRM_PROCESS: {
        120: '提交',
        130: '通过',
        140: '退回',
        210: '提交',
        220: '提交',
        230: '通过',
        240: '退回',
        310: '通过',
      },

      // 提货申请审批
      PICKUP_PROCESS: {
        130: '通过',
        140: '退回',
        210: '提交',
        230: '通过',
        330: '通过',
        340: '退回',
        410: '提交',
        430: '通过',
        440: '退回',
      },

      // CPBH2022001 -- 提货申请审批
      SEND_GOODS_PICKUP_PROCESS: {
        130: '通过',
        140: '退回',
        210: '提交',
        230: '通过',
        310: '通过',
        320: '通过',
        330: '通过',
        340: '退回', // 提货受理审批退回
        410: '提交',
        420: '提交',
        430: '通过',
        440: '退回',
      },

      // 还款申请
      LOAN_REPAY_PROCESS: {
        130: '通过',
        140: '退回',
        120: '提交',
        // 160: '作废',
      },

      // 融资申请
      LOAN_APPLY_PROCESS: {
        // 企业端
        130: '通过',
        140: '退回',
        120: '提交',
        160: '作废',

        // 运营端
        230: '通过', // 融资受理-通过
        250: '拒绝', // 融资受理-拒绝
        220: '通过', // 融资受理审批退回-通过
        330: '通过', // 融资受理审批-通过
        340: '退回', // 融资受理审批-退回
        430: '通过', //"受托支付成功", "已放款"
      },
      // 融资申请-聚池通
      LOAN_APPLY_FOR_POOL_PROCESS: {
        // 企业端
        130: '通过', // 融资审核通过
        140: '退回', // 融资审核退回
        120: '提交', // 融资维护提交
        160: '作废', // 融资作废
        300: '提交', // 融资放款申请提交
        310: '拒绝', // 融资放款申请拒绝
        320: '通过', // 融资放款申请审核通过
        330: '拒绝', // 融资放款申请审核拒绝
        340: '通过', // 核心企业付息签约确认-通过
        350: '拒绝', // 核心企业付息签约拒绝
        360: '通过', // 核心企业付息签约审批通过
        370: '拒绝', // 核心企业付息签约审批拒绝

        // 运营端
        220: '通过', // 融资受理-通过 | 融资受理审批退回-通过
        250: '拒绝', // 融资受理-拒绝 | 融资受理审批退回-拒绝
        230: '通过', // 融资受理审批-通过
        240: '退回', // 融资受理审批-退回
        420: '通过', // 放款受理通过
        450: '拒绝', // 放款受理拒绝
        430: '通过', // 放款受理审批通过
        440: '拒绝', // 放款受理审批拒绝
        460: '通过', // 放款结果登记
      },

      undefined: '',
    };

    return {
      loanApprovalStatusMap,
    };
  },
  methods: {
    getEndTime(endTime) {
      return this.formattedDate(endTime).format('YYYY-MM-DD HH:mm:ss');
    },

    getUserName(item) {
      return item.taskLocalVariables?.currentUser?.userName || 'SYSTEM';
    },

    getStatus(item, originItem) {
      return (
        (this.loanApprovalStatusMap[originItem?.processDefinitionKey] &&
          this.loanApprovalStatusMap[originItem?.processDefinitionKey][item?.taskLocalVariables?.approvalStatus]) ||
        this.loanApprovalStatusMap[item?.taskLocalVariables?.approvalStatus]
      );
    },

    getDesc(item) {
      return item?.taskLocalVariables?.object?.approvalDesc;
    },
  },
};
</script>

<style scoped lang="scss">
.el-timeline {
  padding-left: 0;
}
::v-deep {
  .el-timeline-item {
    &:last-child {
      padding-bottom: 0;
    }
  }
  .el-timeline-item__tail {
    top: 7px;
  }
  .el-timeline-item__node {
    top: 7px;
    width: 10px;
    height: 10px;
    background: #fff;
    border: 2px solid $primaryColor;
  }
  .el-timeline-item__wrapper {
    display: flex;
  }
}
</style>
