// 工作台
const messageCenterRoutes = [
  {
    path: '/messageCenter',
    name: 'messageCenter',
    redirect: '/messageCenter/list',
    meta: {
      title: '消息中心',
      hidden: true,
      layout: 'Layout',
      activeMenu: '/dashboard',
    },
  },
  {
    path: '/messageCenter/list',
    name: 'messageCenterList',
    component: () => import(/* webpackChunkName: "MessageCenter" */ '@/views/MessageCenter/index.vue'),
    meta: {
      title: '消息中心',
      hidden: true,
      layout: 'Layout',
      activeMenu: '/dashboard',
    },
  },
  // 电子仓单
  {
    path: '/messageCenter/warehouseReceipt/videoMonitoring',
    component: () =>
      import(/* webpackChunkName: "MessageCenter" */ '@/views/MessageCenter/warehouseReceipt/videoMonitoring/index'),
    meta: {
      title: '消息中心',
      hidden: true,
      layout: 'Layout',
      activeMenu: '/dashboard',
    },
  },
];

export default messageCenterRoutes;
