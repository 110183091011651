const homeRoutes = [
  {
    path: '/homePage',
    name: 'HomePage',
    component: () => import(/* webpackChunkName: 'homePage' */ '@/views/Home/Home.vue'),
    meta: { title: '首页', routeType: 'HomeRoute', class: 'menu-level-first', layout: 'HomeLayout' },
  },
  // {
  //   path: '/serviceFinance',
  //   name: 'ServiceFinance',
  //   component: () => import(/* webpackChunkName: 'serviceFinance' */ '@/views/Home/ServiceFinance.vue'),
  //   meta: { title: '产品服务', routeType: 'HomeRoute', class: 'menu-level-first', layout: 'HomeLayout' },
  // },
  // {
  //   path: '/platformDynamic',
  //   name: 'PlatformDynamic',
  //   redirect: '/platformDynamic/list',
  //   meta: {
  //     title: '平台动态',
  //     routeType: 'HomeRoute',
  //     class: 'menu-level-first',
  //     layout: 'HomeLayout',
  //   },
  // },
  // {
  //   path: '/platformDynamic/list',
  //   name: 'PlatformDynamicList',
  //   component: () => import(/* webpackChunkName: 'platformDynamic' */ '@/views/Home/PlatformDynamic/index.vue'),
  //   meta: {
  //     title: '平台动态',
  //     routeType: 'HomeRoute',
  //     layout: 'HomeLayout',
  //     hidden: true,
  //     activeMenu: '/platformDynamic',
  //   },
  // },
  // {
  //   path: '/platformDynamic/detail',
  //   name: 'PlatformDynamicDetail',
  //   component: () => import(/* webpackChunkName: 'platformDynamicDetail' */ '@/views/Home/PlatformDynamic/detail.vue'),
  //   meta: {
  //     title: '平台动态',
  //     routeType: 'HomeRoute',
  //     layout: 'HomeLayout',
  //     parentMenu: '/platformDynamic',
  //     hidden: true,
  //     activeMenu: '/platformDynamic',
  //   },
  // },
  // {
  //   path: '/helpCenter',
  //   name: 'HelpCenter',
  //   component: () => import(/* webpackChunkName: 'helpCenter' */ '@/views/Home/HelpCenter/index.vue'),
  //   meta: {
  //     title: '帮助中心',
  //     routeType: 'HomeRoute',
  //     class: 'menu-level-first',
  //     layout: 'HomeLayout',
  //   },
  // },
  // {
  //   path: '/aboutUs',
  //   name: 'AboutUs',
  //   component: () => import(/* webpackChunkName: 'aboutUs' */ '@/views/Home/AboutUs/index.vue'),
  //   meta: { title: '关于平台', routeType: 'HomeRoute', class: 'menu-level-first', layout: 'HomeLayout' },
  // },
  {
    path: '/newPassword',
    name: 'RegisterPage',
    component: () => import(/* webpackChunkName: 'registerPage' */ '@/views/Login/NewPassword.vue'),
    meta: { title: '忘记密码', routeType: 'HomeRoute', hidden: true, class: 'menu-level-first', layout: 'HomeLayout' },
  },
  {
    path: '/registerPage',
    name: 'RegisterPage',
    component: () => import(/* webpackChunkName: 'registerPage' */ '@/views/Login/RegisterPage.vue'),
    meta: { title: '人脸注册', routeType: 'HomeRoute', hidden: true, class: 'menu-level-first', layout: 'RegisterLayout' },
  },
  {
    path: '/faceUrlPage',
    name: 'FaceUrlPage',
    component: () => import(/* webpackChunkName: 'faceUrlPage' */ '@/views/Login/FaceUrlPage.vue'),
    meta: { title: '人脸注册', routeType: 'HomeRoute', hidden: true, class: 'menu-level-first', layout: 'RegisterLayout' },
  },
  {
    path: '/ukeyRegister',
    name: 'UkeyRegister',
    component: () => import(/* webpackChunkName: 'ukeyRegister' */ '@/views/Login/UkeyRegister.vue'),
    meta: { title: 'Ukey注册', routeType: 'HomeRoute', hidden: true, class: 'menu-level-first', layout: 'RegisterLayout' },
  },
  {
    path: '/prompt',
    name: 'prompt',
    component: () => import(/* webpackChunkName: 'prompt' */ '@/views/prompt/index.vue'),
    meta: { title: '提示', routeType: 'HomeRoute', hidden: true, class: 'menu-level-first', layout: 'RegisterLayout' },
  },
  {
    path: '/sign',
    name: 'sign',
    component: () => import(/* webpackChunkName: 'prompt' */ '@/views/prompt/sign.vue'),
    meta: { title: '提示', routeType: 'HomeRoute', hidden: true, class: 'menu-level-first', layout: 'RegisterLayout' },
  },
];

export default homeRoutes;
