var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("el-input-number", {
    class: _vm.item.class,
    staticStyle: { width: "100%" },
    style: _vm.item.style,
    attrs: {
      disabled: _vm.item.disabled,
      "controls-position": _vm.item.controlsPosition,
      min: _vm.item.min,
      max: _vm.item.max,
      step: _vm.item.step,
      "step-strictly": _vm.item.stepStrictly,
      precision: _vm.item.precision,
      controls: _vm.item.controls,
      placeholder: _vm.item.placeholder,
    },
    on: { change: _vm.change, blur: _vm.blur, focus: _vm.focus },
    model: {
      value: _vm.formData[_vm.item.prop],
      callback: function ($$v) {
        _vm.$set(_vm.formData, _vm.item.prop, $$v)
      },
      expression: "formData[item.prop]",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }