// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Variables */
[data-v-6cf6a848]:export {
  menuText: #000;
  menuActiveText: #fff;
  subMenuActiveText: #f4f4f5;
  menuActiveBg: #1589cd;
  menuBg: #fff;
  menuHover: rgba(45, 153, 255, 0.3);
  subMenuBg: #fff;
  subMenuHover: rgba(45, 153, 255, 0.3);
  sideBarWidth: 200px;
  primaryColor: #1589cd;
  primaryFontColor: rgb(45, 153, 255);
  primaryColor_8: rgba(31, 113, 255, 0.08);
  globalColor: #e60012;
  defaultFontColor: rgb(20, 35, 69);
  tableHeaderBg: #fafafa;
  tableHeaderHeight: 40px;
  tableAlign: center;
  fontSize_14: 14px;
  fontSize_16: 16px;
  fontSize_18: 18px;
  fontSize_24: 24px;
}

/* Mixins */
.el-form.el-form--label-left[data-v-6cf6a848] .el-form-item {
  margin-bottom: 24px;
  display: flex;
}
.el-form.el-form--label-left[data-v-6cf6a848] .el-form-item .el-form-item__label {
  flex-shrink: 0;
  width: auto !important;
}
.el-form.el-form--label-left[data-v-6cf6a848] .el-form-item .el-form-item__content {
  flex: 1;
  margin-left: 0;
}
.el-form.el-form--label-left[data-v-6cf6a848] .common-center-button {
  text-align: center;
}
.el-form.el-form--label-left .form-line-buttons-col .el-button[data-v-6cf6a848] {
  margin-left: 2px;
}
.el-form.el-form--label-left .form-line-buttons-col .el-button + .el-button[data-v-6cf6a848] {
  margin-left: 12px;
}
.el-form.el-form--label-right[data-v-6cf6a848] .el-form-item {
  margin-bottom: 24px;
  display: flex;
}
.el-form.el-form--label-right[data-v-6cf6a848] .el-form-item .el-form-item__label {
  flex-shrink: 0;
  min-width: 140px !important;
}
.el-form.el-form--label-right[data-v-6cf6a848] .el-form-item .el-form-item--medium .el-form-item__content {
  line-height: 33px;
}
.el-form.el-form--label-right[data-v-6cf6a848] .el-form-item .el-form-item__content {
  flex: 1;
  margin-left: 0;
  line-height: 33px;
}
.el-form.el-form--label-right[data-v-6cf6a848] .el-input-number.is-without-controls .el-input__inner {
  text-align: left;
}
.el-form.el-form--label-right .form-line-buttons-col .el-button[data-v-6cf6a848] {
  margin-left: 2px;
  background: red;
}
.el-form.el-form--label-right .form-line-buttons-col .form-line-buttons-col .el-button[data-v-6cf6a848] {
  margin-left: 2px;
}
.el-form.el-form--label-right .form-line-buttons-col .form-line-buttons-col .el-button + .el-button[data-v-6cf6a848] {
  margin-left: 12px;
}
.el-form.el-form--label-right[data-v-6cf6a848] .el-form-item__error {
  font-size: 14px;
}
.el-form.el-form--label-right[data-v-6cf6a848] .el-form-item {
  margin-bottom: 24px;
}
.el-form.el-form--label-right[data-v-6cf6a848] .el-form-item__label {
  color: rgb(20, 35, 69);
  font-weight: bold;
}
.el-form.el-form--label-right[data-v-6cf6a848] .el-form-item__content .el-button > span {
  white-space: unset;
}
.el-form.el-form--label-right[data-v-6cf6a848] .common-left-button > .el-form-item__content {
  text-align: right;
}
.el-form.el-form--label-right[data-v-6cf6a848] .common-center-button {
  text-align: center;
}
.el-form.el-form--label-right[data-v-6cf6a848] *::-ms-backdrop,
.el-form.el-form--label-right[data-v-6cf6a848] .el-select .el-select__tags > span {
  display: flex;
  flex-wrap: wrap;
  height: auto;
  width: 100%;
}
.el-form.el-form--label-right[data-v-6cf6a848] *::-ms-backdrop span,
.el-form.el-form--label-right[data-v-6cf6a848] .el-select .el-select__tags > span span {
  width: auto;
  height: auto;
}
.el-form.el-form--label-right[data-v-6cf6a848] .el-select .el-select__tags .el-tag--info {
  display: flex;
  flex-wrap: wrap;
  height: auto;
  width: auto;
}
.el-form.el-form--label-right .divider-title[data-v-6cf6a848] {
  font-size: 16px;
  color: rgb(20, 35, 69);
  font-weight: bold;
}
.el-form.el-form--label-right .el-divider--horizontal[data-v-6cf6a848] {
  margin: 12px 0 15px;
  background-color: #e8e8e8;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
