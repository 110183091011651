<template>
  <!-- password -->
  <el-input
    v-if="item.type === 'password'"
    ref="password"
    auto-complete="new-password"
    v-model.trim="formData[item.prop]"
    :style="item.style ? item.style : { width: '100%' }"
    :type="passwordType"
    :placeholder="!item.disabled && item.placeholder"
    :suffixIcon="item.suffixIcon"
    :prefixIcon="item.prefixIcon"
    :maxlength="item.maxlength"
    :rows="item.rows"
    :autosize="item.autosize"
    :minlength="item.minlength"
    :show-word-limit="item.showWordLimit"
    :disabled="item.disabled"
    :clearable="item.clearable"
    :class="formData[item.prop] ? '' : 'input-status-error'"
    @change="change"
    @keyup.enter.native="handleEnter"
    @input="input"
  >
    <i slot="prefix" class="el-input__icon el-icon-lock"></i>
    <span class="show-pwd" slot="suffix" @click="showPwd">
      <svg-icon
        ref="el-icon-show"
        class="el-input__icon el-icon-show"
        :icon-class="passwordType === 'password' ? 'eye_off' : 'eye_on'"
      />
    </span>
  </el-input>
  <el-input
    v-else
    v-model.trim="formData[item.prop]"
    :style="item.style ? item.style : { width: '100%' }"
    :type="item.type"
    :placeholder="!item.disabled && item.placeholder"
    :suffixIcon="item.suffixIcon"
    :prefixIcon="item.prefixIcon"
    :show-password="item.showPassword"
    :maxlength="item.maxlength"
    :rows="item.rows"
    :autosize="item.autosize"
    :minlength="item.minlength"
    :show-word-limit="item.showWordLimit"
    :disabled="item.disabled"
    :clearable="item.clearable"
    :autocomplete="item.autocomplete"
    :class="formData[item.prop] ? '' : 'input-status-error'"
    @change="change"
    @keyup.enter.native="handleEnter"
    @input="input"
  >
    <el-button
      :disabled="item.slotBtnDisabled ? true : false"
      :type="item.slotBtnType"
      @click="click"
      :slot="item.slot"
      :icon="item.iconShow ? '' : 'el-icon-search'"
    >
      {{ item.slotValue }}
    </el-button>
  </el-input>
</template>

<script type="text/ecmascript-6">
import mixins from '../mixins';

export default {
  components: {},
  props: {},
  mixins: [mixins],
  data() {
    return {
      passwordType: 'password',
    };
  },
  computed: {},
  methods: {
    showPwd() {
      if (this.passwordType === 'password') {
        this.passwordType = '';
      } else {
        this.passwordType = 'password';
      }
      this.$nextTick(() => {
        this.$refs.password.focus();
      });
    },
    input() {
      this.mixinEvent({
        type: 'input',
        prop: this.item.prop,
        value: this.formData[this.item.prop],
      });
    },
    change() {
      this.mixinEvent({
        type: 'change',
        prop: this.item.prop,
        value: this.formData[this.item.prop],
      });
    },
    click() {
      this.mixinEvent({
        type: this.item.slotValue ? 'button' : 'click',
        prop: this.item.prop,
        value: this.formData[this.item.prop],
      });
    },
    handleEnter() {
      this.mixinEvent({
        type: 'enterClick',
        prop: this.item.prop,
        value: this.formData[this.item.prop],
      });
    },
  },
  activated() {},
  mounted() {},
  created() {},
};
</script>

<style scoped lang="scss">
// 高度36px，有2px是边框
.el-input {
  ::v-deep {
    // .el-input__inner {
    //   line-height: 33.5px !important;
    // }
  }
  &.input-status-error.el-input--suffix {
    ::v-deep {
      .el-input__inner {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}
</style>
