<template>
  <CustomTable
    :cardHeader="cardHeader"
    :tableData="tableFileList"
    :tableCols="tableCols"
    :loading="loading"
    :isPagination="true"
    :pagination="pagination"
    :loadData="handleSearch"
  >
    <template slot="FooterSlot">
      <ExampleDialog
        ref="exampleDialog"
        :className="'agreement-read-dialog'"
        :src="src"
        :pdfId="pdfId"
        :pdfIdList="pdfIdList"
        :showFooter="type !== 'detail'"
        :isRead="list && list.length > 0"
        :confirmButton="confirmButton"
        :defaultTitle="defaultTitle"
        :confirmButtonDisabled="confirmButtonDisabled"
        @submit="readSubmit"
        @isReadFn="changeReadStatus"
      />
    </template>
  </CustomTable>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { tableFixedWidth } from '@/utils/enums';
import { ExampleDialog } from '@/components';

export default {
  name: 'AgreementRead',
  components: {
    ExampleDialog,
  },
  props: {
    // 默认展示是否阅读；detail-只能预览
    type: {
      type: String,
      default: '',
    },
    cardHeader: {
      type: String,
      default: '',
    },
    list: {
      type: Array,
      default: () => [],
    },
    bussNo: {
      type: String,
      default: '',
    },
    confirmButton: {
      type: String,
      default: '我已阅读',
    },
    defaultTitle: {
      type: String,
      default: '协议预览',
    },
    bussType: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('agreementRead', ['fileList', 'pagination']),

    tableFileList() {
      return this.type === 'detail' ? this.fileList : this.fileIdList;
    },
  },
  data() {
    return {
      src: '',
      pdfId: '',
      pdfIdList: [],
      fileIdList: [],
      isAllRead: false,
      confirmButtonDisabled: true,
      tableCols: [
        {
          label: '协议编号',
          prop: 'contractNo',
          width: tableFixedWidth.left,
          fixed: 'left',
          align: 'center',
        },
        {
          label: '协议类型',
          prop: 'templateName',
          width: tableFixedWidth.medium,
        },
        ...(this.type === 'detail'
          ? [
              {
                label: '协议名称',
                prop: 'fileName',
                width: tableFixedWidth.right,
                formatter: (val) => {
                  return val?.fileName ? `《${val.fileName.replace(/([^.]+).*/gi, '$1')}》` : '暂无数据';
                },
              },
              {
                label: '操作',
                align: 'center',
                eType: 'Button',
                width: tableFixedWidth.right,
                fixed: 'right',
                formatter: (row) => {
                  return [
                    {
                      label: '预览',
                      type: 'text',
                      handle: () => {
                        this.handleShowModal(row);
                      },
                    },
                  ];
                },
              },
            ]
          : [
              {
                label: '协议预览',
                eType: 'Button',
                width: tableFixedWidth.medium,
                formatter: (val) => {
                  return [
                    {
                      label: val?.contractFileName ? `《${val?.contractFileName}》` : '暂无数据',
                      type: 'text',
                      handle: (row) => {
                        this.handleShowModal(row);
                      },
                    },
                  ];
                },
              },
              {
                label: '是否阅读',
                align: 'center',
                width: tableFixedWidth.right,
                fixed: 'right',
                formatter: (row) => {
                  return row.read ? '是' : '否';
                },
              },
            ]),
      ],
    };
  },
  watch: {
    list: {
      handler(val) {
        this.fileIdList = val || [];
      },
      immediate: true,
    },
    bussNo: {
      handler(val) {
        val && this.handleSearch();
      },
      immediate: true,
    },
  },
  mounted() {
    this.fileIdList = this.list || [];
  },
  methods: {
    ...mapActions('agreementRead', ['getContractList']),

    async handleSearch(page) {
      let { current, pageSize } = page || {};
      current = current || 1;
      pageSize = pageSize || 10;

      if (this.bussNo) {
        await this.getContractList({
          bussType: this.bussType,
          bussNo: this.bussNo,
          current,
          pageSize,
        });
      }
    },

    // 协议阅读按钮可点击
    changeReadStatus(val) {
      if (!this.isAllRead) {
        this.confirmButtonDisabled = !val;
      }
    },

    // 协议阅读
    readSubmit() {
      this.fileIdList = this.fileIdList.map((item) => {
        return { ...item, read: true };
      });
      this.isAllRead = true;
    },

    // 协议阅读弹框展示
    async handleShowModal(row) {
      if (this.type !== 'detail') {
        this.pdfIdList = this.tableFileList.map((item) => item.contractFileId);
        this.$nextTick(() => {
          this.$refs.exampleDialog?.open();
        });
        return;
      }
      const fileId = row?.contractFileId;
      this.pdfId = fileId;
      this.src = undefined;
      this.$nextTick(() => {
        this.$refs.exampleDialog && this.$refs.exampleDialog.open();
      });
    },
  },
};
</script>

<style scoped lang="scss"></style>
