var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-tabs",
    { attrs: { value: _vm.current }, on: { "tab-click": _vm.handleClick } },
    _vm._l(_vm.tabs, function (item) {
      return _c("el-tab-pane", {
        key: item.value,
        attrs: { label: item.key, name: item.value },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }