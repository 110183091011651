// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
/* Variables */
[data-v-3e6b72f0]:export {
  menuText: #000;
  menuActiveText: #fff;
  subMenuActiveText: #f4f4f5;
  menuActiveBg: #1589cd;
  menuBg: #fff;
  menuHover: rgba(45, 153, 255, 0.3);
  subMenuBg: #fff;
  subMenuHover: rgba(45, 153, 255, 0.3);
  sideBarWidth: 200px;
  primaryColor: #1589cd;
  primaryFontColor: rgb(45, 153, 255);
  primaryColor_8: rgba(31, 113, 255, 0.08);
  globalColor: #e60012;
  defaultFontColor: rgb(20, 35, 69);
  tableHeaderBg: #fafafa;
  tableHeaderHeight: 40px;
  tableAlign: center;
  fontSize_14: 14px;
  fontSize_16: 16px;
  fontSize_18: 18px;
  fontSize_24: 24px;
}

/* Mixins */
.button-wrap[data-v-3e6b72f0] {
  padding-left: 24px;
  margin-bottom: 24px;
}
.button-wrap .button-content[data-v-3e6b72f0] {
  margin-bottom: 16px;
}
.button-wrap .button-content p[data-v-3e6b72f0] {
  font-size: 14px;
}
.el-upload[data-v-3e6b72f0] {
  display: inline-block;
  text-align: center;
  cursor: pointer;
  outline: none;
}
.upload-button[data-v-3e6b72f0] .el-upload {
  text-align: left;
}
.preview[data-v-3e6b72f0] {
  font-size: 14px;
}
.preview-item[data-v-3e6b72f0] {
  display: flex;
}
.video-item[data-v-3e6b72f0] {
  width: 148px;
  height: 148px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.video-item video[data-v-3e6b72f0] {
  height: 148px;
  width: 148px;
}
.video-item[data-v-3e6b72f0] .el-upload-list__item-actions {
  height: auto !important;
  opacity: 1;
}
.video-item[data-v-3e6b72f0] .el-upload-list__item-actions span {
  display: inline-block;
}
[data-v-3e6b72f0] .el-dialog__body {
  display: flex;
  justify-content: center;
}
[data-v-3e6b72f0] .el-upload-dragger {
  background-color: #fff;
  border-radius: 6px;
  box-sizing: border-box;
  text-align: center;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 260px;
  height: 100%;
}
[data-v-3e6b72f0] .el-icon-upload {
  font-size: 78px;
  color: #c0c4cc;
  margin: 10px 0;
  line-height: 52px;
  color: rgba(31, 113, 255, 0.5);
}
[data-v-3e6b72f0] .el-upload--picture-card {
  border: none;
}
[data-v-3e6b72f0] .el-upload__tip {
  font-size: 12px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.65);
  white-space: pre-wrap;
  text-align: center;
}
[data-v-3e6b72f0] .el-upload-dragger ~ .el-upload__files {
  border-top: 1px solid #dcdfe6;
  margin-top: 7px;
  padding-top: 5px;
}
[data-v-3e6b72f0] .el-upload-dragger .el-upload__text {
  color: #606266;
  font-size: 14px;
  text-align: center;
}
[data-v-3e6b72f0] .el-upload-dragger .el-upload__text em {
  color: #409eff;
  font-style: normal;
}
[data-v-3e6b72f0] .el-upload-dragger:hover {
  border-color: #409eff;
}
[data-v-3e6b72f0] .el-upload-dragger.is-dragover {
  background-color: rgba(32, 159, 255, 0.06);
  border: 2px dashed #409eff;
}
.disUoloadSty[data-v-3e6b72f0] .el-upload {
  display: none !important;
}
.disUoloadSty[data-v-3e6b72f0] .el-upload-list--text {
  margin-top: -14px;
}
.disUoloadSty[data-v-3e6b72f0] .el-upload-list__item.is-success:focus:not(:hover) {
  display: none !important;
}
.disUoloadSty[data-v-3e6b72f0] .el-upload--picture-card {
  display: none !important; /* 上传按钮隐藏 */
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
