// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Variables */
[data-v-3b1315ad]:export {
  menuText: #000;
  menuActiveText: #fff;
  subMenuActiveText: #f4f4f5;
  menuActiveBg: #1589cd;
  menuBg: #fff;
  menuHover: rgba(45, 153, 255, 0.3);
  subMenuBg: #fff;
  subMenuHover: rgba(45, 153, 255, 0.3);
  sideBarWidth: 200px;
  primaryColor: #1589cd;
  primaryFontColor: rgb(45, 153, 255);
  primaryColor_8: rgba(31, 113, 255, 0.08);
  globalColor: #e60012;
  defaultFontColor: rgb(20, 35, 69);
  tableHeaderBg: #fafafa;
  tableHeaderHeight: 40px;
  tableAlign: center;
  fontSize_14: 14px;
  fontSize_16: 16px;
  fontSize_18: 18px;
  fontSize_24: 24px;
}

/* Mixins */
.table-pop-confirm[data-v-3b1315ad] {
  margin-right: 10px;
}
.table-pop-confirm.left-margin[data-v-3b1315ad] {
  margin-left: 10px;
}
[data-v-3b1315ad] .tableForm .el-form-item {
  margin-top: 18px;
}
[data-v-3b1315ad] .tableForm .el-form-item--small.el-form-item {
  margin-top: 18px;
}
[data-v-3b1315ad] .el-table .el-table__header-wrapper .el-table__header thead {
  background: #fafafa;
  color: rgb(20, 35, 69);
  font-size: 14px;
  height: 40px;
}
[data-v-3b1315ad] .el-table__fixed-right .cell :last-child:not(.el-button) .el-button {
  margin-right: 0;
}
[data-v-3b1315ad] .el-table__fixed-right .cell :first-child:not(.el-button) .el-button {
  margin-left: 0;
}
[data-v-3b1315ad] .el-table__fixed-right .cell :last-child.el-button--text {
  margin-left: 10px;
}
[data-v-3b1315ad] .el-table__fixed-right .cell .el-dropdown .el-button {
  margin-left: 0;
}
[data-v-3b1315ad] .el-table__body-wrapper::-webkit-scrollbar {
  width: 14px;
  height: 14px;
}
[data-v-3b1315ad] .el-table__body-wrapper::-webkit-scrollbar-thumb {
  position: relative;
  background-color: rgba(44, 62, 80, 0.15);
  border-radius: 4px;
}
[data-v-3b1315ad] .el-table__body .el-table__row .el-table__cell {
  padding: 0px !important;
}
[data-v-3b1315ad] .el-table__body .el-table__row .el-table__cell .cell {
  padding-left: 10px;
  padding-right: 10px;
}
[data-v-3b1315ad] .el-table__fixed,[data-v-3b1315ad] .el-table__fixed-right {
  height: auto !important;
  bottom: -7px;
}
[data-v-3b1315ad] .el-table__fixed::before,[data-v-3b1315ad] .el-table__fixed-right::before {
  content: none;
}
[data-v-3b1315ad] .el-pagination span:not([class*=suffix]),[data-v-3b1315ad] .el-pagination button {
  font-size: 14px;
}
[data-v-3b1315ad] .el-input__inner {
  line-height: 28px;
}
[data-v-3b1315ad] .el-pager li {
  font-size: 14px;
}
[data-v-3b1315ad] .el-pagination__sizes .el-input .el-input__inner {
  font-size: 14px;
}
[data-v-3b1315ad] .el-input__inner {
  font-size: 14px;
}
[data-v-3b1315ad] .el-tag--medium {
  font-size: 14px;
}
[data-v-3b1315ad] .el-popover.table-button-pop {
  max-width: 250px;
}
[data-v-3b1315ad] .el-popover__reference-wrapper {
  position: relative;
}
[data-v-3b1315ad] .el-popover__reference-wrapper .table-pop-confirm {
  position: inherit;
}
[data-v-3b1315ad] .el-popover__reference-wrapper .table-pop-confirm::before {
  position: absolute;
  content: "";
  left: -15px;
  right: -15px;
  top: 0;
  bottom: 0;
  z-index: 1;
}
[data-v-3b1315ad] .cell {
  min-height: 32px;
  line-height: 32px;
}
[data-v-3b1315ad] .el-table_expand .el-table__expand-icon {
  display: none;
}
.iconWrap[data-v-3b1315ad] {
  margin-right: 10px;
}
.iconWrap .icon[data-v-3b1315ad] {
  width: 24px;
  height: 24px;
  position: relative;
  top: 5px;
  right: 4px;
}
.iconWrap.enable[data-v-3b1315ad] {
  color: #2cd9c5;
}
.iconWrap.danger[data-v-3b1315ad] {
  color: #ed675e;
}
.iconWrap.delete[data-v-3b1315ad] {
  color: #fd7404;
}
.iconWrap.green[data-v-3b1315ad] {
  color: #1589cd;
}
.iconWrap[data-v-3b1315ad]:last-child {
  margin-right: 0;
}
[data-v-3b1315ad] .el-button.is-disabled,[data-v-3b1315ad] .el-button.is-disabled:hover,[data-v-3b1315ad] .el-button.is-disabled:focus {
  color: #C0C4CC;
  cursor: not-allowed;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
