import store from '@/store';
import { remove } from 'lodash';
import CryptoJS from 'crypto-js';
import numeral from 'numeral';
import { JSEncrypt } from 'jsencrypt';
import { isNumber } from '@/utils/validate';
import defaultPdf from '@/assets/image/defaultPdf.png';
import { getStorage, setStorage } from './storage';
import { getToken, getOrgId, getSecret, getAppId } from '@/utils/auth';
import domMessage from './messageOnce.js';
// new 对象实例
const messageOnce = new domMessage();
/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */
export function parseTime(time, cFormat) {
  if (arguments.length === 0 || !time) {
    return null;
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
  let date;
  if (typeof time === 'object') {
    date = time;
  } else {
    if (typeof time === 'string') {
      if (/^[0-9]+$/.test(time)) {
        // support "1548221490638"
        time = parseInt(time);
      } else {
        // support safari
        // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
        time = time.replace(new RegExp(/-/gm), '/');
      }
    }

    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000;
    }
    date = new Date(time);
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay(),
  };
  const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key];
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value];
    }
    return value.toString().padStart(2, '0');
  });
  return time_str;
}

function localFileType(fileType) {
  if (Array.isArray(fileType)) {
    return fileType.map((item) => {
      if (item === '.xlsx') {
        return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      } else if (item === '.xls') {
        return 'application/vnd.ms-excel';
      } else if (item === '.ppt') {
        return 'application/vnd.ms-powerpoint';
      } else if (item === '.pptx') {
        return 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
      } else if (item === '.doc') {
        return 'application/msword';
      } else if (item === '.docx') {
        return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      } else if (item === '.pdf') {
        return 'application/pdf';
      } else if (item === '.jpg') {
        return 'image/jpg';
      } else if (item === '.jpeg') {
        return 'image/jpeg';
      } else if (item === '.png') {
        return 'image/png';
      } else if (item === '.gif') {
        return 'image/gif';
      } else if (item === '.mp4') {
        return 'video/mp4';
      } else if (item === '.avi') {
        return 'video/x-msvideo';
      } else if (item === '.mov') {
        return 'video/quicktime';
      }
    });
  }
  if (fileType === 'pdf') {
    return 'application/pdf';
  } else if (fileType === 'jpg') {
    return 'image/jpg';
  } else if (fileType === 'jpeg') {
    return 'image/jpeg';
  } else if (fileType === 'png') {
    return 'image/png';
  } else if (fileType === 'gif') {
    return 'image/gif';
  }
}
// 公共格式
export function isFileType(file, fileType) {
  console.log(file, fileType);
  const fileName = file.name;
  const fileTypeList = fileType;
  const fileExtension = fileName && fileName.substring(fileName.lastIndexOf('.'), fileName.length).toLowerCase();
  if (fileExtension && !fileTypeList.includes(fileExtension)) {
    return false;
  }
  if (file.type && !localFileType(fileTypeList).includes(file.type)) {
    return false;
  }
  return true;
}

export function base64ToUrl(base64Data, dataType) {
  // 基于数据类型执行相应的转换逻辑
  switch (dataType) {
    case 'pdf': {
      // 将 Base64 解码为数组缓冲区
      const binaryPdf = atob(base64Data);
      // 创建一个 Uint8Array 对象存储二进制数据
      const uint8Array = new Uint8Array(binaryPdf.length);
      for (let i = 0; i < binaryPdf.length; i++) {
        uint8Array[i] = binaryPdf.charCodeAt(i);
      }
      // 创建 Blob 对象
      const pdfBlob = new Blob([uint8Array], { type: 'application/pdf' });
      // 创建 PDF URL
      return URL.createObjectURL(pdfBlob);
    }
    case 'jpeg':
    case 'jpg':
    case 'png':
    case 'gif':
      return `data:${localFileType(dataType)};base64,${base64Data}`;
    default:
      throw new Error('Unsupported data type');
  }
}
/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
export function formatTime(time, option) {
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000;
  } else {
    time = +time;
  }
  const d = new Date(time);
  const now = Date.now();

  const diff = (now - d) / 1000;

  if (diff < 30) {
    return '刚刚';
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前';
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前';
  } else if (diff < 3600 * 24 * 2) {
    return '1天前';
  }
  if (option) {
    return parseTime(time, option);
  } else {
    return d.getMonth() + 1 + '月' + d.getDate() + '日' + d.getHours() + '时' + d.getMinutes() + '分';
  }
}

export function getPageIndex(page, pageSize) {
  return (page - 1) * pageSize;
}

export const utf8ToB64 = (str) => window.btoa(unescape(encodeURIComponent(str)));

export function isIE() {
  const Edge = navigator.userAgent.indexOf('Edge') > -1;
  if (!!window.ActiveXObject || 'ActiveXObject' in window || Edge) return true;
  return false;
}
// 判断是否为pad
export function isPad() {
  window.test = (flag) => {
    if (flag && !getStorage('isPad')) {
      setStorage('isPad', true);
    } else {
      return true;
    }
  };
}

// 导出
export function exportFile(response) {
  const fileName = response.data && response.data.fileName;
  const fileContent = response.data && response.data.fileContent;
  const element = document.createElement('a'); // 转换完成，创建一个a标签用于下载
  // 兼容ie
  const arr = fileContent.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  const blobObj = new Blob([u8arr], { type: mime });
  element.download = fileName;
  element.href = URL.createObjectURL(blobObj);
  if (isIE()) {
    navigator.msSaveBlob(blobObj, fileName);
  } else {
    element.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
  }
}

// 导出
export function exportBlobFile(response) {
  const fileName = response.fileName
    ? // eslint-disable-next-line
      response.fileName.replace(/^\"|\"$/g, '')
    : '';
  const fileContent = response.fileContent;
  if (!fileContent) {
    messageOnce.warning({
      message: '文件下载失败',
      type: 'warning',
    });
    return;
  }
  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    window.navigator.msSaveBlob(new Blob([fileContent]), fileName);
  } else {
    let url = window.URL.createObjectURL(new Blob([fileContent]));
    let link = document.createElement('a');
    link.style.display = 'none';
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); //下载完成移除元素
    window.URL.revokeObjectURL(url); //释放掉blob对象
  }
}

// 将一维数组转为二维数组
/**
 * @param {array} list 原始一维数组
 * @param {number|string} 二维数组所含项数
 * @returns {array} 二维数组
 */
export function formatArray(list, number) {
  let num = Number(number),
    i = 0;
  let newArray = [];
  if (!list || list.length <= 0) return newArray;
  do {
    newArray.push(list.slice(i, i + num));
    i += num;
  } while (i < list.length);
  return newArray;
}

/**
 * 获取elem元素距离页面顶部的距离
 * @param {dom} elem
 * @returns {string}
 */
export function getElementTop(elem) {
  var elemTop = elem.offsetTop; //获得elem元素距相对定位的父元素的top
  elem = elem.offsetParent; //将elem换成起相对定位的父元素
  while (elem != null) {
    //只要还有相对定位的父元素
    //获得父元素 距他父元素的top值,累加到结果中
    elemTop += elem.offsetTop; //再次将elem换成他相对定位的父元素上;
    elem = elem.offsetParent;
  }
  return elemTop;
}

/**
 * 处理PDF 预览图片
 * @param {dom} elem
 * @returns {string}
 */
export function getPdfOrImageConfig(item) {
  const userToken = getToken();
  const Appid = getAppId();
  const Orgid = getOrgId();
  const Clientid = process.env.VUE_APP_CLIENT_ID;
  if (typeof item === 'string') {
    return getUrl(item);
  }
  // 如果是PDF，展示默认图片
  if (item?.fileName?.endsWith('.pdf')) {
    return {
      pdfId: item.fileId,
      url: defaultPdf,
    };
  }
  return {
    url: getUrl(item?.fileId || item),
  };

  function getUrl(fileId) {
    const url =
      fileId &&
      `/api/thirdparty/file/file-attachment/show-images?fileId=${fileId}&token=${userToken}&appid=${Appid}&clientid=${Clientid}&orgid=${Orgid}`;
    return url;
  }
}
/**
 * 传入对象返回url参数
 * @param {Object} data {a:1}
 * @returns {string}
 */
function getParam(data) {
  let url = '';
  if (data) {
    Object.keys(data).forEach((item, index) => {
      if (data[item]) {
        if (index === 0) {
          url += `${item}=${typeof data[item] === 'object' ? '' : encodeURIComponent(data[item])}`;
        } else {
          url += `&${item}=${typeof data[item] === 'object' ? '' : encodeURIComponent(data[item])}`;
        }
      }
    });
    return url;
  }
  return '';
}
/**
 * 将url和参数拼接成完整地址
 * @param {string} url url地址
 * @param {Json} data json对象
 * @returns {string}
 */
export function getUrl(url, data) {
  //看原始url地址中开头是否带?，然后拼接处理好的参数
  return (url += (url.indexOf('?') < 0 ? '?' : '') + getParam(data));
}

/**
 * url参数处理为json
 * @param {*} url url地址
 * @returns { object, string }
 */
export function getUrlJson(url) {
  let arr = url.split('?')[1].split('&'); //先通过？分解得到？后面的所需字符串，再将其通过&分解开存放在数组里
  let obj = {};
  for (let i of arr) {
    obj[i.split('=')[0]] = i.split('=')[1]; //对数组每项用=分解开，=前为对象属性名，=后为属性值
  }
  return { data: obj, url: url.split('?')[0] };
}

/**
 * 下载文件
 * @param {fileId} 文件id
 * @param {noLocation} 不执行下载操作，需要返回值
 * @returns {string}
 */
export function downloadFile(fileId, noLocation, urlApi) {
  const userToken = getToken();
  const tokenParam = userToken ? `&token=${userToken}` : '';
  let url = '';
  if (urlApi) {
    // url = `/server/api${urlApi}?templateCode=${fileId}${tokenParam}`;
    url = `/server/api${urlApi}?fileId=${fileId}${tokenParam}`;
  } else {
    if (process.env.VUE_APP_ENCRYPT === 'encrypt') {
      // url参数加密
      let enctypeMsg = encryptUrlReq({ fileId: fileId });
      url = `/server/api/pvbank/comm/file/download?${enctypeMsg}${tokenParam}`;
    } else {
      url = `/server/api/pvbank/comm/file/download?fileId=${fileId}${tokenParam}`;
    }
  }
  if (noLocation) {
    return url;
  }
  location.href = url;
}

/**
 * get请求报文加密
 * @param {initialData: {key1: value, key2: ... }} 加密前数据
 * @returns {string} 加密后数据：key1=...&key2=...&...&key=AES密钥
 */
export function encryptUrlReq(initialData) {
  // 随机获取AES密钥
  const AESKeyStr = CryptoJS.lib.WordArray.random(8).toString();
  const AESKey = CryptoJS.enc.Utf8.parse(AESKeyStr);
  // 报文AES加密
  let data = {};
  for (let key in initialData) {
    data[key] = CryptoJS.AES.encrypt(initialData[key], AESKey, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    }).toString();
  }
  // 对AES密钥做RSA加密
  const encryptor = new JSEncrypt({ default_key_size: 1024 });
  let ppkey = getStorage('C3BLZXk');
  encryptor.setPublicKey(ppkey);
  const key = encryptor.encrypt(AESKeyStr);
  let res = '';
  Object.keys(data).forEach((i) => {
    res += `${i}=${encodeURIComponent(data[i])}&`;
  });
  res += `key=${encodeURIComponent(key)}`;
  return res;
}

/**
 * 请求报文加密
 * @param {initialData} 加密前报文
 * @returns {data: string, key: string} 加密后报文：data--AES加密后报文；key--RSA加密后的AES密钥
 */
export function encryptReq(initialData) {
  // 根据用户名获取AES密钥
  console.log(initialData);
  const AESKey = CryptoJS.enc.Utf8.parse(getSecret());
  // 报文AES加密
  const encryptedContent = CryptoJS.AES.encrypt(JSON.stringify(initialData), AESKey, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });
  const data = encryptedContent.ciphertext.toString();
  return {
    body: data,
  };
}

/**
 * 响应报文解密
 * @param {res} 解密前报文
 * @returns {object} 解密后报文
 */
export function decryptRes(data) {
  // 解密key
  const AESKey = CryptoJS.enc.Utf8.parse(getSecret());
  // 解密报文
  const decryptedContent = CryptoJS.AES.decrypt(CryptoJS.format.Hex.parse(data), AESKey, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });
  let plainData = CryptoJS.enc.Utf8.stringify(decryptedContent);
  if (plainData) {
    plainData = JSON.parse(plainData);
  }
  console.log('解密:', plainData);
  return plainData;
}

/**
 * 判断当前按钮权限是否在权限码集合中
 * @param {btnList} 当前按钮权数组
 * @returns {Boolean}
 */
export const buttonAuthFilter = (btnList) => {
  const rspCodes = store.getters.resouceCodes;
  let btnAuthList = [];
  if (btnList.length !== 0 && rspCodes.length !== 0) {
    btnList.forEach((item) => {
      if (rspCodes.includes(item.authority)) {
        btnAuthList.push(item);
      }
    });
    return btnAuthList;
  }
  return [];
};

/**
 * 金额格式化
 * @param {value} 金额
 * @returns {string}
 */
export function numberFormatAmt(num, hasThousand = false, precision = 2) {
  if (num !== '' && num !== undefined && num !== null) {
    if (hasThousand) {
      return numeral(num).divide(10000).format('0,0.00');
    }
    return (num && numeral(num).format('0,0.00')) || '0.00';
  }
  return '';
}

/**
 * 格式化金额转回数字
 * @param {value} 金额
 * @returns {string}
 */
export function numberFormatCnt(value) {
  if (typeof value === 'string') {
    return (value && numeral(value).value()) || 0;
  }
  return value;
}

/**
 * 格式化百分比
 * @param {value} 小数
 * @returns {string}
 */
export function numberFormatRate(value) {
  if (value !== '' && value !== undefined && value !== null) {
    return (value && numeral(value).format('0.00%')) || '0.00%';
  }
  return '';
}

// 回显数据字典
export function selectDictLabel(datas, value, dictItem) {
  const actions = [];
  const dictValue = dictItem ? dictItem.value : 'value';
  const dictLabel = dictItem ? dictItem.label : 'label';
  Object.keys(datas).some((key) => {
    if (datas[key][dictValue] == '' + value) {
      actions.push(datas[key][dictLabel]);
      return true;
    }
  });
  return actions.join('');
}

// 回显数据字典（字符串数组）
export function selectDictLabels(datas, value, separator) {
  var actions = [];
  var currentSeparator = undefined === separator ? ',' : separator;
  var temp = value.split(currentSeparator);
  Object.keys(value.split(currentSeparator)).some((val) => {
    Object.keys(datas).some((key) => {
      if (datas[key].value == '' + temp[val]) {
        actions.push(datas[key].label + currentSeparator);
      }
    });
  });
  return actions.join('').substring(0, actions.join('').length - 1);
}

/**
 * 树形数据转换
 * @param {*} data
 * @param {*} id
 * @param {*} pid
 */
export function treeDataTranslate(data, id = 'id', pid = 'parentId') {
  let res = [];
  let temp = {};
  for (let i = 0; i < data.length; i++) {
    temp[data[i][id]] = data[i];
  }
  for (let k = 0; k < data.length; k++) {
    if (temp[data[k][pid]] && data[k][id] !== data[k][pid]) {
      if (!temp[data[k][pid]]['children']) {
        temp[data[k][pid]]['children'] = [];
      }
      if (!temp[data[k][pid]]['_level']) {
        temp[data[k][pid]]['_level'] = 1;
      }
      data[k]['_level'] = temp[data[k][pid]]._level + 1;
      temp[data[k][pid]]['children'].push(data[k]);
    } else {
      res.push(data[k]);
    }
  }
  return res;
}

/**
 * table手动分页
 * @param {tableData} {pagination}
 * @returns {string}
 */
export const pagTableData = (tableData, pagination) => {
  const { current, pageSize } = pagination;
  if (tableData?.length > 0) {
    return tableData.slice((current - 1) * pageSize, current * pageSize);
  }
};

/**
 * 数字除以100，变为小数后两位的百分比数
 * @param {value}
 * @returns {string}
 */
export const translateScale = (value) => {
  return (Number(value) / 100).toFixed(2);
};

/**
 * 字符串数组和元素字符串之间相互转换
 * @param {value} Array | String
 * @returns {string}
 */
export const translateArrStr = (value) => {
  if (typeof value === 'string') {
    return value.split(',');
  } else if (Array.isArray(value)) {
    return value.join(',');
  } else {
    return value;
  }
};

/**
 * 根据下拉框options value 找到对应的label进行显示
 * @param {value: Number | String || Array} {options}
 * @returns {string}
 */
export const filterOptionsLabel = (value, options) => {
  if (typeof value === 'number') {
    return options?.filter((item) => item.value.toString() === value.toString())[0]?.label;
  }
  if (Array.isArray(value)) {
    let result = [];
    for (let j = 0, len = value.length; j < len; j++) {
      result.push(options?.filter((item) => item.value.toString() === value[j].toString())[0]?.label);
    }
    return result.length !== 0 ? result.join(', ') : '';
  }
  return options?.filter((item) => item.value === value)[0]?.label;
};

/**
 * 数组的批量删除
 * @param {Array} {delete Array}
 * @returns {Array}
 */
export const removeArray = (arr, arrFlag, delArr, delArrFlg) => {
  for (let j = 0, len = delArr.length; j < len; j++) {
    remove(arr, (item) => item[arrFlag] === (delArr[j] && delArr[j][delArrFlg]));
  }
  return arr;
};

/**
 * @description 对象对比，将obj2的元素值，赋值给obj1的对应的key的值
 * @param {*}  obj1， obj2，
 */
export function modifyObjData(obj1, obj2) {
  for (const key in obj2) {
    if (Object.hasOwnProperty.call(obj1, key)) {
      const element = obj2[key];
      obj1[key] = element;
    }
  }
  return obj1;
}

//生成uuid
export function UUID() {
  let s = [];
  let hexDigits = '0123456789abcdefghijklmnopqrstuvwxyz';
  for (let i = 0; i < 33; i++) {
    var flag = Math.floor(Math.random() * 0x19 + 1);
    s[i] = hexDigits.slice(flag, flag + 1);
  }
  s[14] = '4';
  s[19] = hexDigits.slice((s[19] & 0x3) | 0x8, 1);

  let uuid = s.join('');
  return uuid;
}

// 处理图片鉴权
export function handleAuthImg(imgDom, authUrl, callback) {
  const userToken = getToken();
  var client = new XMLHttpRequest();
  client.responseType = 'blob';
  client.open('GET', authUrl);
  client.setRequestHeader('authorization', userToken);
  client.onreadystatechange = () => {
    if (client.readyState == XMLHttpRequest.DONE && client.status == 200) {
      imgDom.onload = () => {
        URL.revokeObjectURL(imgDom.src);
        callback && callback(client.response);
      };
      imgDom.src = URL.createObjectURL(client.response);
    }
  };
  client.send();
}

/**
 * 添加判断字符串是否是json格式方法isJson
 * @param {String}
 * @returns
 */
export const isJson = (str) => {
  if (typeof str === 'string') {
    try {
      var obj = JSON.parse(str);
      if (typeof obj === 'object' && obj) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.log('error：' + str + '!!!' + e);
      return false;
    }
  }
};

/**
 * 获取当前企业身份的产品部分路由
 * @param currCorpIdentity {String} 当前企业身份
 * @returns
 */
export const getProductPathName = (currCorpIdentity) => {
  let path = '';
  switch (currCorpIdentity) {
    case 'H':
      path = 'coreProduct';
      break;
    case 'J':
      path = 'supervisionProduct';
      break;
    case 'R':
      path = 'chainProduct';
      break;
  }
  return path;
};

/**
 * 判断是否有menuTree
 * @param layout {String}
 * @param to {Object} 路由
 * @returns {Boolean}
 */
export const hasMenuTreeFun = async () => {
  let hasMenuTree;
  if (getStorage('appInfo')) {
    hasMenuTree = store.getters.projectMenuTree && store.getters.projectMenuTree.length > 0;
  } else {
    hasMenuTree = store.getters.commonMenuTree && store.getters.commonMenuTree.length > 0;
  }
  return hasMenuTree;
};

// 日期格式化
/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */
export function formattedDate(time, pattern) {
  if (arguments.length === 0 || !time) {
    return null;
  }
  const format = pattern || 'YYYY-MM-DD HH:mm:ss';
  let date;
  if (typeof time === 'object') {
    date = time;
  } else {
    if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
      time = parseInt(time);
    } else if (typeof time === 'string') {
      time = time.replace(new RegExp(/-/gm), '/');
    }
    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000;
    }
    date = new Date(time);
  }
  const formatObj = {
    YYYY: date.getFullYear(),
    MM: date.getMonth() + 1,
    DD: date.getDate(),
    HH: date.getHours(),
    mm: date.getMinutes(),
    ss: date.getSeconds(),
    week: date.getDay(),
  };
  // {(Y+|m|d|h|i|s|a)+}
  const time_str = format.replace(/Y{1,4}|M{1,2}|D{1,2}|H{1,2}|m{1,2}|s{1,2}|a/g, (result) => {
    let value = formatObj[result];
    // Note: getDay() returns 0 on Sunday
    if (result === 'week') {
      return ['日', '一', '二', '三', '四', '五', '六'][value];
    }
    if (value < 10) {
      value = '0' + value;
    }
    return value || 0;
  });
  return time_str;
}

//  base64 转 Blob
export function dataURLtoBlob(toDataURL) {
  var arr = toDataURL.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], {
    type: mime,
  });
}
//转成file
export function blobToFile(Blob, fileName) {
  Blob.lastModifiedDate = new Date();
  Blob.name = fileName;
  return Blob;
}
/**
 * 根据base64创建文件
 * @param base64String {String}
 * @param isBase64 {Boolean}
 */
export function createFile(base64String, isBase64) {
  if (!base64String) {
    return null;
  }
  const bstr = isBase64 ? atob(base64String) : base64String; //atob() 方法用于解码使用 base-64 编码的字符串
  let n = bstr.length;
  const bytes = new Uint8Array(n);
  while (n--) {
    bytes[n] = bstr.charCodeAt(n);
  }
  // const file = new File([bytes], 'image.jpg', { type: 'image/jpg' });
  const file = blobToFile(new Blob([bytes], { type: 'image/jpg' }), 'image.jpg');

  return file;
}

export function dateFormat(date) {
  let returnValue = date;
  if (!date) {
    return undefined;
  }
  if (isNumber(date)) {
    returnValue = date.substring(0, 4) + '-' + date.substring(4, 6) + '-' + date.substring(6, 8);
  } else if (date.includes('年')) {
    returnValue = date.replace('年', '-').replace('月', '-').replace('日', '');
  }
  if (!isNaN(new Date(returnValue).getTime())) {
    return new Date(returnValue);
  }
  return undefined;
}

/**
 * 防抖
 * @param {Function} func
 * @param {number} wait
 * @return {*}
 */
export function debounce(fn, delay = 500) {
  //默认500毫秒
  let timer;
  return function () {
    let args = arguments;
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      fn.apply(this, args); // this 指向vue
    }, delay);
  };
}

/**
 * 获取选中树子集
 * @param {route树} list
 * @param {当前选中} path
 * @returns
 */
export function getTreeChild(list, path) {
  let childrenRoute = {};
  list.forEach((item) => {
    if (item.path === path) {
      childrenRoute = item;
      return;
    } else {
      if (item.children?.length > 0) {
        let res = getTreeChild(item.children, path);
        if (res.path) {
          childrenRoute = res;
          return;
        }
      }
    }
  });
  return childrenRoute;
}

export function deepClone(source) {
  if (!source && typeof source !== 'object') {
    throw new Error('error arguments', 'deepClone');
  }
  const targetObj = source.constructor === Array ? [] : {};
  Object.keys(source).forEach((keys) => {
    if (source[keys] && typeof source[keys] === 'object') {
      targetObj[keys] = deepClone(source[keys]);
    } else {
      targetObj[keys] = source[keys];
    }
  });
  return targetObj;
}
/**
 * Filter asynchronous routing tables by recursion
 * @param routes asyncRoutes
 * @param roles
 */
// menus 请求的角色的菜单路由
export const filterAsyncRoutes = (menus, parent) => {
  let list = [];
  menus.forEach((item) => {
    if (item.childList?.length) {
      const routeChild = filterAsyncRoutes(item.childList, item);
      if (routeChild.length) item.childList = routeChild;
    }
    let obj = {
      path: item.menuUrl,
      name: item.menuUrl,
      hidden: item.isHidden === 'Y' ? true : false,
      children: item.childList,
      redirect: item.redirectUrl,
      meta: {
        title: item.menuName,
        icon: 'dashboard',
        activeMenu: parent ? parent.menuUrl : item.menuUrl,
        layout: parent?.componentUrl === 'Layout' ? 'Layout' : 'ProductLayout',
      },
    };
    if (item.componentUrl) {
      let url = item.componentUrl;
      if (!item.componentUrl.startsWith('views')) {
        url = 'views/' + url;
      }
      obj.component = () => import(`@/${url}.vue`);
    }
    if (!parent) {
      // 是否是根节点 parent为空为根节点
      obj.meta.popClass = 'menu-level-first';
    } else {
      obj.meta.class = 'menu-level-first';
    }
    list.push({
      ...obj,
    });
  });
  return list;
};

export function awaitTo(promise, errorExt) {
  return promise
    .then((data) => [null, data])
    .catch((err) => {
      if (errorExt) {
        const parsedError = Object.assign({}, err, errorExt);
        return [parsedError, undefined];
      }
      return [err, undefined];
    });
}
