import request from '@/utils/request';

/**
 * @description 企业列表
 * @return
 */
export const selectCorpPage = (data) => {
  return request('/auth/corp/selectCorpPage', { method: 'POST', data });
};

/**
 * @description 企业新增
 * @return
 */
export const corpSave = (data) => {
  return request('/auth/corp/save', { method: 'POST', data });
};

/**
 * @description 企业修改
 * @return
 */
export const corpUpdate = (data) => {
  return request('/auth/corp/update', { method: 'POST', data });
};

/**
 * @description 企业详情
 * @return
 */
export const corpDetailById = (data) => {
  return request('/auth/corp/selectByCorpOrgId', { method: 'POST', data });
};

