// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Variables */
[data-v-0323a64e]:export {
  menuText: #000;
  menuActiveText: #fff;
  subMenuActiveText: #f4f4f5;
  menuActiveBg: #1589cd;
  menuBg: #fff;
  menuHover: rgba(45, 153, 255, 0.3);
  subMenuBg: #fff;
  subMenuHover: rgba(45, 153, 255, 0.3);
  sideBarWidth: 200px;
  primaryColor: #1589cd;
  primaryFontColor: rgb(45, 153, 255);
  primaryColor_8: rgba(31, 113, 255, 0.08);
  globalColor: #e60012;
  defaultFontColor: rgb(20, 35, 69);
  tableHeaderBg: #fafafa;
  tableHeaderHeight: 40px;
  tableAlign: center;
  fontSize_14: 14px;
  fontSize_16: 16px;
  fontSize_18: 18px;
  fontSize_24: 24px;
}

/* Mixins */
[data-v-0323a64e] .select-identity-dialog .select-identity-card {
  width: 180px;
  height: 180px;
  margin: 0 auto;
  cursor: pointer;
  background-color: #F1F7FF;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}
[data-v-0323a64e] .select-identity-dialog .select-identity-card .identity-icon {
  font-size: 64px;
  color: rgba(31, 113, 255, 0.4);
}
[data-v-0323a64e] .select-identity-dialog .select-identity-card.currentCorpIdentityChecked {
  background-color: rgba(1, 98, 177, 0.2);
}
[data-v-0323a64e] .select-identity-dialog .select-identity-card.currentCorpIdentityChecked .el-card__body .identity-name {
  color: #1589cd;
  font-weight: 600;
}
[data-v-0323a64e] .select-identity-dialog .select-identity-card.currentCorpIdentityChecked .el-card__body .identity-icon {
  stroke: none;
}
[data-v-0323a64e] .select-identity-dialog .select-identity-card.select-identity-card:hover {
  background-color: rgba(1, 98, 177, 0.2);
}
[data-v-0323a64e] .select-identity-dialog .select-identity-card.select-identity-card:hover .el-card__body .identity-icon {
  stroke: none;
}
[data-v-0323a64e] .select-identity-dialog .select-identity-card .el-card__body {
  text-align: center;
  padding-top: 40px;
}
[data-v-0323a64e] .select-identity-dialog .select-identity-card .el-card__body .identity-name {
  padding-top: 20px;
  font-size: 20px;
  color: rgb(20, 35, 69);
}
[data-v-0323a64e] .select-identity-dialog .company-list-wrap {
  padding-top: 24px;
}
[data-v-0323a64e] .select-identity-dialog .company-list-wrap .el-row .el-col:first-child {
  text-align: right;
}
[data-v-0323a64e] .select-identity-dialog .company-list-wrap .el-row .el-col:nth-child(2) {
  text-align: center;
}
[data-v-0323a64e] .select-identity-dialog .company-list-wrap .company-list {
  padding: 0 0 12px 0;
}
[data-v-0323a64e] .select-identity-dialog .company-list-wrap .company-list .el-radio {
  width: 600px;
  height: 40px;
  vertical-align: middle;
  padding-top: 0;
  line-height: 2.857;
}
[data-v-0323a64e] .select-identity-dialog .company-list-wrap .company-list .el-radio:hover {
  border-color: #1589cd;
  color: #1589cd;
}
[data-v-0323a64e] .select-identity-dialog .company-list-wrap .company-list .el-radio .el-radio__input {
  display: none;
}
[data-v-0323a64e] .select-identity-dialog .company-list-wrap .company-list .el-radio .el-radio__label {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.65);
  line-height: 25px;
}
[data-v-0323a64e] .select-identity-dialog .company-list-wrap .company-list .el-radio:hover {
  border-color: #1589cd;
}
[data-v-0323a64e] .select-identity-dialog .company-list-wrap .company-list .el-radio:hover .el-radio__label {
  color: #1589cd;
}
[data-v-0323a64e] .select-identity-dialog .company-list-wrap .company-list .el-radio.is-checked {
  background-color: #1589cd;
}
[data-v-0323a64e] .select-identity-dialog .company-list-wrap .company-list .el-radio.is-checked .el-radio__label {
  color: #fff;
  font-weight: bold;
}
[data-v-0323a64e] .select-identity-dialog .company-list-wrap .circle-arrow {
  font-size: 18px;
  color: rgba(0, 0, 0, 0.65);
  cursor: pointer;
}
[data-v-0323a64e] .select-identity-dialog .company-list-wrap .circle-arrow.arrow-disabled {
  color: rgba(0, 0, 0, 0.25);
}
[data-v-0323a64e] .select-identity-dialog .company-list-wrap .circle-arrow.arrow-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
}
[data-v-0323a64e] .select-identity-dialog .company-list-wrap .circle-arrow:hover {
  color: #1589cd;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
