import request from '@/utils/request';

/**
 * @description 企业实名认证状态查询
 * @return
 */
export const fetchAdminInfo = (data) => {
  return request('/portal/corp/info/admin', { method: 'POST', data });
};

/**
 * @description 企业实名认证-人脸认证
 * @return
 */
export const authCorpFace = (data) => {
  return request('/portal/auth/corp/face', { method: 'POST', data });
};

/**
 * @description 企业实名认证-ukey认证
 * @return
 */
export const authCorpUkey = (data) => {
  return request('/portal/auth/corp/ukey', { method: 'POST', data });
};

/**
 * @description 企业实名认证成功提交
 * @return
 */
export const submitAuthCorp = (data) => {
  return request('/portal/auth/corp/face/result', { method: 'POST', data });
};

/**
 * @description 营业执照-ocr识别
 * @return
 */
export const orgImgOcr = (data) => {
  return request('/thirdparty/business-license/ocr', { method: 'POST', data });
};

/**
 * @description 身份证-ocr识别
 * @return
 */
export const idCardOcr = (data) => {
  return request('/thirdparty/idcard/ocr', { method: 'POST', data });
    // return request('/thirdparty/fasc/auth/ocr/idCardFaceSide', { method: 'POST', data });
};

/**
 * @description 个人实名认证-人脸认证
 * @return
 */
export const personFaceAuth = (data) => {
  return request('/portal/auth/employee/face', { method: 'POST', data });
};

/**
 * @description 个人实名认证-UKey认证
 * @return
 */
export const personUkeyAuth = (data) => {
  return request('/portal/auth/employee/ukey', { method: 'POST', data });
};

/**
 * @description 个人实名认证成功提交
 * @return
 */
export const submitAuthPerson = (data) => {
  return request('/portal/auth/employee/face/result', { method: 'POST', data });
};