<template>
  <div class="has-logo">
    <el-scrollbar wrap-class="scrollbar-wrapper">
      <el-menu
        :default-active="activeMenu"
        :collapse="isCollapse"
        :default-openeds="defaultOpeneds"
        :unique-opened="true"
        :collapse-transition="false"
        mode="vertical"
        router
      >
        <MenuItem :navMenus="menuData" />
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getTreeChild } from '@/utils/utils';
import { getStorage } from '@/utils/storage';
import MenuItem from './MenuItem.vue';

export default {
  name: 'Siderbar',
  components: {
    MenuItem,
  },

  data() {
    return {
      defaultOpeneds: [],
    };
  },
  watch: {},
  computed: {
    ...mapGetters(['sidebar', 'permission_routes', 'projectMenuTree', 'commonMenuTree']),

    notHomePage() {
      return this.$router.currentRoute?.meta?.routeType !== 'HomeRoute';
    },

    routes() {
      return this.filterRoutes(this.permission_routes);
    },

    activeMenu() {
      const { parentMenu } = this.$route.meta;
      let path = parentMenu || this.$route.path;
      return path;
    },

    isCollapse() {
      return !this.sidebar.opened;
    },

    menuData() {
      const { activeMenu } = this.$route.meta || {};
      const menuList = getStorage('appInfo') ? this.projectMenuTree : this.commonMenuTree;
      const route = getTreeChild(menuList, activeMenu);
      return route?.children || [];
    },
  },

  methods: {
    handleSelect(key, keyPath) {
      this.defaultOpeneds = [keyPath[0]];
    },

    filterRoutes(routes) {
      return routes.filter((item) => item.meta?.routeType !== 'HomeRoute');
    },
  },
};
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>
