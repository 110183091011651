<template>
  <div id="app">
    <component v-if="isRouterAlive" :is="layout()" ></component>
  </div>
</template>
<script>
import DefaultLayout from './layout/defaultLayout/index.vue';
import HomeLayout from './layout/homeLayout/index.vue';
import RegisterLayout from './layout/homeLayout/RegisterLayout.vue'
import Layout from './layout/index.vue';
import ProductLayout from './layout/ProductLayout.vue';

export default {
  components: {
    DefaultLayout,
    HomeLayout,
    Layout,
    RegisterLayout,
    ProductLayout,
  },
  provide(){
    return {
      reload:this.reload
    }
  },
  data() {
    return {
      isLoading: true,
      isRouterAlive:true,
      oldLayout: '',
    };
  },
  methods: {
    layout() {
      const layout = this.$route.meta.layout;
      if (this.oldLayout !== layout) {
        this.isLoading = false;
        this.$nextTick(() => {
          this.isLoading = true;
        });
      }
      this.oldLayout = layout;
      return layout;
    },
    reload(){
      this.isRouterAlive = false;
      this.$nextTick(()=>{
        this.isRouterAlive = true;
      })
    }
  },
};
</script>

<style lang="scss">
.timeline {
  padding-left: 25px;
  .el-timeline-item__timestamp {
    font-size: 14px;
    color: #000;
  }
  .el-timeline-item__tail {
    border-left: 2px solid #c0e0ff;
  }
}
</style>
