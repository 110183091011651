<template>
  <div class="page">
    <el-pagination
      v-if="pagination.total > 0"
      style="text-align: right; font-size: 14px"
      background
      :layout="layout"
      @current-change="handleCurrentChange"
      :page-size="pagination.pageSize"
      @size-change="handleSizeChange"
      :current-page.sync="currentPage"
      :page-sizes="pageSizeList"
      :total="pagination.total"
    ></el-pagination>
  </div>
</template>

<script type="text/ecmascript-6">
export default {
  name: 'zxPage',
  components: {},
  props: {
    //点击页码后是否滚到顶端
    needToTop: { type: Boolean, default: true },
    // 分页数据
    pagination: {
      type: Object,
      default: () => ({ pageSize: 10, pageNum: 1, current: 1, total: 0 }),
    },
    pageSizeList: {
      type: Array,
      default: () => [10, 20, 50, 100, 200, 500],
    },
    layout: { type: String, default: 'total,prev,pager,next,sizes,jumper' },
  },
  data() {
    return {};
  },
  computed: {
    currentPage: {
      get() {
        return this.pagination.current || this.pagination.pageNum || 0;
      },
      set() {},
    },
  },
  methods: {
    handleCurrentChange(val) {
      // eslint-disable-next-line vue/no-mutating-props
      this.pagination.pageNum = val;
      // eslint-disable-next-line vue/no-mutating-props
      this.pagination.current = val;
      this.$emit('refresh', this.pagination);
      this.needToTop && this.backToTop();
    },
    handleSizeChange(val) {
      // eslint-disable-next-line vue/no-mutating-props
      this.pagination.pageSize = val;
      // eslint-disable-next-line vue/no-mutating-props
      this.pagination.pageNum = 1;
      // eslint-disable-next-line vue/no-mutating-props
      this.pagination.current = 1;
      this.$emit('refresh', this.pagination);
      this.needToTop && this.backToTop();
    },
    backToTop() {
      const start = window.pageYOffset;
      let i = 0;
      this.interval = setInterval(() => {
        const next = Math.floor(this.easeInOutQuad(10 * i, start, -start, 500));
        if (next <= 0) {
          window.scrollTo(0, 0);
          clearInterval(this.interval);
        } else {
          window.scrollTo(0, next);
        }
        i++;
      }, 16);
    },
    easeInOutQuad(t, b, c, d) {
      if ((t /= d / 2) < 1) return (c / 2) * t * t + b;
      return (-c / 2) * (--t * (t - 2) - 1) + b;
    },
  },
  mounted() {},
  created() {},
};
</script>

<style scoped lang="scss">
@import './zxTable.scss';
</style>
<style>
.page {
  background-color: #fff;
  padding: 10px 10px 10px 10px;
}
</style>
