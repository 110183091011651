var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page-title" },
    [
      _vm.icons
        ? _c("svg-icon", { attrs: { "icon-class": _vm.icons } })
        : _vm._e(),
      _vm._v(" "),
      _c("p", [_vm._v(_vm._s(_vm.title))]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }