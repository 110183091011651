var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.item.elementUi
    ? _c("i", { class: _vm.item.class })
    : _c("i", { staticClass: "iconfont", class: _vm.item.class })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }