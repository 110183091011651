<template>
  <div class="home-container" ref="scrollHomeRef">
    <div class="home-layout">
      <div class="top-fixed" :style="{ boxShadow: visible ? '0 2px 18px 0 rgba(0, 0, 0, 0.20)' : 'unset' }">
        <section class="navbar horizontalNavbar">
          <div class="home-layout-nav">
            <div class="nav-left-nav"><LeftNav /></div>
            <!-- <div class="nav-right-menu"><RightMenu /></div> -->
          </div>
        </section>
      </div>
      <section class="main-section">
        <transition name="fade-transform" mode="out-in" @after-enter="afterEnter">
          <keep-alive>
            <router-view />
          </keep-alive>
        </transition>
        <!-- <BackTop /> -->
      </section>
      <!-- <BottomNav /> -->
      <Copyright />
    </div>
  </div>
</template>

<script>
// import TopNavbar from '../components/Navbar/TopNavbar/index.vue';
import LeftNav from '../components/Navbar/LeftNav/index.vue';
import RightMenu from '../components/Navbar/RightMenu/index.vue';
// import BottomNav from './BottomNav.vue';
import Copyright from './Copyright.vue';
// import BackTop from '@/components/BackTop/index.vue';

export default {
  name: 'HomeLayout',
  data() {
    return {
      visible: false,
    };
  },
  components: {
    // TopNavbar,
    LeftNav,
    RightMenu,
    // BottomNav,
    Copyright,
    // BackTop,
  },
  computed: {
    key() {
      return this.$route.path;
    },
  },
  methods: {
    afterEnter() {
      // 门户路由切换滚动到页面顶端
      // document.querySelector('.home-container.el-scrollbar__wrap') &&
      //   (document.querySelector('.home-container.el-scrollbar__wrap').scrollTop = 0);
    },
  },
};
</script>

<style lang="scss" scoped>
@import './index.scss';

// .el-scrollbar {
//   height: 100vh;
//   width: 100%;
//   position: relative;
//   overflow: hidden;
// }
.home-container {
  width: 100%;
  height: 100%;
  .home-layout {
    width: 100%;
    height: 100%;
  }
}

.main-section {
  background-image: url('@/assets/backdrop.png');
  margin-top: 64px;
  height: calc(100% - 64px);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  // background-color: #fff;
}
</style>
