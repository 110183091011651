<template>
  <footer>
    <span>{{ settings.footerCopyright }}</span>
    <span>
      ICP备案号：
      <a href="https://beian.miit.gov.cn" target="_blank"> {{ settings.icp }} </a>
    </span>
  </footer>
</template>

<script>
import settings from '@/defaultSettings';

export default {
  name: 'Copyright',
  data() {
    return {
      settings,
    };
  },
};
</script>

<style lang="scss" scoped>
footer {
  border-top: 1px solid rgba(0, 0, 0, 0.08);
  text-align: center;
  height: 48px;
  position: sticky;
  bottom: 0px;
  font-size: $fontSize_14;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  span {
    display: flex;
    margin: 0 10px
  }
  a {
    text-decoration: none;
    color: #fff;
  }
}
</style>
