// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Variables */
[data-v-1ff30a42]:export {
  menuText: #000;
  menuActiveText: #fff;
  subMenuActiveText: #f4f4f5;
  menuActiveBg: #1589cd;
  menuBg: #fff;
  menuHover: rgba(45, 153, 255, 0.3);
  subMenuBg: #fff;
  subMenuHover: rgba(45, 153, 255, 0.3);
  sideBarWidth: 200px;
  primaryColor: #1589cd;
  primaryFontColor: rgb(45, 153, 255);
  primaryColor_8: rgba(31, 113, 255, 0.08);
  globalColor: #e60012;
  defaultFontColor: rgb(20, 35, 69);
  tableHeaderBg: #fafafa;
  tableHeaderHeight: 40px;
  tableAlign: center;
  fontSize_14: 14px;
  fontSize_16: 16px;
  fontSize_18: 18px;
  fontSize_24: 24px;
}

/* Mixins */
.el-timeline[data-v-1ff30a42] {
  padding-left: 0;
}
[data-v-1ff30a42] .el-timeline-item:last-child {
  padding-bottom: 0;
}
[data-v-1ff30a42] .el-timeline-item__tail {
  top: 7px;
}
[data-v-1ff30a42] .el-timeline-item__node {
  top: 7px;
  width: 10px;
  height: 10px;
  background: #fff;
  border: 2px solid #1589cd;
}
[data-v-1ff30a42] .el-timeline-item__wrapper {
  display: flex;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
