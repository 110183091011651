import { bankBranchSelectPage } from '@/api/common';

const defaultState = () => {
  return {
    dataList: [],
    pagination: {
      pageSize: 10,
      current: 1,
      total: 0,
    },
  };
};
export const state = defaultState();

const mutations = {
  SAVE_SELECTPAGELIST(state, data) {
    state.dataList = data.records || [];
    state.pagination.total = Number(data.total);
  },

  RESET_PAGE(state) {
    state.pagination.current = 1;
    state.pagination.pageSize = 10;
  },

  RESET_STATE: (state) => {
    Object.assign(state, defaultState());
  },
};

const actions = {
  async selectPage({ commit }, payload) {
    const { data } = (await bankBranchSelectPage(payload)) || {};
    if (data) {
      commit('SAVE_SELECTPAGELIST', data);
      return data;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
