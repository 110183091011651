var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-link",
    {
      staticClass: "zxLink",
      class: `${_vm.item.class} ${!_vm.item.href ? "disabled" : ""}`,
      style: _vm.item.style,
      attrs: {
        type: _vm.item.type,
        target: _vm.item.target,
        href: _vm.item.href,
        underline: _vm.item.underline ? _vm.item.underline : false,
        disabled: _vm.item.showHash && !_vm.formData[_vm.item.prop],
      },
    },
    [
      _vm.item.showHash
        ? _c("span", [
            _vm.formData[_vm.item.prop]
              ? _c("span", [
                  _vm._v(" 成功上链"),
                  _c("i", { staticClass: "el-icon-link" }),
                ])
              : _c("span", [_vm._v("未上链")]),
          ])
        : _c("span", [
            _vm._v(
              "\n    " +
                _vm._s(
                  (_vm.item.formatter &&
                    _vm.item.formatter(_vm.formData[_vm.item.prop])) ||
                    _vm.formData[_vm.item.prop] ||
                    "暂无数据"
                ) +
                "\n  "
            ),
          ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }