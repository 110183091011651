<template>
  <div>
    <div class="button-wrap">
      <template v-if="!item.isPreview">
        <CustomForm
          ref="form"
          labelPosition="left"
          :showFormLineButtons="false"
          :formLineButtons="[]"
          :formData="searchFormData"
          :formCols="searchFormCols"
          :rules="rules"
          @event="handleEvent"
        >
        </CustomForm>
        <div class="button-content">
          <el-button
            :size="item.size || 'medium'"
            type="primary"
            :disabled="item.disabled"
            :loading="this.loading"
            @click="templateInput"
            >点击上传</el-button
          >
          <p>{{ file.buttonTips }}</p>
        </div>
        <el-form ref="elForm" :model="formData" :rules="item.rules">
          <el-form-item :prop="item.prop" label="">
            <el-upload
              v-show="false"
              ref="uploadFile"
              action="#"
              :headers="item.headers"
              :multiple="item.multiple"
              :data="item.data"
              :name="item.name"
              :accept="item.accept"
              :auto-upload="!item.notAutoUpload"
              :disabled="item.disabled"
              :limit="item.limit || 5"
              :list-type="item.listType"
              :file-list="(fileList && fileList.length > 0) || showFileList ? fileList : item.fileList"
              :show-file-list="false"
              :on-preview="!item.previewDisabled ? handlePictureCardPreview : defaultFunction"
              :on-change="handleChange"
              :on-exceed="handleExceed"
              :http-request="
                (file, fileList) => {
                  return uploadSectionFile(file, fileList);
                }
              "
            >
              <el-button ref="button">{{ item.showButtonTitle || '点击上传' }}</el-button>
            </el-upload>
          </el-form-item>
        </el-form>
      </template>
      <div class="preview">
        <div v-for="val in item.fileType" :key="val.value" class="preview-item">
          <template v-if="getFileList(val) && getFileList(val).length > 0">
            <!-- <p>{{ val.label }}：</p> -->
            <el-upload
              v-if="val.value !== 'video'"
              action="#"
              class="disUoloadSty"
              :disabled="item.isPreview"
              :auto-upload="false"
              :list-type="val.value !== 'file' ? 'picture-card' : 'text'"
              :on-preview="handlePictureCardPreview"
              :on-remove="handleRemove"
              :on-change="handleChange"
              :file-list="getFileList(val)"
            >
            </el-upload>
            <div v-else>
              <el-upload
                action="#"
                class="disUoloadSty"
                :auto-upload="false"
                :disabled="true"
                :list-type="'picture-card'"
                :on-preview="handlePictureCardPreview"
                :on-change="handleChange"
                :file-list="getFileList(val)"
              >
                <template slot="file" slot-scope="{ file }">
                  <!-- 自定义文件项内容 -->
                  <div class="video-item">
                    <video :src="file.url" controls></video>
                    <div class="el-upload-list__item-actions" v-if="!item.isPreview">
                      <span class="el-upload-list__item-delete" @click="handleRemove(file)">
                        <i class="el-icon-delete"></i>
                      </span>
                    </div>
                  </div>
                </template>
              </el-upload>
            </div>
          </template>
        </div>
      </div>
    </div>
    <ExampleDialog
      v-if="dialogImageUrl || fileUrl"
      ref="exampleDialog"
      :src="dialogImageUrl"
      :fileUrl="fileUrl"
      :isFile="false"
    />
  </div>
</template>

<script type="text/ecmascript-6">
import mixins from './mixins';
// pdf默认图片
import idCardFront from '@/assets/image/defaultPdf.png';
import ExampleDialog from '@/components/ExampleDialog/index.vue';
import { fetchFilesMeta, fetchUpload } from '@/api/common';
import { isFileType, getPdfOrImageConfig } from '@/utils/utils';

export default {
  mixins: [mixins],
  name: 'zxUpload',
  computed: {},
  components: {
    ExampleDialog,
  },
  data() {
    return {
      disabled: false,
      dialogImageUrl: '',
      fileUrl: '',
      defaultFunction: () => {},
      fileList: [],
      showFileList: false,
      loading: false,
      noneImg: false,

      file: {
        fileSize: 5,
        buttonTips: '',
        supportFileFormat: [],
      },
      rules: {
        fileType: [{ required: this.isRequired(), message: '请选择' }],
      },
      searchFormData: {
        fileType: '',
      },
      searchFormCols: [
        [
          {
            eType: 'Select',
            placeholder: '请选择',
            label: '选择附件类型：',
            prop: 'fileType',
            noShow: false,
            suffixIcon: '',
            clearable: true,
            filterable: true,
            span: 8,
            options: this.item.fileType,
          },
        ],
      ],
    };
  },
  watch: {
    'item.prop': {
      handler(val) {
        if (Array.isArray(this.formData[val])) {
          if (this.formData[val]?.length === 0 && this.fileList?.length > 0) {
            this.fileList = [];
          }
        } else if (!this.formData[val] && this.fileList?.length > 0) {
          this.fileList = [];
        }
      },
      deep: true,
    },
    'item.fileList': {
      handler(val) {
        this.noneImg = val?.length >= this.item.limit;
        this.fileList = val;
      },
      deep: true,
    },
    fileList(val) {
      this.noneImg = val?.length >= this.item.limit;
    },
  },
  mounted() {
    this.fileList = this.item?.fileList || [];
  },
  methods: {
    templateInput() {
      const { fileType } = this.searchFormData;
      if (!fileType) {
        this.$message.error('请选择附件类型');
        return;
      }
      this.$refs['button'].$el.click();
    },

    isRequired() {
      const { rules, prop } = this.item;
      if (rules[prop]?.length > 0) {
        const val = rules[prop].filter((item) => item.required)[0];
        if (val) {
          return true;
        }
        return false;
      }
      return false;
    },

    uploadSectionFile(file) {
      const fileRaw = file.file;
      const isLt10M = fileRaw.size / 1024 / 1024 <= (this.file.fileSize || 10);
      if (!isFileType(fileRaw, this.file.supportFileFormat)) {
        this.$message.error(this.item.errorTip || '上传文件格式不正确');
        this.removeFailFile(file);
        return false;
      }
      if (!isLt10M) {
        this.$message.error(this.item.errorTip || ` 上传的文件大小不能超过${this.file.fileSize || 10}M!`);
        this.removeFailFile(file);
        return false;
      }
      const data = {
        fetchUrl: this.item.action || '',
      };
      const formData = new FormData();
      // 文件对象
      formData.append('file', fileRaw);
      formData.append('fileName', fileRaw.name);
      this.loading = true;
      fetchUpload(formData, data)
        .then(async (res) => {
          if (res.rspCode === '00000000') {
            this.handleGetFileList({ ...res.data, ...fileRaw }, fileRaw?.name);
            this.mixinEvent({
              type: 'uploadSuccess',
              prop: this.item.prop,
              value: {
                ...res?.data,
                name: fileRaw?.name,
                fileName: fileRaw?.name,
              },
              response: res.data,
              fileList: this.fileList,
            });
          } else {
            this.removeFailFile(file);
            this.showFileList = true;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    // 删除
    handleRemove(file) {
      this.removeFailFile(file);
      this.fileList = this.fileList.filter((item) => item.uid !== file.uid);
      this.formData[this.item.prop] = undefined;
      this.mixinEvent({
        type: 'uploadRemove',
        prop: this.item.prop,
        value: this.formData[this.item.prop],
        fileList: this.fileList,
      });
    },

    getFileList(row) {
      const list = this.fileList.filter((item) => row.supportFileFormat.includes(`.${item.fileSuffix.toLowerCase()}`));
      // console.log(this.fileList, list, 'cccc');
      return list || [];
    },

    handlePreview() {},

    // 处理的默认
    async handleGetFileList(res, fileName) {
      const { fileSuffix, fileId, uid } = res || {};
      // 处理PDF
      if (fileSuffix.toLowerCase() === 'pdf') {
        this.fileList.push({
          uid,
          fileSuffix,
          fileId,
          name: fileName,
          url: idCardFront,
          response: res,
        });
      } else if (
        fileSuffix.toLowerCase() === 'png' ||
        fileSuffix.toLowerCase() === 'jpg' ||
        fileSuffix.toLowerCase() === 'jpeg'
      ) {
        this.fileList.push({
          uid,
          fileSuffix,
          fileId,
          name: fileName,
          url: getPdfOrImageConfig(fileId),
          response: res,
        });
      } else {
        const fileInfo = await fetchFilesMeta({ fileIds: fileId });
        this.fileList.push({
          uid,
          fileSuffix,
          fileId,
          name: fileName,
          url: fileInfo.data[0]?.url,
          response: fileInfo,
        });
      }
      console.log(this.fileList, '========fileList');
    },

    async handlePictureCardPreview(file) {
      if (file.fileId) {
        let obj = file;
        if (!file.fileSuffix) {
          const res = await fetchFilesMeta({ fileIds: file.fileId });
          if (res.rspCode === '00000000') {
            obj = res.data[0];
          }
        }
        if (obj.fileSuffix === 'pdf') {
          const fileUrl = obj.fileId;
          this.dialogImageUrl = undefined;
          this.fileUrl = fileUrl;
        } else if (obj.fileSuffix === 'doc' || obj.fileSuffix === 'docx') {
          this.fileUrl = undefined;
          this.dialogImageUrl = undefined;
          const res = await fetchFilesMeta({ fileIds: file.fileId });
          if (res.rspCode === '00000000') {
            window.open(`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(res.data[0]?.url)}`);
          }
        } else {
          const imageUrl = getPdfOrImageConfig(obj.fileId);
          this.fileUrl = undefined;
          this.dialogImageUrl = imageUrl;
        }
      } else {
        const { raw } = file;
        const url = URL.createObjectURL(raw);
        if (raw.type === 'application/pdf') {
          this.dialogImageUrl = undefined;
          this.fileUrl = url;
        } else {
          this.fileUrl = undefined;
          this.dialogImageUrl = url;
        }
      }
      this.$nextTick(() => {
        if (this.fileUrl || this.dialogImageUrl) {
          this.$refs.exampleDialog.open();
        }
      });
    },

    handleExceed(file) {
      this.$message.error(`最多上传${this.item.limit}个文件`);
    },

    handleChange(file, fileList) {
      if (file.status === 'fail') {
        this.$store.dispatch('user/LogOut');
        return;
      }

      // 不通过上传组件的接口上传，通过另一个按钮继续上传
      if (this.item.notAutoUpload) {
        const isLt10M = file.size / 1024 / 1024 <= (this.item.fileSize || 10);
        if (this.$refs.uploadFile.uploadFiles.length > this.item.limit) {
          this.$message.error(`上传文件已超过上限${this.item.limit}`);
          this.removeFailFile(file);
          return false;
        }
        if (!isLt10M) {
          this.$message.error(`上传的文件大小不能超过${this.item.fileSize || 10}M!`);
          this.removeFailFile(file);
          return isLt10M;
        }
        if (!isFileType(file, this.item.supportFileFormat)) {
          this.$message.error(this.item.errorTip || '上传文件格式不正确');
          this.removeFailFile(file);
          return false;
        }
        this.formData[this.item.prop] = file;
        this.mixinEvent({
          type: 'uploadSuccess',
          prop: this.item.prop,
          value: this.formData[this.item.prop],
          response: file?.response,
          fileList,
        });
      }
    },

    // 去除文件列表失败文件
    removeFailFile(file) {
      let uid = file.uid;
      let idx = this.$refs.uploadFile.uploadFiles.findIndex((item) => item.uid === uid); // 去除文件列表失败文件（uploadFiles为el-upload中的ref值）
      this.$refs.uploadFile.uploadFiles.splice(idx, 1); // 去除文件列表失败文件
    },

    handleEvent(e) {
      const { prop, value, type } = e;
      switch (prop) {
        case 'fileType': {
          if (type === 'change') {
            if (value) {
              this.file = this.item.fileType.filter((item) => item.value == value)[0];
            } else {
              this.file = {
                fileSize: 0,
                supportFileFormat: [],
                buttonTips: '',
              };
            }
          }
        }
      }
    },

    submit() {
      return new Promise((resolve) => {
        this.$refs['elForm'].validate((valid, message) => {
          console.log(valid, message);
          if (valid) {
            resolve(valid);
          }
        });
      });
    },

    resetForm() {
      this.$refs['elForm'].resetFields();
    },

    /**
     * 清楚校验
     * @param prop  数组中唯一标识值  Array | String
     */
    clearValidate() {
      this.$refs['elForm'] && this.$refs['elForm'].clearValidate();
    },
  },
};
</script>

<style scoped lang="scss">
.button-wrap {
  padding-left: 24px;
  margin-bottom: 24px;
  .button-content {
    margin-bottom: 16px;
    p {
      font-size: 14px;
    }
  }
}
.el-upload {
  display: inline-block;
  text-align: center;
  cursor: pointer;
  outline: none;
}

.upload-button {
  ::v-deep {
    .el-upload {
      text-align: left;
    }
  }
}

.preview {
  font-size: 14px;
  &-item {
    display: flex;
  }
}

.video-item {
  width: 148px;
  height: 148px;
  border: 1px solid #ccc;
  border-radius: 5px;
  video {
    height: 148px;
    width: 148px;
  }
  ::v-deep {
    .el-upload-list__item-actions {
      height: auto !important;
      opacity: 1;
      span {
        display: inline-block;
      }
    }
  }
}

::v-deep {
  .el-dialog__body {
    display: flex;
    justify-content: center;
  }
  .el-upload-dragger {
    background-color: #fff;
    border-radius: 6px;
    box-sizing: border-box;

    text-align: center;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    width: 260px;
    height: 100%;
  }
  .el-icon-upload {
    font-size: 78px;
    color: #c0c4cc;
    margin: 10px 0;
    line-height: 52px;
    color: $primaryColor_50;
  }
  .el-upload--picture-card {
    border: none;
  }
  .el-upload__tip {
    font-size: 12px;
    line-height: 20px;
    color: $grayFontColor;
    white-space: pre-wrap;
    text-align: center;
  }

  .el-upload-dragger ~ .el-upload__files {
    border-top: 1px solid #dcdfe6;
    margin-top: 7px;
    padding-top: 5px;
  }

  .el-upload-dragger .el-upload__text {
    color: #606266;
    font-size: 14px;
    text-align: center;
  }

  .el-upload-dragger .el-upload__text em {
    color: #409eff;
    font-style: normal;
  }

  .el-upload-dragger:hover {
    border-color: #409eff;
  }

  .el-upload-dragger.is-dragover {
    background-color: rgba(32, 159, 255, 0.06);
    border: 2px dashed #409eff;
  }
}

.disUoloadSty {
  ::v-deep {
    .el-upload {
      display: none !important;
    }
    .el-upload-list--text {
      margin-top: -14px;
    }
    .el-upload-list__item.is-success:focus:not(:hover) {
      display: none !important;
    }
    .el-upload--picture-card {
      display: none !important; /* 上传按钮隐藏 */
    }
  }
}
</style>
