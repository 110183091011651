// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Variables */
:export {
  menuText: #000;
  menuActiveText: #fff;
  subMenuActiveText: #f4f4f5;
  menuActiveBg: #1589cd;
  menuBg: #fff;
  menuHover: rgba(45, 153, 255, 0.3);
  subMenuBg: #fff;
  subMenuHover: rgba(45, 153, 255, 0.3);
  sideBarWidth: 200px;
  primaryColor: #1589cd;
  primaryFontColor: rgb(45, 153, 255);
  primaryColor_8: rgba(31, 113, 255, 0.08);
  globalColor: #e60012;
  defaultFontColor: rgb(20, 35, 69);
  tableHeaderBg: #fafafa;
  tableHeaderHeight: 40px;
  tableAlign: center;
  fontSize_14: 14px;
  fontSize_16: 16px;
  fontSize_18: 18px;
  fontSize_24: 24px;
}

/* Mixins */
/* Variables */
:export {
  menuText: #000;
  menuActiveText: #fff;
  subMenuActiveText: #f4f4f5;
  menuActiveBg: #1589cd;
  menuBg: #fff;
  menuHover: rgba(45, 153, 255, 0.3);
  subMenuBg: #fff;
  subMenuHover: rgba(45, 153, 255, 0.3);
  sideBarWidth: 200px;
  primaryColor: #1589cd;
  primaryFontColor: rgb(45, 153, 255);
  primaryColor_8: rgba(31, 113, 255, 0.08);
  globalColor: #e60012;
  defaultFontColor: rgb(20, 35, 69);
  tableHeaderBg: #fafafa;
  tableHeaderHeight: 40px;
  tableAlign: center;
  fontSize_14: 14px;
  fontSize_16: 16px;
  fontSize_18: 18px;
  fontSize_24: 24px;
}

/* Mixins */
/* fade */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.28s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

/* fade-transform */
.fade-transform-leave-active,
.fade-transform-enter-active {
  transition: all 0.5s;
}

.fade-transform-enter {
  opacity: 0;
  transform: translateX(-30px);
}

.fade-transform-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

/* breadcrumb transition */
.breadcrumb-enter-active,
.breadcrumb-leave-active {
  transition: all 0.5s;
}

.breadcrumb-enter,
.breadcrumb-leave-active {
  opacity: 0;
  transform: translateX(20px);
}

.breadcrumb-move {
  transition: all 0.5s;
}

.breadcrumb-leave-active {
  position: absolute;
}

.el-breadcrumb__inner,
.el-breadcrumb__inner a {
  font-weight: 400 !important;
}

.el-input.is-disabled .el-input__inner {
  color: #96A6C2;
  background-color: #E9EEF3;
  border: 1px solid #D6E0EA;
}

.el-tabs__item {
  color: rgb(20, 35, 69);
}

.el-upload input[type=file] {
  display: none !important;
}

.el-upload__input {
  display: none;
}

.el-form-item__label {
  color: #52607F;
  font-weight: 500;
  font-size: 14px;
}

.el-input__inner {
  border: 1px solid #DCDFE6;
  border-radius: 4px;
}

.cell .el-tag {
  margin-right: 0px;
}

.small-padding .cell {
  padding-left: 5px;
  padding-right: 5px;
}

.fixed-width .el-button--mini {
  padding: 7px 10px;
  width: 60px;
}

.status-col .cell {
  padding: 0 10px;
  text-align: center;
}
.status-col .cell .el-tag {
  margin-right: 0px;
}

.el-dialog {
  transform: none;
  left: 0;
  position: relative;
  margin: 0 auto;
  transform: none;
  left: 0;
  position: relative;
  margin: 0 auto;
}
.el-dialog .el-dialog__header {
  border-bottom: 1px solid #ebebeb;
}
.el-dialog .el-dialog__header {
  color: #515a6e !important;
  font-weight: 500 !important;
}

.upload-container .el-upload {
  width: 100%;
}
.upload-container .el-upload .el-upload-dragger {
  width: 100%;
  height: 200px;
}

.el-dropdown-menu a {
  display: block;
}

.el-range-editor.el-input__inner {
  display: inline-flex !important;
}

.el-range-separator {
  box-sizing: content-box;
}

.el-table {
  border-radius: 6px;
}

.el-table .el-table__header-wrapper th {
  background: #edf0f4 !important;
  color: rgb(20, 35, 69) !important;
}

.el-table .el-table__fixed-header-wrapper th {
  background: #edf0f4 !important;
  color: rgb(20, 35, 69) !important;
}

.el-table {
  color: rgb(20, 35, 69);
  border: 1px solid #edf0f4 !important;
}

.el-form .el-form-item__label {
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #52607F;
  padding: 0;
}

.el-form .el-checkbox__label {
  color: #52607F;
}

.el-form .el-input__inner {
  color: #52607F;
}

.el-form .el-radio {
  color: #52607F;
}

.el-form .el-textarea__inner {
  color: #52607F;
}

.el-textarea .el-input__count {
  background-color: transparent;
}

.el-table .el-table__cell {
  height: 50px;
  padding: 0;
}

.el-table .el-table__header-wrapper th,
.el-table .el-table__fixed-header-wrapper th {
  height: 50px !important;
}

.el-checkbox__input.is-disabled + span.el-checkbox__label {
  color: rgb(20, 35, 69);
  font-weight: normal;
}

.el-tooltip__popper {
  max-width: 300px !important;
}

/* Variables */
:export {
  menuText: #000;
  menuActiveText: #fff;
  subMenuActiveText: #f4f4f5;
  menuActiveBg: #1589cd;
  menuBg: #fff;
  menuHover: rgba(45, 153, 255, 0.3);
  subMenuBg: #fff;
  subMenuHover: rgba(45, 153, 255, 0.3);
  sideBarWidth: 200px;
  primaryColor: #1589cd;
  primaryFontColor: rgb(45, 153, 255);
  primaryColor_8: rgba(31, 113, 255, 0.08);
  globalColor: #e60012;
  defaultFontColor: rgb(20, 35, 69);
  tableHeaderBg: #fafafa;
  tableHeaderHeight: 40px;
  tableAlign: center;
  fontSize_14: 14px;
  fontSize_16: 16px;
  fontSize_18: 18px;
  fontSize_24: 24px;
}

.blue-btn {
  background: #324157;
}
.blue-btn:hover {
  color: #324157;
}
.blue-btn:hover:before, .blue-btn:hover:after {
  background: #324157;
}

.light-blue-btn {
  background: #3A71A8;
}
.light-blue-btn:hover {
  color: #3A71A8;
}
.light-blue-btn:hover:before, .light-blue-btn:hover:after {
  background: #3A71A8;
}

.red-btn {
  background: #C03639;
}
.red-btn:hover {
  color: #C03639;
}
.red-btn:hover:before, .red-btn:hover:after {
  background: #C03639;
}

.pink-btn {
  background: #E65D6E;
}
.pink-btn:hover {
  color: #E65D6E;
}
.pink-btn:hover:before, .pink-btn:hover:after {
  background: #E65D6E;
}

.green-btn {
  background: #30B08F;
}
.green-btn:hover {
  color: #30B08F;
}
.green-btn:hover:before, .green-btn:hover:after {
  background: #30B08F;
}

.tiffany-btn {
  background: #4AB7BD;
}
.tiffany-btn:hover {
  color: #4AB7BD;
}
.tiffany-btn:hover:before, .tiffany-btn:hover:after {
  background: #4AB7BD;
}

.yellow-btn {
  background: #FEC171;
}
.yellow-btn:hover {
  color: #FEC171;
}
.yellow-btn:hover:before, .yellow-btn:hover:after {
  background: #FEC171;
}

.iconWrap {
  margin-right: 10px;
}
.iconWrap .icon {
  width: 24px;
  height: 24px;
  position: relative;
  top: 5px;
  right: 4px;
}
.iconWrap.enable {
  color: #1589cd;
}
.iconWrap.danger {
  color: #ed675e;
}
.iconWrap.delete {
  color: #fd7404;
}
.iconWrap.green {
  color: #1589cd;
}
.iconWrap:last-child {
  margin-right: 0;
}

::v-deep .el-button.is-disabled,
::v-deep .el-button.is-disabled:hover,
::v-deep .el-button.is-disabled:focus {
  color: #C0C4CC;
  cursor: not-allowed;
}

.pan-btn {
  font-size: 14px;
  color: #fff;
  padding: 14px 36px;
  border-radius: 8px;
  border: none;
  outline: none;
  transition: 600ms ease all;
  position: relative;
  display: inline-block;
}
.pan-btn:hover {
  background: #fff;
}
.pan-btn:hover:before, .pan-btn:hover:after {
  width: 100%;
  transition: 600ms ease all;
}
.pan-btn:before, .pan-btn:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  height: 2px;
  width: 0;
  transition: 400ms ease all;
}
.pan-btn::after {
  right: inherit;
  top: inherit;
  left: 0;
  bottom: 0;
}

.custom-button {
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  background: #fff;
  color: #fff;
  -webkit-appearance: none;
  text-align: center;
  box-sizing: border-box;
  outline: 0;
  margin: 0;
  padding: 10px 15px;
  font-size: 14px;
  border-radius: 4px;
}

html {
  height: 100%;
}

body {
  height: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  overflow: hidden;
}

p,
h2,
h3,
h4,
h6,
ol,
ul {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  font-weight: 400;
}

ol,
ul {
  list-style: none;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  background-color: #F5F7FE;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}

html {
  font-family: Helvetica, Tahoma, Arial, Hiragino Sans GB, Hiragino Sans GB W3, Microsoft YaHei, STXihei, STHeiti, Heiti, SimSun, sans-serif;
}
html ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
html ::-webkit-scrollbar-thumb {
  position: relative;
  background-color: rgba(44, 62, 80, 0.15);
  border-radius: 4px;
}

#nav {
  padding: 30px;
}
#nav a {
  font-weight: bold;
  color: #2c3e50;
}
#nav a.router-link-exact-active {
  color: #42b983;
}

.el-card__header {
  font-size: 18px;
}

.table-tooltip {
  max-width: 250px;
  font-size: 14px;
}
.table-tooltip.el-tooltip__popper[x-placement^=top] .popper__arrow {
  left: 10px !important;
}

.ellipsis-tooltip {
  max-width: 800px;
}

.el-popover.table-button-pop {
  max-width: 250px;
}

.upload-chain-file-dialog .el-dialog__body .dialog-body .custom-steps-item:first-child {
  flex: 1.5;
}
.upload-chain-file-dialog .el-dialog__body .dialog-body .steps-wrap {
  text-align: center;
  padding-bottom: 40px;
}
.upload-chain-file-dialog .el-dialog__body .dialog-body .result-wrap {
  text-align: center;
}
.upload-chain-file-dialog .el-dialog__body .dialog-body .result-wrap .svg-icon {
  font-size: 64px;
  stroke: none;
}
.upload-chain-file-dialog .el-dialog__body .dialog-body .result-wrap .svg-icon.icon-success {
  color: rgb(103, 194, 58);
}
.upload-chain-file-dialog .el-dialog__body .dialog-body .result-wrap .svg-icon.icon-warning {
  color: rgb(250, 173, 20);
}
.upload-chain-file-dialog .el-dialog__body .dialog-body .result-wrap .svg-icon.icon-error {
  color: #ff4d4f;
}
.upload-chain-file-dialog .el-dialog__body .dialog-body .result-wrap .tips-status {
  font-size: 18px;
  padding: 20px 0 20px 0;
}
.upload-chain-file-dialog .el-dialog__body .dialog-body .result-wrap .tips-info {
  font-size: 14px;
}
.upload-chain-file-dialog .el-input-group__append {
  padding: 0;
  border: 0;
  width: 0;
}
.upload-chain-file-dialog .el-input-group__append .el-button {
  display: none;
}

.el-loading-spinner {
  top: 50vh;
}

.margin-tb-20,
.margin-top-20,
.margin-bottom-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
