// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Variables */
[data-v-a2d5db78]:export {
  menuText: #000;
  menuActiveText: #fff;
  subMenuActiveText: #f4f4f5;
  menuActiveBg: #1589cd;
  menuBg: #fff;
  menuHover: rgba(45, 153, 255, 0.3);
  subMenuBg: #fff;
  subMenuHover: rgba(45, 153, 255, 0.3);
  sideBarWidth: 200px;
  primaryColor: #1589cd;
  primaryFontColor: rgb(45, 153, 255);
  primaryColor_8: rgba(31, 113, 255, 0.08);
  globalColor: #e60012;
  defaultFontColor: rgb(20, 35, 69);
  tableHeaderBg: #fafafa;
  tableHeaderHeight: 40px;
  tableAlign: center;
  fontSize_14: 14px;
  fontSize_16: 16px;
  fontSize_18: 18px;
  fontSize_24: 24px;
}

/* Mixins */
.ellipsis-index-ellipsis[data-v-a2d5db78] {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: initial;
  -webkit-box-orient: vertical;
  word-break: break-all;
}
.ellipsis[data-v-a2d5db78] {
  display: inline-block;
  max-width: 70%;
  overflow: hidden;
  word-break: break-all;
}
.lines[data-v-a2d5db78] {
  position: relative;
}
.lines .shadow[data-v-a2d5db78] {
  position: absolute;
  z-index: -999;
  display: block;
  color: transparent;
  opacity: 0;
}
.lineClamp[data-v-a2d5db78] {
  position: relative;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
