<template>
  <section
    class="header-right-menu"
    :class="{ 'header-right-menu-homepage': !notHomePage, 'header-right-menu-dashboard': notHomePage }"
  >
    <el-menu
      :default-active="activeMenu"
      :unique-opened="true"
      :collapse-transition="false"
      mode="horizontal"
      @select="handleSelect"
      :key="`menu${count}`"
    >
      <MenuItem :navMenus="menuData" :showPopClass="showPopClass" />
    </el-menu>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import { getTreeChild } from '@/utils/utils';
import { getStorage } from '@/utils/storage';
import MenuItem from './MenuItem.vue';
import homeRoutes from '@/router/modules/homeRoutes';
import { getUserInfo } from '@/utils/auth';

export default {
  name: 'RightMenu',
  components: {
    MenuItem,
  },
  inject: {
    menuListType: {
      from: 'menuListType',
      default: '',
    },
  },
  data() {
    return {
      isAdmin: false,
      count: 0,
      showPopClass: true,
    };
  },

  mounted() {},

  computed: {
    ...mapGetters(['permission_routes', 'projectMenuTree', 'commonMenuTree']),

    notHomePage() {
      const activeMenus = homeRoutes.map((item) => item.path) || [];
      return !activeMenus.includes(this.activeMenu);
    },

    routes() {
      return this.notHomePage ? this.filterRoutes(this.permission_routes) : homeRoutes;
    },

    activeMenu() {
      const route = this.$route;
      const meta = route['meta'];
      const activeMenu = meta.activeMenu;
      return activeMenu || route.path;
    },

    menuData() {
      let menuList = getStorage('appInfo') ? this.projectMenuTree : this.commonMenuTree;

      const activeMenus = homeRoutes.map((item) => item.path) || [];
      if (activeMenus.includes(this.activeMenu)) {
        return this.retryRoutes(this.routes);
      }
      let list = [];
      if (menuList?.length > 0) {
        list = menuList.map((item) => {
          /* eslint-disable no-unused-vars */
          const { children, ...data } = item;
          return data;
        });
      }
      if (getUserInfo()?.approvalStatus !== 2 || getUserInfo()?.identityFlag !== 'Y') {
        return [];
      }
      return [
        {
          name: 'Dashboard',
          path: '/dashboard',
          meta: {
            popClass: 'menu-level-first',
            icon: 'dashboard',
            layout: 'Layout',
            title: '概览页',
          },
        },
        ...list,
      ];
    },
  },

  methods: {
    filterRoutes(routes) {
      return routes.filter((item) => item.meta?.routeType !== 'HomeRoute');
    },

    /**
     * 计算规则：
     * 1、没有parentMenu的做为第一级菜单。
     * 2、子菜单的parentMenu跟所有菜单的path进行比较，相同的为相应菜单的子菜单。
     */
    retryRoutes(routes) {
      let navRoutes = [];
      // 排除path为 '/'的路由之后的所有路由element，排除layout为ProductLayout的产品首页菜单
      const Routes = routes.filter((item) => {
        return item.path !== '/' && !item.meta?.hidden && item?.meta?.layout !== 'ProductLayout';
      });
      // 筛选一级根菜单
      const rootMenus = Routes.filter((item) => !item.meta?.parentMenu);
      // 筛选出排除根菜单的其他所有路由element
      const otherMenus = Routes.filter((item) => item.meta?.parentMenu);

      // 筛选parentMenu和path
      function checkParentMenu(element) {
        const checkRoutes = otherMenus.filter((item) => item.meta?.parentMenu === element.path);
        return checkRoutes;
      }

      // 遍历上级menu
      function mapMenu(routes) {
        [].forEach.call(routes, (item, index) => {
          if (checkParentMenu(item).length !== 0) {
            routes[index].children = checkParentMenu(item);
            if (routes[index].children.length !== 0) {
              return mapMenu(routes[index].children);
            }
          }
        });
        navRoutes = routes;
      }
      mapMenu(rootMenus);
      return navRoutes;
    },

    handleSelect(key) {
      const menuList = getStorage('appInfo') ? this.projectMenuTree : this.commonMenuTree;
      const route = getTreeChild(menuList, key);
      if (route?.children?.length) {
        this.$router.push(route?.children[0]?.path);
      } else {
        this.$router.push(key);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>
