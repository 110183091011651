import Vue from 'vue';
import defaultSettings from '../defaultSettings';

function isIE() {
  const Edge = navigator.userAgent.indexOf('Edge') > -1;
  if (!!window.ActiveXObject || 'ActiveXObject' in window || Edge) return true;
  return false;
}

/**
 * session 存储
 * @param {*} key
 * @param {*} value
 * @param {*} expires
 */
export function setStorage(key, value, expires) {
  Vue.ls.set(key, value, expires)
}
export function getStorage(key) {
  return Vue.ls.get(key)
}
export function removeStorage(key) {
  Vue.ls.remove(key)
}
export function clearStorage() {
  Vue.ls.clear()
}

/**
 * local 存储
 * @param {*} key
 * @param {*} value
 * @param {*} expires
 */
export function setLocalStorage(key, value, expires) {
  if (isIE()) {
    Vue.ls.set(key, value, expires)
  } else {
    localStorage.setItem(`${defaultSettings.projectPrefix}-${key}`, JSON.stringify(value));
  }
}
export function getLocalStorage(key) {
  if (isIE()) {
    return Vue.ls.get(key)
  }
  const data = localStorage.getItem(`${defaultSettings.projectPrefix}-${key}`)
  return data ? JSON.parse(data) : null;
}
export function removeLocalStorage(key) {
  if (isIE()) {
    Vue.ls.remove(key)
  } else {
    localStorage.removeItem(`${defaultSettings.projectPrefix}-${key}`);
  }
}
export function clearLocalStorage() {
  if (isIE()) {
    Vue.ls.clear();
  } else {
    localStorage.clear();
  }
}
