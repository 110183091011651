import request from '@/utils/request';

/**
 * @description 获取首页banner
 * @return {*}
 */
export const getHomeBannerList = () => {
  return request('/portal/index/banner/list', {
    method: 'POST',
  });
};

/**
 * @description 获取首页合作伙伴
 * @return {*}
 */
// export const getPartnerList = () => {
//   return request('/param/noAuth/selectCorpPartnerList');
// };

/**
 * @description 获取产业金融列表
 * @return {*}
 */
export const getProServiceList = () => {
  return request('/portal/index/product/list', {
    method: 'POST',
  });
};

/**
 * @description 获取平台动态列表
 * @return {*}
 */
export const getNewsList = (data) => {
  return request('/portal/index/news/list', { method: 'POST', data });
};

/**
 * @description 获取文章详情
 * @return {*}
 */
export const getNewsDetail = (id) => {
  return request(`/portal/index/news/detail`, {
    method: 'POST',
    data:{
      newsId:id.id
    }
  });
};

/**
 * @description 获取常见问题列表
 * @return {*}
 */
export const getQuestionList = () => {
  return request('/portal/index/question/list', { method: 'POST' });
};

/**
 * @description 协议
 * @return {*}
 */
export const protocolList = () => {
  return request('/portal/protocol/platform-protocol-sign-detail/list', { method: 'POST' });
};

/**
 * @description 协议签章
 * @return {*}
 */
export const protocoSign = (data) => {
  return request('/portal/protocol/platform-protocol-sign-detail/sign', { method: 'POST', data });
};

/**
 * @description 协议签章回调查询
 * @return {*}
 */
export const protocoUpdateSignDoc = (data) => {
  return request('/portal/sign/updateSignDoc', { method: 'POST', data });
};

/**
 * @description 协议签章流程接口
 * @return {*}
 */
export const protocoStartFlow = () => {
  return request('/portal/protocol/platform-protocol-sign-detail/startFlow', { method: 'POST' });
};

// 协议列表
export const protocolListA = () => {
  return request('/portal/protocol/platform-protocol-sign-result/list', { method: 'POST' });
};

// 获取首页统计
export const getIndexPageSummary = () => {
  return request('/warehouseReceipt/financeInfo/queryIndexPageSummary', { method: 'POST' });
};

