// 订单管理
const orderManagementRoutes = [
  // 经销商角色
  {
    path: '/orderManagement/initiateProcurement/catalogue',
    name: 'catalogue',
    component: () =>
      import(
        /* webpackChunkName: "orderManagement" */ '@/views/Agency/Dealer/OrderManagement/initiateProcurement/catalogue.vue'
      ),
    meta: {
      title: '商品目录',
      icon: 'dashboard',
      activeMenu: '/orderManagement',
      parentMenu: '/orderManagement/initiateProcurement',
      layout: 'ProductLayout',
      corpIdentity: ['DEALER_ENTERPRISE'],
      authority: ['NO-AUTHORITY'],
    },
  },
  {
    path: '/orderManagement/initiateProcurement/orderDetail',
    name: 'orderDetail',
    component: () =>
      import(
        /* webpackChunkName: "orderManagement" */ '@/views/Agency/Dealer/OrderManagement/initiateProcurement/orderDetail.vue'
      ),
    meta: {
      title: '订单信息',
      icon: 'dashboard',
      activeMenu: '/orderManagement',
      parentMenu: '/orderManagement/initiateProcurement',
      layout: 'ProductLayout',
      corpIdentity: ['DEALER_ENTERPRISE'],
      authority: ['NO-AUTHORITY'],
    },
  },
  {
    path: '/orderManagement/initiateProcurement/companyDetail',
    name: 'companyDetails',
    component: () =>
      import(
        /* webpackChunkName: "orderManagement" */ '@/views/Agency/Dealer/OrderManagement/initiateProcurement/companyDetail.vue'
      ),
    meta: {
      title: '公司详情',
      icon: 'dashboard',
      activeMenu: '/orderManagement',
      parentMenu: '/orderManagement/initiateProcurement',
      layout: 'ProductLayout',
      corpIdentity: ['DEALER_ENTERPRISE'],
      authority: ['NO-AUTHORITY'],
    },
  },
  {
    path: '/orderManagement/orderInformation/orderDetail',
    name: 'orderDetail',
    component: () =>
      import(
        /* webpackChunkName: "orderManagement" */ '@/views/Agency/Dealer/OrderManagement/orderInformation/detail.vue'
      ),
    meta: {
      title: '订单详情',
      icon: 'dashboard',
      hidden: true,
      layout: 'ProductLayout',
      activeMenu: '/orderManagement',
      parentMenu: '/orderManagement/orderInformation',
      corpIdentity: ['DEALER_ENTERPRISE'],
      authority: ['NO-AUTHORITY'],
    },
  },
  {
    path: '/orderManagement/deliveryorderInformation/billofLadingDetails',
    name: 'billofLadingDetails',
    component: () =>
      import(
        /* webpackChunkName: "orderManagement" */ '@/views/Agency/Dealer/OrderManagement/deliveryOrderInformation/billofLadingDetail.vue'
      ),
    meta: {
      title: '提货单详情',
      icon: 'dashboard',
      layout: 'ProductLayout',
      hidden: true,
      activeMenu: '/orderManagement',
      parentMenu: '/orderManagement/deliveryorderInformation',
      corpIdentity: ['DEALER_ENTERPRISE'],
      authority: ['NO-AUTHORITY'],
    },
  },

  // 金融机构
  {
    path: '/orderManagement/orderInformation/orderDetail',
    name: 'orderDetail',
    component: () =>
      import(
        /* webpackChunkName: "orderManagement" */ '@/views/Agency/Financial/OrderManagement/orderInformation/orderDetail.vue'
      ),
    meta: {
      title: '订单详情',
      icon: 'dashboard',
      hidden: true,
      layout: 'ProductLayout',
      activeMenu: '/orderManagement',
      parentMenu: '/orderManagement/orderInformation',
      corpIdentity: ['FINANCIAL_ENTERPRISE'],
      authority: ['NO-AUTHORITY'],
    },
  },
  {
    path: '/orderManagement/deliveryorderInformation/billofLadingDetail',
    name: 'billofLadingDetails',
    component: () =>
      import(
        /* webpackChunkName: "orderManagement" */ '@/views/Agency/Financial/OrderManagement/deliveryOrderInformation/billofLadingDetail.vue'
      ),
    meta: {
      title: '提货单详情',
      icon: 'dashboard',
      layout: 'ProductLayout',
      hidden: true,
      activeMenu: '/orderManagement',
      parentMenu: '/orderManagement/deliveryorderInformation',
      corpIdentity: ['FINANCIAL_ENTERPRISE'],
      authority: ['NO-AUTHORITY'],
    },
  },
];

export default orderManagementRoutes;
