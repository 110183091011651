import { render, staticRenderFns } from "./zxTableComponent.vue?vue&type=template&id=29122c6d&scoped=true"
import script from "./zxTableComponent.vue?vue&type=script&lang=js"
export * from "./zxTableComponent.vue?vue&type=script&lang=js"
import style0 from "./zxTableComponent.vue?vue&type=style&index=0&id=29122c6d&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29122c6d",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/mnt/data/jenkins/home/workspace/hd-prod-w51-web-portal/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('29122c6d')) {
      api.createRecord('29122c6d', component.options)
    } else {
      api.reload('29122c6d', component.options)
    }
    module.hot.accept("./zxTableComponent.vue?vue&type=template&id=29122c6d&scoped=true", function () {
      api.rerender('29122c6d', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/TableComponent/zxTableComponent.vue"
export default component.exports