var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("el-rate", {
    class: _vm.item.class,
    style: _vm.item.style,
    attrs: {
      colors: _vm.item.colors,
      "void-color": _vm.item.voidColor,
      "disabled-void-color": _vm.item.disabledVoidColor,
      "disabled-void-icon-class": _vm.item.disabledVoidIconClass,
      max: _vm.item.max,
      disabled: _vm.item.disabled,
      "icon-classes": _vm.item.iconClasses,
      "void-icon-class": _vm.item.voidIconClass,
      "allow-half": _vm.item.allowHalf,
      texts: _vm.item.texts,
      "show-text": _vm.item.showText,
      "low-threshold": _vm.item.lowThreshold,
    },
    on: { change: _vm.change },
    model: {
      value: _vm.formData[_vm.item.prop],
      callback: function ($$v) {
        _vm.$set(_vm.formData, _vm.item.prop, $$v)
      },
      expression: "formData[item.prop]",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }