var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-button",
    {
      class: _vm.item.class,
      style: _vm.item.style,
      attrs: {
        type: _vm.item.type,
        icon: _vm.item.icon,
        loading: _vm.item.loading,
        size: _vm.item.size || "medium",
        round: _vm.item.round,
        disabled: _vm.item.disabled,
        plain: _vm.item.plain,
        circle: _vm.item.circle,
        autofocus: _vm.item.autofocus,
        "native-type": _vm.item.nativeType,
      },
      on: { click: _vm.click },
    },
    [_vm._v("\n  " + _vm._s(_vm.item.value) + "\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }