<template>
  <div style="width: 100%">
      <div v-if="cardHeader">
        <span class="divider-title">{{ cardHeader }}</span>
        <el-divider></el-divider>
      </div>
      <slot></slot>
      <el-form
        v-if="form"
        ref="elForm"
        :model="tableForm"
        label-width="auto"
        :inline="true"
        size="small"
        label-position="center"
        class="tableForm"
      >
        <el-table
          ref="elTable"
          style="width: 100%"
          :row-key="rowKey"
          :tree-props="treeProps"
          :data="tableForm.tableData"
          :size="size"
          :id="id"
          :stripe="stripe"
          :fit="fit"
          :max-height="maxHeight"
          :border="isBorder"
          :show-header="showHeader"
          header-row-class-name="zx-table-header"
          :header-cell-style="headerCellStyle"
          :row-class-name="tableRowClassName"
          highlight-current-row
          :show-summary="showSummary"
          :sum-text="sumText"
          :defaultSelections="defaultSelections"
          v-loading="loading"
          element-loading-text="loading..."
          @cell-mouse-enter="cellMouseEnter"
          @cell-mouse-leave="cellMouseLeave"
          @selection-change="handleSelectionChange"
          @select="select"
          @select-all="selectAll"
          @cell-click="cellClick"
        >
          <el-table-column v-if="isSelection" :width="tableSelectionWidth" type="selection" align="center" />
          <el-table-column
            v-if="isIndex"
            type="index"
            :width="indexLabelWidth"
            :label="indexLabel"
            align="center"
            show-overflow-tooltip
          />
          <template v-for="(item, index) in tableCols">
            <el-table-column
              v-if="item.isShow === false ? false : true"
              :key="index"
              :prop="item.prop"
              :fixed="item.fixed"
              :label="item.label"
              :min-width="item.width"
              :render-header="item.addRedStar"
              :align="item.align"
              :show-overflow-tooltip="showOverflowTooltip"
            >
              <template slot-scope="scope">
                <slot v-if="item.eType === 'slot'" :name="item.slotName" :data="{ ...scope.row, ...item }"> </slot>
                <el-form-item
                  v-else-if="item.eType === 'Input' && !scope.row.isViewText"
                  :prop="`tableData.${scope.$index}.${item.prop}`"
                  :rules="item.rules || []"
                >
                  <el-input
                    v-model.trim="scope.row[item.prop]"
                    :size="item.size || size"
                    :disabled="item.disabled || (item.isDisabled && item.isDisabled(scope.row))"
                    :maxlength="item.maxlength || 32"
                    :clearable="item.clearable"
                    :placeholder="item.placeholder"
                    @focus="item.focus && item.focus(scope.row)"
                    @blur="item.blur && item.blur(scope.row)"
                  >
                  </el-input>
                </el-form-item>
                <el-form-item
                  v-else-if="item.eType === 'InputNumber' && !scope.row.isViewText"
                  :prop="`tableData.${scope.$index}.${item.prop}`"
                  :rules="item.rules || []"
                >
                  <el-input-number
                    v-model.trim="scope.row[item.prop]"
                    :size="item.size || size"
                    :disabled="item.disabled || (item.isDisabled && item.isDisabled(scope.row))"
                    :controls-position="item.controlsPosition"
                    style="width: 100%"
                    :style="item.style"
                    :class="item.class"
                    :min="item.min"
                    :max="item.max"
                    :step="item.step"
                    :step-strictly="item.stepStrictly"
                    :precision="item.precision"
                    :controls="item.controls"
                    :placeholder="item.placeholder"
                    @change="item.change && item.change(scope.row)"
                    @blur="item.blur && item.blur(scope.row)"
                    @focus="item.focus && item.focus(scope.row)"
                  >
                  </el-input-number>
                </el-form-item>
                <el-form-item
                  v-else-if="item.eType === 'Select' && !scope.row.isViewText"
                  :prop="`tableData.${scope.$index}.${item.prop}`"
                  :rules="item.rules || []"
                >
                  <el-select
                    :ref="`select${scope.$index}${item.prop}`"
                    v-model="scope.row[item.prop]"
                    :placeholder="item.placeholder"
                    :size="item.size || size"
                    :clearable="item.clearable"
                    :filterable="item.filterable || true"
                    :disabled="item.disabled || (item.isDisabled && item.isDisabled(scope.row))"
                    @change="
                      selectEvent({
                        prop: item.prop,
                        value: scope.row[item.prop],
                        index: scope.$index,
                        type: 'change',
                      })
                    "
                    @focus="
                      selectEvent({
                        prop: item.prop,
                        value: scope.row[item.prop],
                        index: scope.$index,
                        type: 'focus',
                      })
                    "
                    @blur="
                      selectEvent({
                        prop: item.prop,
                        value: scope.row[item.prop],
                        index: scope.$index,
                        type: 'blur',
                      })
                    "
                  >
                    <template v-for="option in tableCols[index].options">
                      <el-option
                        v-if="option"
                        :key="option[item.dictValue || 'value'] ? option[item.dictValue || 'value'] : option"
                        :label="option[item.dictLabel || 'label'] ? option[item.dictLabel || 'label'] : option"
                        :value="option[item.dictValue || 'value'] ? option[item.dictValue || 'value'] : option"
                      >
                      </el-option>
                    </template>
                  </el-select>
                </el-form-item>
                <el-form-item
                  v-else-if="item.eType === 'Switch' && !scope.row.isViewText"
                  :prop="`tableData.${scope.$index}.${item.prop}`"
                  :rules="item.rules || []"
                >
                  <el-switch
                    v-model="scope.row[item.prop]"
                    :active-value="item.activeValue || true"
                    :inactive-value="item.inactiveValue || false"
                  >
                  </el-switch>
                </el-form-item>
                <el-form-item
                  v-else-if="item.eType === 'Check' && !scope.row.isViewText"
                  :prop="`tableData.${scope.$index}.${item.prop}`"
                  :rules="item.rules || []"
                >
                  <el-checkbox-group v-model="scope.row[item.prop]">
                    <div v-for="option in item.options" :key="option.value ? option.value : option">
                      <el-checkbox
                        :key="option.value ? option.value : option"
                        :label="option.value ? option.value : option"
                        :disabled="item.disabled"
                        :style="item.style"
                        @change="item.change && item.change(scope.row)"
                      >
                        {{ option.label ? option.label : option }}
                      </el-checkbox>
                    </div>
                  </el-checkbox-group>
                </el-form-item>
                <!-- 按钮数量小于等于2个 -->
                <template
                  v-else-if="
                    (item.eType === 'Button' && (item.formatter(scope.row).length <= 2 || item.btnList <= 2)) ||
                    item.buttonNotDropdown
                  "
                >
                  <template v-for="(btn, btnIndex) in item.formatter(scope.row) || item.btnList">
                    <el-button
                      :class="`iconWrap ${btn.iconClass || ''}`"
                      :key="btnIndex"
                      :disabled="btn.disabled || (btn.isDisabled && btn.isDisabled(scope.row))"
                      :type="btn.type"
                      :size="btn.size || size"
                      :icon="btn.icon"
                      @keydown.enter.native.prevent
                      @click.stop="btn.handle(scope.row)"
                    >
                      <svg-icon v-if="btn.type === 'text' && btn.icon" class="icon" :icon-class="btn.icon" />

                      <span>{{ btn.label }}</span>
                    </el-button>
                  </template>
                </template>
                <!-- 按钮数量大于2个 -->
                <template
                  v-else-if="
                    item.eType === 'Button' &&
                    (item.formatter(scope.row).length > 2 || item.btnList > 2) &&
                    !item.buttonNotDropdown
                  "
                >
                  <template
                    v-for="(btn, btnIndex) in slice(item.formatter(scope.row), 0, 1) || slice(item.btnList, 0, 1)"
                  >
                    <el-popconfirm
                      trigger="click"
                      :ref="`popover-${scope.$index}`"
                      :key="btnIndex"
                      :tabindex="btnIndex"
                      v-if="btn.showPopConfirm"
                      :append-to-body="true"
                      :class="btnIndex === 1 ? 'table-popover-right-btn' : ''"
                      :confirm-button-text="btn.confirmText || '确定'"
                      :cancel-button-text="btn.cancelText || '取消'"
                      icon="el-icon-info"
                      icon-color="#1f71ff"
                      placement="top-end"
                      popper-class="table-button-pop"
                      :title="btn.title"
                      @confirm="btn.handle(scope.row, 'popConfirm')"
                      @cancel="btn.handle(scope.row, 'popCancel')"
                    >
                      <el-button
                        slot="reference"
                        :disabled="btn.disabled || (btn.isDisabled && btn.isDisabled(scope.row))"
                        :type="btn.type"
                        :size="btn.size || size"
                        :icon="btn.icon"
                        :class="['table-pop-confirm', btn.leftMargin ? 'left-margin' : '']"
                      >
                        {{ btn.label }}
                      </el-button>
                    </el-popconfirm>
                    <el-button
                      v-else
                      :key="btnIndex"
                      :class="`iconWrap ${btn.iconClass || ''}`"
                      :disabled="btn.disabled || (btn.isDisabled && btn.isDisabled(scope.row))"
                      :type="btn.type"
                      :size="btn.size || size"
                      :icon="btn.icon"
                      @keydown.enter.native.prevent
                      @click.stop="btn.handle(scope.row)"
                    >
                      <svg-icon v-if="btn.type === 'text' && btn.icon" class="icon" :icon-class="btn.icon" />
                      <span>{{ btn.label }}</span>
                    </el-button>
                  </template>
                  <el-dropdown
                    :tabindex="scope.$index"
                    :ref="`dropdown-${scope.$index}`"
                    trigger="click"
                    :hide-on-click="false"
                    @command="handleCommand"
                    style="margin-left: 5px"
                  >
                    <el-button type="text" size="small" class="iconWrap">
                      <svg-icon class="icon" icon-class="more" />
                      <span class="detail">更多</span>
                    </el-button>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item
                        v-for="(btn, btnIndex) in slice(
                          item.formatter(scope.row),
                          1,
                          item.formatter(scope.row).length
                        ) || slice(item.btnList, 1, item.btnList.length)"
                        :key="btnIndex"
                        :disabled="btn.disabled || (btn.isDisabled && btn.isDisabled(scope.row))"
                        :command="{ btn, row: scope.row }"
                      >
                        <el-popconfirm
                          trigger="click"
                          :ref="`popover-${scope.$index}`"
                          :key="btnIndex"
                          :tabindex="btnIndex"
                          v-if="btn.showPopConfirm"
                          :append-to-body="true"
                          :confirm-button-text="btn.confirmText || '确定'"
                          :cancel-button-text="btn.cancelText || '取消'"
                          icon="el-icon-info"
                          icon-color="#1f71ff"
                          placement="top-end"
                          popper-class="table-button-pop"
                          :title="btn.title"
                          @confirm="btn.handle(scope.row, 'popConfirm')"
                          @cancel="btn.handle(scope.row, 'popCancel')"
                        >
                          <el-button
                            slot="reference"
                            :disabled="btn.disabled || (btn.isDisabled && btn.isDisabled(scope.row))"
                            :type="btn.type"
                            :size="btn.size || size"
                            :icon="btn.icon"
                            :class="['table-pop-confirm', btn.leftMargin ? 'left-margin' : '']"
                          >
                            {{ btn.label }}
                          </el-button>
                        </el-popconfirm>
                        <el-button
                          v-else
                          :key="btnIndex"
                          :disabled="btn.disabled || (btn.isDisabled && btn.isDisabled(scope.row))"
                          :type="btn.type"
                          :size="btn.size || size"
                          :icon="btn.icon"
                        >
                          {{ btn.label }}
                        </el-button>
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </template>
                <template v-else-if="item.eType === 'Text'">
                  <slot v-if="item.sLot === 'slot'" :name="item.slotName" :data="{ ...scope.row, ...item }"> </slot>
                  <span v-else>
                    {{ (item.formatter && item.formatter(scope.row)) || scope.row[item.prop] }}
                  </span>
                </template>
                <template v-else-if="item.eType === 'Div'">
                  <div v-html="(item.formatter && item.formatter(scope.row)) || scope.row[item.prop]"></div>
                  <div
                    style="white-space: nowrap; word-break: break-all; text-overflow: ellipsis"
                    v-html="(item.formatter && item.formatter(scope.row)) || scope.row[item.prop]"
                  ></div>
                </template>
                <template v-else-if="scope.row.isViewText && item.eType !== 'Text' && item.eType !== 'Button'">
                  {{ (item.formatter && item.formatter(scope.row)) || scope.row[item.prop] }}
                </template>

                {{ !item.eType ? (item.formatter && item.formatter(scope.row)) || scope.row[item.prop] : '' }}
              </template>
            </el-table-column>
          </template>
          <template v-slot:empty>
            <div class="el-empty">
              <svg-icon class=".el-empty__image" icon-class="table_no_data" style="width: 65px; height: 65px" />
              <span style="display: block; text-align: center">暂无数据</span>
            </div>
          </template>
        </el-table>
      </el-form>
      <el-table
        v-else
        ref="elTable"
        :key="renderTableKey"
        :row-key="rowKey"
        :tree-props="treeProps"
        :data="tableData"
        :size="size"
        :id="id"
        :stripe="stripe"
        :fit="fit"
        :border="isBorder"
        :show-header="showHeader"
        header-row-class-name="zx-table-header"
        :header-cell-style="headerCellStyle"
        :row-class-name="tableRowClassName"
        highlight-current-row
        :show-summary="showSummary"
        :summary-method="getSummaries"
        :defaultSelections="defaultSelections"
        v-loading="loading"
        element-loading-text="loading..."
        @cell-mouse-enter="cellMouseEnter"
        @cell-mouse-leave="cellMouseLeave"
        @selection-change="handleSelectionChange"
        @select="select"
        @select-all="selectAll"
        @cell-click="cellClick"
      >
        <el-table-column
          v-if="isSelection"
          :width="tableSelectionWidth"
          :reserve-selection="true"
          :selectable="selectable"
          type="selection"
          align="center"
          fixed="left"
        />
        <el-table-column
          :show-overflow-tooltip="item.tooltip === false ? false : true"
          v-if="isIndex"
          type="index"
          :width="indexLabelWidth"
          :label="indexLabel"
          align="center"
        />
        <template v-for="(item, index) in tableCols">
          <el-table-column
            :key="index"
            :prop="item.prop"
            :fixed="item.fixed"
            :label="item.label"
            :min-width="!showWidth ? item.width : 'auto'"
            :width="showWidth ? item.width : item?.minWidth || 'auto'"
            :align="item.align"
            :show-overflow-tooltip="item.tooltip === false ? false : true"
            v-if="item.isShow === false ? false : true"
          >
            <template slot-scope="scope">
              <slot v-if="item.eType === 'slot'" :name="item.slotName" :data="{ ...scope.row, ...item }"> </slot>
              <el-input
                v-if="
                  item.eType === 'Input' ||
                  (scope.row.isEdit && item.eType !== 'Button' && item.rowEditType === 'Input')
                "
                v-model.trim="scope.row[item.prop]"
                :size="item.size || size"
                :disabled="item.disabled || (item.isDisabled && item.isDisabled(scope.row))"
                @change="item.change && item.change(scope.row)"
                @focus="item.focus && item.focus(scope.row)"
                @blur="item.blur && item.blur(scope.row)"
              >
              </el-input>
              <el-select
                v-if="
                  item.eType === 'Select' ||
                  (scope.row.isEdit && item.eType !== 'Button' && item.rowEditType === 'Select')
                "
                v-model="scope.row[item.prop]"
                :placeholder="item.placeholder"
                :size="item.size || size"
                :disabled="item.disabled || (item.isDisabled && item.isDisabled(scope.row))"
                @change="item.change && item.change(scope.row)"
                @focus="item.focus && item.focus(scope.row)"
                @blur="item.blur && item.blur(scope.row)"
              >
                <el-option
                  v-for="option in tableCols[index].options"
                  :key="option.value"
                  :label="option.label"
                  :value="option.value"
                >
                </el-option>
              </el-select>
              <el-tag
                v-if="item.eType === 'Tag'"
                size="medium"
                :type="(item.formatter(scope.row) && item.formatter(scope.row).type) || ''"
                >{{ (item.formatter(scope.row) && item.formatter(scope.row).label) || '' }}</el-tag
              >
              <!-- 按钮数量小于等于3个 -->
              <template v-if="item.eType === 'Button' && (item.formatter(scope.row).length <= 3 || item.btnList <= 3)">
                <template v-for="(btn, btnIndex) in item.formatter(scope.row) || item.btnList">
                  <el-popconfirm
                    trigger="click"
                    :ref="`popover-${scope.$index}`"
                    :key="btnIndex"
                    :tabindex="btnIndex"
                    v-if="btn.showPopConfirm"
                    :append-to-body="true"
                    :class="btnIndex === 1 ? 'table-popover-right-btn' : ''"
                    :confirm-button-text="btn.confirmText || '确定'"
                    :cancel-button-text="btn.cancelText || '取消'"
                    icon="el-icon-info"
                    icon-color="#009b4d"
                    placement="top-end"
                    popper-class="table-button-pop"
                    :title="btn.title"
                    @confirm="btn.handle(scope.row, 'popConfirm')"
                    @cancel="btn.handle(scope.row, 'popCancel')"
                  >
                    <el-button
                      slot="reference"
                      :disabled="btn.disabled || (btn.isDisabled && btn.isDisabled(scope.row))"
                      :type="btn.type"
                      :size="btn.size || size"
                      :icon="btn.icon"
                      class="iconWrap"
                      :class="['table-pop-confirm', btn.leftMargin ? 'left-margin' : '']"
                    >
                      <svg-icon v-if="btn.type === 'text' && btn.icon" class="icon" :icon-class="btn.icon" />
                      <span>{{ btn.label }}</span>
                    </el-button>
                  </el-popconfirm>
                  <el-button
                    v-else
                    :key="btnIndex"
                    :disabled="btn.disabled || (btn.isDisabled && btn.isDisabled(scope.row))"
                    :type="btn.type"
                    :size="btn.size || size"
                    :icon="btn.icon"
                    @click="btn.handle(scope.row)"
                    class="iconWrap"
                  >
                    <svg-icon v-if="btn.type === 'text' && btn.icon" class="icon" :icon-class="btn.icon" />
                    <span>{{ btn.label }}</span>
                  </el-button>
                </template>
              </template>
              <!-- 按钮数量大于3个 -->
              <template v-if="item.eType === 'Button' && (item.formatter(scope.row).length > 3 || item.btnList > 3)">
                <template
                  v-for="(btn, btnIndex) in slice(item.formatter(scope.row), 0, 2) || slice(item.btnList, 0, 2)"
                  style="padding-left: 0px !important; padding-right: 0px !important"
                >
                  <el-popconfirm
                    trigger="click"
                    :ref="`popover-${scope.$index}`"
                    :key="btnIndex"
                    :tabindex="btnIndex"
                    v-if="btn.showPopConfirm"
                    :append-to-body="true"
                    :confirm-button-text="btn.confirmText || '确定'"
                    :cancel-button-text="btn.cancelText || '取消'"
                    icon="el-icon-info"
                    icon-color="#009b4d"
                    popper-class="table-button-pop"
                    placement="top-end"
                    :title="btn.title"
                    @confirm="btn.handle(scope.row, 'popConfirm')"
                    @cancel="btn.handle(scope.row, 'popCancel')"
                  >
                    <el-button
                      slot="reference"
                      :disabled="btn.disabled || (btn.isDisabled && btn.isDisabled(scope.row))"
                      :type="btn.type"
                      :size="btn.size || size"
                      :icon="btn.icon"
                      class="iconWrap"
                      :class="['table-pop-confirm', btn.leftMargin ? 'left-margin' : '']"
                    >
                      <svg-icon v-if="btn.type === 'text' && btn.icon" class="icon" :icon-class="btn.icon" />
                      <span>{{ btn.label }}</span>
                    </el-button>
                  </el-popconfirm>
                  <el-button
                    v-else
                    :key="btnIndex"
                    :disabled="btn.disabled || (btn.isDisabled && btn.isDisabled(scope.row))"
                    :type="btn.type"
                    size="medium"
                    :icon="btn.icon"
                    @click="btn.handle(scope.row)"
                    class="iconWrap"
                  >
                    <svg-icon v-if="btn.type === 'text' && btn.icon" class="icon" :icon-class="btn.icon" />
                    <span>{{ btn.label }}</span>
                  </el-button>
                </template>
                <el-dropdown
                  :tabindex="scope.$index"
                  :ref="`dropdown-${scope.$index}`"
                  trigger="click"
                  :hide-on-click="true"
                  @command="handleCommand"
                  style="margin-left: 5px"
                >
                  <!-- // size="small" -->
                  <el-button type="text" size="medium" class="iconWrap">
                    <svg-icon class="icon" icon-class="more" />
                    <span class="detail">更多</span>
                  </el-button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      v-for="(btn, btnIndex) in slice(item.formatter(scope.row), 2, item.formatter(scope.row).length) ||
                      slice(item.btnList, 2, item.btnList.length)"
                      :key="btnIndex"
                      :disabled="btn.disabled || (btn.isDisabled && btn.isDisabled(scope.row))"
                      :command="{ btn, row: scope.row }"
                    >
                      <el-popconfirm
                        trigger="click"
                        :ref="`popover-${scope.$index}`"
                        :key="btnIndex"
                        :tabindex="btnIndex"
                        v-if="btn.showPopConfirm"
                        :append-to-body="true"
                        :confirm-button-text="btn.confirmText || '确定'"
                        :cancel-button-text="btn.cancelText || '取消'"
                        icon="el-icon-info"
                        icon-color="#009b4d"
                        placement="top-end"
                        popper-class="table-button-pop"
                        :title="btn.title"
                        @confirm="btn.handle(scope.row, 'popConfirm')"
                        @cancel="btn.handle(scope.row, 'popCancel')"
                      >
                        <el-button
                          slot="reference"
                          :disabled="btn.disabled || (btn.isDisabled && btn.isDisabled(scope.row))"
                          :type="btn.type"
                          :size="btn.size || size"
                          :icon="btn.icon"
                          class="iconWrap"
                          :class="['table-pop-confirm', btn.leftMargin ? 'left-margin' : '']"
                        >
                          <svg-icon v-if="btn.type === 'text' && btn.icon" class="icon" :icon-class="btn.icon" />
                          <span>{{ btn.label }}</span>
                        </el-button>
                      </el-popconfirm>
                      <el-button
                        v-else
                        :key="btnIndex"
                        :disabled="btn.disabled || (btn.isDisabled && btn.isDisabled(scope.row))"
                        :type="btn.type"
                        size="medium"
                        :icon="btn.icon"
                        class="iconWrap"
                      >
                        <svg-icon v-if="btn.type === 'text' && btn.icon" class="icon" :icon-class="btn.icon" />
                        <span>{{ btn.label }}</span>
                      </el-button>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </template>
              <template v-if="item.eType === 'Div'">
                <el-tooltip :tabindex="index" popper-class="table-tooltip" effect="dark" placement="top-start">
                  <div
                    slot="content"
                    v-html="(item.formatter && item.formatter(scope.row)) || scope.row[item.prop]"
                  ></div>
                  <div
                    style="white-space: nowrap; word-break: break-all; text-overflow: ellipsis"
                    v-html="(item.formatter && item.formatter(scope.row)) || scope.row[item.prop]"
                  ></div>
                </el-tooltip>
              </template>
              <template v-if="item.eType === 'Status'">
                <el-tooltip :tabindex="index" popper-class="table-tooltip" effect="dark" placement="top-start">
                  <div
                    slot="content"
                    v-html="(item.formatter && item.formatter(scope.row)) || scope.row[item.prop]"
                  ></div>
                  <div style="display: flex; align-items: center">
                    <div
                      class="statusColor"
                      :style="`width: 6px; height: 6px;border-radius: 50%;margin-right: 5px;margin-bottom: 2px;background:${
                        (item.formatter && item.formatter(scope.row)) === '已读' ? '#52c41a  ' : '#f5222d'
                      }`"
                    ></div>
                    <span style="white-space: nowrap; word-break: break-all; text-overflow: ellipsis">
                      {{ item.formatter && item.formatter(scope.row) }}
                    </span>
                  </div>
                </el-tooltip>
              </template>
              <template v-if="item.eType === 'Switch'">
                <el-switch
                  :value="(item.formatter && item.formatter(scope.row)) || scope.row[item.prop]"
                  active-color="#009b4d"
                  inactive-color="#DCDFE6"
                >
                </el-switch>
              </template>
              <template v-if="item.eType === 'tooltip'">
                <el-tooltip :tabindex="index" popper-class="table-tooltip" effect="dark" placement="left-end">
                  <div
                    slot="content"
                    v-text="(item.formatter && item.formatter(scope.row)) || scope.row[item.prop]"
                  ></div>
                  <span style="white-space: nowrap; word-break: break-all; text-overflow: ellipsis">
                    {{ (item.formatter && item.formatter(scope.row)) || scope.row[item.prop] }}
                  </span>
                </el-tooltip>
              </template>
              <span v-if="!item.eType">
                {{ (item.formatter && item.formatter(scope.row)) || scope.row[item.prop] }}
              </span>
              <!-- </el-tooltip> -->
            </template>
          </el-table-column>
        </template>
        <template v-slot:empty>
          <el-empty :image-size="100"></el-empty>
        </template>
      </el-table>
      <slot name="FooterSlot"></slot>
      <zx-page
        :pagination="pagination"
        :pageSizeList="pageSizeList"
        :layout="layout"
        @refresh="refresh"
        :needToTop="needToTop"
        v-if="isPagination"
      >
      </zx-page>
  </div>
</template>

<script type="text/ecmascript-6">
import zxPage from './zxPage';
import customTableMixin from '@/mixins/customTableMixin';
import { slice, throttle } from 'lodash';
import { Message } from 'element-ui';

export default {
  name: 'zxTable',
  mixins: [customTableMixin],
  components: {
    zxPage,
  },
  data() {
    return {
      slice,
      showWidth: true,
    };
  },
  watch: {
    tableCols: {
      deep: true,
      immediate: true,
      handler(list) {
        const pageResize = () => {
          this.$nextTick(() => {
            // 整个表格的宽度
            let tableWidth = this.$refs.elTable && this.$refs.elTable?.$el?.clientWidth;
            // 设置的所有宽度总和
            const allListWidth = list?.reduce((total, current) => (total += Number(current.width || 0)), 0);
            if (!tableWidth) {
              tableWidth = document.body.clientWidth - 322;
            }
            if (allListWidth && tableWidth > allListWidth) {
              this.showWidth = false;
              return;
            }
            this.showWidth = true;
          });
        };
        window.removeEventListener('resize', () => {});
        pageResize();
        window.addEventListener('resize', throttle(pageResize, 300));
      },
    },
    defaultSelections: {
      deep: true,
      immediate: true,
      handler(val) {
        this.$nextTick(() => {
          if (val?.length !== 0) {
            val.forEach((row) => {
              this.$refs.elTable && this.$refs.elTable.toggleRowSelection(row);
            });
          } else {
            this.$refs.elTable && this.$refs.elTable.clearSelection();
          }
        });
      },
    },

    tableData: {
      deep: true,
      immediate: true,
      handler(newVal) {
        if (newVal.length > 0) {
          this.$nextTick(() => {
            if (this.defaultSelections?.length > 0) {
              newVal.forEach((row) => {
                if (row && this.defaultSelections?.includes(row.id)) {
                  this.$refs.elTable && this.$refs.elTable.toggleRowSelection(row);
                }
              });
            } else {
              // this.$refs.elTable && this.$refs.elTable.clearSelection();
            }
          });
        }
      },
    },

    clearAllselect(val) {
      if (val) {
        this.$refs.elTable && this.$refs.elTable.clearSelection();
      }
    },
    setChangeSelectList(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.elTable && this.$refs.elTable.toggleRowSelection(row);
        });
      }
    },
  },
  updated() {
    this.$nextTick(() => {
      this.$refs.elTable && this.$refs.elTable.doLayout(); // 解决表格错位
    });
  },
  methods: {
    //提交校验
    submit() {
      return new Promise((resolve) => {
        this.$refs['elForm'].validate((valid, message) => {
          if (valid) {
            resolve(valid);
          } else if (this.needToast) {
            Message({
              type: 'error',
              message: message[Object.keys(message)[0]][0].message
                ? message[Object.keys(message)[0]][0].message
                : '校验失败',
            });
          }
        });
      });
    },
    //重置
    reset() {
      this.$refs['elForm'].resetFields();
    },
    // 手动是设置表格全选
    toggleAllSelection() {
      this.$refs.elTable && this.$refs.elTable.toggleAllSelection();
    },
    // 手动清除所有的已选中的表格
    clearSelection() {
      this.$refs.elTable && this.$refs.elTable.clearSelection();
    },
    // 手动设置勾选表格
    toggleRowSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.elTable && this.$refs.elTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.elTable && this.$refs.elTable.clearSelection();
      }
    },
    // 手动勾选表格
    select(rows, row) {
      if (this.isSingle) {
        this.$refs.elTable.clearSelection();
        this.$refs.elTable.toggleRowSelection(row);
        this.$emit('select', row);
      } else {
        this.$emit('select', rows, row);
      }
    },
    // 手动勾选全选表格
    selectAll(rows) {
      if (this.isSingle) {
        this.$refs.elTable.clearSelection();
        this.$emit('getAllSelect', '1');
        this.$message({ type: 'error', message: '只支持单选' });
      } else {
        this.$emit('selectAll', rows);
      }
    },

    //所有change以及click事件
    selectEvent(params) {
      this.$emit('event', params);
    },

    // 行点击事件
    cellClick(row, column, cell, event) {
      this.$emit('cellClick', row, column, cell, event);
    },
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex;
      if (this.tableRowClassNames) {
        return this.tableRowClassNames(row);
      }
    },
    refresh(pagination) {
      this.loadData(pagination);
    },
    //  获取选中
    handleSelectionChange(val) {
      this.$emit('getAllSelect', val);
    },

    // table中更多菜单中的按钮点击事件
    handleCommand(command) {
      const { btn, row } = command;
      btn.handle(row);
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '总计';
          return;
        }
        const values = data.map((item) => {
          if (this.amountArr.includes(column?.label)) {
            return Number(item[column.property]);
          } else {
            return Number('undefined');
          }
        });
        if (!values.every((value) => isNaN(value))) {
          sums[index] = values
            .reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0)
            .toFixed(2);
        }
      });
      return sums;
    },
  },

  created() {
    // if (this.isLoadData) this.loadData()
  },
};
</script>

<style scoped lang="scss">
@import './zxTable.scss';
</style>
<style>
/*定义滚动条宽高及背景，宽高分别对应横竖滚动条的尺寸*/
.el-table__body-wrapper::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/*定义滚动条的轨道，内阴影及圆角*/
.el-table__body-wrapper::-webkit-scrollbar-track {
  border-radius: 10px;
}

/*定义滑块，内阴影及圆角*/
.el-table__body-wrapper::-webkit-scrollbar-thumb {
  position: relative;
  width: 10px;
  height: 20px;
  border-radius: 10px;
  background-color: rgba(44, 62, 80, 0.15);
  border-radius: 4px;
}
</style>
