var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      ref: "dialog",
      attrs: {
        "custom-class": `custom-dialog ${_vm.className}`,
        visible: _vm.dialogVisible,
        width: _vm.dialogWidth,
        "before-close": _vm.handleClose,
        "modal-append-to-body": _vm.modalAppendToBody,
        "append-to-body": _vm.appendToBody,
        "show-close": _vm.showClose,
        "destroy-on-close": _vm.destroyClose,
        "close-on-click-modal": _vm.closeOnClickModal,
        "close-on-press-escape": _vm.closeOnPressEscape,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "dialog-title",
          class: { "dialog-title-center": _vm.isDialogTitleCenter },
          attrs: { slot: "title" },
          slot: "title",
        },
        [_vm._v("\n    " + _vm._s(_vm.title) + "\n  ")]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "dialog-body" }, [_vm._t("dialogBody")], 2),
      _vm._v(" "),
      _vm.showFooter
        ? _c(
            "div",
            {
              staticClass: "dialog-footer",
              class: { "dialog-footer-center": _vm.isDialogFooterCenter },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _vm.cancelButton
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        type: _vm.cancelButtonType,
                        size: "medium",
                        disabled: _vm.cancelButtonDisabled,
                      },
                      on: { click: _vm.handleCancel },
                    },
                    [
                      _c("svg-icon", {
                        staticStyle: { width: "16px", height: "16px" },
                        attrs: { "icon-class": "cancellation" },
                      }),
                      _vm._v(" " + _vm._s(_vm.cancelButton) + "\n    "),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.confirmButton
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        type: _vm.confirmButtonType,
                        size: "medium",
                        disabled: _vm.confirmButtonDisabled,
                        loading: _vm.confirmButtonLoading,
                      },
                      on: { click: _vm.handleSubmit },
                    },
                    [
                      _c("svg-icon", {
                        staticStyle: { width: "16px", height: "16px" },
                        attrs: { "icon-class": "save" },
                      }),
                      _vm._v(" " + _vm._s(_vm.confirmButton) + "\n    "),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }