<template>
  <el-dialog
    :custom-class="`custom-dialog ${className}`"
    :visible.sync="dialogVisible"
    :width="dialogWidth"
    :before-close="handleClose"
    :modal-append-to-body="modalAppendToBody"
    :append-to-body="appendToBody"
    :show-close="showClose"
    :destroy-on-close="destroyClose"
    :close-on-click-modal="closeOnClickModal"
    :close-on-press-escape="closeOnPressEscape"
    ref="dialog"
  >
    <div slot="title" class="dialog-title" :class="{ 'dialog-title-center': isDialogTitleCenter }">
      {{ title }}
    </div>
    <div class="dialog-body">
      <slot name="dialogBody"></slot>
    </div>
    <div
      slot="footer"
      class="dialog-footer"
      v-if="showFooter"
      :class="{ 'dialog-footer-center': isDialogFooterCenter }"
    >
      <el-button
        :type="cancelButtonType"
        size="medium"
        :disabled="cancelButtonDisabled"
        @click="handleCancel"
        v-if="cancelButton"
      >
      <svg-icon style="width: 16px;height: 16px;" icon-class="cancellation" /> {{ cancelButton }}
      </el-button>
      <el-button
        :type="confirmButtonType"
        size="medium"
        :disabled="confirmButtonDisabled"
        :loading="confirmButtonLoading"
        @click="handleSubmit"
        v-if="confirmButton"
      >
      <svg-icon style="width: 16px;height: 16px;" icon-class="save" /> {{ confirmButton }}
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { throttle } from 'lodash';

export default {
  name: 'Dialog',
  props: {
    dialogWidth: {
      type: String,
      default: '800px',
    },
    title: {
      type: String,
      default: '',
    },
    className: {
      type: String,
      default: '',
    },
    showFooter: {
      type: Boolean,
      default: true,
    },
    showClose: {
      type: Boolean,
      default: true,
    },
    cancelButton: {
      type: String,
      default: '取消',
    },
    cancelButtonType: {
      type: String,
      default: 'default',
    },
    cancelButtonDisabled: {
      type: Boolean,
      default: false,
    },
    cancelButtonEvent: {
      type: String,
      default: 'cancel',
    },
    confirmButton: {
      type: String,
      default: '确定',
    },
    confirmButtonType: {
      type: String,
      default: 'primary',
    },
    confirmButtonDisabled: {
      type: Boolean,
      default: false,
    },
    confirmButtonLoading: {
      type: Boolean,
      default: false,
    },
    confirmButtonEvent: {
      type: String,
      default: 'submit',
    },
    closeEvent: {
      type: String,
      default: 'close',
    },
    hasCloseConfirm: {
      type: Boolean,
      default: false,
    },
    isDialogTitleCenter: {
      type: Boolean,
      default: false,
    },
    isDialogFooterCenter: {
      type: Boolean,
      default: true,
    },
    // 遮罩层是否插入至 body 元素上，若为 false，则遮罩层会插入至 Dialog 的父元素上
    modalAppendToBody: {
      type: Boolean,
      default: false,
    },
    // Dialog 自身是否插入至 body 元素上。嵌套的 Dialog 必须指定该属性并赋值为 true
    appendToBody: {
      type: Boolean,
      default: false,
    },
    // 关闭时销毁 Dialog 中的元素
    destroyClose: {
      type: Boolean,
      default: false,
    },
    // 是否可以通过点击 modal 关闭 Dialog
    closeOnClickModal: {
      type: Boolean,
      default: false,
    },
    // 是否可以通过按下 ESC 关闭 Dialog
    closeOnPressEscape: {
      type: Boolean,
      default: true,
    },
    // 是否为阅读
    isRead: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      dialogVisible: false,
      read: false,
    };
  },

  watch: {
    read() {
      // 移除事件监听 抛出事件
      this.removeEvent();
      this.$emit('isReadFn', true);
    },
  },
  // 移除事件监听
  destroyed() {
    this.removeEvent();
  },
  methods: {
    open() {
      this.$nextTick(() => {
        this.dialogVisible = true;
        // 协议阅读需要监听滚动
        if (this.isRead) {
          setTimeout(() => {
            const element = this.$refs.dialog?.$el
              ?.querySelector('iframe')
              ?.contentWindow?.document?.querySelector('#viewerContainer');
            if (element?.scrollHeight === element?.clientHeight) {
              this.read = !this.read;
            } else element && element.addEventListener('scroll', throttle(this.handleScroll, 50));
          }, 1000);
        }
      });
    },

    removeEvent() {
      const element = this.$refs.dialog?.$el
        ?.querySelector('iframe')
        ?.contentWindow?.document?.querySelector('#viewerContainer');
      element && element.removeEventListener('scroll', this.handleScroll);
    },
    // 滚动的处理方法
    handleScroll(e) {
      const scrollTop = e.target.scrollTop;
      const clientHeight = e.target?.clientHeight;
      const scrollHeight = e.target?.scrollHeight;
      if (scrollHeight - clientHeight - scrollTop < 100) {
        this.read = !this.read;
      }
    },
    close() {
      this.dialogVisible = false;
      this.removeEvent();
    },

    handleClose(done) {
      if (this.hasCloseConfirm) {
        this.$confirm('确认关闭？')
          .then(() => {
            done();
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        this.$emit('event', { prop: this.closeEvent });
        done();
      }
    },

    handleSubmit() {
      this.$emit('event', { prop: this.confirmButtonEvent });
    },
    handleCancel() {
      this.$emit('event', { prop: this.cancelButtonEvent });
      if (this.cancelButtonEvent === 'cancel') {
        this.dialogVisible = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>
