import Vue from 'vue';
import router from './router';
import Cookies from 'js-cookie';
import { setStorage, removeStorage } from './utils/storage';
import * as filters from './filters'; // global filters
import store from './store';

// 贝塞尔曲线
const cubic = (value) => Math.pow(value, 3);
const easeInOutCubic = (value) => (value < 0.5 ? cubic(value * 2) / 2 : 1 - cubic((1 - value) * 2) / 2);

const myGlobal = {
  defaultSize: Cookies.get('size') || 'medium',
  loginFormSize: Cookies.get('size') || 'medium',
  routerPush: async (path, data) => {
    await removeStorage(path);
    if (data) setStorage(path, data);
    router.push(path);
  },
  routerReplace: async (path, data) => {
    await removeStorage(path);
    router.replace(path).then((done) => {
      if (done) {
        setStorage(path, data);
      }
    });
  },
  rollTop: (scrollTop) => {
    const scrollDom = document.querySelector('.page-el-scrollbar');
    if (scrollDom) {
      const beginTime = Date.now();
      const beginValue = scrollTop || 0;
      const rAF = window.requestAnimationFrame || ((func) => setTimeout(func, 16));
      const frameFunc = () => {
        const progress = (Date.now() - beginTime) / 500;
        if (progress < 1) {
          scrollDom.scrollTop = beginValue + beginValue * (1 - easeInOutCubic(progress));
          rAF(frameFunc);
        } else {
          scrollDom.scrollTop = beginValue;
        }
      };
      rAF(frameFunc);
    }
  },
  storeDispatch: async (path, param = {}) => {
    const result = await store.dispatch(path, param);
    return result;
  },
  storeCommit: (path, param) => {
    if (param) {
      return store.commit(path, param);
    } else {
      return store.commit(path);
    }
  },
  storeState: (path, param) => {
    return store.state[path][param];
  },
};

for (const key in myGlobal) {
  if (Vue.prototype.hasOwnProperty.call(myGlobal, key)) {
    const element = myGlobal[key];
    Vue.prototype[`$${key}`] = element;
  }
}

Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});

import CustomCard from './components/CustomCard/index.vue';
import CustomForm from './components/FormComponent/index.vue';
import CustomTable from './components/TableComponent/zxTable.vue';
import CustomDescriptions from './components/DescriptionsComponent/zxDescriptions.vue'
import CommonSubTitle from "@/components/Common/subTitle";
import { TabsCustomCard } from './components';
import Pagination from "@/components/Pagination";

const myComponent = {
  CustomCard,
  CustomForm,
  CustomTable,
  CustomDescriptions,
  TabsCustomCard,
  CommonSubTitle,
  Pagination,
}

Object.keys(myComponent).forEach(key=>{
  Vue.component(key, myComponent[key]);
})
