// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/backdrop.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Variables */
[data-v-9c8f93b0]:export {
  menuText: #000;
  menuActiveText: #fff;
  subMenuActiveText: #f4f4f5;
  menuActiveBg: #1589cd;
  menuBg: #fff;
  menuHover: rgba(45, 153, 255, 0.3);
  subMenuBg: #fff;
  subMenuHover: rgba(45, 153, 255, 0.3);
  sideBarWidth: 200px;
  primaryColor: #1589cd;
  primaryFontColor: rgb(45, 153, 255);
  primaryColor_8: rgba(31, 113, 255, 0.08);
  globalColor: #e60012;
  defaultFontColor: rgb(20, 35, 69);
  tableHeaderBg: #fafafa;
  tableHeaderHeight: 40px;
  tableAlign: center;
  fontSize_14: 14px;
  fontSize_16: 16px;
  fontSize_18: 18px;
  fontSize_24: 24px;
}

/* Mixins */
.home-layout .top-fixed[data-v-9c8f93b0] {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
  background-color: #fff;
}
.home-layout .top-navbar[data-v-9c8f93b0] {
  width: 100%;
  min-width: 1000px;
  height: 52px;
  background-color: rgba(31, 113, 255, 0.7);
}
.home-layout .navbar[data-v-9c8f93b0] {
  width: 100%;
  min-width: 1000px;
  height: 64px;
  overflow: hidden;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  z-index: 99;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.home-layout .navbar.horizontalNavbar[data-v-9c8f93b0] {
  min-height: 64px;
}
.home-layout .navbar .home-layout-nav[data-v-9c8f93b0] {
  width: 95%;
  margin: 0 auto;
  display: flex;
  align-items: center;
}
.home-layout .navbar .home-layout-nav .nav-right-menu[data-v-9c8f93b0] {
  flex: 2;
  text-align: right;
  display: flex;
  justify-content: flex-end;
}
.home-layout[data-v-9c8f93b0] .animate__animated {
  animation-duration: 500ms;
}
.home-layout[data-v-9c8f93b0] .el-loading-mask {
  max-width: 100vw;
  max-height: 100vh;
}
.home-container[data-v-9c8f93b0] {
  width: 100%;
  height: 100%;
}
.home-container .home-layout[data-v-9c8f93b0] {
  width: 100%;
  height: 100%;
}
.main-section[data-v-9c8f93b0] {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  margin-top: 64px;
  height: calc(100% - 64px);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
