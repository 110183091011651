// 仓储管理
const warehousemanagementRoutes = [
  {
    path: '/warehouseManagement/confirmationOfArrivalAtThePort/confirmationOfArrivalDetails',
    name: 'confirmationOfArrivalDetails',
    component: () =>
      import(
        /* webpackChunkName: "warehouseManagement" */ '@/views/Agency/Dealer/WarehouseManagement/confirmationOfArrivalAtThePort/detail.vue'
      ),
    meta: {
      title: '到港确认详情',
      icon: 'dashboard',
      activeMenu: '/warehouseManagement',
      parentMenu: '/warehouseManagement/confirmationOfArrivalAtThePort',
      layout: 'ProductLayout',
      corpIdentity: ['STORAGE_ENTERPRISE', 'DEALER_ENTERPRISE'],
      authority: ['NO-AUTHORITY'],
    },
  },
  {
    path: '/warehouseManagement/arrivalConfirmation/arrivalConfirmationDetail',
    name: 'confirmationOfArrivalDetails',
    component: () =>
      import(
        /* webpackChunkName: "warehouseManagement" */ '@/views/Agency/Dealer/WarehouseManagement/arrivalConfirmation/detail.vue'
      ),
    meta: {
      title: '到货确认详情',
      icon: 'dashboard',
      activeMenu: '/warehouseManagement',
      parentMenu: '/warehouseManagement/arrivalConfirmation',
      layout: 'ProductLayout',
      corpIdentity: ['STORAGE_ENTERPRISE', 'DEALER_ENTERPRISE'],
      authority: ['NO-AUTHORITY'],
    },
  },
  {
    path: '/warehouseManagement/electronicWarehouseReceipt/electronicWarehouseDetail',
    name: 'confirmationOfArrivalDetails',
    component: () =>
      import(
        /* webpackChunkName: "warehouseManagement" */ '@/views/Agency/Dealer/WarehouseManagement/electronicWarehouseReceipt/detail.vue'
      ),
    meta: {
      title: '电子仓单详情',
      icon: 'dashboard',
      activeMenu: '/warehouseManagement',
      parentMenu: '/warehouseManagement/electronicWarehouseReceipt',
      layout: 'ProductLayout',
      corpIdentity: ['STORAGE_ENTERPRISE', 'DEALER_ENTERPRISE'],
      authority: ['NO-AUTHORITY'],
    },
  },
  {
    path: '/warehouseManagement/electronicWarehouseReceipt/cancellation',
    component: (resolve) =>
      require(['@/views/Agency/Dealer/WarehouseManagement/electronicWarehouseReceipt/cancellation'], resolve),
    meta: {
      title: '仓单注销',
      layout: 'ProductLayout',
      hidden: true,
      activeMenu: '/warehouseManagement',
      parentMenu: '/warehouseManagement/electronicWarehouseReceipt',
      authority: ['NO-AUTHORITY'],
    },
  },
];

export default warehousemanagementRoutes;
