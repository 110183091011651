<template>
  <div class="customization-card" :class="cardClassName">
    <div :class="headerClass" v-if="headerTitle">
      <span class="header-title">{{ headerTitle }}</span>
      <span class="sub-title" v-if="subTitle && type === 'desc'">{{ subTitle }}</span>
    </div>
    <slot> </slot>
    <div class="divider-hr"></div>

    <slot name="bodySlot"></slot>

    <div class="divider-dr"></div>
    <CustomForm
      ref="customForm"
      :formRowGutter="formRowGutter"
      :isInline="isInline"
      :formData="formData"
      :formCols="formCols"
      :moreFilterProp="moreFilterProp"
      :label-suffix="labelSuffix"
      :status-icon="statusIcon"
      :size="size"
      :disabled="disabled"
      :inline="isInline"
      :label-width="labelWidth"
      :label-position="labelPosition"
      :rules="rules"
      :needToast="needToast"
      :showFormLineButtons="showFormLineButtons"
      :formLineBtnAuth="formLineBtnAuth"
      :loading="loading"
      @event="event"
      @submit="submit"
      @submit.native.prevent
    />
    <slot name="popConFirmDisabled"></slot>
    <CustomTableComponent
      v-if="tabHidden"
      :rowKey="rowKey"
      :renderTableKey="renderTableKey"
      :tableData="tableData"
      :clearAllselect="clearAllselect"
      :setChangeSelectList="setChangeSelectList"
      :tableSelectionWidth="tableSelectionWidth"
      :tableCols="tableCols"
      :isSelection="isSelection"
      :defaultSelections="defaultSelections"
      :loading="loading"
      :layout="layout"
      :isBorder="isBorder"
      :showOverflowTooltip="showOverflowTooltip"
      :isIndex="isIndex"
      :indexLabel="indexLabel"
      :indexLabelWidth="indexLabelWidth"
      :selectable="selectable"
      @select="select"
      @selectAll="selectAll"
      @getAllSelect="getAllSelect"
      @cellClick="cellClick"
    />

    <zx-page
      :pagination="pagination"
      :pageSizeList="pageSizeList"
      :layout="layout"
      @refresh="refresh"
      :needToTop="needToTop"
      v-if="isPagination"
    />
  </div>
</template>

<script>
import { take, slice } from 'lodash';
import customFormMixin from '@/mixins/customFormMixin';
import customTableMixin from '@/mixins/customTableMixin';
import CustomTableComponent from '../TableComponent/zxTableComponent.vue';
import zxPage from '../TableComponent/zxPage';

export default {
  mixins: [customFormMixin, customTableMixin],
  name: 'CustomCard',
  components: {
    CustomTableComponent,
    zxPage,
  },
  props: {
    rowKey: {
      type: String,
      default: 'id',
    },
    cardClassName: {
      type: String,
      default: '',
      required: false,
    },
    headerClass: {
      type: String,
      default: 'header-wrap',
      required: false,
    },
    contentName: {
      type: String,
      default: 'body-wrap',
      required: false,
    },
    type: {
      type: String,
      default: '',
      required: false,
    },
    formHidden: {
      type: Boolean,
      default: true,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    bodyHidden: {
      type: Boolean,
      default: true,
      required: false,
    },
    subTitle: {
      type: String,
      default: '',
      required: false,
    },
    subTitleHref: {
      type: String,
      default: '',
      required: false,
    },
    headerTitle: {
      type: String,
      default: '',
      required: false,
    },
    dividerTitle: {
      type: String,
      default: '',
      required: false,
    },
  },
  data() {
    return {
      take,
      slice,
    };
  },

  methods: {
    refresh(pagination) {
      this.loadData(pagination);
    },

    //多个表格提交校验
    submit() {
      return new Promise((resolve, reject) => {
        this.$refs['customForm'] &&
          this.$refs['customForm']
            .submit()
            .then((res) => {
              if (res) {
                resolve(res);
                this.$emit('submit', res);
              }
            })
            .catch((errorObj) => {
              reject(errorObj);
            });
      });
    },

    //重置
    reset() {
      this.$refs['elForm'].resetFields();
      this.formCols.forEach((items) => {
        items.forEach((item) => {
          if (item.eType === 'Check' || item.eType === 'CheckButton') {
            this.formData[item.prop].length = 0;
          } else {
            delete this.formData[item.prop];
          }
        });
      });
    },

    //所有change以及click事件
    event(params) {
      this.$emit('event', params);
    },
    getAllSelect(val) {
      this.$emit('getAllSelect', val);
    },
    // 手动勾选表格
    select(rows, row) {
      this.$emit('select', rows, row);
    },
    // 手动勾选全选表格
    selectAll(rows) {
      this.$emit('selectAll', rows);
    },
    // 行点击事件
    cellClick(rows) {
      this.$emit('cellClick', rows);
    },

    // ES7重写submit
    async submitAsync() {
      const res = await this.$refs['customForm'].submitAsync();
      return res;
    },
  },
};
</script>

<style lang="scss" scoped>
@import './indexForIE.scss';
</style>
