var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    { staticClass: "pro-list", attrs: { id: "pro-list" } },
    _vm._l(_vm.proList, function (item, key) {
      return _c(
        "li",
        {
          key: item.id,
          staticClass: "pro-list-item",
          attrs: { "data-aos": "fade-up", "data-aos-delay": (key % 3) * 200 },
        },
        [
          _c("img", { attrs: { src: _vm._f("imgUrl")(item) } }),
          _vm._v(" "),
          _c("h6", [_vm._v(_vm._s(item.productName))]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "pro-list-item-wrap" },
            [
              _c(
                "el-scrollbar",
                {
                  ref: "scrollProListItemRef",
                  refInFor: true,
                  attrs: { "wrap-class": "pro-list-item-container" },
                },
                [
                  _c("p", {
                    staticClass: "pro-list-item-p",
                    domProps: { innerHTML: _vm._s(item.productDesc) },
                  }),
                ]
              ),
            ],
            1
          ),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }