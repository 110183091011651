// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Variables */
:export {
  menuText: #000;
  menuActiveText: #fff;
  subMenuActiveText: #f4f4f5;
  menuActiveBg: #1589cd;
  menuBg: #fff;
  menuHover: rgba(45, 153, 255, 0.3);
  subMenuBg: #fff;
  subMenuHover: rgba(45, 153, 255, 0.3);
  sideBarWidth: 200px;
  primaryColor: #1589cd;
  primaryFontColor: rgb(45, 153, 255);
  primaryColor_8: rgba(31, 113, 255, 0.08);
  globalColor: #e60012;
  defaultFontColor: rgb(20, 35, 69);
  tableHeaderBg: #fafafa;
  tableHeaderHeight: 40px;
  tableAlign: center;
  fontSize_14: 14px;
  fontSize_16: 16px;
  fontSize_18: 18px;
  fontSize_24: 24px;
}

/* Mixins */
.timeline {
  padding-left: 25px;
}
.timeline .el-timeline-item__timestamp {
  font-size: 14px;
  color: #000;
}
.timeline .el-timeline-item__tail {
  border-left: 2px solid #c0e0ff;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
