var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("el-date-picker", {
    class: _vm.item.class,
    staticStyle: { width: "100%" },
    style: _vm.item.style,
    attrs: {
      disabled: _vm.item.disabled,
      type: _vm.item.type,
      editable: _vm.item.editable,
      clearable: true,
      "value-format": _vm.item.valueFormat,
      format: _vm.item.format,
      align: _vm.item.align,
      placeholder: _vm.item.placeholder,
      "picker-options": _vm.item.pickerOptions,
      "default-value": _vm.item.defaultValue,
      "range-separator": _vm.item.rangeSeparator || "~",
      "start-placeholder": _vm.item.startPlaceholder
        ? _vm.item.startPlaceholder
        : "开始日期",
      "end-placeholder": _vm.item.endPlaceholder
        ? _vm.item.endPlaceholder
        : "结束日期",
      "unlink-panels": _vm.item.unlinkPanels || false,
    },
    on: { change: _vm.change, focus: _vm.focus },
    model: {
      value: _vm.formData[_vm.item.prop],
      callback: function ($$v) {
        _vm.$set(_vm.formData, _vm.item.prop, $$v)
      },
      expression: "formData[item.prop]",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }