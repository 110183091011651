/** * create by wangchunyang on 2022-01-25 16:01 * 类注释： * 备注： */
<template>
  <VueJsonTree
    class="img"
    :path="item.path"
    @click="click"
    :data="item.data"
    :selectableType="item.selectableType"
    :showSelectController="item.showSelectController"
    :deep="item.deep"
  >
  </VueJsonTree>
</template>

<script type="text/ecmascript-6">
import VueJsonTree from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
import mixins from '../mixins';

export default {
  components: {
    VueJsonTree,
  },
  props: {},
  mixins: [mixins],
  data() {
    return {};
  },
  computed: {},
  methods: {
    click() {
      this.mixinEvent({
        type: 'click',
        prop: this.item.prop,
        value: this.formData[this.item.prop],
      });
    },
  },
  activated() {},
  mounted() {},
  created() {},
};
</script>

<style scoped lang="scss">
::v-deep {
  .vjs-value {
    word-break: break-all;
  }
  .vjs-key,
  .vjs-tree__brackets {
    white-space: nowrap !important;
  }
}
</style>
