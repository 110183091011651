var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "root", staticClass: "ellipsis" }, [
    _c(
      "div",
      { ref: "content" },
      [
        _vm.showExtra
          ? _c(
              "el-tooltip",
              {
                staticClass: "label-desc",
                attrs: {
                  "popper-class": "ellipsis-tooltip",
                  effect: "dark",
                  content: _vm.text,
                  placement: "top-start",
                },
              },
              [
                _c("span", { ref: "node" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        (_vm.targetCount > 0 &&
                          _vm.textVal.substring(0, _vm.targetCount)) ||
                          ""
                      ) +
                      _vm._s(
                        (_vm.targetCount > 0 &&
                          _vm.textVal.length > _vm.targetCount &&
                          "...") ||
                          ""
                      ) +
                      "\n      "
                  ),
                ]),
              ]
            )
          : _c("span", { ref: "node" }, [
              _vm._v(
                "\n      " +
                  _vm._s(
                    (_vm.targetCount > 0 &&
                      _vm.textVal.substring(0, _vm.targetCount)) ||
                      ""
                  ) +
                  _vm._s(
                    (_vm.targetCount > 0 &&
                      _vm.textVal.length > _vm.targetCount &&
                      "...") ||
                      ""
                  ) +
                  "\n    "
              ),
            ]),
        _vm._v(" "),
        _vm.showExtra
          ? _c("div", { ref: "shadowChildren", staticClass: "shadow" }, [
              _vm._v(_vm._s(_vm.textVal)),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.showExtra
          ? _c("div", { ref: "shadow", staticClass: "shadow" }, [
              _c("span", [_vm._v(_vm._s(_vm.textVal))]),
            ])
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }