// 工作台
const workbenchRoutes = [
  {
    path: '/workbench',
    name: 'workbench',
    redirect: '/workbench/list',
    meta: {
      title: '待办任务',
      hidden: true,
      layout: 'Layout',
      activeMenu: '/dashboard',
    },
  },
  {
    path: '/workbench/list',
    name: 'WorkbenchList',
    component: () => import(/* webpackChunkName: "WorkbenchList" */ '@/views/WorkBench/index.vue'),
    meta: {
      title: '任务中心',
      hidden: true,
      layout: 'Layout',
      activeMenu: '/dashboard',
    },
  },
  {
    path: "/workbench/approveList/balanceSupplement",
    name: "balanceSupplement",
    component: () =>
      import(/* webpackChunkName: "WorkbenchList" */ "@/views/ExamineAndApprove/approveList/balanceSupplement"),
    meta: {
      title: "余额补充",
      icon: "dashboard",
      activeMenu: "/balanceSupplement",
      layout: "Layout",
      class: "menu-level-first",
    },
  },
  //
  {
    path: "/workbench/approveList/financingApplicationApproval",
    name: "financingApplicationApproval",
    component: () =>
      import(/* webpackChunkName: "WorkbenchList" */ "@/views/ExamineAndApprove/approveList/financingApplicationApproval"),
    meta: {
      title: "融资申请",
      icon: "dashboard",
      activeMenu: "/financingApplicationApproval",
      layout: "Layout",
      class: "menu-level-first",
    },
  },
  {
    path: "/workbench/approveList/associateDeliveryOrderApproval",
    name: "associateDeliveryOrderApproval",
    component: () =>
      import(/* webpackChunkName: "WorkbenchList" */ "@/views/ExamineAndApprove/approveList/associateDeliveryOrderApproval"),
    meta: {
      title: "融资确认",
      icon: "dashboard",
      activeMenu: "/associateDeliveryOrderApproval",
      layout: "Layout",
      class: "menu-level-first",
    },
  },
  {
    path: "/workbench/enterpriseManagement/companyAuthenticate",
    name: "authenticateCompany",
    component: () =>
      import(/* webpackChunkName: "WorkbenchList" */ "@/views/enterpriseManagement/companyAuthenticate/index.vue"),
    meta: {
      title: "企业信息管理",
      icon: "dashboard",
      activeMenu: "/enterpriseManagement",
      layout: "Layout",
      class: "menu-level-first",
      authority: ["PROTAL_CORP.MANAGEMENT-CENTER.COMPANY-AUTHENTICATE"],
    },
  },
  //
  {
    path: "/workbench/customerAccess",
    name: "customerAccess",
    component: () =>
      import(/* webpackChunkName: "WorkbenchList" */ "@/views/ExamineAndApprove/newApproveList/customerAccess"),
    meta: {
      title: "莱商银行客户准入申请",
      icon: "dashboard",
      activeMenu: "/customerAccess",
      layout: "Layout",
      class: "menu-level-first",
    },
  },
  {
    path: "/workbench/financialReport",
    name: "financialReport",
    component: () =>
      import(/* webpackChunkName: "WorkbenchList" */ "@/views/ExamineAndApprove/newApproveList/financialReport"),
    meta: {
      title: "莱商银行财报补充",
      icon: "dashboard",
      activeMenu: "/financialReport",
      layout: "Layout",
      class: "menu-level-first",
    },
  },
  {
    path: "/workbench/depositPayment",
    name: "depositPayment",
    component: () =>
      import(/* webpackChunkName: "WorkbenchList" */ "@/views/ExamineAndApprove/newApproveList/depositPayment"),
    meta: {
      title: "定金支付",
      icon: "dashboard",
      activeMenu: "/depositPayment",
      layout: "Layout",
      class: "menu-level-first",
    },
  },
  {
    path: "/workbench/balanceSupplement",
    name: "newbalanceSupplement",
    component: () =>
      import(/* webpackChunkName: "WorkbenchList" */ "@/views/ExamineAndApprove/newApproveList/balanceSupplement"),
    meta: {
      title: "尾款支付",
      icon: "dashboard",
      activeMenu: "/balanceSupplement",
      layout: "Layout",
      class: "menu-level-first",
    },
  },
  {
    path: "/workbench/financingApplication",
    name: "financingApplication",
    component: () =>
      import(/* webpackChunkName: "WorkbenchList" */ "@/views/ExamineAndApprove/newApproveList/financingApplication"),
    meta: {
      title: "莱商融资申请",
      icon: "dashboard",
      activeMenu: "/financingApplication",
      layout: "Layout",
      class: "menu-level-first",
    },
  },
  {
    path: "/workbench/arrivalConfirm",
    name: "arrivalConfirm",
    component: () =>
      import(/* webpackChunkName: "WorkbenchList" */ "@/views/ExamineAndApprove/newApproveList/arrivalConfirm"),
    meta: {
      title: "到货确认",
      icon: "dashboard",
      activeMenu: "/arrivalConfirm",
      layout: "Layout",
      class: "menu-level-first",
    },
  },
  {
    path: "/workbench/financingFailure",
    name: "financingFailure",
    component: () =>
      import(/* webpackChunkName: "WorkbenchList" */ "@/views/ExamineAndApprove/newApproveList/financingFailure"),
    meta: {
      title: "融资申请确认",
      icon: "dashboard",
      activeMenu: "/financingFailure",
      layout: "Layout",
      class: "menu-level-first",
    },
  },
  {
    path: "/workbench/generateWarehouseReceipt",
    name: "generateWarehouseReceipt",
    component: () =>
      import(/* webpackChunkName: "WorkbenchList" */ "@/views/flowPath/generateWarehouseReceipt/index.vue"),
    meta: {
      title: "生成仓单确认",
      icon: "dashboard",
      activeMenu: "/generateWarehouseReceipt",
      layout: "Layout",
      class: "menu-level-first",
    },
  },
  {
    path: "/workbench/productDetails",
    name: "productDetails",
    component: () =>
      import(/* webpackChunkName: "WorkbenchList" */ "@/views/ExamineAndApprove/newApproveList/productDetails"),
    meta: {
      title: "订单详情",
      icon: "dashboard",
      activeMenu: "/productDetails",
      layout: "Layout",
      class: "menu-level-first",
    },
  },
  {
    path: "/workbench/zyOrderSupplement",
    component: () => import( /* webpackChunkName: "WorkbenchList" */ '@/views/flowPath/orderSupplement/index.vue'),
    meta: {
      title: "自营订单补充",
      icon: "dashboard",
      activeMenu: "/zyOrderSupplement",
      layout: "Layout",
      class: "menu-level-first",
    },
  },
  {
    path: "/workbench/warehouseReceiptSignature",
    name: "warehouseReceiptSignature",
    component: () =>
      import(/* webpackChunkName: "WorkbenchList" */ "@/views/flowPath/warehouseReceiptSignature/index.vue"),
    meta: {
      title: "仓单开立签章",
      icon: "dashboard",
      activeMenu: "/warehouseReceiptSignature",
      layout: "Layout",
      class: "menu-level-first",
    },
  },
  {
    path: "/workbench/supplementRollback",
    name: "supplementRollback",
    component: () =>
      import(/* webpackChunkName: "WorkbenchList" */ "@/views/flowPath/supplementRollback/index.vue"),
    meta: {
      title: "自营订单补充",
      icon: "dashboard",
      activeMenu: "/supplementRollback",
      layout: "Layout",
      class: "menu-level-first",
    },
  },  {
    path: "/workbench/personalAuthentication",
    name: "personalAuthentication",
    component: () =>
      import(/* webpackChunkName: "WorkbenchList" */ "@/views/ExamineAndApprove/newApproveList/personalAuthentication"),
    meta: {
      title: "莱商银行个人准入申请",
      icon: "dashboard",
      activeMenu: "/personalAuthentication",
      layout: "Layout",
      class: "menu-level-first",
    },
  },
  {
    path: "/warehousetransferReview/index",
    name: "warehousetransferReview",
    component: () =>
      import(/* webpackChunkName: "WorkbenchList" */ "@/views/flowPath/warehousetransferReview/index.vue"),
    meta: {
      title: "仓单转让审核（存货人）",
      icon: "dashboard",
      activeMenu: '/dashboard',
      layout: "Layout",
      class: "menu-level-first",
    },
  },
  {
    path: "/warehouseReceipt/index",
    name: "warehouseReceipt",
    component: () =>
      import(/* webpackChunkName: "WorkbenchList" */ "@/views/flowPath/warehouseReceiptIssuance/index.vue"),
    meta: {
      title: "仓单开立审核",
      icon: "dashboard",
      activeMenu: '/dashboard',
      layout: "Layout",
      class: "menu-level-first",
    },
  },
  {
    path: "/warehouseReceiptReturn/index",
    name: "warehouseReceiptReturn",
    component: () =>
      import(/* webpackChunkName: "WorkbenchList" */ "@/views/flowPath/warehouseReceiptReturn/index.vue"),
    meta: {
      title: "仓单开立退回",
      icon: "dashboard",
      activeMenu: '/dashboard',
      layout: "Layout",
      class: "menu-level-first",
    },
  },
  {
    path: "/workbench/warehouseReceiptissuanceCompleted/index",
    name: "WarehouseReceiptissuanceCompleted",
    component: () =>
      import(/* webpackChunkName: "WorkbenchList" */ "@/views/flowPath/warehouseReceiptissuanceCompleted/index.vue"),
    meta: {
      title: "仓单开立审核",
      icon: "dashboard",
      activeMenu: '/dashboard',
      layout: "Layout",
      class: "menu-level-first",
    },
  },
  {
    path: "/warehouseCancellation/index",
    name: "warehouseCancellation",
    component: () =>
      import(/* webpackChunkName: "WorkbenchList" */ "@/views/flowPath/warehouseCancellation/index.vue"),
    meta: {
      title: "仓单注销",
      icon: "dashboard",
      activeMenu: '/dashboard',
      layout: "Layout",
      class: "menu-level-first",
    },
  },
  {
    path: "/warehouseReceiptPickupCancellation/index",
    name: "warehouseReceiptPickupCancellation",
    component: () =>
      import(/* webpackChunkName: "WorkbenchList" */ "@/views/flowPath/warehouseReceiptPickupCancellation/index.vue"),
    meta: {
      title: "仓单提货注销审核",
      icon: "dashboard",
      activeMenu: '/dashboard',
      layout: "Layout",
      class: "menu-level-first",
    },
  },
  {
    path: "/financingApplicationReturn/index",
    name: "financingApplicationReturn",
    component: () =>import(/* webpackChunkName: "WorkbenchList" */ "@/views/flowPath/financingApplicationReturn/index.vue"),
    meta: {
      title: "融资申请审核（退回）",
      icon: "dashboard",
      activeMenu: '/dashboard',
      layout: "Layout",
      class: "menu-level-first",
    },
  },
  {
    path: "/financingApplicationReturn/details",
    name: "financingApplicationDetails",
    component: () =>import(/* webpackChunkName: "WorkbenchList" */ "@/views/flowPath/financingApplicationReturn/details.vue"),
    meta: {
      title: "融资申请审核",
      icon: "dashboard",
      activeMenu: '/dashboard',
      layout: "Layout",
      class: "menu-level-first",
    },
  },
  {
    path: "/financingApplicationOffline/index",
    name: "financingApplicationOffline",
    component: () =>import(/* webpackChunkName: "WorkbenchList" */ "@/views/flowPath/financingApplicationOffline/index.vue"),
    meta: {
      title: "融资申请（线下）",
      icon: "dashboard",
      activeMenu: '/dashboard',
      layout: "Layout",
      class: "menu-level-first",
    },
  },
  {
    path: "/financingApplicationOffline/bankIndex",
    name: "financingApplicationBankIndexe",
    component: () =>import(/* webpackChunkName: "WorkbenchList" */ "@/views/flowPath/financingApplicationOffline/bankIndex.vue"),
    meta: {
      title: "融资申请（线下）",
      icon: "dashboard",
      activeMenu: '/dashboard',
      layout: "Layout",
      class: "menu-level-first",
    },
  },
  {
    path: "/billLadingInformationEntry/index",
    name: "billLadingInformationEntry",
    component: () =>import(/* webpackChunkName: "WorkbenchList" */ "@/views/flowPath/billLadingInformationEntry/index.vue"),
    meta: {
      title: "提单信息录入",
      icon: "dashboard",
      activeMenu: '/dashboard',
      layout: "Layout",
      class: "menu-level-first",
    },
  },
  {
    path: "/financeFinancingApplicationReview/index",
    name: "financeFinancingApplicationReview",
    component: () =>import(/* webpackChunkName: "WorkbenchList" */ "@/views/flowPath/financeFinancingApplicationReview/index.vue"),
    meta: {
      title: "融资申请审核",
      icon: "dashboard",
      activeMenu: '/dashboard',
      layout: "Layout",
      class: "menu-level-first",
    },
  },
  {
    path: "/warehouseApplicationDealerOffline/index",
    name: "warehouseApplicationDealerOffline",
    component: () =>import(/* webpackChunkName: "WorkbenchList" */ "@/views/flowPath/warehouseApplicationDealerOffline/index.vue"),
    meta: {
      title: "仓单开立申请",
      icon: "dashboard",
      activeMenu: '/dashboard',
      layout: "Layout",
      class: "menu-level-first",
    },
  },
  {
    path: "/warehouseAuditingStorageOffline/index",  // /warehousetransferReview/index
    name: "warehouseAuditingStorageOffline",
    component: () =>
      import(/* webpackChunkName: "WorkbenchList" */ "@/views/flowPath/warehouseAuditingStorageOffline/index.vue"),
    meta: {
      title: "仓单开立申请审核（仓储方）",
      icon: "dashboard",
      activeMenu: '/dashboard',
      layout: "Layout",
      class: "menu-level-first",
    },
  },
  {
    path: "/protocolVerification/index",
    name: "ProtocolVerification",
    component: () =>
      import(/* webpackChunkName: "WorkbenchList" */ "@/views/flowPath/protocolVerification/index.vue"),
    meta: {
      title: "平台授权协议签署审核",
      icon: "dashboard",
      activeMenu: '/dashboard',
      layout: "Layout",
      class: "menu-level-first",
    },
  },
];

export default workbenchRoutes;
