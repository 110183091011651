import request from '@/utils/request';

export const selectByToken = () => {
  return request('/auth/corpUser/selectByToken');
};

export const updateUserHeader = (data) => {
  return request('/auth/corpUser/updateUserHeader', {
    method: 'POST',
    data,
  });
};
