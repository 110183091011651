import { fetchTaskList } from '@/api/Workflow/workflow';

const defaultState = () => {
  return {
    taskList: [],
    taskTotal: 0,
    messageList: [],
    messageTotal: 0,
  };
};

export const state = defaultState();

const actions = {

};

const mutations = {
  // 待办任务
  SET_TASKLIST_TOTAL(state, payload) {
    state.taskTotal = Number(payload || 0);
  },
  // 消息总数
  SET_MESSAGE_TOTAL(state, payload) {
    state.messageTotal = Number(payload || 0);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
