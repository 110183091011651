var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page-title" },
    [
      _vm.icons
        ? _c("svg-icon", { attrs: { "icon-class": _vm.icons } })
        : _vm._e(),
      _vm._v(" "),
      _vm.isRequired
        ? _c("span", { staticClass: "required" }, [_vm._v("*")])
        : _vm._e(),
      _vm._v(" "),
      _c("p", [_vm._v(_vm._s(_vm.title))]),
      _vm._v(" "),
      _vm._m(0),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "round" }, [
      _c("div", { staticClass: "line" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }