<template>
  <div :class="['authenticate-card', className, isIEClassName]" v-if="showStep || showAlert">
    <el-card shadow="hover" class="el-card__body_bg">
      <el-row :gutter="24">
        <el-col :span="stepSpan" :offset="stepOffset">
          <el-alert
            v-if="showHeaderAlert"
            :title="alertTitle"
            :type="alertType"
            :description="alertDescription"
            show-icon
            @close="handleAlertClose"
          />
          <div class="steps-wrap" v-if="showStep">
            <CustomSteps :stepsList="stepsList" :current="current" />
          </div>
          <el-alert
            v-if="!hideAlert"
            :title="alertTitle"
            :type="alertType"
            :description="alertDescription"
            show-icon
            @close="handleAlertClose"
          />
        </el-col>
        <el-col :span="4" v-if="showImg">
          <img :src="todoList[alertCardStyle]" />
        </el-col>
      </el-row>

      <slot name="authenticateBody"></slot>
    </el-card>
  </div>
</template>

<script>
import CustomSteps from '../CustomSteps/index.vue';
import unTodo from '../../assets/authenticate/unTodo.png';
import approve from '../../assets/authenticate/approve.png';
import success from '../../assets/authenticate/success.png';
import rollback from '../../assets/authenticate/rollback.png';
import { isIE } from '@/utils/utils';

export default {
  name: 'AuthenticateCard',
  components: {
    CustomSteps,
  },
  props: {
    className: {
      type: String,
      default: '',
    },
    showStep: {
      type: Boolean,
      default: true,
    },
    hideAlert: {
      type: Boolean,
      default: false,
    },
    showHeaderAlert: {
      type: Boolean,
      default: false,
    },
    showImg: {
      type: Boolean,
      default: true,
    },
    // 当前处于步骤n
    current: {
      type: Number,
      default: 1,
    },
    stepsList: {
      type: Array,
      default: () => [
        {
          key: '填写机构信息',
          value: 1,
        },
      ],
    },
    stepSpan: {
      type: Number,
      default: 20,
    },
    stepOffset: {
      type: Number,
      default: 0,
    },
    alertCardStyle: {
      type: String,
      default: 'todo',
    },
    alertTitle: {
      type: String,
      default: '温馨提示',
    },
    alertType: {
      type: String,
      default: 'info',
    },
    alertDescription: {
      type: String,
      default:
        '为了更好的为您提供服务，交易前请先上传实名认证材料进行认证，上传的单个图片不能大于10M，仅支持JPG、JPEG、PNG、PDF格式。',
    },
  },

  data() {
    return {
      todoList: {
        unTodo,
        approve,
        success,
        rollback,
      },
      showAlert: true,
      isIEClassName: isIE() ? 'ie-authenticate-card' : '',
    };
  },

  methods: {
    handleAlertClose() {
      this.showAlert = false;
      this.$emit('close', true);
    },
  },
};
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>
