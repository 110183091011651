<template>
  <section class="app-main-footer">
    <span>{{ settings.footerCopyright }}</span>
    <span>
      ICP备案号：
      <a href="https://beian.miit.gov.cn" target="_blank"> {{ settings.icp }} </a>
    </span>
  </section>
</template>

<script>
import settings from '@/defaultSettings';

export default {
  name: 'Footer',
  data() {
    return {
      settings,
    };
  },
};
</script>

<style lang="scss" scoped>
.app-main-footer {
  height: 58px;
  line-height: 35px;
  font-size: $fontSize_14;
  color: rgba(0, 0, 0, 0.45);
  background: $defaultPageBgColor;
  padding-bottom: $componentsSpace;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  span {
    display: flex;
    margin: 0 10px;
    align-items: center;
  }
  a {
    color: rgba(0, 0, 0, 0.45);
    text-decoration: none;
  }
}
</style>
