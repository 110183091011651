// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
/*定义滚动条宽高及背景，宽高分别对应横竖滚动条的尺寸*/
.el-table__body-wrapper::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/*定义滚动条的轨道，内阴影及圆角*/
.el-table__body-wrapper::-webkit-scrollbar-track {
  border-radius: 10px;
}

/*定义滑块，内阴影及圆角*/
.el-table__body-wrapper::-webkit-scrollbar-thumb {
  position: relative;
  width: 10px;
  height: 20px;
  border-radius: 10px;
  background-color: rgba(44, 62, 80, 0.15);
  border-radius: 4px;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
