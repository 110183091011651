var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CustomCard",
    {
      attrs: {
        headerTitle: _vm.headerTitle,
        subDividerTitle: _vm.subDividerTitle,
        subDividerTitleType: _vm.subDividerTitleType,
        subDividerTitlePath: _vm.subDividerTitlePath,
        rowObj: _vm.rowObj,
        dividerTitle: _vm.dividerTitle,
        formLineButtons: [],
        tabHidden: false,
        showFormLineButtons: false,
        span: 24,
      },
      on: {
        subDividerTitleEvent: function ($event) {
          return _vm.$emit("subDividerTitleEvent", true)
        },
      },
    },
    [
      _vm._t("default"),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { slot: "bodyHeaderSlot" }, slot: "bodyHeaderSlot" },
        [
          _c("ElTabsComponent", {
            attrs: {
              current: _vm.current,
              tabs: _vm.tabs,
              dangers: _vm.dangers,
            },
            on: { tabsClick: _vm.tabsClick },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { slot: "bodySlot" }, slot: "bodySlot" },
        [_vm._t("bodySlot")],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }