// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Variables */
[data-v-102feba0]:export {
  menuText: #000;
  menuActiveText: #fff;
  subMenuActiveText: #f4f4f5;
  menuActiveBg: #1589cd;
  menuBg: #fff;
  menuHover: rgba(45, 153, 255, 0.3);
  subMenuBg: #fff;
  subMenuHover: rgba(45, 153, 255, 0.3);
  sideBarWidth: 200px;
  primaryColor: #1589cd;
  primaryFontColor: rgb(45, 153, 255);
  primaryColor_8: rgba(31, 113, 255, 0.08);
  globalColor: #e60012;
  defaultFontColor: rgb(20, 35, 69);
  tableHeaderBg: #fafafa;
  tableHeaderHeight: 40px;
  tableAlign: center;
  fontSize_14: 14px;
  fontSize_16: 16px;
  fontSize_18: 18px;
  fontSize_24: 24px;
}

/* Mixins */
.pro-list[data-v-102feba0] {
  display: flex;
  flex-wrap: wrap;
  width: 1200px;
  margin: 0 auto;
}
.pro-list .pro-list-item[data-v-102feba0] {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 360px;
  height: 480px;
  box-sizing: border-box;
  margin-top: 60px;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 8px;
  box-shadow: 4px 4px 16px rgba(31, 113, 255, 0.3);
  padding-top: 40px;
  margin-right: 60px;
}
.pro-list .pro-list-item[data-v-102feba0]:hover {
  box-shadow: 4px 4px 24px 4px rgba(31, 113, 255, 0.5);
  background-color: rgb(255, 255, 255);
}
.pro-list .pro-list-item:hover h6[data-v-102feba0] {
  font-weight: 700;
}
.pro-list .pro-list-item:hover .button.el-button[data-v-102feba0] {
  background-color: #1589cd;
  color: #fff;
  border-color: #1589cd;
}
.pro-list .pro-list-item:hover p[data-v-102feba0] {
  color: rgb(20, 35, 69);
}
.pro-list .pro-list-item[data-v-102feba0]:nth-of-type(1), .pro-list .pro-list-item[data-v-102feba0]:nth-of-type(2), .pro-list .pro-list-item[data-v-102feba0]:nth-of-type(3) {
  margin-top: 0;
}
.pro-list .pro-list-item[data-v-102feba0]:nth-of-type(3n) {
  margin-right: 0;
}
.pro-list .pro-list-item img[data-v-102feba0] {
  width: 64px;
  height: 64px;
}
.pro-list .pro-list-item h6[data-v-102feba0] {
  font-size: 28px;
  font-weight: 400;
  line-height: 1em;
  color: rgba(31, 113, 255, 0.85);
  min-height: 68px;
  max-height: 78px;
  max-width: 100%;
  padding: 20px 30px 0;
  word-break: break-all;
  box-sizing: border-box;
  overflow: hidden;
  overflow-wrap: break-word;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.pro-list .pro-list-item .pro-list-item-wrap[data-v-102feba0] {
  padding: 10px 30px;
  height: 194px;
}
.pro-list .pro-list-item .el-scrollbar[data-v-102feba0] {
  height: 269px;
  width: 300px;
  position: relative;
  overflow: hidden;
}
.pro-list .pro-list-item .el-scrollbar[data-v-102feba0] .pro-list-item-container {
  overflow-x: hidden;
  overflow-y: auto;
}
.pro-list .pro-list-item .pro-list-item-p[data-v-102feba0] {
  font-size: 18px;
  line-height: 2em;
  color: rgba(0, 0, 0, 0.65);
  margin-bottom: 30px;
  padding-right: 6px;
}
.pro-list .pro-list-item .button[data-v-102feba0] {
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
}
.pro-list .pro-list-item .button .circle-arrow-right[data-v-102feba0] {
  padding-left: 3px;
}
.pro-list .pro-list-item .button.el-button[data-v-102feba0] {
  border-color: #1589cd;
  color: #1589cd;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
