import Vue from 'vue';
import confirm from './index.vue';
let confirmConstructor = Vue.extend(confirm);
let theConfirm = function (text) {
  return new Promise((res, rej) => {
    //promise封装，ok执⾏resolve，no执⾏rejectlet
    let confirmDom = new confirmConstructor({
      el: document.createElement('div'),
    });
    document.body.appendChild(confirmDom.$el); //new⼀个对象，然后插⼊body⾥⾯
    confirmDom.text = text; //为了使confirm的扩展性更强，这个采⽤对象的⽅式传⼊，所有的字段都可以根据需求⾃定义
    confirmDom.width = text?.width || '380px';
    confirmDom.ok = function () {
      res();
      confirmDom.isShow = false;
    };
    confirmDom.close = function () {
      rej();
      confirmDom.isShow = false;
    };
  });
};
export default theConfirm;

//在需要的地⽅直接⽤以下⽅法调⽤即可：
// this.$theConfirm({
//  title:'提示',
//  msg:'是否删除这条信息？',
//  btn:{
//   ok:'yes',
//   no:'no'
//  }
// }).then(() => {
//  console.log('ok')
// })
// .catch(() => {
//  console.log('no')
// })
