<template>
  <div class="customization-card">
    <CommonTitle
      class="commonTitle"
      v-if="!dividerTitleHidden"
      :icons="dividerIcon"
      :title="dividerTitle || $route.meta?.title"
    />
    <slot></slot>
    <div v-if="bodyHidden" :class="contentName">
      <slot name="bodyHeaderSlot"></slot>
      <slot name="bodySlot"></slot>
      <el-row
        :type="sheetStyle.type"
        :align="sheetStyle.align"
        :justify="sheetStyle.justify"
        v-show="formHidden"
        :gutter="5"
      >
        <el-col :span="24">
          <CustomForm
            ref="customForm"
            :formRowGutter="formRowGutter"
            :isInline="isInline"
            :formData="formData"
            :formCols="formCols"
            :moreFilterProp="moreFilterProp"
            :label-suffix="labelSuffix"
            :status-icon="statusIcon"
            :size="size"
            :disabled="disabled"
            :inline="isInline"
            :label-width="labelWidth"
            :label-position="labelPosition"
            :rules="rules"
            :needToast="needToast"
            :formLineButtons="formLineButtons"
            :showFormLineButtons="showFormLineButtons"
            :formLineBtnAuth="formLineBtnAuth"
            :isSelection="isSelection"
            @event="event"
            @submit="submit"
            @formSlotClick="formSlotClick"
            @submit.native.prevent
          >
            <template v-for="item in formSlotList" v-slot:[item.slotName]="{ data }">
              <slot :name="item.slotName" :data="{ ...data }"></slot>
            </template>
            <template slot="selectParentRes"><slot name="selectParentRes"></slot></template>
          </CustomForm>
          <slot name="popConFirmDisabled"></slot>
        </el-col>
      </el-row>
      <slot name="summary"></slot>
      <CustomTable
        class="customTable"
        v-show="tabHidden"
        ref="customTable"
        :lazy="lazy"
        @getload="getload"
        :rowKey="rowKey"
        :tableData="tableData"
        :clearAllselect="clearAllselect"
        :setChangeSelectList="setChangeSelectList"
        :tableSelectionWidth="tableSelectionWidth"
        :tableCols="tableCols"
        :isSelection="isSelection"
        :pagination="pagination"
        :isPagination="isPagination"
        :expandRowKeys="expandRowKeys"
        :loading="loading"
        :loadData="loadData"
        :layout="layout"
        :isBorder="isBorder"
        :pageSizeList="pageSizeList"
        :showOverflowTooltip="showOverflowTooltip"
        :defaultExpandAll="defaultExpandAll"
        @event="event"
        @select="select"
        @selectAll="selectAll"
        @getAllSelect="getAllSelect"
        @sortChange="sortChange"
      >
        <template v-for="item in tableSlotList" v-slot:[item.slotName]="{ data }">
          <slot :name="item.slotName" :data="{ ...data }"></slot>
        </template>
      </CustomTable>
      <slot name="FooterSlot"></slot>
    </div>
  </div>
</template>

<script>
import { take, slice } from 'lodash';
import customFormMixin from '@/mixins/customFormMixin';
import customTableMixin from '@/mixins/customTableMixin';
import CommonTitle from '@/components/Common/title';

export default {
  mixins: [customFormMixin, customTableMixin],
  name: 'CustomCard',
  components: {
    CommonTitle,
  },
  props: {
    load: {
      type: Function,
      default: () => {},
    },
    expandRowKeys: {
      type: Array,
      default: () => {
        return [];
      },
    },
    lazy: {
      type: Boolean,
      default: false,
    },
    rowKey: {
      type: [String, Function],
      default: 'id',
    },
    contentName: {
      type: String,
      default: 'body-wrap',
      required: false,
    },
    type: {
      type: String,
      default: '',
      required: false,
    },
    formHidden: {
      type: Boolean,
      default: true,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dividerTitleHidden: {
      type: Boolean,
      default: false,
      required: false,
    },
    bodyHidden: {
      type: Boolean,
      default: true,
      required: false,
    },
    subTitle: {
      type: String,
      default: '',
      required: false,
    },
    subTitleHref: {
      type: String,
      default: '',
      required: false,
    },
    headerTitle: {
      type: String,
      default: '',
      required: false,
    },
    dividerTitle: {
      type: String,
      default: '',
      required: false,
    },
    dividerIcon: {
      type: String,
      default: '',
      required: false,
    },
    defaultExpandAll: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    formSlotList() {
      // 处理表格跨组件slot
      let list = [];
      this.formCols.forEach((items) => {
        items.forEach((item) => {
          if (item.eType === 'slot') {
            list.push(item);
          }
        });
      });
      return list;
    },
    tableSlotList() {
      // 处理表格跨组件slot
      const list =
        this.tableCols && this.tableCols.length > 0 && this.tableCols.filter((item) => item.eType === 'slot');
      return list;
    },
  },
  data() {
    return {
      take,
      slice,
    };
  },

  methods: {
    handleDownload() {
      const a = document.createElement('a'); // 创建a标签
      a.setAttribute('download', this.subTitleHref); // download属性
      a.setAttribute('href', this.subTitleHref); // href链接
      a.click(); // 自执行点击事件
    },

    handleReset() {
      this.$mForm.reset();
      this.$emit('formReset');
    },

    //提交校验

    //多个表格提交校验
    submit() {
      return new Promise((resolve) => {
        this.$refs['customForm'] &&
          this.$refs['customForm'].submit().then((res) => {
            if (res) {
              resolve(res);
              this.$emit('submit', res);
            }
          });
      });
    },

    /**
     * 清除校验
     * @param prop  数组中唯一标识值  Array | String
     */
    clearValidate(prop) {
      if (prop) {
        this.$refs['customForm'] && this.$refs['customForm'].clearValidate(prop);
      } else {
        this.$refs['customForm'] && this.$refs['customForm'].clearValidate();
      }
    },

    /**
     * 对部分表单字段进行校验的方法
     * @param prop  数组中唯一标识值  Array | String
     */
    validateField(prop) {
      if (prop) {
        this.$refs['customForm'] && this.$refs['customForm'].validateField(prop);
      } else {
        this.$refs['customForm'] && this.$refs['customForm'].validateField();
      }
    },

    //重置
    reset() {
      this.$refs['elForm'].resetFields();
      this.formCols.forEach((items) => {
        items.forEach((item) => {
          if (item.eType === 'Check' || item.eType === 'CheckButton') {
            this.formData[item.prop].length = 0;
          } else {
            delete this.formData[item.prop];
          }
        });
      });
    },
    formSlotClick(item) {
      this.$emit('formSlotClick', item);
    },
    formItemClick(item) {
      this.$emit('formItemClick', item);
    },
    formItemDbClick(item) {
      this.$emit('formItemDbClick', item);
    },
    //所有change以及click事件
    event(params) {
      this.$emit('event', params);
    },
    sortChange({ column, prop, order }) {
      this.$emit('sortChange', { column, prop, order });
    },
    getAllSelect(val) {
      this.$emit('getAllSelect', val);
    },
    // 手动勾选表格
    select(rows, row) {
      this.$emit('select', rows, row);
    },
    // 手动勾选全选表格
    selectAll(rows) {
      this.$emit('selectAll', rows);
    },
    getload(val) {
      this.$emit('getload', val);
    },
    validateForm(prop) {
      return this.$refs['customForm'].validateField(prop);
    },
  },
};
</script>

<style lang="scss" scoped>
@import './index.scss';
.smsTemplate {
  width: 100%;
  height: auto;
  overflow-x: hidden;
}
.toopSty {
  line-height: 20px;
  max-width: 300px;
}
.smsTemplate {
  width: 200px;
  height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
