import router, { resetRouter } from './router';
import store from './store';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import { isNewVersion } from '@/utils/systemUpdate';
import settings from './defaultSettings';
import { hasMenuTreeFun } from '@/utils/utils';
import { getToken, getRefreshToken, getUserInfo } from '@/utils/auth';
import { getStorage, setStorage, removeStorage, clearStorage } from './utils/storage';

NProgress.configure({ showSpinner: false });

const whiteList = settings.routeWhiteList;

const getPageTitle = (pageTitle) => {
  if (pageTitle) {
    return `${pageTitle} - ${settings.title}`;
  }
  return `${settings.title}`;
};
// 获取面客端资源路由
export const handleRoutes = async () => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let res = {};
    if (getStorage('appInfo')) {
      res = await store.dispatch('permission/getProjectResourceList');
    } else {
      res = await store.dispatch('permission/getResourceList');
    }
    // 重置所有路由，然后添加新路由
    if (res.accessRoutes?.length > 0) {
      resetRouter();
      res.accessRoutes.forEach((route) => {
        router.addRoute(route);
      });
      resolve(res);
    } else {
      reject('菜单数据为空');
    }
  });
};

router.beforeEach(async (to, from, next) => {
  NProgress.start();

  // 判断版本号，如果不一致则提示用户刷新页面
  const isUpdate = await isNewVersion();
  if (isUpdate) return false;

  if (to.path === from.path) {
    return;
  }
  document.title = getPageTitle(to?.meta?.title);
  const loginInfo = getUserInfo();
  if (to.path === '/homePage') {
    next();
    NProgress.done();
  } else if (to.path === '/403') {
    next();
    NProgress.done();
    store.dispatch('ResetToken');
    clearStorage();
  } else {
    if (getToken()) {
      // 进入首页，刪除項目信息
      if (to.path === '/dashboard') {
        removeStorage('appInfo');
        store.commit('permission/SET_PROJECT_MENU_TREE', []);
      }
      if (whiteList.indexOf(to.path) === -1) {
        // 平台协议
        if (loginInfo?.signStatus !== '2') {
          const userInfo = getStorage('User-Info');
          if (!getStorage('currentCorpIdentity')) {
            setStorage('currentCorpIdentity', userInfo.corpIdentities[0]);
          }
          next('/agreementSigning'); // 平台服务协议
          NProgress.done();
          return;
        }
        // 企业实名认证
        if (loginInfo?.approvalStatus !== 2) {
          next('/authing');
          NProgress.done();
          return;
        }
        //  个人需要修改密码，则跳转修改密码页面
        if (loginInfo?.pwdForceChange === 'Y') {
          next('/updateNewUserPassword');
          NProgress.done();
          return;
        }
        //  个人未实名认证，则跳转个人实名认证页面
        if (loginInfo?.identityFlag === 'N') {
          next('/beforePersonAuth');
          NProgress.done();
          return;
        }
      }

      const hasMenuTree = await hasMenuTreeFun();
      if (!hasMenuTree) {
        try {
          let _rspCode = getStorage('rspCode');
          if (_rspCode == '00000000') {
            NProgress.done();
            handleRoutes().then(() => {
              next({ ...to, replace: true });
            });
          } else {
            const userInfo = await store.dispatch('user/getAdminInfo');
            if (userInfo.rspCode !== '00000000') {
              next('/homePage');
              NProgress.done();
              return;
            }
            if (userInfo.rspCode === '00000000') {
              if (userInfo.data?.userIdentity?.length === 1 && !getStorage('currentCorpIdentity')) {
                setStorage('currentCorpIdentity', userInfo.data.userIdentity[0]);
              }
            }
            handleRoutes().then(() => {
              next({ ...to, replace: true });
            });
          }
        } catch {
          store.dispatch('user/LogOut', { noRequest: true });
        }
      } else {
        next();
      }
      setStorage('rspCode', '');
      NProgress.done();
    } else if (getRefreshToken()) {
      store
        .dispatch('user/getRefreshToken')
        .then(async () => {
          const hasMenuTree = await hasMenuTreeFun();
          if (!hasMenuTree) {
            try {
              const userInfo = await store.dispatch('user/getAdminInfo');
              if (userInfo?.rspCode !== '00000000') {
                if (to.path !== '/homePage') {
                  next(`/homePage`);
                } else {
                  next();
                }
                NProgress.done();
                return;
              }
              if (userInfo.rspCode === '00000000') {
                if (userInfo.data.userIdentity.length === 1 && !getStorage('currentCorpIdentity')) {
                  setStorage('currentCorpIdentity', userInfo.data.userIdentity[0]);
                }
              }
              handleRoutes().then(() => {
                next({ ...to, replace: true });
              });
            } catch {
              store.dispatch('user/LogOut', { noRequest: true });
            }
          } else {
            next();
          }
          NProgress.done();
        })
        .catch(() => {
          store.dispatch('user/LogOut', { noRequest: true });
        });
    } else {
      if (whiteList.indexOf(to.path) !== -1) {
        next();
      } else {
        next(`/homePage`);
      }
      NProgress.done();
    }
  }
});

router.afterEach(() => {
  // hack: https://github.com/PanJiaChen/vue-element-admin/pull/2939
  NProgress.done();
});
