var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-upload",
    {
      ref: "uploadFile",
      class: _vm.item.multiple
        ? ""
        : [
            "upload-content",
            _vm.item.class || "",
            _vm.noneImg && "disUoloadSty",
          ],
      attrs: {
        action: "#",
        headers: _vm.item.headers,
        multiple: _vm.item.multiple,
        data: _vm.item.data,
        name: _vm.item.name,
        drag: _vm.item.drag,
        accept: _vm.item.accept,
        "auto-upload": !_vm.item.notAutoUpload,
        disabled: _vm.item.disabled,
        limit: _vm.item.limit || 5,
        "list-type": _vm.item.listType,
        "file-list":
          (_vm.fileList && _vm.fileList.length > 0) || _vm.showFileList
            ? _vm.fileList
            : _vm.item.fileList,
        "show-file-list": _vm.item.showFileList,
        "on-preview": !_vm.item.previewDisabled
          ? _vm.handlePictureCardPreview
          : _vm.defaultFunction,
        "on-remove": _vm.remove,
        "on-change": _vm.handleChange,
        "on-exceed": _vm.handleExceed,
        "http-request": (file, fileList) => {
          return _vm.uploadSectionFile(file, fileList)
        },
      },
    },
    [
      _vm.item.showButton
        ? [
            _c(
              "el-button",
              {
                attrs: {
                  size: _vm.item.size || "medium",
                  type: "primary",
                  loading: this.loading,
                },
              },
              [
                _vm.item.showButtonIcon
                  ? _c("i", { staticClass: "el-icon-upload2 el-icon--left" })
                  : _vm._e(),
                _vm._v(_vm._s(_vm.item.showButtonTitle || "点击上传")),
              ]
            ),
            _vm._v(" "),
            _vm.item.buttonTips
              ? _c("p", [_vm._v(_vm._s(_vm.item.buttonTips))])
              : _vm._e(),
          ]
        : [
            _c("i", { staticClass: "el-icon-upload" }),
            _vm._v(" "),
            _vm.item.tip
              ? _c("div", { staticClass: "el-upload__tip" }, [
                  _vm._v(
                    "\n      " +
                      _vm._s(
                        _vm.item.tipDesc ||
                          "点击或将文件拖拽到这里上传\n支持JPG、JPEG、PNG、PDF格式"
                      ) +
                      "\n    "
                  ),
                ])
              : _c("div", { staticClass: "el-upload__text" }, [
                  _vm._v("\n      将文件拖到此处，或"),
                  _c("em", [_vm._v("点击上传")]),
                ]),
          ],
      _vm._v(" "),
      _vm.dialogImageUrl || _vm.fileUrl
        ? _c("ExampleDialog", {
            ref: "exampleDialog",
            attrs: {
              src: _vm.dialogImageUrl,
              fileUrl: _vm.fileUrl,
              isFile: false,
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }