<template>
  <section
    class="right-menu"
    :class="[{ headerContainer: menuType === 1 }, { 'right-menu-homepage': !notHomePage }]"
    id="right-menu"
  >
    <template v-if="Object.keys(loginInfo).length !== 0">
      <el-badge :value="taskTotal" :max="99">
        <div class="task" @click="$router.push('/workbench/list')"><i class="el-icon-s-order"></i>待办</div>
      </el-badge>
      <el-badge :value="messageTotal" :max="99">
        <div class="message" @click="$router.push('/messageCenter/list')"><i class="el-icon-message-solid"></i>消息</div>
      </el-badge>
      <div class="username">
        <img class="iconfont top-svg-icon" src="@/assets/dashboard/icon_head.png" alt="" />您好，{{
          loginInfo && loginInfo.nickname
        }}
      </div>
      <!-- <el-button type="text">
        <svg-icon
          class="iconfont top-svg-icon"
          icon-class="home"
        />
        <a
          href="/homePage"
          target="_blank"
          class="a-style"
        >门户官网</a>
      </el-button> -->
      <div class="logout" @click="logout">
        <svg-icon class="iconfont" icon-class="logout" />
        退出
      </div>
    </template>
    <template v-else>
      <el-button @click="$router.push('/registerPage')" round class="top-right-button">注册</el-button>
      <el-button @click="$router.push('/login')" round class="top-right-button">登录</el-button>
    </template>
  </section>
</template>

<script>
import settings from '@/defaultSettings';
import { mapState, mapMutations } from 'vuex';
import { getToken, getUserInfo } from '@/utils/auth';
import { fetchTaskList } from '@/api/Workflow/workflow';
import { fetchMessageList } from '@/api/messageCenter/index';

export default {
  name: 'TopNavbarRight',

  computed: {
    ...mapState('dashboard', ['messageTotal', 'taskTotal']),
    notHomePage() {
      return this.$router.currentRoute?.meta?.routeType !== 'HomeRoute';
    },
  },

  data() {
    return {
      menuType: settings.menuType,
      loginInfo: getUserInfo(),
    };
  },
  async mounted() {
    if (!getToken()) {
      this.logout();
    }
    if (this.$route.path !== '/workbench/list' && this.$route.path !== '/dashboard') {
      this.getTaskList();
    }
    if (this.$route.path !== '/messageCenter/list') {
      this.getMessageList();
    }
  },

  methods: {
    ...mapMutations('dashboard', ['SET_TASKLIST_TOTAL', 'SET_MESSAGE_TOTAL']),

    // 待办任务
    async getTaskList() {
      const res = await fetchTaskList({ pageNo: 1, pageSize: 5, selectWaitHandle: Date.now() });
      if (res.rspCode === '00000000') {
        this.SET_TASKLIST_TOTAL(res.data?.total);
      }
    },

    // 消息列表
    async getMessageList() {
      const res = await fetchMessageList({ pageNo: 1, pageSize: 10, readFlag: 2 });
      if (res.rspCode === '00000000') {
        this.SET_MESSAGE_TOTAL(res.data?.total);
      }
    },

    logout() {
      this.$store.dispatch('user/LogOut', { noRequest: true });
    },
  },
};
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>

<style lang="scss">
.right-pop-container {
  padding: 0 !important;
}

.a-style {
  color: white;
  text-decoration: none;
}
</style>
