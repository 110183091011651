/** * create by Songsong_Yu on 2021-12-14 18:24 * 类注释： * 备注： */
<template>
  <el-checkbox-group
    :min="item.min"
    :max="item.max"
    v-model="formData[item.prop]"
    size="medium"
    :class="item.class || ''"
  >
    <el-checkbox-button
      v-for="option in options"
      :key="option.value ? option.value : option"
      :label="option.value ? option.value : option"
      :disabled="item.disabled"
      :style="item.style"
      @change="change"
      type="text"
    >
      {{ option.label ? option.label : option }}
    </el-checkbox-button>
  </el-checkbox-group>
</template>

<script type="text/ecmascript-6">
import mixins from '../mixins';

export default {
  components: {},
  mixins: [mixins],
  props: {},
  data() {
    return {};
  },
  computed: {
    options() {
      if (this.item.options instanceof Array) {
        return this.item.options;
      } else {
        let list = this.item.options.split(',');
        return list.map((item) => {
          return { value: item, label: item };
        });
      }
    },
  },
  methods: {
    change() {
      this.mixinEvent({
        type: 'change',
        prop: this.item.prop,
        value: this.formData[this.item.prop],
      });
    },
  },
  activated() {},
  mounted() {},
  created() {
    if (!this.formData[this.item.prop]) {
      this.$set(this.formData, this.item.prop, []);
    }
  },
};
</script>

<style scoped lang="scss">
.space-check-button {
  ::v-deep {
    .el-checkbox-button {
      margin-right: 10px;

      &:first-child {
        .el-checkbox-button__inner {
          border-left: 1px solid $defaultPageBgColor;
        }

        .is-checked {
          .el-checkbox-button__inner {
            border-left: 1px solid $primaryColor;
          }
        }
      }
      &:first-child.is-checked {
        .el-checkbox-button__inner {
          border-left: 1px solid $primaryColor;
        }
      }
      &:hover {
        .el-checkbox-button__inner {
          color: $primaryColor;
          background: $primaryColor_20;
          border-color: transparent;
        }
      }
    }
    .el-checkbox-button__inner {
      padding: 9px 20px;
      border-radius: 4px;
      border: 1px solid $defaultPageBgColor;
      background: $defaultPageBgColor;
      color: $defaultFontColor;
    }
    .el-checkbox-button.is-focus {
      .el-checkbox-button__inner {
        border-radius: 4px;
      }
    }
    .el-checkbox-button.is-checked {
      .el-checkbox-button__inner {
        border-radius: 4px;
        background: transparent;
        color: $primaryColor;
        border-top: 1px solid $primaryColor;
        border-bottom: 1px solid $primaryColor;
        border-right: 1px solid $primaryColor;
      }
    }
  }
}
</style>
